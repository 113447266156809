const AddMark = (mark, colectionId) => {
    var markToAdd = {
        colection_id: colectionId
    }
    if (mark.category === 'patent') {
        markToAdd.id_mongo = mark.id;
        //markToAdd.tag = 'Patente';
        markToAdd.tag = 'patents';
        markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
        markToAdd.addDate = new Date().toISOString();
    } else {
        if (mark.category === 'video') {
            markToAdd.tag = 'video';
            markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
            markToAdd.id_mongo = mark.id;
            markToAdd.addDate = new Date().toISOString();
        } else if (mark.category === 'new') {
            //markToAdd.tag = 'Noticia';
            markToAdd.tag = 'news';
            markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
            markToAdd.addDate = new Date().toISOString();
            //markToAdd.id_mongo = mark._id.$oid;
            markToAdd.id_mongo = mark.id;
        } else if (mark.category === 'event') {
            //markToAdd.tag = 'Evento';
            markToAdd.tag = 'events';
            markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
            markToAdd.id_mongo = mark.id;
            markToAdd.addDate = new Date().toISOString();
        } else if (mark.category === 'startup') {
            //markToAdd.tag = 'Startup';
            markToAdd.tag = 'Startups';
            markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
            markToAdd.id_mongo = mark.id;
            markToAdd.addDate = new Date().toISOString();
        } else if (mark.category === 'convocatoria') {
            //markToAdd.tag = 'convocatorias';
            markToAdd.tag = 'convocations';
            markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
            markToAdd.id_mongo = mark.id;
            markToAdd.addDate = new Date().toISOString();
        } else if (mark.category === 'article') {
            //markToAdd.tag = 'convocatorias';
            markToAdd.tag = 'articles';
            markToAdd.title = Array.isArray(mark.title) ? mark.title[0] : mark.title;
            markToAdd.id_mongo = mark.id;
            markToAdd.addDate = new Date().toISOString();
        } else{
            markToAdd.tag = 'ecuations';
            markToAdd.title = mark;
            markToAdd.id_mongo = 'ecuations';
            markToAdd.addDate = new Date().toISOString();
        }

    }
    return markToAdd;
}
export default AddMark;