import React from 'react'
import Bars from '../../../../../../graphics/Plotly/Bars'
import Bar from '../../../../../../graphics/d3/BarGraphic/Bar'

const VerticalBars = ({ graphic, clickImage }) => {
    const plotlyBarClick = (xAxis) => {
        clickImage(xAxis.points[0].x);
    }
    const d3BarClick = (xAxis) => {
        clickImage(xAxis);
    }

    let comp;
    if (graphic.data.total === 0) {
        comp =
            <>
                <h1>No tenemos resultados para analizar esta busqueda</h1>
            </>
    }
    else if (graphic.name === 'Cantidad por propietario' || graphic.name === 'Sector economico' || graphic.name === 'Cantidad por año') {
        comp =
            <Bars
                xAxis={graphic.data.items.x}
                yAxis={graphic.data.items.y}
                orientation={'v'}
                color={graphic.color}
                title={''}
                barClick={plotlyBarClick}
                name={graphic.name}
            />

    }
    else if (graphic.name === 'Año de aplicación') {
        comp =
            <Bar
                application={graphic.data.Year_application}
                publication={graphic.data.Year_publication}
                width={900}
                height={200}
                top={20}
                bottom={30}
                left={50}
                right={0}
                title={''}
                title1={'Patentes por Año de aplicación'}
                title2={'Patentes por Año de publicación'}
                graphicColor={graphic.color}
                d3BarClick={d3BarClick}
            />

    }
    return (
        <>
            {graphic.type === 'vertical' && comp}
        </>
    );
}

export default VerticalBars;