import React from 'react'
import './loadingTable.scss'
import Skeleton from "react-loading-skeleton";

const LoadingTable = () => {
    return (
        <div className='mainLoadingTable'>

            {
                [...Array(10).keys()].map(i => {
                    return (
                        <div className={`row ${i === 9 ? '' : 'resultTableSkeleton'}`}>
                            <div className="col-12 col-md-7 col-lg-7">
                                <div className="row">
                                    <div className="col-3 col-md-1 col-lg-1">
                                        <Skeleton circle={true} height={25} width={25} />
                                    </div>
                                    <div className="col-3 col-md-1 col-lg-1">
                                        <Skeleton height={30} width={50} />
                                    </div>
                                    <div className="col-3 col-md-10 col-lg-10">
                                        <Skeleton height={10} width={'80%'} />
                                        <Skeleton height={10} width={'75%'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <Skeleton height={10} width={'100%'} />
                                <Skeleton height={10} width={'90%'} />
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pl-30">
                                <Skeleton height={10} width={'95%'} />
                                <Skeleton height={10} width={'97%'} />
                            </div>
                        </div>
                    );
                })
            }

        </div>
    )
}

export default LoadingTable
