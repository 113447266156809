import React, { useState, useRef } from 'react'
import unavailableImage from '../../../../../../../images/global/unavailableImage.png'
import SaveFavouritesDropdown from '../../saveFavouritesDropdown/SaveFavouritesDropdown'
import Order from '../../../../../../../helpers/Order'
import './allTable.scss'

const AllTable = ({ results, viewDescription, id, goToResult }) => {

    const [resultados, setResultados] = useState(results);
    const [ordering, setOrdering] = useState(false);

    const dateProccess = (item) => {
        switch (item.category) {
            case 'event':
                return item.datePublication

            case 'convocatoria':
                return item.apertura[0].substr(0, 10) + item.cierre[0].substr(0, 10)

            default:
                return item.datePublication.toString().substr(0, 10)
        }
    }

    const order = (field) => {
        let orderedResults = Order(field, resultados, ordering)
        setOrdering(orderedResults[0]);
        setResultados(orderedResults[1]);
    }

    return (
        <div className="AllTableMain">
            <div className="resultTable row">
                <div className="col-md-7 col-lg-7"><p>Título <img onClick={() => order('title')} style={{ cursor: 'pointer' }} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p>Fecha <img onClick={() => order('date')} style={{ cursor: 'pointer' }} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p>Fuente <img onClick={() => order('source')} style={{ cursor: 'pointer' }} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-1 col-lg-1"></div>
            </div>
            {(resultados[0].category !== 'empty' && resultados[0].category !== 'fail') ?
                resultados.map(r => {
                    return (<div className="resultTableBody row">
                        <div className="col-md-2 col-lg-2">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 firstColumns pl-0">
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <div className="categoryDiv">
                                            <img onClick={() => goToResult(r)} className="icon" src={r.icon} alt='' />
                                        </div>
                                        <div className="addRepository">
                                            <SaveFavouritesDropdown item={r} />
                                        </div>
                                        <div className="imageToPreviewDiv">
                                            {r.thumbnail ?
                                                <img className="imageToPreview" src={r.imageUrlFail ? unavailableImage : r.thumbnail} alt='' />
                                                :
                                                /*<div className="imageToPreviewEmpty"></div>*/
                                                <img className="imageToPreview" src={unavailableImage} alt='' />
                                            }
                                            <p onClick={() => viewDescription(r)} style={{ color: 'var(--primaryBlueOne)', fontSize: '14px', cursor: 'pointer' }}>ver más</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 col-lg-10 threeLastColumns">
                            <div onClick={() => goToResult(r)} className="row">
                                <div className="title col-md-6 col-lg-6" >
                                    <p style={{ float: 'left' }} className="title">{Array.isArray(r.title) ? r.title[0].substr(0, 35) : r.title.substr(0, 35)}...</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.datePublication && (
                                        dateProccess(r)
                                    )}</p>

                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <p style={{marginBottom:'0px !important'}}>{ r.category=== 'new' ? `${r.source[0].substr(0,20)} 
                                     ${r.source[0].substr(20, r.source[0].length)}`: r.source}</p>
                                </div>
                            </div>
                        </div>
                        {(id === r.id) &&
                            <div className="view">
                                <p style={{ textAlign: 'left' }}>{Array.isArray(r.description) ? r.description[0].substr(0, 300) : r.description.substr(0, 300)}...
                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => goToResult(r)}>ver todo</span>
                                </p>
                            </div>
                        }
                    </div>)

                }) :
                <div>no se encontraron resultados</div>
            }
        </div>
    )
}

export default AllTable
