import React,{ useState, useEffect } from 'react'
import clienteAxios from '../../config/axios'

const Company = ({setCompanySignup}) => {

    //STATES    
    const [workspaces,setWorkSpaces] = useState([]);//state para los workspace de la BD
    const [workspaceSelected,setWorkSpaceSelected] = useState({
        name:'',
        key:''
    });//state para la confirmacion de la key del la empresa
    //state para el formulario    
   const [error,setError] = useState('');

    useEffect(() => {
        workSpaceAxios();      
    }, [])

    //FUNCIONES

    //funcion para llamar los workspace del signup
    const workSpaceAxios = async () => {
        //endpoint de la bd
        const response = await clienteAxios.get('SignUp');
        setWorkSpaces(response.data);
    };

    //funcion para agregar al state el workspace
    const onWorkChange = e =>{
        setWorkSpaceSelected({
            ...workspaceSelected,
            [e.target.name] : e.target.value
        })
    }

    //valida en el backend que la clave secreta corresponda con la del usuario
    const WorkspaceValidate = async() => {

        if(workspaceSelected.name.trim() === ''){
            //error al usuario nombre invalido
        }else if(workspaceSelected.key === ''){
            //error al usuario del clave vacia            
        }else{
            /**devuelve 0:clave invalida, 1:coincide clave */
            const response = await clienteAxios.post('signup/workspace/validate',workspaceSelected);
            if(response.data){
                setError('');
                setCompanySignup(false);
            }else{
                setError('Clave invalida');
            }
        }
    }

    return ( 
        <>
            <div className="inputWorkspace">
                
                <label htmlFor="slWorkspaceName">Empresa:</label>
                <select 
                    name="name" 
                    onChange={onWorkChange}
                    defaultValue={'DEFAULT'}
                >
                    <option value="DEFAULT" disabled>Selecciona tu empresa</option>
                    {workspaces.map((workpace,index) => (
                        <option key={index} >{workpace.name}</option>
                    ))}
                </select>
                <label htmlFor="keyWorkspace">Clave secreta:</label>
                <input 
                    type="text" 
                    id="keyWorkspace" 
                    name="key"
                    value={workspaceSelected.key}
                    onChange={onWorkChange}
                    disabled=""/>
                <button 
                    className="untBtn darkblue" 
                    onClick={WorkspaceValidate}
                >Validar</button>
                <h1>{error}</h1>
            </div>
        </>
    );
}
 
export default Company;