import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import './header.scss'
import { setGraphicAction } from '../../../../../../actions/graphicAction'
/**Imagenes*/
import magic from '../../../../../../images/icons/modules/graphics/magic.png'
import pie from '../../../../../../images/icons/modules/graphics/pie.png'
import hbars from '../../../../../../images/icons/modules/graphics/hbars.png'
import vbars from '../../../../../../images/icons/modules/graphics/vbars.png'
import linear from '../../../../../../images/icons/modules/graphics/linear.png'
import maps from '../../../../../../images/icons/modules/graphics/maps.png'
import tree from '../../../../../../images/icons/modules/graphics/tree.png'
import pieSelected from '../../../../../../images/icons/modules/graphics/pieSelected.png'
import hbarsSelected from '../../../../../../images/icons/modules/graphics/hbarsSelected.png'
import vbarsSelected from '../../../../../../images/icons/modules/graphics/vbarsSelected.png'
import linearSelected from '../../../../../../images/icons/modules/graphics/linearSelected.png'
import mapsSelected from '../../../../../../images/icons/modules/graphics/mapsSelected.png'
import treeSelected from '../../../../../../images/icons/modules/graphics/treeSelected.png'
/**filtros*/
import PieFilter from './filters/PieFilter'
import HbarsFilter from './filters/HbarsFilter'
import LinearFilter from './filters/LinearFilter'
import MapsFilter from './filters/MapsFilter'
import TreeFilter from './filters/TreeFilter'
import VbarsFilter from './filters/VbarsFilter'

const GraphicalHeader = () => {

    //states
    const graphicSelected = useSelector(state => state.graphic.graphicSelected);

    //redux
    const dispatch = useDispatch();

    //funciones
    const graphicClick = (graphic) => {
        graphicSelected === graphic 
            ? dispatch(setGraphicAction('')) 
            : dispatch(setGraphicAction(graphic))
    }
    
    return ( 
        <>
            <div className='lantia-square row w-100 graphical-header'>
                <div className="col-2 row">
                    <img className='graph-header' style={{marginRight:'1px'}} src={magic} alt=""/>
                    <div className='magic-header'>
                        <h3>Automático</h3>
                    </div>
                </div>
                <div className="col-10 row justify-content-center">                
                    {/* <img className='graph-header' onClick={() => graphicClick('pieSelected')} src={graphicSelected === 'pieSelected' ? pieSelected : pie} alt=""/>             */}
                     <img className='graph-header' onClick={() => graphicClick('hbarsSelected')} src={graphicSelected === 'hbarsSelected' ? hbarsSelected : hbars} alt=""/>   
                   {/* <img className='graph-header' onClick={() => graphicClick('vbarsSelected')} src={graphicSelected === 'vbarsSelected' ? vbarsSelected : vbars} alt=""/>*/}
                    <img className='graph-header' onClick={() => graphicClick('linearSelected')} src={graphicSelected === 'linearSelected' ? linearSelected : linear} alt=""/> 
                    <img className='graph-header' onClick={() => graphicClick('mapsSelected')} src={graphicSelected === 'mapsSelected' ? mapsSelected : maps} alt=""/>
                    <img className='graph-header' onClick={() => graphicClick('treeSelected')} src={graphicSelected === 'treeSelected' ? treeSelected : tree} alt=""/>           
                </div>
                {graphicSelected === 'pieSelected' && <PieFilter />}
                {graphicSelected === 'hbarsSelected' && <HbarsFilter />}
                {graphicSelected === 'vbarsSelected' && <VbarsFilter />}
                {graphicSelected === 'linearSelected' && <LinearFilter />}
                {graphicSelected === 'mapsSelected' && <MapsFilter />}
                {graphicSelected === 'treeSelected' && <TreeFilter />}
            </div>
        </>
     );
}
 
export default GraphicalHeader;