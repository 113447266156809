import React from 'react'
import Skeleton from "react-loading-skeleton";
import './article.scss'

const ArticleTemplate = () => {
    return (
        <div className='articleTemplateMain row'>
            {[...Array(3).keys()].map(i => {
                return (
                    <div className={`article ${i === 2 ? '' : 'box'}`}>
                        <Skeleton height={10} width={'50%'} />
                        <Skeleton height={150} width={'90%'} />
                        <Skeleton height={10} width={'95%'} />
                        <Skeleton height={10} width={'91%'} />
                    </div>
                )
            })}
        </div>
    )
}

export default ArticleTemplate
