import React, { useState, useRef } from 'react'
import { setParamsOfSearch, typeOfSearch } from '../../../../actions/homeAction';
import { addColectionAction, addMarkAction } from '../../../../actions/markAction'
import { useDispatch, useSelector } from 'react-redux';
import { MIS_ECUACIONES } from '../../../../globalConstants/reservedColection'
import { ASSISTED_SEARCH } from '../../../../globalConstants/typeOfSearch'
import AddMark from '../../../../helpers/AddMark';
import './assistedSearch.scss'

const AssistedSearch = () => {

    //redux
    const dispatch = useDispatch();
    const colections = useSelector(state => state.mark.colections);

    //imput states
    const [addWordInput, setAddWordInput] = useState('')
    const [addWordArray, setAddWordArray] = useState([])
    const [emptyParams, setEmptyParams] = useState(false);
    const [params, setParams] = useState('')
    const saveEcuation = useRef(false)

    const [delWordInput, setDelWordInput] = useState('')
    const [delWordArray, setDelWordArray] = useState([])

    //funciones para indispensables
    const addWordAdd = () => {
        saveEcuation.current = true;
        var ArrayToSpread = addWordInput.split(',');
        var finalArray = addWordArray;

        for (let value of ArrayToSpread) {
            finalArray.push(value)
        }
        setAddWordArray(finalArray);
        setAddWordInput('');

        if (params === '') {
            setParams(ArrayToSpread);
            return;
        }

        setParams(`${params} AND  ${ArrayToSpread}`);

    }

    const delOneAddWord = (word) => {
        var finalArray = addWordArray;
        var final = [];
        for (var i = 0; i < finalArray.length; i++) {
            if (finalArray[i] !== word) {
                final.push(finalArray[i]);
            }
        }
        setAddWordArray(final);
    }

    //funciones para excluidas
    const addWordDel = () => {
        saveEcuation.current = true;
        var ArrayToSpread = delWordInput.split(',');
        var finalArray = delWordArray;

        for (let value of ArrayToSpread) {
            finalArray.push(value)
        }
        setDelWordArray(finalArray);
        setDelWordInput('');

        if (params === '') {
            setParams(`NOT(${ArrayToSpread})`);
            return;
        }

        setParams(`${params} NOT(${ArrayToSpread})`)
    }

    const delOneDelWord = (word) => {
        var finalArray = delWordArray;
        var final = [];
        for (var i = 0; i < finalArray.length; i++) {
            if (finalArray[i] !== word) {
                final.push(finalArray[i]);
            }
        }
        setDelWordArray(final);
    }

    const search = () => {
        setEmptyParams(false);
        let query = 'and='
        addWordArray.map(word => {
            query = query + word + ','
        })

        query = query + '&not='
        delWordArray.map(word => {
            query = query + word + ','
        })

        if (addWordArray.length === 0) {
            setEmptyParams(true);
            return;
        }

        dispatch(setParamsOfSearch(query));
        dispatch(typeOfSearch(ASSISTED_SEARCH));
    }

    const keepEcuation = () => {
        let existColection = colections.find(mark => {
            return mark.name === MIS_ECUACIONES;
        });

        if (existColection === undefined) {
            dispatch(addColectionAction(MIS_ECUACIONES)).then(idCollection => {
                if (idCollection !== 0) {
                    dispatch(addMarkAction(AddMark(params, idCollection)));
                    saveEcuation.current = false;
                }
            })
        } else {
            dispatch(addMarkAction(AddMark(params, existColection.id)));
            saveEcuation.current = false;
        }
    }

    return (
        <div className='assistedSearch lantia-square row searchBoxPanel'>
            <div style={{ paddingRight: '2px' }} className="col-8">
                <h3 className="sizeLetter">Digita las palabras que sean indispensables para los resultados, separadas por
                comas ( , ). Escribe también las palabras que quieras excluir de la búsqueda.
                </h3>
                <div className="row">
                    <div style={{ paddingLeft: '0px' }} className="col-8">
                        <input value={addWordInput} onChange={(e) => setAddWordInput(e.target.value)} type="text" class="form-control" />
                    </div>
                    <div style={{ paddingRight: '8px' }} className="col-4">
                        <button onClick={addWordAdd} className='btn btn-success btn-block'>ADICIONAR</button>
                    </div>
                </div>
                <div className="row my-2">
                    <div style={{ paddingLeft: '0px' }} className="col-8">
                        <input value={delWordInput} onChange={(e) => setDelWordInput(e.target.value)} type="text" class="form-control" />
                    </div>
                    <div style={{ paddingRight: '8px' }} className="col-4">
                        <button onClick={addWordDel} style={{ color: 'white' }} className='btn btn-warning btn-block'>EXCLUIR</button>
                    </div>
                </div>
                <div className="row">
                    <div style={{ paddingLeft: '0px' }} className="col-8">
                        {/* <h3 className='text-center pt-2'>Terminado esto, haz clic en buscar.</h3> */}
                        <div className="row">
                            {params !=='' &&
                            <div className="col-12 col-md-9 ecuation">
                                <h3 style={{ color: 'var(--primaryBlueFour)' }} className='text-center pt-2 sizeLetter'>{params}</h3>
                            </div>
                            }
                            <div className="col-12 col-md-3">
                                {saveEcuation.current &&
                                    <div onClick={keepEcuation} className="saveEcuationButton">Guardar ecuación</div>
                                }
                            </div>
                        </div>
                        {emptyParams &&
                            <h3 style={{ color: 'var(--secundaryOne)' }} className='text-center pt-2 sizeLetter'>Debes ingresar parametros de búsqueda.</h3>
                        }
                    </div>
                    <div style={{ paddingRight: '8px' }} className="col-4">
                        <button onClick={search} className='btn btn-primary btn-block'>BUSCAR</button>
                    </div>
                </div>
            </div>
            <div className="col-4 right-panel">
                <h3 className='text-center sizeLetter'>Términos de búsqueda</h3>
                <div className="row">
                    <div style={{ padding: '0px 1px 0px 0px' }} className="col-6">
                        <div className='lantia-square assisted-add'>
                            <h3 className='add-title sizeLetter'>Indispensables</h3>
                            <div className="assisted-addWithScroll">
                                {addWordArray.map((word) => (
                                <div className="row one-row">
                                    <h3 className='one-add-word sizeLetter'>{word}</h3>
                                    <h3 onClick={() => delOneAddWord(word)} style={{ cursor: 'pointer' }}>x</h3>
                                </div>
                            ))}
                            </div>
                            
                        </div>
                    </div>
                    <div style={{ padding: '0px 0px 0px 1px' }} className="col-6">
                        <div className='lantia-square assisted-delete'>
                            <h3 className='delete-title sizeLetter'>Excluídas</h3>
                            <div style={{height:'7rem'}}className='assisted-addWithScroll'>
                                 {delWordArray.map((word) => (
                                <div className="row one-row">
                                    <h3 className='one-del-word sizeLetter'>{word}</h3>
                                    <h3 onClick={() => delOneDelWord(word)} style={{ cursor: 'pointer' }}>x</h3>
                                </div>
                            ))}
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AssistedSearch;