import React from 'react'
import processData from '../../../../../../../hooks/useProccessData'
import SaveFavouritesDropdown from '../../saveFavouritesDropdown/SaveFavouritesDropdown'
import { useDispatch, useSelector } from 'react-redux';
import { setParamsOfSearch } from '../../../../../../../actions/homeAction';
import { NORMAL_SEARCH, ADVANCED_SEARCH, ASSISTED_SEARCH } from '../../../../../../../globalConstants/typeOfSearch';
import './articles.scss'

const ArticlesTable = ({ results, viewDescription, id, goToResult }) => {

    const dispatch = useDispatch();
    const typeOfSearch = useSelector(state => state.home.typeOfSearch);

    const order = (filter) => {
        let params = `filter=${filter}&sort=1`

        if (typeOfSearch === NORMAL_SEARCH || ASSISTED_SEARCH) {
            dispatch(setParamsOfSearch(params))
        }
        if (typeOfSearch === ADVANCED_SEARCH) {
            //dispatch(getSearchAdvanced(currentPage, paramsOfAdvancedSearch, end))
        }
    }

    return (
        <div className='articlesTableMain'>
            <div className="resultTable row">
                <div className="col-md-7 col-lg-7"><p className='firtsChild'>Articulo <img onClick={() => order('titulo')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p>Fecha <img onClick={() => order('date')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p>Fuente <img onClick={() => order('source')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
                <div className="col-md-1 col-lg-1"></div>
            </div>
            {(results[0].category !== 'empty' && results.length !== 0) ?
                processData(results).map(r => {
                    return (<div className="resultTableBody row">
                        <div className="col-md-2 col-lg-2">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 firstColumns pl-0">
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <div className="categoryDiv">
                                            <img onClick={() => goToResult(r)} className="icon" src={r.icon} alt='' />
                                        </div>
                                        <div className="addRepository">
                                            <SaveFavouritesDropdown item={r} />
                                        </div>
                                        {r.thumbnail !== '' ?
                                            <div className="imageToPreviewDiv">
                                                <img className="imageToPreview" src={r.thumbnail} alt='' />
                                                <p onClick={() => viewDescription(r)} style={{ color: 'var(--primaryBlueOne)', fontSize: '14px', cursor: 'pointer' }}>ver más</p>
                                            </div>
                                            :
                                            <div style={{ paddingTop: '2rem' }}>
                                                {/* <div className='imageToPreviewDivEmpty'>

                                                </div> */}
                                                <p onClick={() => viewDescription(r)} style={{ color: 'var(--primaryBlueOne)', fontSize: '14px', cursor: 'pointer' }}>ver más</p>
                                            </div>

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 col-lg-10 threeLastColumns">
                            <div onClick={() => goToResult(r)} className="row">
                                <div className="title col-md-6 col-lg-6" >
                                    <p style={{ float: 'left' }} className="title">{Array.isArray(r.title) ? r.title[0].substr(0, 60) : r.title.substr(0, 60)}...</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{Array.isArray(r.datePublication) ?
                                        (r.datePublication[0] !== '' ? r.datePublication[0].substr(0, 10) : '') :
                                        r.datePublication !== '' ? r.datePublication.substr(0, 10) : ''}</p>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    {r.hasOwnProperty('sorce') &&
                                        <p>{r.source.substr(0, 40)}...</p>
                                    }
                                </div>
                            </div>
                        </div>
                        {(id === r.id) &&
                            <div className="view">
                                <p style={{ textAlign: 'left' }}>{r.description.substr(0, 400)}...
                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => goToResult(r)}>ver más</span>
                                </p>
                            </div>
                        }

                    </div>)

                }) :
                <div>No se encontraron resultados</div>
            }
        </div>
    )
}

export default ArticlesTable

