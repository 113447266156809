import {
    GET_COMPANIES,
    SET_SEARCH,   
} from '../types/companyTypes';

//funcion para traer empresas de la bd
export function getCompaniesAction(page){    
    return async(dispatch,getState) => {
        try {      
                //variable de filtros aplicados del usuario
                //const filter = getState().patent.filterEndpoint;

                //variables para los dos casos(1.busqueda avanzada, 2.cualquier otro llamado)
                let endpoint = '';
                    
                const search = getState().patent.search;
                               
                //endpoint =`empresas?q=${search}&start=${page}`+filter;
                //const response = await clienteAxios.get(endpoint); 

                var response = {};
                response.items = [{   
                    id:1,
                    name:'Wetpaint',
                    homepage_url:'http://www.wetpaint.com',
                    logo_url:'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/v1397180177/2036b3394a37152e0ff69f27c71bc883.jpg',
                    facebook_url:'https://www.facebook.com/Wetpaint',
                    country_code:'USA',
                    region:'New York',
                    city:'New York',
                    short_description:'Wetpaint offers an online social publishing platform that helps digital publishers grow their customer base.'
                },
                {   
                    id:2,
                    name:'eBay',
                    homepage_url:'http://ebay.com',
                    logo_url:'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/kx4zcwarburlmyywxgmi',
                    facebook_url:'http://www.facebook.com/eBay',
                    country_code:'USA',
                    region:'California',
                    city:'San Jose',
                    short_description:'eBay is an online marketplace that connects a global network of buyers and sellers.'
                },
                {   
                    id:3,
                    name:'Cisco',
                    homepage_url:'http://www.cisco.com',
                    logo_url:'https://crunchbase-production-res.cloudinary.com/image/upload/c_lpad,h_120,w_120,f_jpg/w0jp4bmv89nqrd83keix',
                    facebook_url:'http://www.facebook.com/Cisco',
                    country_code:'USA',
                    region:'California',
                    city:'San Jose',
                    short_description:'Cisco develops, manufactures, and sells networking hardware, telecommunications equipment, and other high-technology services and products.'
                }]

                //agrega pagina actual
                response.page = page;

                //Guarda en redux
                dispatch(getCompnies(response));
            
            return 1;
        } catch (error) {

            return 0;
        }
    }
}


////////***********************************FUNCIONES DE BUSQUEDA***********************************////////////////

//funcion para cambiar el search del store de redux
export function setSearchAction(search,direction){    
    return async(dispatch) => {
        try {
            //cambia el search de la bd
            dispatch(setSearh(search));

            //click = usuario dio click en buscar, keyboard = usuario cambio una letra 
            if(direction === 'click'){       
                //hace la nueva busquda en el endpoint
                await dispatch(getCompaniesAction(0));
            }                        
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

////////***********************************FUNCIONES DEL REDUCER***********************************////////////////

//carga empresas
const getCompnies = companies => ({
    type:GET_COMPANIES,
    payload:companies
});

//***** BUSQUEDA ******/
//cambia el search
const setSearh = search => ({
    type:SET_SEARCH,
    payload:search
});