import React, { useState, useEffect } from 'react';
import SearchBox from '../../ui/searchBox/SearchBox'
import ResultBox from './resultBox/ResultBox';
import MyInformation from './myInformation'
import News from './news'
import Patents from './patents'
import Articles from './articles'
import Startups from './startups/Startups'
import Announcement from './announcement/Announcement'
import Courses from './courses'
import History from './history'
import Videos from './videos'
import Tweets from './tweets'
import Events from './events'
import './homeApp.scss'
import axios from '../../config/axios'
import axiosNormal from 'axios'
import clienteAxios from 'axios'
import LoadingTable from '../../ui/skeletonTemplates/skeletonLoadingTable/LoadingTable'
import Section from '../../ui/skeletonTemplates/sectionTemplate/Section'
import ArticleTemplate from '../../ui/skeletonTemplates/articleTemplate/ArticleTemplate'
import ColorTitle from '../../ui/styles/ColorTitle'
import processData from '../../../hooks/useProccessData'
import Grouplac from '../grouplac/Grouplac';
import CvLac from '../cvlac/CvLac';
import cookie from 'react-cookies'

//redux
import { useSelector } from 'react-redux'

const HomeApp = () => {

    //constantes redux
    const search = useSelector(state => state.home.search);
    const paramsOfSearch = useSelector(state => state.home.paramsOfSearch);
    const typeOfSearch = useSelector(state => state.home.typeOfSearch);
    const auth = useSelector(state => state.auth);
    console.log('auth', auth);

    //states
    const [lastWords, setLastWords] = useState(null);
    const [news, setNews] = useState(null);
    const [patents, setPatents] = useState(null);
    const [videos, setVideos] = useState(null);
    const [tweets, setTweets] = useState(null);
    const [events, setEvents] = useState(null);
    const [startups, setStartups] = useState(null);
    const [announcement, setAnnouncement] = useState(null);
    const [articles, setArticles] = useState(null);
    const [cvlac, setcvlac] = useState('');
    const [grouplac, setgrouplac] = useState('');
    const [key, setkey] = useState('')


    useEffect(() => {
        getDatas();
    }, [])

    const getDatas = async () => {

        try {
            var keyWords = await axios.get(`user_interest`);
            console.log('user_interest', keyWords);
            setkey(keyWords.data)
            keyWords = keyWords.data;
            getNews(keyWords);
            getPatents(keyWords);
            getLastWords();
            getVideos(keyWords);
            getTweets(keyWords);
            getEvents(keyWords);
            getArticles(keyWords);
            getStartups(keyWords);
            getAnnouncement(keyWords);
            getGroupLac(keyWords);
            getCvLac(keyWords);
        } catch (error) {

        }

    }

    //llamado de endpoints
    const getLastWords = async () => {
        try {
            axios.get('./lastwords').then(function (response) {
                try {
                    response.data.items.length > 0 && setLastWords(response.data.items.reverse());
                } catch (error) {

                }
            })
        } catch (error) { }
    }
    const getNews = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=noticias_homepage&intereses=${keyWords}`);
            let responseProccessed = []
            for (var i = 0; i < response.data.items.length; i++) {
                try {
                    let size = await imageProcess(response.data.items[i].thumbnail[0])
                    response.data.items[i].imageWidth = size.width;
                    response.data.items[i].imageHeight = size.height;
                    response.data.items[i].category = 'new';
                    responseProccessed.push(response.data.items[i])
                    if (responseProccessed.length === response.data.items.length) {

                        setNews(processData(responseProccessed));
                    }
                }
                catch (e) {
                    setNews(processData(responseProccessed));
                }
            }
        } catch (error) {
        }
    }
    const getPatents = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patentes_homepage&intereses=${keyWords}`);
            response.data.items = response.data.items.map(item => {

                item.category = 'patent';
                return item;
            })
            setPatents(processData(response.data.items));
        } catch (error) { }
    }
    const getVideos = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=videos_homepage&intereses=${keyWords}`);
            response.data.items = response.data.items.map(item => {
                item.category = 'video';
                return item;
            })
            setVideos(processData(response.data.items));
        } catch (error) { }
    }
    const getTweets = async (keyWords) => {
        try {

            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=tweets&q=${keyWords}`);
            console.log('tweets response', response);
            setTweets(response.data)

        } catch (error) {
        }
    }
    const getEvents = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=eventos_homepage&intereses=${keyWords}`);
            response.data.items = response.data.items.map(item => {

                item.category = 'event';
                return item;
            })
            setEvents(processData(response.data.items));
        } catch (error) { }
    }
    const getArticles = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=articulos_homepage&intereses=${keyWords}`);
            response.data.items = response.data.items.map(item => {
                item.category = 'article';
                return item;
            })
            setArticles(processData(response.data.items));
        } catch (error) { }
    }

    const getStartups = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=startups_homepage&intereses=${keyWords}`);
            response.data.items = response.data.items.items.map(item => {
                item.category = 'startup';
                return item;
            })
            setStartups(processData(response.data.items));
        } catch (error) {

        }
    }

    function dateCompare(d1, d2) {
        const date1 = new Date(d1);
        const date2 = new Date(d2);

        if (date1 > date2) {
            return 'Abierto';
        } else if (date1 < date2) {
            return 'Cerrado';

        } else {
        }
    }

    const getAnnouncement = async (keyWords) => {
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=convocatorias&q=${'innpulsa'}&start=0&rows=30`);
            console.log('getAnnouncement response', response);
            let filterAnnouncement = [];
            const today = new Date();
            response.data.items = response.data.items.map(item => {
                console.log('item----->', item);
                item.category = 'convocatoria';
                // if (item.hasOwnProperty('cierre')) {
                //     if (dateCompare(item.cierre[0].substr(0, 10), today) === 'Abierto') {
                //         filterAnnouncement.push(item)
                //     }
                // }

                filterAnnouncement.push(item)

            })
            console.log('filterAnnouncement', filterAnnouncement);
            setAnnouncement(processData(filterAnnouncement));
        } catch (error) {
            console.log('EER', error);
        }
    }

    const getGroupLac = async (keyWords) => {
        try {
            /*var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=gp_lac&start=0&rows=10&filter=date&sort=1&q=${'(%20*22protectionism%20*22%20OR%20*22%20self%20consumption%20*22%20OR%20*22%20regionalization%20*22%20OR%20*22%20imposition%20of%20tariffs%20*22%20OR%20*22%20techonomic%20war%20*22%20OR%20*22%20technologyical%20war*22%20)%20NOT%20(%20*22patient%20*22%20OR%20*22%20pollution%20*22%20OR%20*22%20cancer%20*22%20OR%20*22%20diagnosis%20*22%20OR%20*22%20health%20*22%20OR%20*22%20medicine%20*22%20OR%20*22%20heart%20*22%20OR%20*22%20gen%20*22%20OR%20*22%20genetic*22%20)'
                }`);*/

            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=gp_lac&start=0&rows=10&filter=date&sort=1&q=${'*'}`);

            //var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=gp_lac&q=${keyWords}&start=0&rows=30`);

            setgrouplac(response.data);
        } catch (error) {
        }
    }


    const getCvLac = async (keyWords) => {
        try {
            /*var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=cv_lac&start=0&rows=10&filter=date&sort=1&q=${'(%20*22protectionism%20*22%20OR%20*22%20self%20consumption%20*22%20OR%20*22%20regionalization%20*22%20OR%20*22%20imposition%20of%20tariffs%20*22%20OR%20*22%20techonomic%20war%20*22%20OR%20*22%20technologyical%20war*22%20)%20NOT%20(%20*22patient%20*22%20OR%20*22%20pollution%20*22%20OR%20*22%20cancer%20*22%20OR%20*22%20diagnosis%20*22%20OR%20*22%20health%20*22%20OR%20*22%20medicine%20*22%20OR%20*22%20heart%20*22%20OR%20*22%20gen%20*22%20OR%20*22%20genetic*22%20)'
                }`);*/

            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=cv_lac&start=0&rows=10&filter=date&sort=1&q=${'*'}`);
            setcvlac(response.data);
        } catch (error) {
        }
    }


    const imageProcess = (src) => {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = src
        }).then(values => {

            if (values.width > 200) {
                return { width: '100%', height: '144px' };
            }

            return { width: values.width.toString() + 'px', height: values.height.toString() + 'px' }

        })
    }

    const go = (place) => {
        if (place === 'obs') {
            const token = cookie.load('token');
            window.location.href = `http://observatorioeconomico.lantiasas.com/login/${token}`;
            return null;
        }
        if (place === 'tnd') {
            const token = cookie.load('token');
            window.location.href = `http://tendencias.lantiasas.com/login/${token}`;
            return null;
        }
        if (place === 'mapaInd') {
            const token = cookie.load('token');
            window.location.href = `http://lantiav1.lantiasas.com/EconomicMap`;
            return null;
        }
        if (place === 'mapaOp') {
            const token = cookie.load('token');
            window.location.href = `http://lantiav1.lantiasas.com/Synapse`;
            return null;
        }
    }

    return (
        <div className='homeApp'>
            <div className='mt-4 '>
                <SearchBox />
            </div>
            <div style={{ padding: '3%' }} className='text-center homeContainer'>
                {typeOfSearch === '' ?
                    <div>
                        <div className='row align-items-center justify-content-center'>
                            <div onClick={() => go('tnd')} className='idx_btn_datos' id='idx_btn_tendencias'></div>
                            <div onClick={() => go('obs')} className='idx_btn_datos' id='idx_btn_economico'></div>
                            {/* {auth.userId !== 5785 &&
                                <div style={{ display: 'inline-flex' }}>
                                    <div onClick={() => go('mapaInd')} className='idx_btn_datos' id='idx_btn_mapaInd'></div>
                                    <div onClick={() => go('mapaOp')} className='idx_btn_datos' id='idx_btn_mapaOp'></div>
                                </div>
                            } */}



                        </div>
                        <MyInformation />
                        <div className="row mt-4">
                            <div style={{ padding: '0' }} className="col-8">
                                <div style={{ marginLeft: '0' }}>
                                    <ColorTitle
                                        color={'--secundarySix'}
                                        icon={'articlesNocircle.png'}
                                        name={'Artículos'}
                                        width={20}
                                        height={25}
                                    />
                                    {
                                        articles !== null ?
                                            <Articles
                                                keyWords={key}
                                                articles={articles}
                                            />
                                            :
                                            <div className='lantia-square pt-4'>
                                                <LoadingTable />
                                            </div>

                                    }
                                    <br />
                                    <ColorTitle
                                        color={'--secundaryFour'}
                                        icon={'patentsNocircle.png'}
                                        name={'Patentes'}
                                        width={20}
                                        height={25}
                                    />
                                    {
                                        patents !== null ?
                                            <Patents
                                                patents={patents}
                                            /> :
                                            <div className='lantia-square pt-4'>
                                                <LoadingTable />
                                            </div>
                                    }
                                    <br />
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-12">
                                            <ColorTitle
                                                color={'--secundarySeven'}
                                                icon={'newsNocircle.png'}
                                                name={'Noticias'}
                                                width={20}
                                                height={20}
                                            />
                                            {news !== null ?
                                                <News
                                                    news={news}
                                                /> :
                                                <div>
                                                    <div style={{ height: '29rem' }} className='lantia-square '>
                                                        <ArticleTemplate />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <br />
                                    <div className="row ">
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <ColorTitle
                                                color={'--primaryBlueOne'}
                                                icon={'lupa.png'}
                                                name={'Grupos de investigación'}
                                                width={20}
                                                height={20}
                                            />
                                            <div className="redes">
                                                <Grouplac grouplac={grouplac} />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6">
                                            <ColorTitle
                                                color={'--primaryGreyThree'}
                                                icon={'man.png'}
                                                name={'Investigadores'}
                                                width={20}
                                                height={20}
                                            />
                                            <div className="redes">
                                                <CvLac cvlac={cvlac} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '0' }} className="col-4">
                                <div style={{ marginLeft: '25px' }}>
                                    <ColorTitle
                                        color={'--primaryGreyFour'}
                                        icon={'historyNocircle.png'}
                                        name={'Historial'}
                                        width={25}
                                        height={25}
                                    />
                                    {lastWords !== null ?
                                        <History
                                            lastWords={lastWords}
                                        /> :
                                        <div className='lantia-square pt-4' style={{ height: '300px' }}>
                                            <Section />
                                        </div>

                                    }

                                    {/* <Alarms /> */}
                                    <br />
                                    <ColorTitle
                                        color={'--secundaryEight'}
                                        icon={'videosNocircle.png'}
                                        name={'Videos'}
                                        width={25}
                                        height={25}
                                    />
                                    {videos !== null ?
                                        <Videos
                                            videos={videos}
                                        />
                                        /*  <div></div>*/
                                        :
                                        <div className='lantia-square pt-4'>
                                            <Section />
                                        </div>
                                    }
                                    <br />
                                    <ColorTitle
                                        color={'--primaryBlueOne'}
                                        icon={'tweet.png'}
                                        name={'Tweets'}
                                        width={25}
                                        height={25}
                                    />
                                    {tweets !== null ?
                                        // <Tweets
                                        //     tweets={tweets}
                                        // />
                                        <div></div>
                                        :
                                        <div className='lantia-square pt-4' style={{ height: '300px' }}>
                                            <Section />
                                        </div>
                                    }
                                    <br />
                                    <ColorTitle
                                        color={'--primaryBlueTwo'}
                                        icon={'startUpsNocircle.png'}
                                        name={'Startups'}
                                        width={25}
                                        height={25}
                                    />
                                    {startups !== null ?
                                        <div style={{ marginTop: '0px !important' }}>
                                            <div style={{ padding: '0' }} className="col-12">
                                                <Startups startups={startups} />
                                            </div>
                                        </div>
                                        :
                                        <Section />
                                    }
                                    <br />
                                    <ColorTitle
                                        color={'--primaryBlueTwo'}
                                        icon={'convocatoriasNocircle.png'}
                                        name={'Convocatorias'}
                                        width={25}
                                        height={25}
                                    />
                                    {announcement !== null ?
                                        <div style={{ margin: '0px 15px 15px 15px', width: '100%' }}>
                                            <div style={{ padding: '0' }} className="col-12 col-md-12 col-lg-12">
                                                <Announcement announcement={announcement} />
                                            </div>
                                        </div>
                                        :
                                        <ArticleTemplate />
                                    }
                                    <div style={{ height: '12px' }}></div>
                                    <ColorTitle
                                        color={'--secundaryNine'}
                                        icon={'eventsNocircle.png'}
                                        name={'Eventos'}
                                        width={20}
                                        height={20}
                                    />
                                    {events !== null ?
                                        <Events
                                            events={events}
                                        /> :
                                        <div className='lantia-square'>
                                            <Section />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* <div className="row mt-3">
                            <div style={{ padding: '0' }} className="col-12">
                                <Courses />
                            </div>
                        </div> */}





                        {/* <Recommendations /> */}
                        {/* <LastSearches /> */}
                        {/* <Information />  */}
                    </div>
                    :
                    <ResultBox />
                }
            </div>
        </div>
    );
}

export default HomeApp;
