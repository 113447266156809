import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import {expertAddNew} from '../../../../actions/expertsAction';
import { useHistory } from 'react-router-dom';

const ExpertForm = () => {

    const amIexpert = useSelector(state => state.experts.amIexpert);
    const hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00",
        "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00",
        "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"];



    const [formValues, setformValues] = useState({
        user_id: useSelector(state => state.auth.userId),
        id: amIexpert === null || amIexpert ==='' ? '' : amIexpert.id,
        headline: amIexpert === null || amIexpert ==='' ? '' : amIexpert.headline,
        specialties: amIexpert === null || amIexpert ==='' ? '' : amIexpert.specialties,
        education: amIexpert === null || amIexpert ==='' ? '' : amIexpert.education,
        disponibilityFrom: amIexpert === null || amIexpert ==='' ? '' : amIexpert.disponibility.substr(0, 5),
        disponibilityTo: amIexpert === null || amIexpert ==='' ? '' : amIexpert.disponibility.substr(6, 5)
    })
    const [error, setError] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const handleInputChange = (event) => {
        setformValues({
            ...formValues,
            [event.target.name]: event.target.value
        })
    }

    const sendData = (event) => {
        event.preventDefault();
        let internalError = '';
        if(formValues.disponibilityFrom === '' || formValues.disponibilityFrom === undefined) {
            internalError = 'La hora inicial es requerida, ';
        }
        if(formValues.disponibilityTo === '' || formValues.disponibilityTo === undefined) {
            internalError = 'La hora final es requerida, ';
        }
        if(formValues.headline === '' || formValues.headline === undefined) {
            internalError = 'El resumen es requerido, ';
        }
        if(formValues.specialties === '' || formValues.specialties === undefined) {
            internalError = internalError + 'La especialidad es requerida, ';
        }
        if(formValues.education === '' || formValues.education === undefined) {
            internalError = internalError + 'La educación es requerida ';
        }
        if(internalError === '') {
            dispatch(expertAddNew(formValues));
            setError('');
            history.push('/expertApp');
        } else {
            setError(internalError);
        }
    }


    return (
        <div id="expertForm">
            <form className="row" onSubmit={sendData}>
            <label htmlFor="">Disponibilidad</label>
                <div className="col-md-12 expertFormItem">
                    <label htmlFor="">Desde</label>
                    <select name="disponibilityFrom" value={formValues.disponibilityFrom} onChange={handleInputChange}>
                        <option>--</option>
                        {hours.map(x => <option value={x} >{x}</option>)}
                    </select>
                    <label>Hasta</label>
                    <select name="disponibilityTo" value={formValues.disponibilityTo} onChange={handleInputChange}>
                        <option>--</option>
                        {hours.map((x, i) => i > 1 && <option value={x} >{x}</option>)}
                    </select>
                </div>
                <div className="col-md-12 expertFormItem">
                    <textarea type="textarea" rows="10" cols="50" defaultValue={formValues.headline} placeholder="resumen de su curriculum" className="form-control" onChange={handleInputChange} name="headline"></textarea>
                </div>
                <div className="col-md-12 expertFormItem">
                    <textarea type="textarea" rows="10" cols="50" defaultValue={formValues.specialties} placeholder="especialidades" className="form-control" onChange={handleInputChange} name="specialties"></textarea>
                </div>
                <div className="col-md-12 expertFormItem">
                    <textarea type="textarea" rows="10" cols="50" defaultValue={formValues.education} placeholder="educación" className="form-control" onChange={handleInputChange} name="education"></textarea>
                </div>
                {error !== '' && <div>{error}</div>}
                <button type="submit" className="btn btn-primary">Enviar</button>
            </form>
        </div>
    )
}
export default ExpertForm;