import React, { useState } from 'react'
import ItemsCarousel from 'react-items-carousel';
import './grouplac.scss'

const Grouplac = ({ grouplac }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [tab, setTab] = useState('Lineas de investigación')


    return (
        <div className="GrouplacMain">
            {grouplac !== "" &&
                grouplac.items.length !== 0 ?
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={1}
                    gutter={20}
                    leftChevron={<i className="fas fa-angle-left fa-3x arrowIcon"></i>}
                    rightChevron={<i className="fas fa-angle-right fa-3x arrowIcon"></i>}
                    outsideChevron
                    chevronWidth={40}
                    infiniteLoop={true}
                >
                    {
                        grouplac.items.map(item => {
                            //goupLacItem
                            return <div className="" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                                <div style={{ textAlign: 'left' }}>
                                    {
                                        item.hasOwnProperty('clasificacion') &&
                                        <div className='clasification'>
                                            {item.clasificacion[0]}
                                        </div>
                                    }
                                    {item.hasOwnProperty('nombre') &&
                                        //groupTitle
                                        <div style={{ fontSize: '15px', marginTop:'10px' }}><strong>{item.nombre[0]}</strong></div>
                                    }
                                    {item.hasOwnProperty('instituciones') &&
                                        //groupTitle
                                        <div style={{ fontSize: '15px' }}>{item.instituciones[0]}</div>
                                    }
                                    {item.hasOwnProperty('dep_city') &&
                                        <div>{item.dep_city}</div>
                                    }

                                </div>
                                <div className='tabs'>
                                    <div className={tab === 'Lineas de investigación' ? 'tab2' : 'tab1'}>
                                        <div style={{ fontSize: '12px', color: `${tab === 'Lineas de investigación' ? '#fff' : 'gray'}`, width: '150px', marginLeft: '10px' }} onClick={() => setTab('Lineas de investigación')}>Lineas de investigación</div>
                                        <div style={{ fontSize: '12px', color: `${tab === 'Articulos publicados' ? '#fff' : 'gray'}`, width: '150px', marginLeft: '10px' }} onClick={() => setTab('Articulos publicados')}>Articulos publicados</div>
                                    </div>
                                </div>

                                {tab == 'Lineas de investigación' &&
                                item.hasOwnProperty('lineas_investigacion') &&
                                <div className='tabSection'>
                                    <div className='articlesPublic'>
                                        {item.lineas_investigacion.map(item => {
                                            let fixItem = item.split(';;')
                                            return (
                                                <div style={{padding:'10px'}}>
                                                    {fixItem.map(element => {
                                                        return (
                                                            <div style={{ textAlign: 'left' }}>{element}</div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            }
                            {tab == 'Articulos publicados' &&
                                item.hasOwnProperty('articulos_publicados') &&
                                // <div style={{ display: 'inline-flex', padding: '10px 30px 10px 30px', marginLeft: '-25px', textAlign: 'left', width: '80%', borderBottom: '1px solid var(--grayOne)' }}>
                                <div className='tabSection' style={{ display: 'inline-flex' }}>
                                    <div>
                                        {/* <div className="groupLacCircle"> */}
                                        <div className="">
                                            {/* <img className="groupLacGrayImage" src={require('../../../images/ui/icons/groupLacGray.png').default} width="30" height="30" /> */}
                                        </div>
                                        {/* <div style={{ fontSize: '13px', color: 'var(--grayOne)' }}>GrupLAC</div> */}
                                    </div>
                                    <div className='articlesPublic'>
                                        {item.articulos_publicados.map(area => {
                                            console.log('area', area);
                                            let elemento = area.split(';;')
                                             console.log('item artic', elemento);

                                            return <div style={{margin:'20px', textAlign:'left', paddingBottom:'10px'}}>•{elemento[1]}</div>
                                        })}</div>
                                </div>
                            }



                                {/* {
                                    item.hasOwnProperty('informes_tecnicos') &&
                                    <div className="producciones">
                                        <h5>Informes tecnicos</h5>
                                        {
                                            item.informes_tecnicos.map(info => {

                                                let data1 = info.split(';;')

                                                return (<div style={{ borderBottom: '1px solid gray' }}>
                                                    <br />
                                                    <div>{data1[0]}</div>
                                                    <br />
                                                    <br />
                                                    <div>{data1[1]}</div>
                                                    <br />
                                                    <br />
                                                    <div>{data1[2]}</div>
                                                    <br />
                                                </div>)
                                            })
                                        }

                                    </div>
                                } */}
                                {/* {
                                    item.hasOwnProperty('libros_publicados') &&
                                    <div className="libros">
                                        <h5>Libros publicados</h5>
                                        {
                                            item.libros_publicados.map(book => {

                                                let libro = book.split(';;')
                                                return (<div>
                                                    <div>{libro[0]}</div>
                                                    <div>{libro[1]}</div>
                                                    <hr />
                                                </div>)
                                            })
                                        }
                                    </div>

                                } */}
                                {item.hasOwnProperty('lider') &&
                                    <div style={{ marginTop: '8px' }}>
                                        <div style={{float:'left'}}><strong>Lider del grupo</strong></div>
                                        <div style={{ textAlign: 'left', display: 'inline-flex', float: 'left' }}>
                                            <div style={{ display: 'inline-flex' }}>
                                                <img style={{ marginRight: '10px' }} src={require('../../../images/icons/avatarcvlac.png').default} width="50px" height="50px" alt="" />
                                                <div style={{ display: 'inline-block' }}>
                                                    <div>{item.lider[0]}</div>
                                                    <div>
                                                        {item.hasOwnProperty('email') &&
                                                            <div>
                                                                {item.email[0]}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                }
                            </div>
                        })
                    }
                </ItemsCarousel>

                :
                <div>No se encontraron Perfiles relacionados</div>
            }
        </div>
    )
}

export default Grouplac
