import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
//redux
import { useDispatch, useSelector } from 'react-redux'
import { setIpcFilterAction } from '../../../../actions/patentAction'

const IpcFilter = ({setEndpointIpc}) => {
    
    //redux
    //redux
    const dispatch = useDispatch();
    const ipc = useSelector(state => state.patent.ipcFilter);

    /**States */
    const [tipText] = useState('Para consultar por la Clasificación Internacional<br/>de Patentes tenga en cuenta el siguiente formato<br/>B 65 B 47 / 02<br/> B-> Sección del ipc <br/> 65 -> Generalmente 2 digitos, clase del ipc <br/> B -> Subclase del ipc <br/> 47 -> Grupo del ipc <br/>02X -> Subgrupo')

    /**Funciones */
    //cuando el usuario digita en el input de ipc, cambia el ipcfilter del redux
    const ipcChange = e => {
        dispatch(setIpcFilterAction(e.target.value));
    }

    return ( 
        <>
            <div className="form-group row" data-tip={tipText} data-for="text">
                <div className="col-sm-10">
                    <input 
                        type="text" 
                        className="form-control-sm" 
                        id="inputPassword" 
                        placeholder="Password"
                        value={ipc}
                        onChange={ipcChange}
                    />
                </div>
                <button 
                    className="col-sm-2 btn-sm btn-primary" 
                    onClick={() => setEndpointIpc('&ipc_filtro='+ipc)}
                >aca</button>
            </div>
            <ReactTooltip id="text" place="top" type="dark" effect="solid" multiline={true} />
            
        </>
     );
}
 
export default IpcFilter;