import React from 'react'
import Skeleton from "react-loading-skeleton";
import './graphicTemplate.scss'

const GraphicTemplate = () => {
    return (
        <div className="graphicTemplate">
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 50px)'}} height={350} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 100px)'}} height={300} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 300px)'}} height={100} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 200px)'}} height={200} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 300px)'}} height={100} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 350px)'}} height={50} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 200px)'}} height={200} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 300px)'}} height={100} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 350px)'}} height={50} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 300px)'}} height={100} width={'50px'} />
            <Skeleton style={{marginRight:'10px', marginBottom:'20px', transform:'translate(0px, 350px)'}} height={50} width={'50px'} />
        </div>
    );
}
export default GraphicTemplate