import React, { useEffect, useState } from 'react'
import SaveFavouritesDropdown from '../../saveFavouritesDropdown/SaveFavouritesDropdown'
import { useDispatch, useSelector } from 'react-redux';
import { getSearch, setParamsOfSearch } from '../../../../../../../actions/homeAction';
import { NORMAL_SEARCH, ADVANCED_SEARCH, ASSISTED_SEARCH } from '../../../../../../../globalConstants/typeOfSearch';
import processData from '../../../../../../../hooks/useProccessData'
import './patentTable.scss'

const PatentTable = ({ results, viewDescription, id, goToResult, currentPage }) => {

    console.log('results', results);
    const dispatch = useDispatch();
    const [resultados, setResultados] = useState(results);
    const typeOfSearch = useSelector(state => state.home.typeOfSearch);
    const search = useSelector(state => state.home.search);
    const source = useSelector(state => state.home.source);
    const [ordering, setOrdering] = useState(false);

    useEffect(() => {
        let resultswithTitleOk = results.map(r => {
            r.title = r.hasOwnProperty('invention_title') ? r.invention_title[0].charAt(0).toUpperCase() + r.invention_title[0].slice(1).substr(0, 30).toLowerCase() :
                r.hasOwnProperty('invention_title_en') ? r.invention_title_en[0].charAt(0).toUpperCase() + r.invention_title_en[0].slice(1).substr(0, 30).toLowerCase() :
                    Array.isArray(r.title) ? r.title[0].charAt(0).toUpperCase() + r.title[0].slice(1).substr(0, 30).toLowerCase() : r.title.charAt(0).toUpperCase() + r.title.slice(1).substr(0, 30).toLowerCase();

            r.originaltitle = r.hasOwnProperty('invention_title') ? r.invention_title[0].charAt(0).toUpperCase() + r.invention_title[0].slice(1).toLowerCase() :
                r.hasOwnProperty('invention_title_en') ? r.invention_title_en[0].charAt(0).toUpperCase() + r.invention_title_en[0].slice(1).toLowerCase() :
                    Array.isArray(r.title) ? r.title[0].charAt(0).toUpperCase() + r.title[0].slice(1).toLowerCase() : r.title.charAt(0).toUpperCase() + r.title.slice(1).toLowerCase();
            return r;
        })
        setResultados(resultswithTitleOk);
    }, [results])

    const order = (filter) => {
        let params = `filter=${filter}&sort=1`

        if (typeOfSearch === NORMAL_SEARCH || ASSISTED_SEARCH) {
            dispatch(setParamsOfSearch(params))
        }
        if (typeOfSearch === ADVANCED_SEARCH) {
            //dispatch(getSearchAdvanced(currentPage, paramsOfAdvancedSearch, end))
        }
    }

    return (
        <div className="patentTableMain">
            <div className="resultTable row">
                <div className="col-md-2 col-lg-2"></div>
                <div className="col-md-2 col-lg-2"><p style={{ float: 'left' }}>Número de publicación <img style={{ cursor: 'pointer' }} onClick={() => order('public_number')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
                <div className="col-md-5 col-lg-5"><p style={{ float: 'inline-end' }}>Patente <img style={{ cursor: 'pointer' }} onClick={() => order('patente')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
                <div className="col-md-1 col-lg-1 pl-0"><p style={{ float: 'left' }}>Fecha <img style={{ cursor: 'pointer' }} onClick={() => order('date')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p >Aplicante <img style={{ cursor: 'pointer' }} onClick={() => order('aplicante')} src={require('../../../../../../../images/icons/arrowDirections/rowDown.png').default} className='rowDown' alt="" /></p></div>
            </div>
            {(resultados[0].category !== 'empty' && resultados[0].category !== 'fail') ?
                resultados.map(r => {
                    return (<div className="resultTableBody row">
                        <div className="col-md-2 col-lg-2">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 firstColumns pl-0">
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <div className="categoryDiv">
                                            <img onClick={() => goToResult(r)} className="icon" src={r.icon} alt='' />
                                        </div>
                                        <div className="addRepository">
                                            <SaveFavouritesDropdown item={r} />
                                        </div>
                                        <div className="imageToPreviewDiv">
                                            {r.thumbnail ?
                                                <img className="imageToPreview" src={r.thumbnail ? r.thumbnail : ''} />
                                                :
                                                <div style={{ paddingTop: '1.5rem' }}>
                                                    {/* <div className='imageToPreviewDivEmpty'>

                                                </div> */}
                                                    <p onClick={() => viewDescription(r)} style={{ color: 'var(--primaryBlueOne)', fontSize: '14px', cursor: 'pointer' }}>ver más</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 col-lg-10 threeLastColumns">
                            <div onClick={() => goToResult(r)} className="row">
                                <div className="title col-md-2 col-lg-2" >
                                    <p style={{ float: 'left' }} className="title">
                                        {r.publicacion_reference_country && r.publicacion_reference_country[0]}
                                        {r.publicacion_reference_doc_number && r.publicacion_reference_doc_number[0]}
                                        {r.publicacion_reference_kind && r.publicacion_reference_kind[0]}
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <p>{r.title}...</p>
                                    {/* <p>{r.hasOwnProperty('invention_title') ? r.invention_title[0].charAt(0).toUpperCase() + r.invention_title[0].slice(1).substr(0, 30).toLowerCase():
                                        r.hasOwnProperty('invention_title_en') ? r.invention_title_en[0].charAt(0).toUpperCase() + r.invention_title_en[0].slice(1).substr(0, 30).toLowerCase() :
                                            Array.isArray(r.title) ? r.title[0].charAt(0).toUpperCase() + r.title[0].slice(1).substr(0, 30).toLowerCase() : r.title.charAt(0).toUpperCase() + r.title.slice(1).substr(0, 30).toLowerCase()}...</p> */}
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.datePublication && r.datePublication.toString().substr(0, 4)}</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.assignee_name && r.assignee_name[0].substr(0, 10)}...</p>
                                </div>
                            </div>
                        </div>
                        {(id === r.id) &&
                            <div className="view">
                                <p style={{ textAlign: 'left' }}>{Array.isArray(r.description) ? r.description[0].substr(0, 300) : r.description.substr(0, 300)}...
                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => goToResult(r)}>ver todo</span>
                                </p>
                            </div>
                        }

                    </div>)

                }) :
                <div>No se encontraron resultados</div>
            }
        </div>
    )
}

export default PatentTable
