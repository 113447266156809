import React, { useState, useEffect } from 'react';
import PageOne from './userPreferences/PageOne'
import Pagetwo from './userPreferences/PageTwo'
import SendProfile from './SendProfile'
import './profile.scss'

//redux
import { useSelector, useDispatch } from 'react-redux';
import { getOptionsAction } from '../../actions/profileActions';

const Profile = () => {

    //redux dispatch and redux variables
    const dispatch = useDispatch();
    const optioninterests = useSelector(state => state.profile.optioninterests);
    const optionsectors = useSelector(state => state.profile.optionsectors);
    const optionprofessions = useSelector(state => state.profile.optionprofessions);
    const optionstudies = useSelector(state => state.profile.optionstudies);

    //states del componente
    const [page, SetPage] = useState(0);
    const [firstModal, setFirstModal] = useState('');//muestra primer popup
    const [secondModal, setSecondModal] = useState('');//muestra segundo popup

    //effect para traer los perfiles de la bd
    useEffect(() => {
        dispatch(getOptionsAction());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //funcion para cambio de pagina
    const changePage = page => {
        SetPage(page);
    }

    //funcion para hacer visible el modal
    const addModal = modal => {
        if (modal === 1) {
            setFirstModal('addmodal');
        } else {
            setFirstModal('');
            setSecondModal('addmodal');
        }
    }

    return (
        <div className='profile'>
            <div className='headerRegister'>
                <div className="logoInnabierta"></div>
                <div style={{ width: '20%', paddingTop: '13px', paddingRight: '20px', display: 'flex', justifyContent: 'end' }}>
                </div>
            </div>
            <div className="inscription_box-title">
                <div className='inscription_box-title--primary'>FORMULARIO DE REGISTRO</div>
                <div className='inscription_box-title--secondary'>Completa la información y haz parte de la plataforma Lantia</div>
            </div>
            <div className="_lnt_ContentWrapper">
                <div id="appProfiler">
                    {page === 0 ?
                        <PageOne
                            optioninterests={optioninterests}
                            optionsectors={optionsectors}
                            changePage={changePage}
                        />
                        :
                        <Pagetwo
                            optionsectors={optionsectors}
                            optionstudies={optionstudies}
                            changePage={changePage}
                            addModal={addModal}
                        />
                    }
                </div>
            </div>
            <SendProfile
                firstModal={firstModal}
                secondModal={secondModal}
                addModal={addModal}
            />
        </div>
    );
}

export default Profile;