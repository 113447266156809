import {
    GET_PATENTS,
    SET_SEARCH,
    SET_GRAPHICS_ARRAY,
    SET_GRAPHICS_SCURVE,
    SET_GRAPHICS_IPC,
    SET_FILTER_ENDPOINT,
    SET_ADVANCED_ENDPOINT,
    SET_CONNECTOR_ASSISTED,
    SET__MODE_ADVANCED,
    SET__EQUATION_ADVANCED,
    DELETE_EQUATION,
    SET_TYPES_FILTER,
    DELETE_TYPES_FILTER,
    SET_COUNTRIES_FILTER,
    DELETE_COUNTRIES_FILTER,
    SET_IPC_FILTER,
    DELETE_IPC_FILTER,
    SET_DATES_FILTER,
    DELETE_DATES_FILTER,    
} from '../types/patentTypes';

//state
const initialState = {
    page:0,  //pagina actual para el envio del endpoint
    patents:[], //se guardan las 15 patentes de page
    results:0, //numero total de patentes
    equation:'',
    /**Busquedas */
    search:'*', //variable para busqueda
    advancedEndPoint:'',//si el usuario ha generada busqeuda guiada se guardara aca
    advancedSearch:'', //variable para hacer o no visible la busqueda avanzada o guiada  
    wordsConnectorAssisted:{},//objecto que guardara dos arrays, palabras and y or para subrayar en la busqueda asistida 
    /**Filtros */
    filterEndpoint:'', //si el usuario ha generado algun filtro se guardara aca
    datesFilter:{
        type:'',
        startDate:new Date(),
        finalDate:new Date()
    },
    typesFilter:{
        utility:false,
        design:false,
        plant:false,
        reissue:false
    },//filtro de tipo de patentes
    countryFilter:[],//filtro de paises
    ipcFilter:'',//filtro de Ipc
    //**Gráficas */
    graphicsArray: [],//este es el array que trae el endpoint de las gráficas
    ipcGraphic: '',//este es el endpoint que trae las gráficas de IPC
    
}

export default function(state = initialState, action){
    switch(action.type){

        case GET_PATENTS:
            return{
                ...state,
                patents:action.payload.items,
                results:action.payload.results,
                page:action.payload.page
            }
        /**BUSQUEDAS */
        case SET_SEARCH:
            return{
                ...state,
                search:action.payload
            }
            
        case SET_GRAPHICS_ARRAY:
            return{
                ...state,
                graphicsArray:action.payload
            }

        case SET_GRAPHICS_SCURVE:
            return{
                ...state,
                graphicsSCurve:action.payload
            }

        case SET_GRAPHICS_IPC:
            return{
                ...state,
                graphicsIpc:action.payload
            }
        
        case SET_ADVANCED_ENDPOINT:
            return{
                ...state,
                advancedEndPoint:action.payload
            }
        
        case SET_CONNECTOR_ASSISTED:
            return{
                ...state,
                wordsConnectorAssisted:action.payload
            }
        
        case SET__MODE_ADVANCED:
            return{
                ...state,
                advancedSearch:action.payload
            }
        
        case SET__EQUATION_ADVANCED:
            return{
                ...state,
                equation:action.payload
            }
        
        case DELETE_EQUATION:
            return{
                ...state,
                equation:''
            }

        /**FILTROS */
        case SET_FILTER_ENDPOINT:
            return{
                ...state,
                filterEndpoint:action.payload
            }

        case SET_DATES_FILTER:
            return{
                ...state,
                datesFilter:action.payload
            }
        
        case DELETE_DATES_FILTER:
            return{
                ...state,
                datesFilter:{type:'',startDate:new Date(),finalDate:new Date()}
            }
        
        case SET_TYPES_FILTER:
            return{
                ...state,
                typesFilter:action.payload
            }

        case DELETE_TYPES_FILTER:
            return{
                ...state,
                typesFilter:{utility:false,design:false,plant:false,reissue:false}
            }
        
        case SET_COUNTRIES_FILTER:
            return{
                ...state,
                countryFilter:action.payload
            }
        
        case DELETE_COUNTRIES_FILTER:
            return{
                ...state,
                countryFilter:[]
            }
        
        case SET_IPC_FILTER:
            return{
                ...state,
                ipcFilter:action.payload
            }
        
        case DELETE_IPC_FILTER:
            return{
                ...state,
                ipcFilter:''
            }

        default:
            return state;
    }
}