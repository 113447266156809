import React, { useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3v5";
import Axis from "./Axis.jsx";
import Chart from "./Chart.jsx";
import Tooltip from "./Tooltip.jsx";
import ReactTooltip from "react-tooltip";
import './trendGraphic.css';
import { selectAll } from "d3";

const margin = {
  top: 30,
  right: 10,
  bottom: 110,
  left: 460
};
const width = 1200 - margin.left - margin.right;
const height = 400 - margin.top - margin.bottom;

const TrendsBar = ({ trends,clickImage }) => {

    const [tooltipContent, setTooltipContent] = useState(null);
    let vals = Object.values(trends);
    vals.sort((a, b) => a - b);
    vals.reverse()
    vals = vals.slice(0, 10);

    const trendsBigest = Object.fromEntries(
        Object.entries(trends).filter(
            ([key, val]) => vals.includes(val)
        ));

    const Data = [];

    for(const property in trendsBigest){
        Data.push({name:property, value:trendsBigest[property]});
    }


  const [hoveredBar, setHoveredBar] = useState(null);

  const onBarHover = bar => () => {
    setHoveredBar(bar);
    bar != null ?
    setTooltipContent(bar.value):
    setTooltipContent(bar)
  };

  const xScale = d3
    .scaleLinear()
    .domain([0, d3.max(Data, d => d.value)])
    .rangeRound([0, width])
    .nice();

  const yScale = d3
    .scaleBand()
    .domain(Data.map(d => d.name))
    .rangeRound([height, 0])
    .padding(0.05);


  return (
    <div className="m-2 d-flex justify-content-center align-items-center">
        {/*<h5>Códigos IPC</h5>*/}
      <svg 
        data-tip=''
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      >
        <g transform={`translate(340, ${margin.top})`}>
          {Data.map(d => (
            <Chart
              style={{cursor:'pointer'}}
              key={d.country}
              x={0}
              y={yScale(d.name)}
              width={xScale(d.value)}
              height={yScale.bandwidth()}
              onMouseEnter={onBarHover(d)}
              onMouseLeave={onBarHover(null)}
              onClick={() => {
                clickImage(d.name);
              }}
            />
          ))}
          <Axis
            orientation="Bottom"
            scale={xScale}
            xTransform={0}
            yTransform={height}
            className="xAxis"
            label="Patentes"
          />
          <Axis
            orientation="Left"
            xTransform={0}
            yTransform={0}
            scale={yScale}
            className="yAxis"
            label=""
          />
        </g>
      </svg>
      {hoveredBar ? (
        <Tooltip
          hovered={hoveredBar}
          xScale={xScale}
          yScale={yScale}
          marginLeft={margin.left}
          marginTop={margin.top}
        />
      ) : null}
      <ReactTooltip>{tooltipContent}</ReactTooltip>
    </div>
  );
};


export default TrendsBar;