import React,{ useState, useEffect, useRef } from 'react'

const AdvancedSearchItem = ({word,index,setFinalEndPoint}) => {
    //ref para prevenir que se ejecuten funciones la primera vez
    const didMountRef = useRef(false);

    /**States */
    const [option,setOption] = useState('ti-abs-cla'); //state del select por default la opcion ti-abs-cla
    const [sinonymousInput,setSinonymousInput] = useState(''); //state del input

    //si se escribe una nueva letra o se selecciona una nueva opcion de select, se envia al padre para tener el endpoint
    useEffect(() => {
        if (didMountRef.current){
            setIndividualPoint();
        }else{
            didMountRef.current = true;
        }
        
    }, [sinonymousInput,option])

    /**Funciones */
    //arma endpoint de las palabras de ese input
    const setIndividualPoint = () => {

        var sinonymousArray = sinonymousInput.trim().split(" ")
        let end = '';
        sinonymousArray.forEach(function(sinony) {
                if(end === ''){
                    end = option+':'+sinony;
                }else{
                    end = end+'+OR+'+sinony
                }
        });
        if(sinonymousArray.length !== 0){
            setFinalEndPoint(end,index) 
        } 
    }

    //selecciona tipo de sinonimo
    const onSelectChange = (e) =>{
        setOption(e.target.value);
    }
    
    return ( 
        <div className='row'>
            <h6>{index}</h6>
            <h4 className='mr-3'>{word}</h4>
            <select 
                name="area" 
                className='prueba'
                onChange={onSelectChange}
                defaultValue={'ti-abs-cla'}
            >
                <option value="ti-abs-cla" >Ti-abs-cla</option>
                <option value="Title" >Title</option>
                <option value="Abstract" >Abstract</option>
                <option value="Claims" >Claims</option>
            </select> 
            <input type="text" placeholder='sinónimos' value={sinonymousInput} onChange={(e) => setSinonymousInput(e.target.value)}/>                      
        </div>
     );
}
 
export default AdvancedSearchItem;