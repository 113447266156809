import React, { useState, useEffect } from 'react'
import translateNew from '../../../../../../hooks/useTranslate'
import TranslateModal from '../../../../../ui/translateModal/TranslateModal'
import './convoResult.scss';

const today = new Date();
const ConvoResult = ({ resultSelected }) => {

    const [descriptionText, setDescription] = useState(resultSelected.descripcion)
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (translate) {
            translateText();
            return;
        }
        setDescription(resultSelected.descripcion)
    }, [resultSelected, translate])

    const translateText = () => {
        translateNew(descriptionText).then(response => {
            setDescription(response)
        });
    }

    function dateCompare(d1, d2){
        const date1 = new Date(d1);
        const date2 = new Date(d2);
    
        if(date1 > date2){
            return 'Abierto';
        } else if(date1 < date2){
            return 'Cerrado';

        } else{
        }
    }

    return (
        <div className="convoResultMain">
            <div className="row">
                <div className="col-12 col-md-8">
                    <p style={{ color: 'var(--secundaryFirtFour)', fontSize: '23px', paddingLeft: '14px' }}>{resultSelected.title}</p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-2">
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 col-lg-4">
                    <h6 style={{ color: 'var(--primaryGreyThree)' }}>Apertura: {resultSelected.apertura[0].substr(0, 10)} </h6>
                    <h6 style={{ color: 'var(--primaryGreyThree)' }}>Estado: {resultSelected.hasOwnProperty('cierre') ?dateCompare(resultSelected.cierre[0].substr(0, 10), today): ''}</h6>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                    <h6 style={{ color: 'var(--primaryGreyThree)' }}>Cierre: {resultSelected.hasOwnProperty('cierre') && resultSelected.cierre && resultSelected.cierre[0].substr(0, 10)}</h6>
                </div>
                <div className="col-12 col-md-4 col-lg-4">
                    <h6 style={{ color: 'var(--primaryGreyThree)' }}>Enlace: <a target="_blank" style={{ textDecoration: 'none' }} href={resultSelected.link}>{resultSelected.link}</a></h6>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <h6 style={{ color: 'var(--primaryGreyThree)' }}>Descripción</h6>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                    {descriptionText &&
                        descriptionText.map(paragraph => {
                            return (
                                <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                    {paragraph}
                                </p>
                            );
                        })}
                </div>
            </div>
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} />
        </div>
    );
}
export default ConvoResult;