import React from 'react'


const ArticlePage = ({ ...props }) => {

    
    return ( 
        <>
            <h1>soy articulo con el id:{props.computedMatch.params.id}</h1>
        </>
    );
}
 
export default ArticlePage;