import {
    SET_SEARCH,
    SET_RESULTS_OF_SEARCH,
    SET_RESULTS_SELECTED,
    SET_NETWORK_GRAPHIC_ID,
    CLEAN_RESULTS,
    NUMBER_RESULTS,
    CLEAN_NUMBER_RESULTS,
    LAST_REGISTERS,
    CLEAN_LAST_REGISTERS,
    NUMBER_OF_PAGES,
    CLEAN_NUMBER_OF_PAGES,
    PARAMS_OF_SEARCH,
    CLEAN_PARAMS_OF_SEARCH,
    TYPE_OF_SEARCH,
    ADVANCED_SEARCH_PARAMS,
    SOURCE,
    LAST_SEARH,
    SET_EQUATION
} from '../types/homeTypes';
import axiosLantiaBack from '../components/config/axios'
import axios from 'axios';
import { sources } from '../globalConstants/sources'
import { fetchData } from '../helpers/fetchData';
import { NORMAL_SEARCH, ADVANCED_SEARCH } from '../globalConstants/typeOfSearch'

export function getSearch(keyword, currentPage, source, params, end) {

    return async (dispatch, getState) => {

        try {
            let translateKeyword = ''
            if (params.search('equation') === -1) {
                console.log('this is the source', source);
                try {
                    if (source === 'gp_lac' || source === 'cv_lac') {
                        translateKeyword = await axios.get(`https://apertium.org/apy/translate?q=${keyword}&langpair=eng%7Cspa`);
                        translateKeyword = '*22'+translateKeyword.data.responseData.translatedText.replace('*', '')+'*22'
                    }else{
                        translateKeyword = keyword;
                    }
                } catch (error) {
                    translateKeyword = keyword;
                }
            } else {
                translateKeyword = keyword
            }

            const apiKey = getState().auth.apiKey;

            let filteredResponse = [];
            let lastRegistersResponse = 0;
            let totalPagesResponse = 0;
            let totalResultsResponse = 0;

            if (source === 'all') {
                for (let i = 0; i < sources.length; i++) {
                    let response = await fetchData(apiKey, translateKeyword, currentPage !== null ? currentPage * 2 : end, sources[i], 2, params, NORMAL_SEARCH);
                    if (response[0][0] !== 'empty' && response[0][0] !== 'fail') {
                        filteredResponse = [...filteredResponse, ...response[0]];
                        lastRegistersResponse = lastRegistersResponse + response[1];
                        totalPagesResponse = totalPagesResponse + response[2];
                        totalResultsResponse = totalResultsResponse + response[3];
                    } else {
                        //filteredResponse = [...filteredResponse, 'empty'];
                    }

                }
            } else {
                let response = await fetchData(apiKey, translateKeyword, currentPage !== null ? currentPage * 14 :
                    end, sources.filter(s => s.source === source)[0], 14, params, NORMAL_SEARCH);
                if (response[0][0] !== 'empty' && response[0][0] !== 'fail') {
                    filteredResponse = response[0];
                    lastRegistersResponse = response[1];
                    totalPagesResponse = response[2];
                    totalResultsResponse = response[3];
                } else {
                    //filteredResponse = [...filteredResponse, 'empty'];
                }

            }
            dispatch(setResultsOfSearh(filteredResponse));
            dispatch(lastRegisters(lastRegistersResponse));
            dispatch(totalPages(totalPagesResponse));
            dispatch(totalResults(totalResultsResponse))

        } catch (error) {
        }
    }
}


export function cleanAllResultsState() {
    return async (dispatch) => {
        try {

            dispatch(setResultsOfSearh([]));
            dispatch(lastRegisters(0));
            dispatch(totalPages(0));
            dispatch(totalResults(0))
        } catch (error) {
        }
    }
}

export function getSearchAdvanced(currentPage, params, end) {
    return async (dispatch) => {
        try {
            let response = await fetchData('', '', currentPage !== null ? currentPage * 14 :
                end, sources.filter(s => s.source === params.source)[0], 14, params, ADVANCED_SEARCH);
            dispatch(setResultsOfSearh(response[0]));
            dispatch(lastRegisters(response[1]));
            dispatch(totalPages(response[2]));
            dispatch(totalResults(response[3]))
        } catch (error) {
        }
    }
}

export function setEquation(equation) {
    return async (dispatch) => {
        try {
            dispatch(putEquation(equation));
        } catch (error) {

        }
    }
}

export function getLastSearh() {
    return async (dispatch) => {
        try {
            let response = await axiosLantiaBack.get('lastwords');
            dispatch(setLastSearch(response.data.items));
        } catch (error) {
        }
    }
}

export const typeOfSearch = (searchType) => {
    return async (dispatch) => {
        try {
            dispatch(keepTypeOfSearch(searchType));
        } catch (error) {

        }
    }
}

export const cleanTotal = () => {
    return async (dispatch) => {
        try {
            dispatch(cleanTotalResults());
        } catch (error) {

        }
    }
}

//manda el search al store de redux
export function setSearchAction(search) {
    return async (dispatch) => {
        try {
            let currentDate = new Date
            const wordToSend = {
                word: search
            }
            axiosLantiaBack.post('lastresearch/save', wordToSend);
            dispatch(setLastSearch([{
                word: search,
                date_inserted: currentDate.toISOString().substr(0, 10)
            }]));
            dispatch(setSearh(search));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

export function cleanResult() {
    return async (dispatch) => {
        try {
            dispatch(cleanResultArray());
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

export function registerSelected(r) {
    return async (dispatch) => {
        try {
            dispatch(setRegisterSelected(r));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//obtener el id de la grafica de nodos
export function setTagNetworkGraphic(id) {
    return async (dispatch) => {
        try {
            dispatch(setIdOfNetworkGraphic(id));
        } catch (error) {
            return 0;
        }
    }
}

export function cleanLast() {
    return async (dispatch) => {
        try {
            dispatch(cleanLastRegisters());
        } catch (error) {
            return 0;
        }
    }
}


//numero de paginas
export function totalPages(numberOfresultsPages) {
    return async (dispatch) => {
        try {
            dispatch(setPagesResults(numberOfresultsPages));
        } catch (error) {
            return 0;
        }
    }
}

//limpiar resultados de paginas
export function cleantoTalPages() {
    return async (dispatch) => {
        try {
            dispatch(cleanPagesResults());
        } catch (error) {
            return 0;
        }
    }
}

//parametros de busqueda
export function setParamsOfSearch(params) {
    return async (dispatch) => {
        try {
            dispatch(setParams(params));
        } catch (error) {
            return 0;
        }
    }
}

//limpiar parametros de busqueda
export function cleanParamsOfSearch() {
    return async (dispatch) => {
        try {
            dispatch(cleanParams());
        } catch (error) {
            return 0;
        }
    }
}

//parametros de la busqueda avanzada
export const advancedSearchParams = (params) => {
    return async (dispatch) => {
        try {
            dispatch(paramsOfadvancedSearch(params));
        } catch (error) {
            return 0;
        }
    }
}


export const changeSource = (source) => {
    return async (dispatch) => {
        try {
            dispatch(setSource(source));
        } catch (error) {
            return 0;
        }
    }
}

//manda ecuacion al redux
const putEquation = (equation) => ({
    type: SET_EQUATION,
    payload: equation
});

//cambia el search
const setSearh = search => ({
    type: SET_SEARCH,
    payload: search
});

//resultados de la busqueda
export const setResultsOfSearh = results => ({
    type: SET_RESULTS_OF_SEARCH,
    payload: results
});

//resgistro seleccionado
const setRegisterSelected = result => ({
    type: SET_RESULTS_SELECTED,
    payload: result
});

//obtener el id de la grafica de nodos
const setIdOfNetworkGraphic = id => ({
    type: SET_NETWORK_GRAPHIC_ID,
    payload: id
})

//limpia el results en el store
const cleanResultArray = () => ({
    type: CLEAN_RESULTS,
    payload: []
})

//numero de resultados
const totalResults = (number) => ({
    type: NUMBER_RESULTS,
    payload: number
})

//limpiar numero de resultados
const cleanTotalResults = () => ({
    type: CLEAN_NUMBER_RESULTS,
    payload: 0
})

const lastRegisters = (r) => ({
    type: LAST_REGISTERS,
    payload: r
})

const cleanLastRegisters = () => ({
    type: CLEAN_LAST_REGISTERS,
    payload: 0
})

const setPagesResults = (numberOfresultsPages) => ({
    type: NUMBER_OF_PAGES,
    payload: numberOfresultsPages
})

const cleanPagesResults = () => ({
    type: CLEAN_NUMBER_OF_PAGES,
    payload: 0
})

const setParams = (params) => ({
    type: PARAMS_OF_SEARCH,
    payload: params
})

const cleanParams = () => ({
    type: CLEAN_PARAMS_OF_SEARCH,
    payload: ''
})

const keepTypeOfSearch = (keepType) => ({
    type: TYPE_OF_SEARCH,
    payload: keepType
})

const paramsOfadvancedSearch = (params) => ({
    type: ADVANCED_SEARCH_PARAMS,
    payload: params
})

const setSource = (source) => ({
    type: SOURCE,
    payload: source
})

const setLastSearch = (lastSearches) => ({
    type: LAST_SEARH,
    payload: lastSearches
})