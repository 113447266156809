 import React from "react";
 import PropTypes from "prop-types";
 import './trendGraphic.css';

 const Chart = props => <rect {...props} className="rect" />;

 Chart.propTypes = {
   x: PropTypes.number,
   y: PropTypes.number,
   width: PropTypes.number,
   height: PropTypes.number
 };

 export default Chart;
