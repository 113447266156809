import React, {useState, useEffect} from 'react';
import axios from '../config/axios'
import clienteAxios from '../config/axiosBigData'
import omniAxios from 'axios';
import {useHistory} from 'react-router-dom';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {optionesSuccessAction} from '../../actions/authAction'
import {getUserOptionsAction} from '../../actions/profileActions'
import Schedule from './userPreferences/Schedule';

const SendProfile = ({firstModal, secondModal, addModal}) => {

    //redux dispatch and redux variables
    const history = useHistory();
    const dispatch = useDispatch();
    const userinterests = useSelector(state => state.profile.userinterests);
    const usersectors = useSelector(state => state.profile.usersectors);
    const userprofessions = useSelector(state => state.profile.userprofessions);
    const userstudies = useSelector(state => state.profile.userstudies);
    const optionsInterests = useSelector(state => state.profile.optionsinterests);
    const apiKey = useSelector(state => state.auth.apiKey);

    //states del componente
    const [error, setError] = useState(false);//state del error si el formulario no pudo ser actualizado en la BD
    const [jobArea, setJobArea] = useState([]);//state con las areas de trabajo de la bd
    const [job, setJob] = useState({
        area: '',
        jobWrote: ''
    });//state para el envio del perfil del usuario

    //state para escoger horarios de informacion
    const [days, setDays] = useState([]);
    const [startTime, setStartTime] = useState(new Date('2020-10-10 06:00:00'));
    const [finishTime, setFinishTime] = useState(new Date('2020-10-10 23:00:00'));

    //effect para traer los perfiles de la bd
    useEffect(() => {
        getJobArea();
    }, []);

  
    //funciones para los horarios de informacion
    const dayChange = day => {
        var index = days.indexOf(day);
        if(index === -1) {
            setDays([...days, day])
        }
        else {
            var daysCopy = [...days];
            daysCopy.splice(index, 1);
            setDays(daysCopy);
        }
    }

    //funcion para agregar area y cargo al formdata
    const onJobChange = e => {
        setJob({
            ...job,
            [e.target.name]: e.target.value
        })
    }

    //funcion para llamar los tipos de areas de trabajo del usuario
    const getJobArea = async () => {
        //endpoint de la bd
        const response = await axios.get('profile/jobarea');
        setJobArea(response.data.type);
    }

    //ajusta horario permitido de envio de informacion al usuario
    const sendTime = async () => {
        var daysCopy = [...days];
        function sortDays(a, b) {return a - b;}
        daysCopy.sort(sortDays);

        var schedule = {};
        schedule.days = daysCopy.toString();
        schedule.startTime = startTime;
        schedule.finishTime = finishTime;
        var rta = await axios.post('profile/schedule', schedule);
    }

    //funcion para enviar el perfil a la bd
    const sendProfile = async () => {
        getRecomended();
        sendTime();
        let profileForm = new FormData();
        profileForm.append('interests', JSON.stringify(userinterests));
        profileForm.append('sectors', JSON.stringify(usersectors));
        profileForm.append('professions', JSON.stringify(userprofessions));
        profileForm.append('studies', JSON.stringify(userstudies));
        profileForm.append('area', job.area);
        profileForm.append('jobWrote', job.jobWrote);
        try {
            const response = await axios.post('profile/save', profileForm);
            if(response.data === 'save') {
                addModal(2);
                try {
                    await dispatch(getUserOptionsAction());
                } catch(error) {
                }
            } else {
                setError(true)
            }
        } catch(error) {
            setError(true);
        }
    }

    //obtener recomendados y enviarlos por omnicanalidad
    const getRecomended = async () => {

        try {
            let interests = userinterests.map(x => x.name_en);
        const response = await clienteAxios.get(`recomendacion_modelo_NN?q=${interests.join(" OR ")}&col=noticias&key=${apiKey}`);
        const list= [{first_name:'Stevet',last_name:'Giraldo',cellphone:'3016231000',email:'estivetg@gmail.com',platform:'Ed_4.0'}]
        
        const slashCharacterReplace ='jfn-mdrt}{';
        
        for (var i = 0; i < 5; i++) {
            const dataToSend = {
                publicType: 'one_person',
                users_list: list,
                time: addHours(i === 0 ? 1 : i*4),
                channel: 'whatsapp',
                email_subject: 'recomendado',
                message_to_send: response.data.item.recommendationen[0].title_en[0],
                check_attachment: 'on',
                link: `http://localhost:3000/resultSelected/${response.data.item.recommendationen[0].id.split('/').join(slashCharacterReplace)}`,
                file: null
            }
        
            var data = dataToSend;
            data.users_list = list;
    
            const channels = ['email', 'whatsapp', 'sms']
            for (var i = 0; i < 4; i++) {
                data.channel = channels[i]; 
                const formData = new FormData();
                formData.append('file',dataToSend.file);     
                formData.append('json',JSON.stringify(data));
                var rta = await omniAxios.post('https://lantiasas.com:5011/communications_module/',formData);
            }
         }  
        } catch (error) {
            
        }
         
    }


    const addHours = (hours) => {
        Date.prototype.addHours= function(h){
            this.setHours(this.getHours()+h);
            return this;
        }
    
         var dt = new Date().addHours(hours) 
    
    
        return `${dt.getDate().toString().padStart(2, '0')}/${(
            dt.getMonth()+1).toString().padStart(2, '0')}/${
                dt.getFullYear().toString().padStart(4, '0')} ${
                dt.getHours().toString().padStart(2, '0')}:${
                dt.getMinutes().toString().padStart(2, '0')}:${
                dt.getSeconds().toString().padStart(2, '0')}`;
    }
    


    const goHomeApp = () => {
        getRecomended();
        dispatch(optionesSuccessAction());
        history.push('/homeApp');
    }


    return (
        <>
            <div id="submitPopup" className={`lnt-modal-overlay ${firstModal}`}>
                <div className="lnt-modal-body">
                    <div className="lnt-modal-contain">
                        <div className="lnt-contain-modal-header">
                            <h1>¡Ya casi!</h1>
                            <p>Estás a punto de terminar, solo te pedimos que respondas a estás últimas preguntas y podrás empezar a usar nuestros potentes buscadores.</p>
                        </div>
                        <div className="lnt-contain-modal-body">
                            <div className="modal-item form-group">
                                <p>¿Dentro de tu organización en que área te desempeñas?</p>
                                <select
                                    name="area"
                                    id="sltd-jobArea"
                                    className='form-control'
                                    onChange={onJobChange}
                                    defaultValue={'DEFAULT'}
                                >
                                    <option value="DEFAULT" disabled>Área en la organización</option>
                                    {jobArea.map((area, index) => (
                                        <option key={index} >{area}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="modal-item">
                                <p>¿Cuál es tu cargo en la organización?</p>
                                <input
                                    type="text"
                                    className='form-control mb-3'
                                    name="jobWrote"
                                    placeholder="Tu cargo"
                                    onChange={onJobChange}
                                />
                            </div>
                            {/*<div className="modal-item d-flex justify-content-center">
                                <Schedule
                                    days={days}
                                    dayChange={dayChange}
                                    setStartTime={setStartTime}
                                    setFinishTime={setFinishTime}
                                    startTime={startTime}
                                    finishTime={finishTime}
                                />
                                    </div>*/}
                            <div className="modal-item d-flex justify-content-center">
                                <button
                                    className='btn btn-primary'
                                    onClick={sendProfile}
                                >
                                    Aceptar
                                </button>
                            </div>
                            {error ?
                                <div className="alert alert-danger lnt-alert" role='alert'>
                                    la petición no pudo ser enviada, intenta mas tarde.
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div id="finalPopup" className={`lnt-modal-overlay ${secondModal}`}>
                <div className="lnt-modal-body">
                    <div className="lnt-modal-contain">
                        <div className="lnt-contain-modal-header">
                            <h1>¡Perfecto!</h1>
                            <p>Hemos guardado tus datos, ahora podremos darte resultados mas acorde a tus intereses.</p>
                            <p style={{textAlign: 'center'}}>¡Ahora, puedes usar nuestros buscadores!</p>
                        </div>
                    </div>
                    <div className="lnt-contain-modal-body">
                        <div className="modal-item d-flex justify-content-center" style={{height: '50px'}}>
                            <button
                                className='btn btn-primary mt-3'
                                onClick={goHomeApp}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SendProfile;