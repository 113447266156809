import React from 'react'
import Skeleton from "react-loading-skeleton";
import './section.scss'

const Section = () => {
    return (
        <div className='sectionMain'>
            {[...Array(3).keys()].map(i => {
                return (
                    <div className={`row ${i === 2?'': 'rowSection'}`}>
                        <div className='col-2 col-md-2 col-lg-2'>
                            <Skeleton circle={true} height={25} width={25} />
                        </div>
                        <div className='col-10 col-md-10 col-lg-10'>
                            <Skeleton height={10} width={'100%'} />
                            <Skeleton height={10} width={'95%'} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default Section
