import React from "react";
import { Link } from "react-router-dom";
import recurso12 from "../../../images/icons/form register/Recurso 12.png";
import recurso14 from "../../../images/icons/form register/Recurso 14.png";
import recurso16 from "../../../images/icons/form register/Recurso 16.png";

const Register = ({ onSignUpChange, signUpForm, sendForm, errorRegister }) => {
    return (
        <>
            <div className="title1">
                <h4 className="white-title">1. INFORMACIÓN DE CONTACTO</h4>{" "}
            </div>
            <div className="row justify-content-center align-items-center">
                <div className="boxRegister">
                    <div className="">
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="username">
                                        <img
                                            src={recurso14}
                                            height="15"
                                            px
                                            alt="usuario"
                                        ></img>
                                        Usuario
                                    </label>
                                    <input
                                        type="text"
                                        name="username"
                                        maxLength="100"
                                        placeholder="NOMBRE DE USUARIO"
                                        className="form-control"
                                        vaue={signUpForm.username}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="password"></label>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="CONTRASEÑA"
                                        className="form-control"
                                        vaue={signUpForm.password}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="firstname">
                                        <img
                                            src={recurso16}
                                            height="16"
                                            px
                                            alt="usuario"
                                        ></img>
                                        Contacto
                                    </label>
                                    <input
                                        type="text"
                                        name="firstname"
                                        className="form-control"
                                        placeholder="NOMBRE"
                                        vaue={signUpForm.firstname}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="lastname"></label>
                                    <input
                                        type="text"
                                        name="lastname"
                                        className="form-control"
                                        placeholder="APELLIDOS"
                                        vaue={signUpForm.lastname}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* agregar funcionalidad cargo */}
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="firstname"></label>
                                    <input
                                        type="text"
                                        name="charge"
                                        className="form-control"
                                        placeholder="CARGO"
                                        vaue={signUpForm.charge}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>

                            {/* agregar funcionalidad area */}
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="lastname"></label>
                                    <input
                                        type="text"
                                        name="area"
                                        className="form-control"
                                        placeholder="ÁREA"
                                        vaue={signUpForm.area}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="email"></label>
                                    <input
                                        type="email"
                                        name="email"
                                        maxLength="45"
                                        className="form-control"
                                        placeholder="CORREO ELECTRÓNICO"
                                        vaue={signUpForm.email}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="phone"></label>
                                    <input
                                        type="number"
                                        name="phone"
                                        className="form-control"
                                        placeholder="NÚMERO DE CELULAR"
                                        vaue={signUpForm.phone}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* agregar funcionalidad empresa */}
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="email">
                                        <img
                                            src={recurso12}
                                            height="16"
                                            px
                                            alt="empresa"
                                        ></img>
                                        Empresa
                                    </label>
                                    <input
                                        type="text"
                                        name="company"
                                        maxLength="45"
                                        className="form-control"
                                        placeholder="EMPRESA"
                                        vaue={signUpForm.company}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>

                            {/* agregar funcionalidad Nit */}
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="phone"></label>
                                    <input
                                        type="number"
                                        name="nit"
                                        className="form-control"
                                        placeholder="NIT"
                                        vaue={signUpForm.nit}
                                        onChange={onSignUpChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                            />

                            <p className="policity-personal-data">
                                Mediante ese registro, acepto la Politica de
                                tratamiento de datos y los Términos de uso
                            </p>
    </div> */}

                        <div className="mt-3">
                            <label className="agreements" htmlFor="tos_agree">
                                Acepto las Condiciones del Servicio
                            </label>
                            <input
                                type="checkbox"
                                name="agree"
                                onChange={onSignUpChange}
                            />
                        </div>
                        {errorRegister && (
                            <div
                                className="w-100 alert alert-danger lnt-alert"
                                role="alert"
                            >
                                {errorRegister}
                            </div>
                        )}
                        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button
                                className="btn btn-success me-md-2"
                                type="submit"
                                onClick={sendForm}
                            >
                                {" "}
                                {/* btn btn-primary btn-block mt-4 */}
                                Siguiente
                            </button>
                        </div>
                        <label className="my-3">
                            ¿Ya tienes cuenta?
                            <Link to={"/login"}> Iniciar Sesión</Link>
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
