import React, { useEffect, useState, useRef } from 'react'
import './tableOfResults.scss'
import TableOfResultsTemplate from '../../../../ui/skeletonTemplates/tableOfResultsTemplate/tableOfResultsTemplate';
import { registerSelected } from '../../../../../actions/homeAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import back from '../../../../../images/icons/arrowDirections/back.png';
import ahead from '../../../../../images/icons/arrowDirections/ahead.png';
import ArticlesTable from './tablesComponents/articlesTable/ArticlesTable'
import EventTable from './tablesComponents/eventTable/EventTable.jsx'
import NewsTable from './tablesComponents/newsTable/NewsTable.jsx'
import PatentTable from './tablesComponents/patentTable/PatentTable.jsx'
import StartUpTable from './tablesComponents/startUpTable/StartUpTable.jsx'
import VideosTable from './tablesComponents/videosTable/VideosTable.jsx'
import ConvoTable from './tablesComponents/convoTable/ConvoTable.jsx'
import GpLacTable from './tablesComponents/gpLacTable/GpLacTable';
import CvLacTable from './tablesComponents/cvlacTable/CvLacTable';
import AllTable from './tablesComponents/allTable/AllTable.jsx'
import validateData from '../../../../../helpers/validateData';
import processEquation from '../../../../../helpers/processEquation';
import begin from '../../../../../images/icons/arrowDirections/begin.png';
import end from '../../../../../images/icons/arrowDirections/end.png';
import { getSearch, cleanResult, cleanTotal, cleanLast, cleantoTalPages, getSearchAdvanced } from '../../../../../actions/homeAction';
import { NORMAL_SEARCH, ADVANCED_SEARCH, ASSISTED_SEARCH } from '../../../../../globalConstants/typeOfSearch';

const TableOfResults = () => {

    //redux
    const history = useHistory();
    const total = useSelector(state => state.home.totalResults);
    const equation = useSelector(state => state.home.equation);
    const lastRegisters = useSelector(state => state.home.lastRegisters);
    const numberOfPages = useSelector(state => state.home.numberOfPages);
    const paramsOfAdvancedSearch = useSelector(state => state.home.paramsOfAdvancedSearch);
    const typeOfSearch = useSelector(state => state.home.typeOfSearch);
    const sources = useSelector(state => state.home.source);
    console.log('cambio la sources', sources);
    const search = useSelector(state => state.home.search);
    const paramsOfSearch = useSelector(state => state.home.paramsOfSearch);
    const dispatch = useDispatch();
    const marks = useSelector(state => state.mark.marks);
    const resultStore = useSelector(state => state.home.results);
    console.log('resultStore', resultStore);

    //states
    const [results, setResults] = useState([]);
    const currentPage = useRef(0);
    const loading = useRef(true);

    const [id, setId] = useState('');

    useEffect(() => {
        setResults([]);
        dispatch(cleanResult());
        dispatch(cleanTotal());
        dispatch(cleanLast());
        dispatch(cleantoTalPages());
        if (typeOfSearch !== ADVANCED_SEARCH) {
            if (equation !== '') {
                fetchSearch(equation, 0, sources, `equation=${processEquation(equation)}`);
            } else {
                fetchSearch(search, currentPage.current, sources, paramsOfSearch);
            }
        }

        currentPage.current = 0;
    }, [search, sources, paramsOfSearch])

    useEffect(() => {
        setResults([])
        loading.current = true
        console.log('resultStore', resultStore);
        validateData(resultStore, sources).then(response => {
            loading.current = false
            setResults(response)

        });
    }, [resultStore])

    const goToResult = async (r) => {
        console.log('goToResult r', r);
        if (r.category === 'empty' || r.category === 'fail') {
            return;
        }
        await dispatch(registerSelected(r));
        history.push('/resultSelected')
    }

    //conectar con el modulo de expertos
    // const sendKeywordToFindExperts = async (title) => {

    //     try {
    //         title = 'my house is big'.replace(/ /g, '+');
    //         const titleTranslate = await axios.get(`http://64.225.59.218:2737/translate?langpair=eng|spa&q=${title}`);
    //         await dispatch(expertConnect(titleTranslate.data.responseData.translatedText));
    //         history.push('/expertApp');
    //     } catch (error) {
    //     }

    // }

    const viewDescription = (r) => {
        if (id === '') {
            setId(r.id);
        } else {
            setId('');
        }
    }

    const fetchSearch = (keyword, currentPage, source, params, end) => {
        if (typeOfSearch === NORMAL_SEARCH || ASSISTED_SEARCH) {
            dispatch(getSearch(keyword, currentPage, source, params, end));
        }
        if (typeOfSearch === ADVANCED_SEARCH) {
            dispatch(getSearchAdvanced(currentPage, paramsOfAdvancedSearch, end))
        }
    }

    const nexPage = () => {
        let exit = false;
        results.map((x, i) => {
            if (x.category !== 'empty' && x.category !== 'fail') {
                return;
            }
            if (i === results.length - 1) {
                exit = true
                return;
            }
        })

        if (exit) {
            return;
        }

        setResults([]);
        dispatch(cleanResult());
        dispatch(cleanTotal());
        dispatch(cleanLast());
        currentPage.current = currentPage.current + 1;

        if (equation !== '') {
            fetchSearch(equation, currentPage.current, sources, `equation=${processEquation(equation)}`);
            return;
        }

        fetchSearch(search, currentPage.current, sources, paramsOfSearch);
    }

    const previousPage = () => {
        if (currentPage.current > 0) {
            setResults([]);
            dispatch(cleanResult())
            dispatch(cleanTotal());
            dispatch(cleanLast());
            currentPage.current = currentPage.current - 1;
            dispatch(cleanLast());
            fetchSearch(search, currentPage.current, sources, paramsOfSearch);
        }
    }

    // const sendParamsToResultBox = (params) => {
    //     setResults([]);
    //     dispatch(cleanResult());
    //     dispatch(cleanTotal());
    //     dispatch(cleanLast());
    //     dispatch(cleantoTalPages());
    //     currentPage.current = 1;
    //     setParams(params);
    //     fetchSearch(search, currentPage.current, sources, paramsOfSearch);
    // }

    const goEnd = (end) => {
        setResults([]);
        dispatch(cleanResult());
        dispatch(cleanTotal());
        dispatch(cleanLast());

        if (equation !== '') {
            fetchSearch(equation, null, sources, `equation=${processEquation(equation)}`, Math.round(end / 12));
            return;
        }

        fetchSearch(search, null, sources, paramsOfSearch, Math.round(end / 12));
    }

    const goBegin = () => {
        setResults([]);
        dispatch(cleanResult());
        dispatch(cleanTotal());
        dispatch(cleanLast());
        currentPage.current = 0;

        if (equation !== '') {
            fetchSearch(equation, currentPage.current, sources, `equation=${processEquation(equation)}`);
            return;
        }

        fetchSearch(search, 0, sources, paramsOfSearch);
    }

    let comp;
    if (sources === 'eventos') {
        console.log('entroooooooooooooooo', results);
        // if (results[0].category === 'event') {
        comp = <EventTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />
        // }

    } else if (sources === 'noticias') {
        // if (results[0].category === 'new') {
        comp = <NewsTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />
        // }

    } else if (sources === 'videos') {
        // if (results[0].category === 'video') {
        comp = <VideosTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />
        // }

    } else if (sources === 'patentes') {
        // if (results[0].category === 'patent') {
        comp = <PatentTable results={results}
            currentPage={currentPage.current}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />
        // }

    } else if (sources === 'startups') {
        // if (results[0].category === 'startup') {
        comp = <StartUpTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />
        // }


    } else if (sources === 'articles') {

        // if (results[0].category === 'article') {
        comp = <ArticlesTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />
        // }


    } else if (sources === 'convocatorias') {
        console.log('results', results);
        // if (results[0].category === 'convocatoria') {
        comp = <ConvoTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />

        // }


    } else if (sources === 'all' && results !== 'fail') {
        comp = <AllTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />

    } else if (sources === 'cv_lac' && results !== 'fail') {
        comp = <CvLacTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />

    }
    else if (sources === 'gp_lac' && results !== 'fail') {
        comp = <GpLacTable results={results}
            goToResult={goToResult}
            viewDescription={viewDescription}
            id={id}
        />

    } else {
        comp = <div>
            no se encontraron resultados
        </div>
    }
    // }


    return (
        <div className='tableOfResultsMain'>
            <div>
                <div style={{ height: '1rem' }}></div>
                {(results.length !== 0 && results !== "empty") &&
                    <div style={{heigth:'auto'}}>
                        {comp}
                    </div>
                }
                {/* {results.length === 0 &&
                   
                } */}
                {results.length === 0 &&
                    <TableOfResultsTemplate />
                }
                <div className='row paginationResultBox'>
                    <div className='col-12 col-md-5 col-lg-5 ml-0'>
                        <p style={{ marginTop: '1rem' }}>{typeof total === 'number' ? total : ''} resultados encontrados</p>
                    </div>
                    <div className="pagination col-12 col-md-7 col-lg-7 ml-0">
                        <img onClick={() => goBegin()} style={{ cursor: 'pointer' }} src={begin} className="backAhead" />
                        <img style={{ cursor: 'pointer' }} onClick={previousPage} src={back} className="backAhead" />
                        <div style={{ width: 'auto', fontSize: '13px' }}> <strong>{currentPage.current + 1}/{numberOfPages}</strong></div>
                        <img style={{ cursor: 'pointer' }} onClick={nexPage} src={ahead} className="backAhead" />
                        <img onClick={() => goEnd(lastRegisters)} style={{ cursor: 'pointer' }} src={end} className="backAhead" />
                    </div>
                </div>


            </div>
        </div>
    )
}

export default TableOfResults
