import React, { useEffect } from 'react'
import SearchBox from './searchBox/SearchBox'
import CompanyItem from './companyItem/CompanyItem'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getCompaniesAction } from '../../../actions/companyActions'

const CompaniesApp = () => {

    //redux
    const dispatch = useDispatch();
    const search = useSelector(state => state.company.search);//busqueda del usuario, si esta vacia se toma *
    const companies = useSelector(state => state.company.companies);//busqueda del usuario, si esta vacia se toma *

    //si el buscador queda en blanco hace llamado a la api
    useEffect(() => {
        if(search.trim() === '' || search.trim() === '*'){
            getCompanies(0);
        }
    }, [search])// eslint-disable-line react-hooks/exhaustive-deps

    //hace el llamado de traer empresas al endpoint
    const getCompanies = async(pageSended) => {
        await dispatch(getCompaniesAction(pageSended));        
    }

    return ( 
        <>
            <SearchBox />

            <div className="row">
                <div className="col-3">
                    <h1>Hola soy filtros</h1>
                    <h2>Hola soy filtros</h2>
                    <h3>Hola soy filtros</h3>
                </div>
                <div className="col-9">
                    {companies.map((company,index) => (
                        <CompanyItem 
                            company={company}
                            key={index}
                        />
                    ))

                    }
                </div>
            </div>
        </>
     );
}
 
export default CompaniesApp;