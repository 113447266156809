export const SET_SEARCH = 'SET_SEARCH';
export const SET_RESULTS_OF_SEARCH = 'SET_RESULTS_OF_SEARCH';
export const SET_RESULTS_SELECTED = 'SET_RESULTS_SELECTED';
export const SET_NETWORK_GRAPHIC_ID = 'SET_NETWORK_GRAPHIC_ID';
export const CLEAN_RESULTS = 'CLEAN_RESULTS';
export const NUMBER_RESULTS = 'NUMBER_RESULTS';
export const CLEAN_NUMBER_RESULTS = 'CLEAN_NUMBER_RESULTS';
export const LAST_REGISTERS = 'LAST_REGISTERS';
export const CLEAN_LAST_REGISTERS = 'CLEAN_LAST_REGISTERS';
export const NUMBER_OF_PAGES = 'NUMBER_OF_PAGES';
export const CLEAN_NUMBER_OF_PAGES = 'CLEAN_NUMBER_OF_PAGES'
export const PARAMS_OF_SEARCH = 'PARAMS_OF_SEARCH';
export const CLEAN_PARAMS_OF_SEARCH = 'CLEAN_PARAMS_OF_SEARCH';
export const TYPE_OF_SEARCH = 'TYPE_OF_SEARCH';
export const ADVANCED_SEARCH_PARAMS = 'ADVANCED_SEARCH_PARAMS';
export const SOURCE = 'SOURCE';
export const LAST_SEARH = 'LAST_SEARH';
export const SET_EQUATION = 'SET_EQUATION';