import React, {useState, useRef, useEffect} from "react";
import * as d3 from "d3v5";
import * as d4 from "d3-force";
import ReactTooltip from "react-tooltip";
import * as d3Chromatic from "d3-scale-chromatic";
import {setTagNetworkGraphic} from '../../../../actions/homeAction';
import {useDispatch, useSelector} from 'react-redux';

const NetworkGraphic = ({nodesProp, linksprop}) => {

    const data = {
        "nodes": nodesProp,
        "links": linksprop
    }

    //redux
    const dispatch = useDispatch();

    const chartNetworking = useRef();
    const [tooltipContent, setTooltipContent] = useState("");
    const margin = {top: 10, right: 10, bottom: 10, left: 10}
    const width = 800
    const height = 400

    const links = data.links.map(d => Object.create(d));
    const nodes = data.nodes.map(d => Object.create(d));


    const renderChart = () => {

        let color = d3.scaleOrdinal()
            .domain(data)
            .range(d3Chromatic.schemeCategory10);

        const simulation = d4.forceSimulation(nodes)
            .force("link", d4.forceLink(links).id(d => d.id))
            .force("charge", d4.forceManyBody())
            .force("center", d4.forceCenter(width / 2, height / 2));

        const svg = d3.select(chartNetworking.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const link = svg.append("g")
            .attr("stroke", "#999")
            .attr("stroke-opacity", 0.6)
            .selectAll("line")
            .data(links)
            .join("line")
            .attr("stroke-width", d => Math.sqrt(d.value))
            .on('mouseover', function (d) {return setTooltipContent(`stroke-line: ${d.value}`)})
            .on('mouseout', function () {return setTooltipContent('')});

        const node = svg.append("g")
            .attr("stroke", "#fff")
            .attr("stroke-width", 1.5)
            .selectAll("circle")
            .data(nodes)
            .join("circle")
            .attr("r", 10)
            .attr("fill", function (d) {return (color(d.group))})
            .call(d3.drag()  //sets the event listener for the specified typenames and returns the drag behavior.
                .on("start", dragstarted) //start - after a new pointer becomes active (on mousedown or touchstart).
                .on("drag", dragged)      //drag - after an active pointer moves (on mousemove or touchmove).
                .on("end", dragended)     //end - after an active pointer becomes inactive (on mouseup, touchend or touchcancel).
            )
            .on("click", (d)=>{
                dispatch(setTagNetworkGraphic(d.id));
            })

        svg
            .selectAll('#networking')
            .on('mouseover', function (d) {return setTooltipContent(`node: ${d.id}`)})
            .on('mouseout', function () {return setTooltipContent('')});

        node.append("title")
            .text(d => d.id);

        simulation.on("tick", () => {
            link
                .attr("x1", d => d.source.x * 1.8)
                .attr("y1", d => d.source.y * 1.8)
                .attr("x2", d => d.target.x * 1.8)
                .attr("y2", d => d.target.y * 1.8);

            node
                .attr("cx", d => d.x * 1.8)
                .attr("cy", d => d.y * 1.8);
        });

        function dragstarted(d) {
            if(!d3.event.active) simulation.alphaTarget(0.3).restart();//sets the current target alpha to the specified number in the range [0,1].
            d.fy = d.y; //fx - the node’s fixed x-position. Original is null.
            d.fx = d.x; //fy - the node’s fixed y-position. Original is null.
        }

        //When the drag gesture starts, the targeted node is fixed to the pointer
        function dragged(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        //the targeted node is released when the gesture ends
        function dragended(d) {
            if(!d3.event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;

        }

    }

    useEffect(() => {
        renderChart();
    }, [])

    return (
        <div style={{border:'1px solid gray'}}>
            <svg id='networking' data-tip="" width="800" height="400" ref={chartNetworking}></svg>
            <ReactTooltip>{tooltipContent}</ReactTooltip>
        </div>
    );
}


export default NetworkGraphic;