import React, { useState, useEffect, useRef } from 'react'
//redux
import { useDispatch, useSelector } from 'react-redux'
import { setCountryFilterAction } from '../../../../actions/patentAction'

const CountryFilter = ({setEndpointCountries}) => {
    //ref para prevenir que se ejecuten funciones la primera vez
    const didMountRef = useRef(false);

    //redux
    const dispatch = useDispatch();
    const countryFilter = useSelector(state => state.patent.countryFilter);

    //** States */
    const [countries] = useState([
        {lt :"US", name:"Estados Unidos de América"},
        {lt :"JP", name:"Japón"},
        {lt :"KR", name:"República de Corea"},
        {lt :"DE", name:"Alemania"},
        {lt :"TW", name:"Taiwan, Provincia de China"},
        {lt :"CN", name:"China"},
        {lt :"FR", name:"Francia"},
        {lt :"CA", name:"Canadá"},
        {lt :"GB", name:"Reino Unido"},
        {lt :"NL", name:"Países Bajos"}
    ]);//states que contiene todos los paises

    //state para agregar paises seleccionados por el usuario
    const [endCountries,setEndCountries] = useState([]); 

    //cuando se agrega o elimina un pais recorre el array y actualiza el endpoint final con los paises seleccionados
    useEffect(() => {
        //iguala el state interno al de redux
        setEndCountries(countryFilter);
        if (!didMountRef.current){
            //primera vez no llama endpoint
        }else{
            let end = '';
            endCountries.forEach(function(country) {
                if(end === ''){
                    end = '&geo='+country;
                }else{
                    end = end+'+OR+'+country
                }
            });
            if(countryFilter.length !== 0){
                setEndpointCountries(end); // eslint-disable-next-line react-hooks/exhaustive-deps 
            }  
        } 
     }, [countryFilter]);// eslint-disable-next-line react-hooks/exhaustive-deps
    
     //cambia los tipos de patente en el redux store cuando el usuario selecciona un filtro de patente
    useEffect(() => {
        if (didMountRef.current){
            dispatch(setCountryFilterAction(endCountries));
        }else{
            didMountRef.current = true;
        }
        
    }, [endCountries])

    /** Funciones */
    //funcion para agregar o eliminar paises seleccionados por el usuario
    const countryChange = country =>{
        var index = endCountries.indexOf(country);        
        if(index === -1){
            setEndCountries([...endCountries,country])
        }else{
            var countriesCopy = [...endCountries];
            countriesCopy.splice(index, 1);
            setEndCountries(countriesCopy);
        }        
    }

    return ( 
        <div className='ml-3'>  
           {countries.map((country,index) => (
                <div key={index}>
                    <div className="opt row">
                        <input type="checkbox" name="utility" checked={countryFilter.indexOf(country.lt)>-1 ? true : false} onChange={() => countryChange(country.lt)} />
                        <div className="text ml-1">{country.name}</div>
                    </div>
               </div>
           ))} 
        </div>
     );
}
 
export default CountryFilter;