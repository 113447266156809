import {
    ADD_COLECTION,
    GET_COLECTION,
    DELETE_MARK,
    SET_MARK,
    ADD_MARK,
    ADD_CONTENT_MARK,
    COLLECTION_WITH_LATEST_MARK
} from '../types/markTypes';

//state
const initialState = {
    colections: [],
    marks: [],
    collectionWithLatestMark: ''
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_CONTENT_MARK:

            return {
                ...state,
                colections: [...state.colections.filter(colection => colection.id !== action.payload),
                {
                    id: state.colections.find(colection => colection.id === action.payload).id,
                    name: state.colections.find(colection => colection.id === action.payload).name,
                    dateCreated: state.colections.find(colection => colection.id === action.payload).dateCreated,
                    total: state.colections.find(colection => colection.id === action.payload).total + 1
                }],
                marks: action.payload.marks
            }

        case GET_COLECTION:
            return {
                ...state,
                colections: action.payload.colections,
                marks: action.payload.marks
            }

        case ADD_COLECTION:
            return {
                ...state,
                colections: [...state.colections, action.payload]
            }

        case DELETE_MARK:
            return {
                ...state,
                marks: state.marks.filter(mark => mark.id !== action.payload)
            }

        case SET_MARK:
            return {
                ...state,
                marks: state.marks.filter(mark => mark.id === action.payload.id ? action.payload : mark)
            }

        case ADD_MARK:
            return {
                ...state,
                marks: [action.payload, ...state.marks]
            }

        case COLLECTION_WITH_LATEST_MARK:
            return {
                ...state,
                collectionWithLatestMark: action.payload
            }

        default:
            return state;
    }
}