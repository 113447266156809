import React from 'react'
import { useSelector } from 'react-redux';


const GpLacResult = () => {

    const grupo = useSelector(state => state.home.resultSelected);
    console.log('grupo', grupo);

    return (
        <div>
            <h3>{grupo.nombre[0]}</h3>
            <div>
                Lider: {grupo.lider[0] + ' ' + '(' + grupo.email[0] + ')'}
            </div>
            <br />
            <div>
                {grupo.hasOwnProperty('category') &&
                    <div>
                        Categoria: {grupo.clasificacion[0]}
                    </div>
                }
                <br />
                {grupo.hasOwnProperty('instituciones') &&
                    <div>
                        Instituciones: {grupo.instituciones.map(item => {
                            return (<div>
                                {item}
                            </div>);
                        })}
                    </div>
                }
                <br />
                <div>Articulos</div>

                {(grupo.hasOwnProperty('articulos_publicados') && grupo.articulos_publicados.length > 0) &&
                    grupo.articulos_publicados.map(item => {
                        let fixItem = item.split(';;')
                        console.log('fixxxxxxxxxx', fixItem);
                        return (
                            <div>
                                {fixItem.map(element => {
                                    return (
                                        <div style={{ textAlign: 'justify' }}>{element}</div>
                                    )
                                })}
                                <hr />
                            </div>
                        )
                    })
                }
                {grupo.hasOwnProperty('dep_city') &&
                    <div>
                        Ciudad: {grupo.dep_city}
                    </div>
                }
                <br />
                {grupo.hasOwnProperty('lineas_investigacion') &&
                    <div>
                        Lineas de investigación: {grupo.lineas_investigacion.map(item => {
                            return (
                                <div>
                                    {item}
                                </div>
                            )
                        })}
                    </div>
                }
            </div>

        </div>
    )
}

export default GpLacResult
