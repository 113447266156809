import React from 'react'
import { Link } from 'react-router-dom'


const NewItem = ({oneNew}) => {
    return (    
        <>
            <h1>{oneNew.name}</h1>
            <h4>Pais: {oneNew.country_code}</h4>
            <h4>Region: {oneNew.region}</h4>
            <h4>Ciudad: {oneNew.city}</h4>
            <Link to={{pathname: `/newpage/${oneNew.id}`}}>
                <button className='btn btn-outline-dark'>Conoce mas</button>
            </Link>     
        </>
      );
}
 
export default NewItem;