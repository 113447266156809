import React, {useState} from 'react'
import VideoConference from '../../../../auth/login/VideoConference'
import {useSelector} from 'react-redux';
import './jtsiStreaming.scss'
import {useHistory} from 'react-router-dom';

const JtsiStreaming = () => {

    const history = useHistory();


    const activeEvent = useSelector(state => state.calendar.activeEvent);
    const [jitsi,setJitsi] = useState(true);
    const onClose = () => {
        setJitsi(false);
        history.push('/expertApp');
    }

    return (
        <div className="streamingMain">
            <div className="streamingBox">
                {jitsi && 
                <VideoConference 
                    roomToGo={`sala${activeEvent.id.toString()}`} 
                    password={`password${activeEvent.id.toString()}`}
                    onClose={onClose}
                />}
            </div>
        </div>
    )
}

export default JtsiStreaming
