import React from 'react'
import Pie from '../../../../../../graphics/d3/PieGraphic/Pie'

const Pies = ({graphic,clickImage}) => {

    return ( 
        <>               
            {graphic.type === 'pie' &&
                <Pie
                    width={450}
                    height={450}
                    margin={20}
                    title={''}
                    type={graphic.data}
                    office={true}
                    d3PieClick={clickImage}
                />
            } 
        </>
     );
}
 
export default Pies;