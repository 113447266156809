import React, { useState, useEffect } from 'react'
import translateNew from '../../../../../../hooks/useTranslate'
import TranslateModal from '../../../../../ui/translateModal/TranslateModal'
import './newResult.scss'

const NewResult = ({ date, source, title, description, image, link }) => {

    const [descriptionText, setDescription] = useState('')
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(translate){
            translateText();
            return;
        }
        setDescription(description.split('\n'))
    }, [description, translate])

    const translateText = () => {
        translateNew(description).then(response => {
            setDescription(response)
        });
    }

    return (
        <div className="newResultMain">
            <div className="row">
                <div className="col-12 col-md-8">
                    <p style={{ color: 'var(--secundaryFirtFour)', fontSize: '23px' }}>{title}</p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-2">
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                </div>
            </div>
            <div className="row">
                <div className="item col-12 col-md-12 col-lg-12"><h6>Portal: </h6><p>{source[0]}</p></div>

                <div className="item col-12 col-md-6 col-lg-6"><h6>Fecha de publicación: </h6><p>{date[0].substr(0, 10)}</p></div>
                <div className="item col-12 col-md-6 col-lg-6">
                    <div className="redirect">
                        <img src={require('../../../../../../images/icons/arrowDirections/redirectIcon.jpg')} style={{ width: '20px', height: '20px' }} alt='' />
                        <a target="_blank" style={{ textDecoration: 'none' }} href={link}>Ir al portal</a>
                    </div>
                </div>

                {image && <img src={image} alt="alt text" style={{ float: 'right' }}></img>}
                {descriptionText !== '' &&
                    descriptionText.map(paragraph => {
                        return (
                            <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                {paragraph}
                            </p>
                        );
                    })}

            </div>
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} />
        </div>
    )
}
export default NewResult;

