import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setDatesFilterAction } from '../../../../actions/patentAction'

const DateFilter = ({setEndpointDates}) => {

    //redux
    const dispatch = useDispatch();
    const dates = useSelector(state => state.patent.datesFilter);

    /**States */
    const [startDate,setStartDate] = useState(dates.startDate);//fecha inicial seleccionada
    const [finalDate,setFinalDate] = useState(dates.finalDate);//fecha final seleccionada
    const [typeDate,setTypeDate] = useState(dates.type);//tipo de fecha seleccionada

    //mira si se ha eliminado el filtro de fecha para renderizar
    useEffect(() => {
        setStartDate(dates.startDate);
        setFinalDate(dates.finalDate);
        setTypeDate(dates.type);
        
    }, [dates])

    /**Funciones */
    //seleccion de tipo de fecha
    const dateSelected = (e) => {
        setTypeDate(e.target.value);
    }

    //envio de fechas a redux
    const sendDates = () => {
        if(typeDate === ''){
            alert('selecciona un tipo de filtro de fecha');
        }else{
            //prepara variale y envia a redux store
            var dateToSend = {type:typeDate,startDate:startDate,finalDate:finalDate};
            dispatch(setDatesFilterAction(dateToSend));

            //variables de fecha inicial y final para el endpoint
            let startPoint = '';
            let finalPoint = '';

            //la variable cambia dependiendo del filtro de fecha seleccionado por el usuario 
            if(typeDate === 'aplicacion'){
                startPoint='&date_apli1=';
                finalPoint = '&date_apli2=';
            }else if(typeDate === 'publicacion'){
                startPoint='&date_pub1=';
                finalPoint = '&date_pub2=';
            }else if(typeDate === 'prioridad'){
                startPoint='&date_pro1=';
                finalPoint = '&date_pro2=';
            }

            //prepara endpoint
            const endpoint = startPoint+startDate.toISOString()+finalPoint+finalDate.toISOString();

            //actualiza el endpoint para mostart el filtro al usuario
            setEndpointDates(endpoint);
        }
    }

    return ( 
        <>
             {/* Aquí DatePicker inicial */}
                <div className="filterContain">
                    {/** div para seleccion tipo de fecha */}
                    <div className='mb-2'>
                        <div>
                            <div>
                                <input type="radio" onChange={dateSelected} checked ={ typeDate === 'aplicacion' ? true : false} value="aplicacion" name="gender" /> Filtrar por fecha de aplicación
                            </div>
                            <div>                                
                                <input type="radio" onChange={dateSelected} checked ={ typeDate === 'publicacion' ? true : false} value="publicacion" name="gender" /> Filtrar por fecha de publicación
                            </div>
                            <div>                                
                                <input type="radio" onChange={dateSelected} checked ={ typeDate === 'prioridad' ? true : false} value="prioridad" name="gender" /> Filtrar por fecha de prioridad
                            </div>
                        </div>
                    </div>
                    
                    {/** Row para seleccion de fecha */}
                    <div className="row mb-1">
                        <div className="col-md-5 col-lg-5 col-xl-5">
                            {/* Aquí DatePicker inicial */}
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="filterTextDate">Fecha inicial</div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(e) => setStartDate(e)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-lg-5 col-xl-5">
                            {/* Aquí DatePicker final */}
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <div className="filterTextDate">Fecha final</div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <DatePicker
                                        selected={finalDate}
                                        onChange={(e) => setFinalDate(e)}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2" style={{ paddingLeft: 10, paddingRight: 0, marginTop: 30}}>
                            <div className="btnPatents" style={{ width: '65%' }} onClick={sendDates}>
                                <span className="icon-checkmark" style={{ fontSize: '.85em' }}></span>
                            </div>
                        </div>
                    </div>
                </div>
        </>
     );
}
 
export default DateFilter;