import React, { useState, useEffect } from "react";
import ClienteAxios from "../../../config/axios";
import "./myProfile.scss";

//redux
import { useSelector } from "react-redux";
import { pointer } from "d3";

const MyProfile = () => {
    //redux
    const {
        firstname,
        lastname,
        charge,
        area,
        email,
        phone,
        company,
        nit,
        img_profile,
        userId,
    } = useSelector((state) => state.auth);

    //**States */
    const [profile, setProfile] = useState({
        userId: userId,
        name: firstname,
        lastName: lastname,
        charge: charge,
        area: area,
        company: company,
        nit: nit,
        email: email,
        phone: phone,
        file: null,
    });

    //**Funciones */
    const profileChange = (e) => {
        if ([e.target.name][0] === "file") {
            setProfile({
                ...profile,
                file: e.target.files[0],
            });
        } else {
            setProfile({
                ...profile,
                [e.target.name]: e.target.value,
            });
        }
    };

    const sendProfile = async () => {
        const config = {
            headers: {
                "content-type": "multipart/form-data",
            },
        };
        const formData = new FormData();
        profile.file && formData.append("file", profile.file);
        formData.append("profile", JSON.stringify(profile));
        //var file = profile.file
        var rta = await ClienteAxios.post("/profile/update", formData);
    };

    return (
        <div className="row myProfileMain">
            dasdas
            <div className="col-6 offset-md-4">
                <div className="w-75">
                    <div style={{ width: "100%" }}>
                        <p className="mt-5 title">Datos del perfil</p>
                    </div>
                    {/**ID de cuenta */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">
                            Nombre de usuario
                        </label>
                        <div
                            className="col-sm-6"
                            style={{ paddingLeft: "15px" }}
                        >
                            <label className="col-sm-12 col-form-label disabled">
                                Lokom
                            </label>
                        </div>
                    </div>
                    {/**ID de cuenta */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">
                            ID de cuenta
                        </label>
                        <div
                            className="col-sm-6"
                            style={{ paddingLeft: "15px" }}
                        >
                            <label className="col-sm-12 col-form-label disabled">
                                {profile.userId}
                            </label>
                        </div>
                    </div>
                    {/**Nombres */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">
                            Nombres
                        </label>
                        <div className="col-sm-6">
                            <input
                                name="name"
                                value={profile.name}
                                type="text"
                                className="form-control"
                                onChange={profileChange}
                                placeholder=""
                            />
                        </div>
                    </div>
                    {/**Apellidos */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">
                            Apellidos
                        </label>
                        <div className="col-sm-6">
                            <input
                                name="lastName"
                                value={profile.lastName}
                                type="text"
                                className="form-control"
                                onChange={profileChange}
                                placeholder=""
                            />
                        </div>
                    </div>
                    {/**Correo Electronico */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">
                            Correo Electronico
                        </label>
                        <div className="col-sm-6">
                            <input
                                name="email"
                                value={profile.email}
                                type="email"
                                className="form-control"
                                onChange={profileChange}
                                placeholder=""
                            />
                        </div>
                    </div>
                    {/**Celular */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">
                            Celular
                        </label>
                        <div className="col-sm-6">
                            <input
                                name="phone"
                                value={profile.phone}
                                type="text"
                                className="form-control"
                                onChange={profileChange}
                            />
                        </div>
                    </div>
                    {/**Foto Perfil */}
                    <div className="form-group row  mt-4">
                        <label className="col-sm-6 col-form-label">foto</label>
                        <div className="col-sm-6">
                            {/*<div className="col-sm-6 inputFile" />
                            <input name='file' type="file" className="form-control inputFileTag" onChange={profileChange} />*/}
                            {/*<input type="file" class="custom-file-input" value = "" onChange={profileChange}></input>*/}

                            <label for="file-upload" class="custom-file-upload">
                                <p className="msje">Seleccionar archivo</p>
                            </label>
                            <input
                                onChange={profileChange}
                                id="file-upload"
                                type="file"
                            />
                        </div>
                    </div>
                    <div style={{ width: "100%", marginTop: "3rem" }}>
                        <button
                            onClick={sendProfile}
                            className="sendButton mt-2 btn btn-primary p-2"
                        >
                            <label style={{ cursor: "pointer", color: "#fff" }}>
                                GUARDAR CAMBIOS
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            {/*<div className='col-6'>
                <img src={`http://127.0.0.1:8000/storage/${img_profile}`} alt="imagen de perfil"/>
            </div>*/}
        </div>
    );
};

export default MyProfile;
