import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux';
import cookie from 'react-cookies'

//redux
import { useDispatch } from 'react-redux';
import { loginTokenAction } from '../../../actions/authAction'

const ProtectedRoute = ({...props}) => {
    //componente prop
    const Component = props.component;

     //redux
     const dispatch = useDispatch();
     const autenticated = useSelector(state => state.auth.autenticated);
     const profiled = useSelector(state => state.auth.profiled);
     //useEffect
     useEffect(() => {
        if(cookie.load('token')){
            dispatch(loginTokenAction());
        }
     }, [])// eslint-disable-line react-hooks/exhaustive-deps

    if(cookie.load('token')){
        if(autenticated && profiled === 0){
            return <Redirect to={{ pathname: '/profile' }} />
        }else if(autenticated && profiled === 1){
            return <Redirect to={{ pathname: '/homeApp' }} />
        }else{
            return ( <h1>Loading...</h1>)
        }
    }else{
        return  <Component />
    }
    
    
}

export default ProtectedRoute;