import {
    NEW_EXPERT, 
    GET_EXPERT,
    SEARCH_EXPERT,
    EXPERT_SELECTED,
    FIND_EXPERT
} from '../types/expertTypes';

const initialState = {
    user_id: null,
    headline: '',
    specialties: '',
    education: '',
    disponibilityFrom: '',
    disponibilityTo: '',
    suspended: null,
    experts:[],
    amIexpert:'',
    expertSelected:{},
    pages:null,
    results:null,
    keywordToFindExpert:''
}

export default function (state = initialState, action) {
    
    switch(action.type) {
        case NEW_EXPERT:
            return {
                ...state,
                user_id: action.payload.user_id,
                headline: action.payload.headline,
                specialties: action.payload.specialties,
                education: action.payload.education,
                disponibilityFrom: action.payload.disponibilityFrom,
                disponibilityTo: action.payload.disponibilityTo,
                suspended: 0
            }
        case GET_EXPERT: return {
            ...state, 
            experts:action.payload.experts.data,
            results:action.payload.experts.total,
            pages:action.payload.experts.last_page,
            amIexpert:action.payload.amIexpert
        }

        case FIND_EXPERT: return{
            ...state,
            keywordToFindExpert: action.payload
        }

        case EXPERT_SELECTED: return {
            ...state, 
            expertSelected: action.payload
        }
        
        case SEARCH_EXPERT:
            return {
                ...state, 
                experts:action.payload.data,
                results:action.payload.total,
                pages:action.payload.last_page,
            }

        default:
            return state;
    }
}