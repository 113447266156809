import React, {useState} from 'react';
import * as d3 from 'd3v5';
import ReactTooltip from "react-tooltip";
import { selectAll } from 'd3';

const Pie = (props) => {

  const chart = React.useRef();
  const { width, height, margin, type, title } = props;
  const [tooltipContent, setTooltipContent] = useState("");
  const colors = ['#613FA0', '#7D94C2', '#C0B2D9', '#E7E7E7'];
    // init all d3-chart related data here.

    const renderChart = () => {
        let radius = Math.min(width, height) / 2 - margin;

        // select d3 chart using "useRef" instance
        let svg = d3.select(chart.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', "translate(" + width / 2 + "," + height / 2 + ")");

        // set dummy data
        let data = type;

        // set the color scale
        let color = d3.scaleOrdinal()
            .domain(data)
            .range(colors);

        let pie = d3.pie()
            .value(function (d) { return d.value; });
        let data_ready = pie(d3.entries(data));

        svg
            .selectAll('#pie')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('d', d3.arc()
                .innerRadius(0)
                .outerRadius(radius)
            )
            .attr('fill', function (d) { return (color(d.data.key)) })
            .attr("stroke", "black")
            .style("stroke-width", "1px")
            .style("opacity", 0.7)
            .on('mouseover', function (d) {return setTooltipContent(d.data.value)})
            .on('click',function (d) {return props.d3PieClick(d.data.key);})
            .on('mouseout',function () {return setTooltipContent('')});

    }

    React.useEffect(() => {
        renderChart();
    }, [])

    const sortable = []
    for (var item in type) {
        sortable.push([item, type[item]]);
    }
    
    return (
        <div className="piechart_container m-2">
            {title && <h5>{title}</h5>}
            <div className="d-flex justify-content-center align-items-center">
                <svg id="pie" data-tip="" width={width} height={height} ref={chart} />
                <div className="d-flex">
                    {sortable.length > 0 && sortable.map((item, index) => {
                        return <div key={index} >
                        <div style={{"background": `${colors[index]}`, "display": "block", "width": "20px", "height": "20px"}}></div><h6 className="mr-4">{item[0]}</h6>
                        </div>
                    })}
                </div>
            </div>
            <ReactTooltip>{tooltipContent}</ReactTooltip>
        </div>
    )
}

export default Pie;