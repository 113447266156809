import React, { useState } from 'react'
import Verticalbars from './verticalBars/index'
import HorizontalBars from './horizontalBars/index'
import Maps from './maps/index'
import Pies from './pies/index'
import Linear from './linear/index'
import LineChart from '../../../../../graphics/chartjs/Line/LineChart'
import lock from '../../../../../../images/icons/modules/graphics/lock.png'
import fix from '../../../../../../images/icons/modules/graphics/fix.png'
import exit from '../../../../../../images/icons/modules/graphics/exit.png'
import filter from '../../../../../../images/searchBox/filterLight.png'
import { useDispatch } from 'react-redux';
import './graphics.scss'
import { deleteGraphicAction, lockGraphicAction } from '../../../../../../actions/graphicAction'
import { useSelector } from 'react-redux'
import clienteAxios from '../../../../../config/axiosBigData'
import PatentTable from '../../tableOfResults/tablesComponents/patentTable/PatentTable'
import { addMarkAction } from '../../../../../../actions/markAction';
import { registerSelected } from '../../../../../../actions/homeAction';
import DoubleLineChart from '../../../../../graphics/chartjs/Line/DoubleLineChart'
import folder from '../../../../../../images/icons/colorModulesIcons/folder.jpg'
import ReactTooltip from "react-tooltip";
import { useHistory } from 'react-router-dom';

//images
import noticias from '../../../../../../images/icons/colorModulesIcons/news.png';
import patentes from '../../../../../../images/icons/colorModulesIcons/patents.png';
import videos from '../../../../../../images/icons/colorModulesIcons/Videos.png';
import Startups from '../../../../../../images/icons/colorModulesIcons/Startups.png';
import events from '../../../../../../images/icons/colorModulesIcons/events.png';
import article from '../../../../../../images/icons/colorModulesIcons/articles.png';
import Treemap from '../../../../../graphics/d3/TreeMapsGraphic/Treemap'

const Graphics = ({ graphic }) => {
    console.log('Graphics', graphic);

    const [imageFilter, setImageFilter] = useState('');
    const [results, setResult] = useState('');
    const search = useSelector(state => state.home.search);
    const apiKey = useSelector(state => state.auth.apiKey);
    const colections = useSelector(state => state.mark.colections);
    const [id, setId] = useState('');
    const history = useHistory();
    const dispatch = useDispatch();

    const deleteGraphic = () => {
        dispatch(deleteGraphicAction(graphic.id));
    }

    const lockChange = () => {
        dispatch(lockGraphicAction(graphic.id));
    }

    const clickImage = async (filter) => {
        setImageFilter(filter);
        if (graphic.source === 'Patentes') {
            var finalDate = filter.substring(0, 4) + '-' + '12' + '-' + '31';
            var response = await clienteAxios.get(`?url=patentes&q=${search}&start=0&rows=12&key=${apiKey}&date1=${filter}&date2=${finalDate}`);
            response.data.items = response.data.items.map(patent => {
                patent.category = 'patent';
                return patent;
            });
            var newResult = processData(response.data.items);
            setResult(newResult);
        }
    }

    const goToResult = async (r) => {
        if (r.category === 'empty' || r.category === 'fail') {
            return;
        }
        await dispatch(registerSelected(r));
        history.push('/resultSelected')
    }

    const processData = (results) => {
        return results.map(r => {
            if (r !== "fail" && r !== "empty") {
                r.description = r.description_en ? r.description_en[0] :
                    r.description_es ? r.description_es[0] :
                        r.descripcion ? r.descripcion[0] :
                            r.description ? r.description[0] :
                                r.abstract_en ? r.abstract_en[0] :
                                    r.summary ? r.summary[0] :
                                        r.description_es ? r.description_es[0] : '';

                r.title = r.title_en ? r.title_en[0] :
                    r.title_es ? r.title_es[0] :
                        r.name ? r.name[0] :
                            r.titulo ? r.titulo[0] :
                                r.title ? r.title[0] :
                                    r.invention_title_en ? r.invention_title_en[0] : '';

                r.source = r.category === 'video' ? 'YouTube' : r.portal ? r.portal : '';

                r.datePublication = r.pubDate ? r.pubDate :
                    r.pubdate ? r.pubdate[0] :
                        r.publishedAt ? r.publishedAt :
                            r.fecha ? r.fecha :
                                r.date_produced ? r.date_produced : '';

                r.thumbnail = r.thumbnails ? r.thumbnails :
                    r.picture ? r.picture[0] :
                        r.thumbnail ? r.thumbnail[0] : '';

                switch (r.category) {
                    case 'new':
                        r.icon = noticias
                        break;
                    case 'patent':
                        r.icon = patentes
                        break;

                    case 'video':
                        r.icon = videos
                        break;

                    case 'startup':
                        r.icon = Startups
                        break;

                    case 'event':
                        r.icon = events
                        break;

                    case 'article':
                        r.icon = article
                        break;

                    case 'empty':
                        r.empty = 'No se encontraron resultados';
                        break;

                    case 'fail':
                        r.fail = 'Error de conexión'
                        break;
                    default:
                        break;
                }
                return r;
            }
        });
    }

    const addMark = (mark, colectionId) => {
        var markToAdd = {
            colection_id: colectionId
        }
        if (mark.category === 'patent') {
            markToAdd.id_mongo = mark.id;
            //markToAdd.tag = 'Patente';
            markToAdd.tag = 'patents';
            markToAdd.title = mark.invention_title_en[0];
        } else {
            if (mark.category === 'new') {
                //markToAdd.tag = 'Noticia';
                markToAdd.tag = 'news';
                markToAdd.title = mark.title;
                //markToAdd.id_mongo = mark._id.$oid;
                markToAdd.id_mongo = mark.id;
            } else if (mark.category === 'event') {
                //markToAdd.tag = 'Evento';
                markToAdd.tag = 'events';
                markToAdd.title = mark.title;
                markToAdd.id_mongo = mark.id;
                markToAdd.addDate = new Date();
            } else if (mark.category === 'startup') {
                //markToAdd.tag = 'Startup';
                markToAdd.tag = 'Startups';
                markToAdd.title = mark.title;
                markToAdd.id_mongo = mark.id;
            }

        }
        dispatch(addMarkAction(markToAdd));
    }

    const viewDescription = (r) => {
        if (id === '') {
            setId(r.id);
        } else {
            setId('');
        }
    }

    return (
        <div className='graphics-result lantia-square mt-3'>
            <div className='grap-result-header d-flex justify-content-between'>
                <div>

                </div>
                <div>
                    <p>{graphic.name}</p>
                </div>
                <div className='row'>
                    <ReactTooltip id="registerTip" place="top" effect="solid">
                        fijar gráfica
                    </ReactTooltip>
                    {graphic.lock ? <img data-tip data-for="registerTip" onClick={lockChange} className='img-grap-header' src={fix} alt="" /> :
                        <img data-tip data-for="registerTip" onClick={lockChange} className='img-grap-header' src={lock} alt="" />}
                    <img className='img-grap-header' src={filter} alt="" />
                    <img onClick={deleteGraphic} className='img-grap-header' src={exit} alt="" />
                </div>
            </div>
            <div className='justify-content-center align-items-center row'>
                {graphic.type === 'vertical' &&
                    <Verticalbars
                        graphic={graphic}
                        clickImage={clickImage}
                    />
                }
                {graphic.type === 'horizontal' &&
                    <HorizontalBars
                        graphic={graphic}
                        clickImage={clickImage}
                    />
                }
                {graphic.type === 'pie' &&
                    <Pies
                        graphic={graphic}
                        clickImage={clickImage}
                    />
                }
                {/* {graphic.type === 'linear' &&
                    <Linear
                        graphic={graphic}
                        clickImage={clickImage}
                    />
                } */}
                {(graphic.type === 'linear' && graphic.data.hasOwnProperty('labels')) &&
                    <LineChart
                        labels={graphic.data.labels}
                        datos={graphic.data.data}
                        type={graphic.source}
                    />
                }
                {graphic.type === 'tree' &&
                    <Treemap
                        chartHeight={400}
                        colorRange={["rgba(0,0,255, 0.1)", "rgba(0,0,255, 1)"]}
                        data={graphic.data}
                    />
                }
                {(graphic.type === 'linear' && graphic.data.hasOwnProperty('_labels')) &&
                    <DoubleLineChart
                    labels={graphic.data._labels}
                    datos={graphic.data.data}
                    type={graphic.source}
                    />
                }
            </div> {graphic.type === 'maps' &&
                <Maps
                    graphic={graphic}
                    clickImage={clickImage}
                />
            }

            {/* {imageFilter !== '' &&
                <div className='graphic-filter'>
                    <p>Filtro activo, palabra clave:{search} , source:, {graphic.source}, filtro: {imageFilter}</p>
                    {(results && graphic.source === 'Patentes') &&
                        <PatentTable results={results}
                            goToResult={goToResult}
                            addMark={addMark}
                            folder={folder}
                            colections={colections}
                            viewDescription={viewDescription}
                            id={id}
                        />
                    }
                    {(results && graphic.source === 'Eventos') &&
                        <p>tabla de eventos</p>
                    }
                </div>
            } */}
        </div>
    );
}

export default Graphics;