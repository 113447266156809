import clienteAxios from 'axios'
import { convoStates } from '../globalConstants/convoStates'
import { NORMAL_SEARCH, ADVANCED_SEARCH } from '../globalConstants/typeOfSearch'

export const fetchData = async (apiKey, keyword, currentPage, s, rows, params, typeOfSearch) => {
    try {
        let response = '';

        if (typeOfSearch === NORMAL_SEARCH) {            
            response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=${s.source}&q=${keyword.replace(/ /g, "+")}&start=${currentPage}&${params}&rows=${rows}&key=${apiKey}`);
        }
        if (typeOfSearch === ADVANCED_SEARCH) {
            console.log('params del fetchdata', params);
            response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=advanced_search&date1=${params.date1}&date2=${params.date2}&fuente=${params.filtersChosen}&filtro=${params.finalFilter}&start=${currentPage}&rows=14`);
            
            let pages = (response.data.no_results/14)
            response.data.no_pages = pages < 1 ? 1 : pages.toString().substr(0,1)
        }
        let lastRegisters = response.data.hasOwnProperty('results') ?
            response.data.results - rows :
            response.data.hasOwnProperty('no_results') ?
                response.data.no_results - rows : 0
        let totalPages = response.data.no_pages;

        let totalResults = response.data.hasOwnProperty('no_results') ? response.data.no_results : response.data.results;
        
        response = response.data.items;
        //agrega propiedad 'category'
        if (response.length !== 0) {

            response = response.map(n => {
                if(s.category === 'cv_lac'){
                    n.categoria =n.category
                }
                n.category = s.category;
                return n;
            });

            let filteredResponse = [];
            if (s.source === 'convocatorias') {

                response.map(item => {
                    if (!convoStates.includes(item.estado[0])) {
                        filteredResponse.push(item);
                    }

                });

            } else {
                filteredResponse = response;
            }
            return [filteredResponse, lastRegisters, totalPages, totalResults];
        } else {
            //sin resultados
            return [['empty'], ['empty']]
        }

    } catch (error) {
        return [['fail'], ['fail']]
    }
}