import React, { useState, useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { addMarkAction, setColectionWithLatestMark } from '../../../../../../actions/markAction';
import AddMark from '../../../../../../helpers/AddMark';
import folder from '../../../../../../images/icons/colorModulesIcons/folder.jpg'
import blueFolder from '../../../../../../images/icons/colorModulesIcons/blueFolder.png'
import { useDispatch, useSelector } from 'react-redux';
import {MIS_ECUACIONES} from '../../../../../../globalConstants/reservedColection'
import './saveFavouritesDropdown.scss'

const SaveFavouritesDropdown = ({item}) => {


    const [showQuestion, setShowQuestion] = useState(true);
    const collectionWithLatestMark = useSelector(state => state.mark.collectionWithLatestMark);
    const colections = useSelector(state => state.mark.colections);
    const marks = useSelector(state => state.mark.marks);
    const showListOfCollections = useRef(collectionWithLatestMark === '' ? true : false)
    const saveFromList = useRef(false)

    //redux
    const dispatch = useDispatch();

    const changeStates = () => {
        showListOfCollections.current = true
        setShowQuestion(false)
    }

    const saveInCollection = (register, collection) => {
        saveFromList.current = false
        setShowQuestion(true)
        if (collection !== '') {
            setTimeout(() => {
                setShowQuestion(false)
                if (!saveFromList.current) {
                    _addMark(register, collection);
                }
            }, 3000);
        }
    }

    const _addMark = (mark, colection) => {
        dispatch(setColectionWithLatestMark(colection))
        setTimeout(() => {
            if (showListOfCollections.current) {
                return;
            }
            dispatch(addMarkAction(AddMark(mark, colection.id)));
            let elem = document.querySelector('#dropdownMark');
            if (elem !== null) {
                elem.remove('show');
            }
        }, 3000);
    }


    return (<div>
        <Dropdown>
            <div onClick={() => saveInCollection(item, collectionWithLatestMark)}>
                <Dropdown.Toggle className="btn-dropdown-header" id="dropdown-basic">
                    <img
                        src={marks !== undefined ? marks.find(mark => mark.id_mongo === item.id) !== undefined ? blueFolder : folder : folder}
                        className="header-link"
                        style={{ width: '13px', height: '11px', borderRadius: '0' }} />

                </Dropdown.Toggle>
            </div>
            <Dropdown.Menu id="dropdownMark">
                {colections &&
                    <div>
                        <div className="selectOtherColl">
                            {collectionWithLatestMark !== '' &&
                                <div style={{ display: 'inline-flex' }}>
                                    <div className="collectionSelected">{'se ha guardado en ' + collectionWithLatestMark.name}</div>
                                    {showQuestion &&
                                        <div className="changeButton" onClick={changeStates}>
                                            Cambiar
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div>
                            {showListOfCollections.current &&
                                colections.filter(colection => colection.name !== MIS_ECUACIONES).map(colection => (
                                    <div className="collectionItem" onClick={() => {
                                        _addMark(item, colection);
                                        showListOfCollections.current = false
                                        saveFromList.current = true
                                        setTimeout(() => {
                                            setShowQuestion(false);
                                        }, 3000);
                                    }
                                    }>{colection.name}</div>
                                ))
                            }
                        </div>
                    </div>
                }
            </Dropdown.Menu>
        </Dropdown >
    </div>);
}
export default SaveFavouritesDropdown
