import React, { Fragment, useState } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
    ComposableMap,
    Geographies,
    Geography,
    Sphere,
    Graticule
} from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import './geochart.scss'


const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale = scaleLinear()
    .domain([0, 4000])
    .range(["#56c2ea", "#013B54"]);


const GeoChart = ({ countries, d3Click }) => {
    const [tooltipContent, setTooltipContent] = useState("");
    const dataset = [...Object.values(countries)];
    let firstData = dataset.map((item, index) => {
        let obj = {}
        obj['ISO2'] = Object.keys(countries)[index];
        obj['value'] = item;
        return obj;
    }, {})

    let data = firstData.filter(item => item.ISO2.length < 3)

    const max = data.reduce(item => Math.max(item.value))

    return (
        <div className="geochartmain">
            <div className="card m-2">
                <ComposableMap
                    height='400'
                    data-tip=""
                    projectionConfig={{
                        rotate: [-10, 0, 0],  //-250 0 1300 700
                        scale: 120
                    }}
                >
                    <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
                    <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
                    {countries && (
                        <Geographies geography={geoUrl}>
                            {({ geographies }) =>
                                geographies.map(geo => {
                                    const d = data.find(s => s.ISO2 === geo.properties.ISO_A2);
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            onClick={() => {
                                                const { NAME } = geo.properties;
                                                const { value } = d != undefined ? d : 0;
                                                d != undefined ?
                                                    d3Click((`${NAME}`)) :
                                                    d3Click(`${NAME}`);
                                            }}
                                            onMouseEnter={() => {
                                                const { NAME } = geo.properties;
                                                const { value } = d != undefined ? d : 0;
                                                d != undefined ?
                                                    setTooltipContent(`${NAME} — ${(value)}`) :
                                                    setTooltipContent(`${NAME} — 0`)
                                                    ;
                                            }}
                                            onMouseLeave={() => {
                                                setTooltipContent("");
                                            }}
                                            style={{
                                                default: {
                                                    fill: `${d ? colorScale(d["value"]) : "#d2e0ee"}`,
                                                    outline: "none"
                                                },
                                                hover: {
                                                    fill: `#013B54`,
                                                    outline: "none"
                                                },
                                                pressed: {
                                                    fill: "#E42",
                                                    outline: "none"
                                                }
                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    )}
                </ComposableMap>
                <ReactTooltip>{tooltipContent}</ReactTooltip>
                <div className="d-flex">
                    {/*<div style={{"background": "#0A1C48", "display": "block", "width": "50px", "height": "20px"}}></div>
        <div style={{"background": "#5F4A9E", "display": "block", "width": "50px", "height": "20px"}}></div>
        <div style={{"background": "#C4BED4", "display": "block", "width": "50px", "height": "20px"}}></div>
        <div style={{"background": "#D8D6DF", "display": "block", "width": "50px", "height": "20px"}}></div>
        <div style={{"background": "#E7E7E7", "display": "block", "width": "50px", "height": "20px"}}></div>*/}
                </div>
                <div className="leyendBox">
                    <p>Cantidad de patentes por país</p>
                    <div style={{ display: "inline-flex" }}>
                        <p className="moreOrless">-</p>
                        <div className="leyend"></div>
                        <p className="moreOrless">+</p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GeoChart;