import React, { useState, useEffect } from 'react'
import './repository.scss'
import Colection from './colection/Colection'
import { useHistory } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux'
import { addColectionAction, getColectionAction } from '../../../actions/markAction'


const MarksIndex = () => {

    //redux
    const dispatch = useDispatch();
    const colections = useSelector(state => state.mark.colections);
    const autenticated = useSelector(state => state.auth.autenticated);
    const history = useHistory();

    //states
    const [colection, setColection] = useState('');
    const [visibleRepository, setVisibleRepoitory] = useState(false);

    const addColection = (e) => {
        if (e.key === 'Enter') {
            dispatch(addColectionAction(colection))
            setColection('');
        }
    }

    const goToColection = (colect) => {
        history.push(`/myInformation/${colect.id}`);
    }


    return (
        <div style={visibleRepository ?{width:'15rem'}: {width:'0rem'}} className='marksMain'>
            {autenticated === true &&
                <div style={{ display: 'inline-flex', backgroundColor:'var(--primaryBlueThree)', borderRadius: '20px 0 0 20px', paddingLeft:`${visibleRepository ? '16px':'0px'}`}}> 
                    <div onClick={() => setVisibleRepoitory(!visibleRepository)} className="marksHidden">
                    </div>
                    {visibleRepository &&
                        <div>
                            <div style={{ display: 'inline-flex' }}>
                                <p className='title'>MI INFORMACIÓN</p>
                                <img style={{ marginTop: '13px', marginRight: '10px' }} src={require('../../../images/icons/colorModulesIcons/whiteFolder.jpg')} width='15' height='15' alt="" />
                            </div>
                            <div className='add-colection-button'>
                                <input
                                    onKeyDown={addColection} onChange={(e) => setColection(e.target.value)}
                                    className='form-control-sm add-colection-input mb-4 inputColection'
                                    type="text"
                                    style={{ direction: 'ltr' }}
                                    value={colection}
                                    maxLength='50'
                                    placeholder='Crear una colección'
                                />
                            </div>
                            <div className='divcolection'>
                                {colections !== undefined &&
                                    colections.map((colect, index) => (
                                        <div onClick={() => goToColection(colect)} style={{ cursor: 'pointer' }}>
                                            <Colection
                                                key={index}
                                                colection={colect}
                                            />
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                    }

                </div>
            }
        </div>
    );
}

export default MarksIndex;