import React from 'react'
import './graphicalAnalysis.scss'
import Header from './header'
import Graphics from './graphics'
import Gbody from './GraphicalAnalysis.js'
import GraphicTemplate from '../../../../ui/skeletonTemplates/graphicTemplate/GraphicTemplate'

//redux
import { useSelector } from 'react-redux'

const GraphicalAnalysis = () => {

    const graphicArray = useSelector(state => state.graphic.graphicArray);
    const loading = useSelector(state => state.graphic.loading);

    return (
        <>
            <div className='graphical-analysis'>
                <Header />
                {loading ?
                    <GraphicTemplate /> :
                    graphicArray.map((graphic, index) => {
                        return (
                            <>
                                <Graphics
                                    key={index}
                                    graphic={graphic}
                                />
                            </>
                        )
                    })}
            </div>
        </>
    );
}

export default GraphicalAnalysis;