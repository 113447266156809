import {
    GET_PATENTS,
    SET_SEARCH,
    SET_GRAPHICS_ARRAY,
    SET_GRAPHICS_SCURVE,
    SET_GRAPHICS_IPC,
    SET_FILTER_ENDPOINT,
    SET_ADVANCED_ENDPOINT,
    SET_CONNECTOR_ASSISTED,
    SET__MODE_ADVANCED,
    SET__EQUATION_ADVANCED,
    DELETE_EQUATION,
    SET_TYPES_FILTER,
    DELETE_TYPES_FILTER,
    SET_COUNTRIES_FILTER,
    DELETE_COUNTRIES_FILTER,
    SET_IPC_FILTER,
    DELETE_IPC_FILTER,
    SET_DATES_FILTER,
    DELETE_DATES_FILTER
} from '../types/patentTypes';
import clienteAxios from '../components/config/axiosBigData';

//funcion para traer patentes de la bd
export function getPatentsAction(page,equation){  
    return async(dispatch,getState) => {
        try {      
                //variable de filtros de redux para los dos casos
                const filter = getState().patent.filterEndpoint;
                const apiKey = getState().auth.apiKey;

                //variables para los dos casos(1.busqueda avanzada, 2.cualquier otro llamado)
                let endpoint = '';
                let endpointGraphics = '';
                let endpointGraphicsIpc = '';
                let endpointGraphicSCurve = '';
                if (typeof equation !== 'undefined') {
                    //guarda ecuacion
                    dispatch(setEquation(equation));
                    //prepara variable endpoint para ser utilizada en axios                
                    endpoint =`patentes?${equation}&start=${page}${filter}&key=${apiKey}`;
                }else{
                    //variable de filtros de redux
                    const advanced = getState().patent.advancedEndPoint;
                    const search = getState().patent.search;

                    //prepara variable endpoint para ser utilizada en axios                
                    endpoint =`patentes?q=${search}&start=${page}${filter}${advanced}&key=${apiKey}`;
                    //creamos endpoints para gráficas
                    endpointGraphics =`patents_analytics_data?q=${search}${filter}${advanced}&key=${apiKey}`;
                    endpointGraphicsIpc =`ipc_graphic?q=${search}${filter}${advanced}&key=${apiKey}`;
                    endpointGraphicSCurve =`curve_s?q=smart&q=${search}${filter}${advanced}&Year_predict=2023&key=${apiKey}`;

                }
                //endpoint de la bd
                const response = await clienteAxios.get(endpoint); 

                //agrega pagina a response
                response.data.page = page;

                //Guarda en redux storage las 15 patentes
                dispatch(getPatents(response.data));
                //Tratamiento para array de gráficas
                const responseGraphics = await clienteAxios.get(endpointGraphics); 
                dispatch(setGraphicArray(responseGraphics.data));

                const responseGraphicsSCurve= await clienteAxios.get(endpointGraphicSCurve); 
                dispatch(setGraphicSCurve(responseGraphicsSCurve.data));

                const responseGraphicsIpc = await clienteAxios.get(endpointGraphicsIpc); 
                dispatch(setGraphicIpc(responseGraphicsIpc.data));

            return 1;
        } catch (error) {
            return 0;
        }
    }
}

////////***********************************FUNCIONES DE BUSQUEDA***********************************////////////////

//funcion para cambiar el search del store de redux
export function setSearchAction(search,direction){    
    return async(dispatch) => {
        try {
            //cambia el search de la bd
            dispatch(setSearh(search));

            //click = usuario dio click en buscar, keyboard = usuario cambio una letra 
            if(direction === 'click'){                
                //guarda log en bd
                await dispatch(insertLog('search'));

                //hace la nueva busquda en el endpoint
                await dispatch(getPatentsAction(0));
            }                        
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para cambiar el endpoint con busqeuda avanzada del store de redux
export function setAdvancedAction(endpoint){    
    return async(dispatch) => {
        try {
            //cambia el advancedEndPoint de la bd
            await dispatch(setAdvanced(endpoint));  

            //hace la nueva busquda en el endpoint
            await dispatch(getPatentsAction(0));                     
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para cambiar las palabras de busqueda asistida a subrayar
export function setAssistedWordsAction(connector){    
    return async(dispatch) => {
        try {             
            //cambia la variable
            await dispatch(setAssistedWords(connector));                     
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//cambio de modo de busqueda avanzada
export function setModeAdvancedAction(mode){    
    return async(dispatch,getState) => {
        try {
            //variables de redux
            const advancedSearch = getState().patent.advancedSearch;

            //si advancedSearch != mode quiere decir que esta ubicada en otra ventana, si es la misma tendra que cerrarla
            advancedSearch !== mode ? dispatch(setModeAdvanced(mode)) : dispatch(setModeAdvanced(''));
                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//cambio de modo de busqueda avanzada
export function deleteEquationAction(){    
    return async(dispatch) => {
        try {
            //llama funcion que elimina ecuacion
            dispatch(deleteEquation());                              
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

////////***********************************FUNCIONES DE FILTROS***********************************////////////////

//funcion para cambiar el endpoint con filtro del store de redux
export function setFilterAction(filter){    
    return async(dispatch) => {
        try {
            //cambia el filterendpoint de la bd
            dispatch(setFilter(filter));
                        
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//agrega o elimina fecha inicial
export function setDatesFilterAction(date){    
    return async(dispatch) => {
        try {            
            dispatch(setDatesFilter(date));                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//elimina todos los filtros de tipo de patente
export function deleteDateFilterAction(){    
    return async(dispatch) => {
        try {            
            dispatch(deleteDateFilter());                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//agrega o elimina filtro de tipo de patente
export function setTypeFilterAction(types){    
    return async(dispatch) => {
        try {            
            dispatch(setTypeFilter(types));                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//elimina todos los filtros de tipo de patente
export function deleteTypeFilterAction(){    
    return async(dispatch) => {
        try {            
            dispatch(deleteTypeFilter());                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//agrega o elimina filtro de paises
export function setCountryFilterAction(countries){    
    return async(dispatch) => {
        try {            
            dispatch(setCountryFilter(countries));                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//elimina todos los filtros de paises
export function deleteCountriesFilterAction(){    
    return async(dispatch) => {
        try {            
            dispatch(deleteCountryFilter());                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//agrega o elimina filtro de Ipc
export function setIpcFilterAction(ipc){    
    return async(dispatch) => {
        try {            
            dispatch(setIpcFilter(ipc));                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//elimina el filtro de Ipc
export function deleteIpcFilterAction(){    
    return async(dispatch) => {
        try {            
            dispatch(deleteIpcFilter());                                
            return 1;
        } catch (error) {
            return 0;
        }
    }
}


////////***********************************FUNCIONES DE LOGS***********************************////////////////

//funcion para guardar log de la busqueda del usuario en la API
export function insertLog(direction){    
    return async(dispatch,getState) => {
        try {
            //variables de redux
            const userId = getState().auth.userId;
            const search = getState().patent.search;
            
            //anexa al formdata datos globales
            let formData = new FormData();
            formData.append("component", "Patents");
            formData.append("userId", userId);
            formData.append("date", Date.parse(new Date()));

            //anexa al formdata datos unicos de la accion busqueda
            if(direction === 'search'){
                formData.append("query", search);
                formData.append("description", "QuerySearch");
            }else if(direction === 'fullPatent'){

            }

            //endpoint de la bd
            //clienteAxios.post('insert_log',formData);

            return 1;
        } catch (error) {
            
            return 0;
        }
    }
}

////////***********************************FUNCIONES DEL REDUCER***********************************////////////////

//carga patentes
const getPatents = patents => ({
    type:GET_PATENTS,
    payload:patents
});

//***** BUSQUEDA ******/
//cambia el search
const setSearh = search => ({
    type:SET_SEARCH,
    payload:search
});

//cambia el advancedEndPoint
const setGraphicArray = graphicsArray => ({
    type:SET_GRAPHICS_ARRAY,
    payload:graphicsArray
});

//cambia el advancedEndPoint
const setGraphicSCurve = graphicsSCurve => ({
    type:SET_GRAPHICS_SCURVE,
    payload:graphicsSCurve
});

//cambia el advancedEndPoint
const setGraphicIpc = graphicsIpc => ({
    type:SET_GRAPHICS_IPC,
    payload:graphicsIpc
});

const setAdvanced = endpoint => ({
    type:SET_ADVANCED_ENDPOINT,
    payload:endpoint
});

//cambia el advancedEndPoint
const setAssistedWords = connector => ({
    type:SET_CONNECTOR_ASSISTED,
    payload:connector
})

//cambia la ventana de busqueda avanzada
const setModeAdvanced = mode => ({
    type:SET__MODE_ADVANCED,
    payload:mode
});

//elimina ecuacion
const deleteEquation = () => ({
    type:DELETE_EQUATION
});

//guarda ecuacion de busqueda avanzada
const setEquation = equation => ({
    type:SET__EQUATION_ADVANCED,
    payload:equation
});

//***** FILTROS ******/
//cambia el filterEndpoint
const setFilter = filter => ({
    type:SET_FILTER_ENDPOINT,
    payload:filter
});

//agrega o elimina filtro de fecha
const setDatesFilter = date => ({
    type:SET_DATES_FILTER,
    payload:date
});

//elimina todos los filtro de fecha
const deleteDateFilter = () => ({
    type:DELETE_DATES_FILTER
});

//agrega o elimina filtro de tipo de patente
const setTypeFilter = types => ({
    type:SET_TYPES_FILTER,
    payload:types
});

//elimina todos los filtro de tipo de patente
const deleteTypeFilter = () => ({
    type:DELETE_TYPES_FILTER
});

//agrega o elimina filtro de paises
const setCountryFilter = countries => ({
    type:SET_COUNTRIES_FILTER,
    payload:countries
});

//elimina todos los filtro de paises
const deleteCountryFilter = () => ({
    type:DELETE_COUNTRIES_FILTER
});

//agrega o elimina filtro de Ipc
const setIpcFilter = ipc => ({
    type:SET_IPC_FILTER,
    payload:ipc
});

//elimina el filtro de Ipc
const deleteIpcFilter = () => ({
    type:DELETE_IPC_FILTER
});

// //agrega o elimina la gráfica de barras
// const setBarGraphic = date => ({
//     type:SET_BAR_GRAPHIC,
//     payload:date
// });

// //agrega o elimina la gráfica geográfica
// const setGeoGraphic = date => ({
//     type:SET_DATES_FILTER,
//     payload:date
// });

// //agrega o elimina la gráfica de torta
// const setPieGraphic = date => ({
//     type:SET_DATES_FILTER,
//     payload:date
// });

// //agrega o elimina la gráfica de linea
// const setLineGraphic = date => ({
//     type:SET_DATES_FILTER,
//     payload:date
// });

// //agrega o elimina la gráfica de árbol
// const setTreeGraphic = date => ({
//     type:SET_DATES_FILTER,
//     payload:date
// });