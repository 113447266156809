import processData from '../hooks/useProccessData';

const validateData = async (resultStore, sources) => {
console.log('validateData', resultStore);
    if (sources === 'all') {
        const numberOfResults = resultStore.filter(r => (r !== 'fail' && r !== 'empty'))

        let resultsWithValidateImage = []
        for (var i = 0; i < numberOfResults.length; i++) {
            numberOfResults[i].imageUrlFail = await imageProcess(getUrl(numberOfResults[i]));
            resultsWithValidateImage.push(numberOfResults[i]);
        }
        return numberOfResults.length !== 0 ? processData(resultsWithValidateImage) : [];
    }

    if (sources !== 'all') {
        const fail = resultStore.filter(r => r === 'fail')
        const empty = resultStore.filter(r => r === 'empty')
        
        if (fail.length !== 0) {
            return "fail";
        }

        if (empty.length !== 0) {
            return "empty";
        }

        let resultsWithValidateImage = []
        for (var i = 0; i < resultStore.length; i++) {
            resultStore[i].imageUrlFail = await imageProcess(getUrl(resultStore[i]));
            resultsWithValidateImage.push(resultStore[i]);
        }
        
        return processData(resultsWithValidateImage.length > 14 ? resultsWithValidateImage.slice(0, 14) : resultsWithValidateImage);
    }

}
export default validateData;

export const getUrl = (item) => {
    let url = item.thumbnails ? item.thumbnails :
        item.picture ? item.picture[0] :
            item['thumbnails.default.url'] ? item['thumbnails.default.url'][0] :
                item.thumbnail ? item.thumbnail : '';
    return url;
}

export const imageProcess = (src) => {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
    }).then(() => {
        return false;
    }).catch(error => {
        return true;
    })
}