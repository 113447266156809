import React, { useState } from "react";
import UserCompany from "./UserCompany";
import Company from "./Company";
import Register from "./Register";
import HeaderLogo from "../ui/HeaderLogo";
import "./signUp.scss";

//redux
import { useDispatch } from "react-redux";
import { signUpAction } from "../../../actions/authAction";
import { useHistory } from "react-router-dom";

const SignUp = () => {
    //redux
    const dispatch = useDispatch();
    const history = useHistory();

    //////states
    const [companySignup, setCompanySignup] = useState(null); //state ver el formulario de registro
    const [company, setCompany] = useState(null);
    const [errorRegister, setErrorRegister] = useState("");
    const [signUpForm, setSignUpForm] = useState({
        firstname: "",
        lastname: "",
        phone: "",
        username: "",
        charge: "",
        company: "",
        area: "",
        nit: "",
        email: "",
        password: "",
        agree: false,
    });
    //funcion  para agregar al state el formulario
    const onSignUpChange = (e) => {
        e.target.name !== "agree"
            ? setSignUpForm({
                ...signUpForm,
                [e.target.name]: e.target.value,
            })
            : setSignUpForm({
                ...signUpForm,
                agree: !signUpForm.agree,
            });
    };

    //envio formulario de registro
    const sendForm = async (e) => {
        console.log('signUpForm', signUpForm);
        e.preventDefault();
        //validacion
        var error = "";

        if (signUpForm.firstname.trim() === "") {
            error = "Por favor digita un nombre";
            setErrorRegister(error);
            return;
        } else if (signUpForm.lastname.trim() === "") {
            error = "Por favor digita un apellido";
            setErrorRegister(error);
            return;
        } else if (signUpForm.phone === "") {
            error = "Por favor digita un telefono";
            setErrorRegister(error);
            return;
        } else if (signUpForm.username.trim() === "") {
            error = "Por favor digita un nombre de usuario";
            setErrorRegister(error);
            return;
        } else if (signUpForm.email.trim() === "") {
            error = "Por favor digita un email";
            setErrorRegister(error);
            return;
        }
        else if (!signUpForm.email.includes('@')) {
            error = "El correo ingresado no es válido";
            setErrorRegister(error);
            return;
        } else if (signUpForm.password.trim() === "") {
            error = "Por favor digita un password";
            setErrorRegister(error);
            return;
        }
        else if (signUpForm.nit.trim() === "") {
            error = "Por favor digita el nit";
            setErrorRegister(error);
            return;
        }
        else if (signUpForm.company.trim() === "") {
            error = "Por favor digita el nombre de la empresa";
            setErrorRegister(error);
            return;
        }
        else if (signUpForm.charge.trim() === "") {
            error = "Por favor digita el cargo";
            setErrorRegister(error);
            return;
        }
        else if (signUpForm.area.trim() === "") {
            error = "Por favor digita el área";
            setErrorRegister(error);
            return;
        }

        signUpForm.role = company;
        const response = await dispatch(signUpAction(signUpForm));
        console.log('response------------>>', response);
        if (response === "usuario existente") {
            error = "Usuario existente";
            setErrorRegister(error);
            return;
        }
        response.data && history.push("/profile");
    };

    return (
        <>
            <div className="sign-up">
                <div className="headerRegister">
                    <div className="logoInnabierta"></div>
                    <div
                        style={{
                            width: "20%",
                            paddingTop: "13px",
                            paddingRight: "20px",
                            display: "flex",
                            justifyContent: "end",
                        }}
                    ></div>
                </div>
                <div className="inscription_box-title">
                    <div className="inscription_box-title--primary">
                        FORMULARIO DE REGISTRO
                    </div>
                    <div className="inscription_box-title--secondary">
                        Completa la información y haz parte de la plataforma
                        Lantia
                    </div>
                </div>
                <div className="">
                    {company === null && (
                        <UserCompany
                            setCompany={setCompany}
                            setCompanySignup={setCompanySignup}
                        />
                    )}
                    {companySignup === true && company === 1 && (
                        <Company setCompanySignup={setCompanySignup} />
                    )}
                    {company === 0 && (
                        <Register
                            onSignUpChange={onSignUpChange}
                            signUpForm={signUpForm}
                            sendForm={sendForm}
                            errorRegister={errorRegister}
                        />
                    )}
                    {companySignup === false && (
                        <Register
                            onSignUpChange={onSignUpChange}
                            signUpForm={signUpForm}
                            sendForm={sendForm}
                            errorRegister={errorRegister}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default SignUp;
