/////////PATENTES//////////////
/**Busquedas */
export const GET_PATENTS = 'GET_PATENTS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_ADVANCED_ENDPOINT = 'SET_ADVANCED_ENDPOINT';
export const SET_CONNECTOR_ASSISTED = 'SET_CONNECTOR_ASSISTED';
export const SET__MODE_ADVANCED = 'SET__MODE_ADVANCED';
export const SET__EQUATION_ADVANCED = 'SET__EQUATION_ADVANCED';
export const DELETE_EQUATION = 'DELETE_EQUATION';
/**Filtros */
export const SET_FILTER_ENDPOINT = 'SET_FILTER_ENDPOINT';
export const SET_DATES_FILTER = 'SET_DATES_FILTER';
export const DELETE_DATES_FILTER = 'DELETE_DATES_FILTER';
export const SET_TYPES_FILTER = 'SET_TYPES_FILTER';
export const DELETE_TYPES_FILTER = 'DELETE_TYPES_FILTER';
export const SET_COUNTRIES_FILTER = 'SET_COUNTRIES_FILTER';
export const DELETE_COUNTRIES_FILTER = 'DELETE_COUNTRIES_FILTER';
export const SET_IPC_FILTER = 'SET_IPC_FILTER';
export const DELETE_IPC_FILTER = 'DELETE_IPC_FILTER';
/**Gráficas */
export const SET_GRAPHICS_ARRAY = 'SET_GRAPHICS_ARRAY';
export const SET_GRAPHICS_IPC = 'SET_GRAPHICS_IPC';
export const SET_GRAPHICS_SCURVE = 'SET_GRAPHICS_SCURVE';
