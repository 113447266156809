import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clienteAxios from '../../config/axios';
import loginAxios from '../../config/axiosLogin';
import axios from 'axios';
import normalaxios from 'axios'
import help from '../../../images/icons/header/help.png';
//import home from '../../../images/icons/header/homeIcon.png';
import profile from '../../../images/icons/header/profile.png';
import logo from '../../../images/auth/logo_inline.png'
import logoUser from '../../../images/icons/perfil.png'
import headerImage from '../../../images/global/headerImage.png'
import { Dropdown } from 'react-bootstrap';
import { Button, Modal, Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { logOutAction } from '../../../actions/authAction'
import { getEvents, clearEventPending, userOk } from '../../../actions/eventsAction';
import { setSearchAction, cleanParamsOfSearch, typeOfSearch } from '../../../actions/homeAction'
import './header.scss';
import Slider from "react-slick";

const Header = () => {

    //state
    const [indicators, setIndicators] = useState([]);
    const [dropdownProfile, setDropdownProfile] = useState(false)

    //redux
    const userId = useSelector(state => state.auth.userId);
    const dispatch = useDispatch();
    const history = useHistory();
    const rememberToken = useSelector(state => state.auth.rememberToken);
    const role = useSelector(state => state.auth.role);
    const eventsPendings = useSelector(state => state.calendar.eventsPending);
    const eventsAcceptedOrRejected = useSelector(state => state.calendar.eventsAcceptedOrRejected);
    const apiKey = useSelector(state => state.auth.apiKey);

    useEffect(() => {
        if (userId !== null) {
            dispatch(getEvents(userId));
        }
    }, [userId])

    useEffect(() => {
        if (rememberToken !== -1) {
            window.addEventListener("beforeunload", onUnload());
        }
    }, [rememberToken])

    useEffect(() => {
        let indicators = [];
        //axios.defaults.headers.get['authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsAAATORcCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZVIStoSI6IkpvaG4gRG9lIiwiaWREF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
        //console.log('acaaa',axios.defaults.headers);
        

        axios.get(`https://vtsas.co/diagnosis/api/bridge?url=indicadores`,).then(function (response) {
            for (var i in response.data.yesterday) {
                if (response.data.yesterday[i] < response.data.today[i]) {
                    indicators.push({ indicator: [i, response.data.yesterday[i], response.data.today[i], "icon-arrow-up2"] })
                } else if (response.data.yesterday[i] > response.data.today[i]) {
                    indicators.push({ indicator: [i, response.data.yesterday[i], response.data.today[i], "icon-arrow-down2"] })
                } else {
                    indicators.push({ indicator: [i, response.data.yesterday[i], response.data.today[i], ""] })
                }
            }
            setIndicators(indicators);
        }) 
    }, [])

    const onUnload = () => {
        const token = { rememberToken: rememberToken }
        loginAxios.post('login/refrestoken', token);
    }

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        cssEase: 'linear',
        useTransform: false
    };

    //**Funciones */
    const logOut = async () => {
        await dispatch(logOutAction());
        history.push('/');
    }

    const clickLogin = (e) => {
        history.push('/login')
    }

    //redux
    const { firstname, autenticated } = useSelector(state => state.auth);

    const convert = (str) => {
        let numberConvert = str;
        for (var i = 0; i < str.length / 3; i++) {
            numberConvert = str.substr(0, str.length - ((i + 1) * 3)) + '.' + str.substr(-(i + 1) * 3);
        }
    };

    const formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    const formatNameProperty = (name) => {
        name = name.replace(/\b\w/g, l => l.toUpperCase())
        name = name.replace(/_/g, ' ');
        return name;
    }

    //aceptar evento pendiente
    const acceptEvent = (ep) => {
        dispatch(clearEventPending(ep, 'accepted'));
    }

    //rechazar evento pendiente
    const rejectEvent = (ep) => {
        dispatch(clearEventPending(ep, 'rejected'));
    }

    //usuario acepta
    const userAccept = (e) => {
        dispatch(userOk(e));
    }

    const indicatorsFormat = (number) => {
        return 0;
    }

    return (

        <div>
            <div style={{ display: 'inline-flex', width: '100%' }}>
                <div className='logo-img'>
                    <img onClick={() => {
                        history.push('/homeApp');
                        // dispatch(setSearchAction(""));
                        // dispatch(cleanParamsOfSearch())
                        dispatch(typeOfSearch(''))
                    }
                    } src={logo} alt="Lantia" className="pltLogo" width="150px" style={{zIndex: '10', position: 'absolute', cursor:'pointer', marginLeft:'4%'}} />
                </div>
                <img src={headerImage} alt="" style={{ 'width': '100%', 'height': '63px' }} />
                {autenticated === true &&
                    <div className="indicatorsSlide" style={{ width: '60%', 'marginLeft': '20%', 'margin-right': 'auto', zIndex: '10', position: 'absolute', marginTop: '10px' }}>
                        {indicators &&
                            <Slider {...settings} className="sliderTag">
                                {indicators.map(indic => {
                                    return (
                                        <div>
                                            <p><strong>{indic.indicator[0]}: {Intl.NumberFormat().format(indic.indicator[1])}</strong></p>
                                        </div>
                                    );
                                })
                                }
                            </Slider>
                        }
                    </div>
                }
                <div style={{ zIndex: '10', position: 'absolute', marginLeft: '87%', marginTop: '15px', cursor: 'pointer' }}>
                    {autenticated === true ?
                        <div style={{ color: '#a4a9a9' }} className="m-0 ml-4 mt-1 header-link" onClick={logOut}>CERRAR SESIÓN</div>
                        :
                        <div style={{ color: '#a4a9a9' }} className="m-0 mr-3 ml-5 header-link" onClick={(e) => clickLogin(e)}>INICIAR SESIÓN</div>
                    }
                </div>

            </div>

            {autenticated === true &&
                <div style={{ paddingTop: '7rem' }} className='row align-items-center justify-content-center w-100 mt-4'>
                    <img width='70px' src={logoUser} alt="Perfil" className='mr-4' />
                    <h1>{firstname}, Bienvenido a nuestra plataforma</h1>
                </div>

            }
        </div>
    );
}

export default Header;





{/*
 <div className='main-header'>
                <div className='logo-img'>
                    <img onClick={() => {
                        history.push('/homeApp');
                        // dispatch(setSearchAction(""));
                        // dispatch(cleanParamsOfSearch())
                        dispatch(typeOfSearch(''))
                    }
                    } src={logo} alt="Lantia" className="pltLogo" width="150px" />
                </div>
                <div class="divLeftEleven"></div>
                <div class="divLefttwelve"></div>
                <div class="navbarCurve">
                    <div class="logoDiv"></div>
                    <div class="divLeftTen"></div>
                    <div class='logoDivMiddle'></div>
                    <div class="triangle-up"></div>
                    <div class="curves">
                        <div class="square"></div>
                        <div class="squareLeft"></div>
                        <div class="line">
                            <div class="lineLeft">
                            </div>
                            <div class="lineRight">
                            </div>
                        </div>
                    </div>
                    <div className="header">
                    </div>
                    {autenticated === true ?
                        <div style={{ display: 'inline-flex', float: 'right', marginRight: '4rem', marginTop: '0.5rem', position: 'absolute', zIndex: '80', marginLeft: '85%' }}>

                            <Dropdown>
                                <Dropdown.Toggle className="btn-dropdown-header" id="dropdown-basic">
                                   <img src={help} alt="ayudaLantia" className="m-0 mx-2 header-link" style={{"width": "24px", height: '27px', paddingTop: '2px'}} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu'>
                                        home
                                    </Dropdown.Menu>
                                </Dropdown>
    
                                <Dropdown>
                                    <Dropdown.Toggle className="btn-dropdown-header" id="dropdown-basic">
                                        {/* <img src={help} alt="ayudaLantia" className="m-0 mx-2 header-link" style={{"width": "24px", height: '27px', paddingTop: '2px'}} /> 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu'>
                                        help
                                    </Dropdown.Menu>
                                </Dropdown>
    
                                <Dropdown>
                                    <Dropdown.Toggle className="btn-dropdown-header" id="dropdown-basic">
                                        <img src={profile} alt="userLantia" className="m-0 ml-3 header-link" style={{ "width": "24px", height: '24px' }} />
                                    </Dropdown.Toggle>
    
                                    <Dropdown.Menu className='dropdown-menu'>
                                     {role === 2 && <Link style={{display: 'block'}} to={'/massiveSignup'}>Massive register</Link>}
                                    {role === 2 && <Link style={{display: 'block'}} className="singup" to={'/omnichannel'}>Omnicanalidad</Link>}
                                    {role === 2 && <Link style={{display: 'block'}} className="singup" to={'/graphicTest'}>Graficas</Link>}
                                    <Link className="singup" to={'/massiveSignup'}>Perfil</Link>
    
                                    <Link className="singup" to={'/expertApp'}>Expertos</Link>
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Card.Header >
                                                Eventos
                                                    </Card.Header>
    
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                    Eventos Pendientes:
                                                        </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div>
                                                        {eventsPendings.length !== 0 ? eventsPendings.map(ep => {
                                                            return (<div className="eventsPending">
                                                                <hr />
                                                                <p className="infoEventPending">
                                                                    Tienes un evento pendiente con
                                                                    {`${ep.firstname} ${ep.lastname}`}
                                                                </p>
                                                                <p className="infoEventPending">
                                                                    el dia {ep.start_date.substr(0, 10)}
                                                                            desde las {`${ep.start_date.substr(11, 5)}`}
                                                                        hasta las {`${ep.end_date.substr(11, 5)}`}
                                                                </p>
                                                                <button onClick={() => acceptEvent(ep.id)} type='button' className="btn btn-sm btn-primary aceptButton">
                                                                    Aceptar
                                                                    </button>
                                                                <button onClick={() => rejectEvent(ep.id)} type='button' className="btn btn-sm btn-danger">
                                                                    Rechazar
                                                                    </button>
                                                            </div>)
                                                        }) : <div className="eventsPending">
                                                                <p>No tienes eventos pendientes</p>
                                                            </div>}
                                                        {
                                                            eventsAcceptedOrRejected.length !== 0 && eventsAcceptedOrRejected.map(e => {
                                                                return (<div className="eventsPending">
                                                                    <hr />
                                                                    <p className="infoEventPending">El evento que programaste con el experto</p>
                                                                    <p className="infoEventPending">{` ${e.firstname} ${e.lastname}`} el día
                                                                        {`${e.start_date.substr(0, 10)} `} desde las {` ${e.start_date.substr(11, 5)} `}</p>
                                                                    <p className="infoEventPending">
                                                                        hasta las {` ${e.end_date.substr(11, 5)} `}
                                                                            ha sido {e.state === 'rejected' ? 'rechazado' : 'aceptado'}
                                                                    </p>
                                                                    <div type='button' className="okButton" onClick={() => userAccept(e.id)}>ok</div>
                                                                </div>)
                                                            })
                                                        }
    
                                                    </div>
    
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion> 
    
    
    
    
                                        <div>
                                            <p>Eventos</p>
                                            {eventsPendings.length !== 0 ? eventsPendings.map(ep => {
                                                return (<div className="eventsPending">
                                                    <hr />
                                                    <p className="infoEventPending">
                                                        Tienes un evento pendiente con
                                                {`${ep.firstname} ${ep.lastname}`}
                                                    </p>
                                                    <p className="infoEventPending">
                                                        el dia {ep.start_date.substr(0, 10)}
                                                desde las {`${ep.start_date.substr(11, 5)}`}
                                                hasta las {`${ep.end_date.substr(11, 5)}`}
                                                    </p>
                                                    <button onClick={() => acceptEvent(ep.id)} type='button' className="btn btn-sm btn-primary aceptButton">
                                                        Aceptar
                                            </button>
                                                    <button onClick={() => rejectEvent(ep.id)} type='button' className="btn btn-sm btn-danger">
                                                        Rechazar
                                            </button>
                                                </div>)
                                            }) : <div className="eventsPending">
                                                    <p>No tienes eventos pendientes</p>
                                                </div>}
                                            {
                                                eventsAcceptedOrRejected.length !== 0 && eventsAcceptedOrRejected.map(e => {
                                                    return (<div className="eventsPending">
                                                        <hr />
                                                        <p className="infoEventPending">El evento que programaste con el experto</p>
                                                        <p className="infoEventPending">{` ${e.firstname} ${e.lastname}`} el día
                                                {`${e.start_date.substr(0, 10)} `} desde las {` ${e.start_date.substr(11, 5)} `}</p>
                                                        <p className="infoEventPending">
                                                            hasta las {` ${e.end_date.substr(11, 5)} `}
                                                     ha sido {e.state === 'rejected' ? 'rechazado' : 'aceptado'}
                                                        </p>
                                                        <div type='button' className="okButton" onClick={() => userAccept(e.id)}>ok</div>
                                                    </div>)
                                                })
                                            }
    
                                        </div>
                                        <p className="m-0 ml-4 mt-1 header-link" onClick={logOut}>CERRAR SESIÓN</p>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            :
                            <div className='row justify-content-center align-items-center logout'>
                                <p className="m-0 mr-3 ml-5 header-link" onClick={(e) => clickLogin(e)}>INICIAR SESIÓN</p>
                                {/* <p className="m-0 mr-4 header-link">ESPAÑOL (ESP)</p>
                            </div>
    
                        }
    
                    </div>
    
                   
                </div>

*/}