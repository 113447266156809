import React from 'react'
import One from '../ui/OneBigElement'
import CarouselBox from '../../../ui/carouselBox/CarouselBox';
// import {setResultsOfSearh} from '../../../../actions/homeAction';
// import {useDispatch} from 'react-redux';

const News = ({ news }) => {
    // const dispatch = useDispatch();
    // dispatch(setResultsOfSearh(news));
    return (
        <>
            {news !== null &&
                <div style={{ height: '300px'}} className="lantia-square smallHeight">
                    <CarouselBox
                        numberOfCards={2}
                        itemsArray={news}
                        title={''}
                    />
                </div>
            }
        </>
    );
}

export default News;