import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { imageProcess } from '../../../../../../helpers/validateData'
import TranslateModal from '../../../../../ui/translateModal/TranslateModal'
import translateText from '../../../../../../hooks/useTranslate'

const StartupResult = (props) => {

    const [picture, setPicture] = useState(false)
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState(props.description[0].split('\n'));

    imageProcess(props.picture).then(response => {
        setPicture(response)
    })

    useEffect(() => {
        if(translate){
            translateText(props.description).then(response => {
                setDescription(response)
            });
        }else{
            setDescription(props.description[0].split('\n'))
        }
    }, [props.title, translate])


    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <p><strong>{props.title}</strong></p>
                </div>
                <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-2">
                        <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                    </div>
            </div>
            {props.modal ?
                <p style={{ columnCount: 2, marginTop: '2rem' }}>{
                    props.description.slice(0, 360)} <Link to={'/resultSelected'}> ver mas...</Link>
                </p> :
                <div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p><strong>Ubicación: </strong>{props.location}</p>
                            {!picture &&
                                <img src={props.picture} alt="" width='100' height='100' />
                            }
                        </div>
                        <div className="col-12 col-md-6">

                            {props.description !== '' &&
                                description.map(paragraph => {
                                    return (
                                        <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                            {paragraph}
                                        </p>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            }
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} />
        </div>
    )
}
export default StartupResult;