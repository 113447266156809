import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SEND_OPTIONS,
    LOG_OUT,    
} from '../types/authTypes';
import {
    LOG_OUT_PROFILE
} from '../types/profileTypes';
//import Cookies from 'js-cookie';
import cookie from 'react-cookies'
import clienteAxios from '../components/config/axios';
import loginAxios from '../components/config/axiosLogin';
import dataAxios from '../components/config/axiosBigData';
import {getColectionAction} from './markAction'


//funcion para autenticarse con usuario y password
export function loginAction(user){    
    return async(dispatch) => {
        try {
            //endpoint de la bd
            const response = await loginAxios.post('login',user);
            if(response.data.token){
                const maxAge = 3600*24*15;
                const expires = new Date()
                    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', response.data.token, { path: "/",expires,maxAge});
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                dataAxios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsAAATORcCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZVIStoSI6IkpvaG4gRG9lIiwiaWREF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
               await dispatch(loginSuccess(response.data));

               //llama colecciones desde el action de marks
               await dispatch(getColectionAction());
               if(response.data.user.profiled === 1){                
                    return '1';
                }else{
                    return '0';
                }
            }else if(response.data.message){
                return 'invalid';
            }
        } catch (error) {
            return 'error';
        }
    }
}

//funcion para loguearse con el token guardado
export function loginTokenAction(){
    return async(dispatch) => {
            try {
            const token = cookie.load('token');
            //llama endpoint login con token
            clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            loginAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            dataAxios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsAAATORcCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZVIStoSI6IkpvaG4gRG9lIiwiaWREF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
            const response = await loginAxios.post('login/token');
            var rtr = false;
            if(response.data.user.profiled > -1){
                var profiled = response.data.user.profiled;

                //llama colecciones desde el action de marks
                await dispatch(getColectionAction());
            //Guarda en redux storage
               await dispatch(loginSuccess(response.data));
                profiled === 1 ? rtr = 1 : profiled = 0;
            }else{
                cookie.remove('token');
                await dispatch(loginFail());
            }
            return rtr;            
        } catch (error) {
            cookie.remove('token');
            await dispatch(loginFail());
            return false;
        }
    }
}

//funcion para registro del usuario en el signup
export function signUpAction(user){
    return async(dispatch) => {
            try {
            const response = await loginAxios.post('signup/createaccount',user);
            if(response.data.token){
                const expires = new Date()
                    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
                cookie.save('token', response.data.token, { path: "/",expires});
                dataAxios.defaults.headers.common['Authorization'] = 'Bearer eyJhbGciOiJIUzI1NiIsAAATORcCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZVIStoSI6IkpvaG4gRG9lIiwiaWREF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c';
                clienteAxios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                await dispatch(loginSuccess(response.data));
                return true;
            }else{
                return response.data;
            }      
        } catch (error) {
            return false;
        }
    }
}

//funcion cerrar sesion
export function logOutAction(){
    return async(dispatch) => {
        cookie.remove('token', { path: '/' });        
        dispatch(logOut());
        dispatch(logOutProfile());
        await loginAxios.post('logout');
    }
}

//funcion para cierre de ventana o refresco de paginia
export function loginRefresh(){
    return async(dispatch,getState) => {
       
        const token = {
            rememberToken : getState().auth.rememberToken
        }
        await loginAxios.post('login/refrestoken',token);
    }
}

//funcion para cambiar el perfil a uno cuando las opciones seleccionadas se han registrado en la BD
export function optionesSuccessAction(){
    return async(dispatch) => {
        dispatch(optionsSuccess()); 
    }
}

//carga favoritos al loguearse
/*const getColection = () => {
    return async(dispatch) => {
        try {
            var rta = await clienteAxios.get('/bookmarks');
            dispatch(getColection(rta.data));            
            return 1;
        } catch (error) {
            return 0;
        }
    }
}*/

//login exitoso
const loginSuccess = user => ({
    type:LOGIN_SUCCESS,
    payload:user
});

//fallo de login
const loginFail = () => ({
    type:LOGIN_FAIL
});

//cambio de perfil del usuario
const optionsSuccess = () => ({
    type:SEND_OPTIONS
});

//cierra sesion
const logOut = () => ({
    type:LOG_OUT
});

//cierra sesion en perfil
const logOutProfile = () => ({
    type:LOG_OUT_PROFILE
});