import React, {useState, useEffect} from 'react'
import {Button, Modal, Accordion, Card} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {selectExpert} from '../../../../actions/expertsAction'
import { Link } from 'react-router-dom';
import {goToSchedule} from '../../../../actions/eventsAction';

const ExpertItem = (props) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [expert, setExpert] = useState({  
        id : props.item.id,
        user_id: props.item.user_id,
        headline: props.item.headline,
        education: props.item.education,
        disponibility: props.item.disponibility,
        specialties: props.item.specialties,
        suspended: props.item.suspended,
        firstname:props.item.firstname,
        lastname: props.item.lastname, 
        email: props.item.email,
    });
    

    useEffect(() => {
        setExpert({  
            id : props.item.id,
            user_id: props.item.user_id,
            headline: props.item.headline,
            education: props.item.education,
            disponibility: props.item.disponibility,
            specialties: props.item.specialties,
            suspended: props.item.suspended,
            firstname:props.item.firstname,
            lastname: props.item.lastname, 
            email: props.item.email,
        });
    }, [props.item]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="expertItem">
            <div>
                <span><strong>Nombre: </strong>{`${expert.firstname} ${expert.lastname}`}</span>
            </div>
            <hr />
            <Accordion defaultActiveKey="0">
            <Card>
                    <Card.Header onClick={handleShow}>
                        ver resumen
                    </Card.Header>
                    
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Formación Academica: 
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>{expert.education}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Aptitudes y conocimiento:
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>{expert.specialties}</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        Disponibilidad:
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>{expert.disponibility}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Link to='/expertApp/calendar' onClick={()=>{dispatch(selectExpert(expert)); dispatch(goToSchedule(false))}}>Agendar cita</Link>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span>{`${expert.firstname} ${expert.lastname}`}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <span>Extracto</span>
                    </div>
                    <br />
                    <div>
                        <span><p>{expert.headline}</p></span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ExpertItem
