import React, { useState } from 'react'

//redux
import { useDispatch } from 'react-redux'
import { setAdvancedAction, setAssistedWordsAction } from '../../../../actions/patentAction'

const AssistedSearchBox = () => {
    //redux
    const dispatch = useDispatch();

    /**States */
    const [dictionaryInterface] = useState({
        none:'¿Deseas hacer una búsqueda guiada?',
        or:'En el siguiente campo de texto, introduce sinónimos de tu búsqueda separados por comas ( , ). Una vez los introduzcas haz clic en siguiente.',
        and:'Digita las palabras que sean indispensables para los resultados, separadas por comas ( , ). Terminado esto, haz clic en siguiente.',
        not:'¿Qué palabras no quieres que aparezcan en tu búsqueda?',
        confirm:'Ya tenemos todas tus palabras de búsqueda, haz clic en buscar.'
    });//array con los textos de busqueda guiada
    const [page,setPage] = useState('none');//ubicacion de la busqueda guiada
    const [connector,setConnector] = useState({
        orConnector:'',
        andConnector:'',
        notConnector:''
    });//state para guardar datos introducidos por el usuario

    
    /**Funciones */
    //funcion para cambio de pagina
    const changePage = page => {
        page === 'delete' ? console.log('quita busqueda guiada') : setPage(page);
    }

    //funcion para agregar palabras a busqueda guiada
    const changeConnector = (e) => {
        setConnector({
            ...connector,
            [e.target.name] : e.target.value
        })
    }

    //funcion para enviar la busqueda guiada
    const sendSearch = () => {
        //arma array de palabras escritas para subrayar en el texto
        var connectorObject = {'or' : connector.orConnector.replaceAll(',', '+'),'and' : connector.andConnector.replaceAll(',', '+')};
        dispatch(setAssistedWordsAction(connectorObject));

        //changePage('');
        var endpoint = '';
        if(connector.orConnector !== ''){
            var endOr = connector.orConnector.split(",").join('');
            //reemplaza espacios en blanco por un +
            endOr = endOr.replace(/\s+/g, '+');
            endpoint = endpoint+'&or='+endOr;
        }
        if(connector.andConnector !== ''){
            var endand = connector.andConnector.split(",").join('');
            //reemplaza espacios en blanco por un +
            endand = endand.replace(/\s+/g, '+');
            endpoint = endpoint+'&and='+endand;
        }
        if(connector.notConnector !== ''){
            var endnot = connector.notConnector.split(",").join('');
            //reemplaza espacios en blanco por un +
            endnot = endnot.replace(/\s+/g, '+');
            endpoint = endpoint+'&not='+endnot;
        }
        //envia endpoint al action
        if(endpoint !== ''){
            dispatch(setAdvancedAction(endpoint));
        }
    }

    return ( 
       <>   
            <div className="row w-100">
                {/**Col 6 para los mensajes de seleccion de busqueda guiada */}
                <div className="col-7 row w-100 justify-content-center">
                    {page === 'none' ?
                        (   
                            <div className="row w-100 justify-content-center">
                                <div>
                                    <h2>{dictionaryInterface.none}</h2>
                                    <div className="row w-100 justify-content-center">
                                        <button className="btn btn-outline-secondary mr-3" onClick={() => changePage('or')}>Si</button>
                                        <button className="btn btn-outline-secondary" onClick={() => changePage('delete')}>No</button>
                                    </div>
                                </div>
                            </div>
                        )://el usuario quiere una busqueda avanzada y entra en sinonimos
                        page === 'or' ? (
                            <div className="row w-100 justify-content-center">
                                <div>
                                    <p>{dictionaryInterface.or}</p>
                                    <div className="row w-100 justify-content-center">
                                        <input 
                                            type="text"
                                            name='orConnector'  
                                            onChange={changeConnector}
                                            value={connector.orConnector}
                                        />
                                        <button className="btn btn-outline-secondary" onClick={() => changePage('and')}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        ) : //el usuario entra a palabras indispensables
                        page === 'and' ? (
                            <div className="row w-100 justify-content-center">
                                <div>
                                    <p>{dictionaryInterface.and}</p>
                                    <div className="row w-100 justify-content-center">
                                        <input 
                                            type="text"
                                            name='andConnector'  
                                            onChange={changeConnector}
                                            value={connector.andConnector}
                                        />
                                        <button className="btn btn-outline-secondary" onClick={() => changePage('not')}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        ) : //el usuario entra a palabras excluidas
                        page === 'not' ? (
                            <div className="row w-100 justify-content-center">
                                <div>
                                    <p>{dictionaryInterface.not}</p>
                                    <div className="row w-100 justify-content-center">
                                        <input 
                                            type="text"
                                            name='notConnector'  
                                            onChange={changeConnector}
                                            value={connector.notConnector}
                                        />
                                        <button className="btn btn-outline-secondary" onClick={() => changePage('confirm')}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        ) : //el usuario entra a confirmacion para busqueda
                        page === 'confirm' ? (
                            <div className="row w-100 justify-content-center">
                                <div>
                                    <p>{dictionaryInterface.confirm}</p>
                                    <div className="row w-100 justify-content-center">
                                        <button className="btn btn-outline-secondary" onClick={sendSearch}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                        )
                        : ''
                    }
                </div>
                {/**Col 6 para observar las palabras seleccionadas de busqueda guiada */}
                <div className="col-5">
                        {connector.orConnector.trim() !== '' ?
                            <p><strong>Sinónimos: </strong>{connector.orConnector}</p>
                            : ''
                        }
                        {connector.andConnector.trim() !== '' ?
                            <p><strong>palabras indispensables: </strong>{connector.andConnector}</p>
                            : ''
                        }
                        {connector.notConnector.trim() !== '' ?
                            <p><strong>palabras excluidas: </strong>{connector.notConnector}</p>
                            : ''
                        }
                </div>
            </div>
            
       </>
     );
}
 
export default AssistedSearchBox;