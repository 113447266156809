import React from 'react'
import ColorTitle from '../../../ui/styles/ColorTitle'
import Slider from "react-slick";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { registerSelected } from '../../../../actions/homeAction';
import './events.scss'

const Events = ({events}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 2,
        vertical:true
      };

      const resultSelet = (item) => {
        item.related = events;
        dispatch(registerSelected(item));
        history.push('/resultSelected')
        window.scrollTo(0, 250)
    }

    return ( 
        <div className='sectionHome homeEvents'>
            <div className="lantia-square smallHeight" style={{height:'400px'}}>
                {(events !== null && events.length > 0) ?
                    <Slider {...settings}>
                        {events.map((event) => (
                            <div onClick={() => resultSelet(event)} className='row mt-3 eventBox' style={{height:'100%'}}>
                                <div style={{height:'auto'}} className="row">
                                    <div style={{height:'100%'}}className="col-12">
                                        <p className='title'> <strong>{event.titulo[0].substring(0,30)}</strong></p>
                                        <p className='eventParagraph'>{event.descripcion[0].substr(0,80)}...</p>
                                    </div>
                                    <div style={{height:'100%'}}className="col-12 viewMore">
                                        <span style={{cursor:'pointer', color:'var(--primaryBlueOne)', fontSize:'12px', float:'left', marginLeft:'30px'}}>Ver mas</span>
                                    </div>
                                </div>    
                                <hr />                            
                            </div>
                        ))}
                    </Slider>                
            :
                    <div>No se encontraron eventos relacionados</div>
            }               
            </div>
        </div>
     );
}
 
export default Events;