import {
    LOAD_OPTIONS,
    ADD_INTEREST,
    ADD_SECTOR,
    ADD_PROFESSION,
    ADD_STUDY,
    REMOVE_INTEREST,
    REMOVE_SECTOR,
    REMOVE_PROFESSION,
    REMOVE_STUDY,
    LOAD_OPTIONS_USER,
    GET_RECOMMENDATION,
    LOG_OUT_PROFILE
} from '../types/profileTypes';

//state
const initialState = {
    //estas cuatro variables representan las opciones traidas del bd a escoger    
    optioninterests:[],
    optionsectors:[],
    optionprofessions:[],
    optionstudies:[],
    name:'',
    //estas cuatro variables representan las opciones escogidas por el usuario
    userinterests:[],
    usersectors:[],
    userprofessions:[],
    userstudies:[],
    //
    recommendations:[]
}

export default function(state = initialState, action){
    switch(action.type){

        case LOAD_OPTIONS:
            return{
                ...state,
                optioninterests:action.payload.interests,   
                optionprofessions:action.payload.professions,      
                optionstudies:action.payload.studies,      
                optionsectors:action.payload.sectors      
            }
        case LOAD_OPTIONS_USER:
            return{
                ...state,
                userinterests:action.payload.interests,   
                usersectors:action.payload.sectors,      
                userprofessions:action.payload.studies,      
                userstudies:action.payload.professions      
            }
        case ADD_INTEREST:
            return{
                ...state,
                userinterests:[...state.userinterests,action.payload]      
            }
        case ADD_SECTOR:
            return{
                ...state,
                usersectors:[...state.usersectors,action.payload]      
            }
        case ADD_PROFESSION:
            return{
                ...state,
                userprofessions:[...state.userprofessions,action.payload]      
            }
        case ADD_STUDY:
            return{
                ...state,
                userstudies:[...state.userstudies,action.payload]      
            }
        case REMOVE_INTEREST:
            return{
                ...state,
                userinterests:state.userinterests.filter(interest => interest !== action.payload)      
            }
        case REMOVE_SECTOR:
            return{
                ...state,
                usersectors:state.usersectors.filter(sector => sector !== action.payload)      
            }
        case REMOVE_PROFESSION:
            return{
                ...state,
                userprofessions:state.userprofessions.filter(profession => profession !== action.payload)      
            }
        case REMOVE_STUDY:
            return{
                ...state,
                userstudies:state.userstudies.filter(study => study !== action.payload)      
            }
        case GET_RECOMMENDATION:
            return{
                ...state,
                recommendations:action.payload      
            }
        case LOG_OUT_PROFILE:
            return{
                ...state,
                userinterests:[],
                usersectors:[],
                userprofessions:[],
                userstudies:[]
            }
        default:
            return state;
    }
}