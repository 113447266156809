import {
    GET_ARTICLES
} from '../types/articleTypes';

//state
const initialState = {
    search:'',
    page:0,  //pagina actual para el envio del endpoint
    articles:[],
    results:0, //total de videos encontradas
}

export default function(state = initialState, action){
    switch(action.type){

        case GET_ARTICLES:
            return{
                ...state,
                articles:action.payload.items,
                page:action.payload.page
            }

        default:
            return state;
    }
}