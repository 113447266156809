import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Modal from 'react-modal';
import EventForm from '../EventForm'
import {useHistory} from 'react-router-dom';

import {uiCloseModal} from '../../../../../actions/uiAction';
import {eventClearActiveEvent, editEvent} from '../../../../../actions/eventsAction';
import {DeleteEventFab} from '../../ui/DeleteEventFab';
import './calendarModal.scss'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
Modal.setAppElement('#root');

export const CalendarModal = () => {

    const {modalOpen} = useSelector(state => state.ui);
    const {activeEvent, editE} = useSelector(state => state.calendar);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
     if(!editE){
        dispatch(eventClearActiveEvent());
     }
    }, [editE])

    const closeModal = () => {
        // TODO: cerrar el modal
        dispatch(editEvent(false))
        dispatch(uiCloseModal());
    }

    const redirectStreaming = () => {
        history.push('/expertApp/streaming')
    }


    return (
        <div className="mainModal">
            <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                style={customStyles}
                closeTimeoutMS={200}
                overlayClassName="modal-fondo"
            >

                {activeEvent ?
                    !editE ?
                        <div style={{display:'inline-block'}}>
                            <div style={{float:'right', cursor:'pointer'}} onClick={()=> closeModal()}>X</div>
                            <div style={{display:'inline-flex'}}>
                                <div style={{marginRight:'10px'}} onClick={() => dispatch(editEvent(true))}>
                                    <i class="fas fa-edit"></i>
                                </div>
                                    <DeleteEventFab />
                            </div>
                            <div>
                                <p>Asesoria con: {activeEvent.user.name}</p>
                                <p>Fecha del Evento: {activeEvent.start
                                                                 .toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric'})
                                                                 .toString() + ' ' + activeEvent.start.toLocaleTimeString()}</p>
                            </div>
                            <div>
                                <button className="btn btn-success btn-sm" onClick={redirectStreaming}>ir al evento</button>
                            </div>
                        </div>

                        :
                        <EventForm />

                    :
                    <EventForm />
                }

            </Modal >
        </div>

    )
}
