export const colectionWithLatestMark = (response) => {
    let marksWithformatedDates = response.marks.filter(mark => mark.tag !== 'ecuations')
    marksWithformatedDates.map(mark => {
        mark.addDate = new Date(mark.addDate);
    })

    let colection = response.colections.find(colection => colection.id === marksWithformatedDates.find(mark =>
        mark.addDate.toISOString() === new Date(Math.max(...marksWithformatedDates.map(mark => mark.addDate))).toISOString()
    ).colection_id);

    return {id: colection.id, name: colection.name}
}