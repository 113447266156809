import React, {useRef, useEffect, useState} from "react";
import {
    select,
    line,
    curveCardinal,
    axisBottom,
    axisRight,
    axisLeft,
    scaleLinear
} from "d3v5";
import './linechart.scss'
import * as d3 from "d3v5";

function LineChart(props) {

    const [dataInit, setData] = useState({
        Year_application: props.Year_application,
        Year_publication: props.Year_publication
    });

    const svgRef = useRef();

    useEffect(() => {
        setData({
            Year_application: props.Year_application,
            Year_publication: props.Year_publication
        })
    }, [props.Year_application, props.Year_publication])

    // will be called initially and on every data change
    useEffect(() => {
        const data = processData(dataInit);

        let arrayValues = data.applicationYear.map((d) => {
            return d.value
        })

        data.publicationYear.forEach((d) => {
            arrayValues.push(d.value)
        })

        let arrayName = data.applicationYear.map((d) => {
            return d.name
        })

        const svg = select(svgRef.current);
        const xScale = scaleLinear()
            .domain([0, data.applicationYear.length - 1])
            .range([0, 300]);

        const yScale = scaleLinear()
            .domain([0, d3.max(arrayValues)])
            .range([d3.max(arrayValues) * 0.5, 0]);

        const xAxis = axisBottom(xScale)
            .ticks(data.applicationYear.length)
            .tickFormat(index => data.applicationYear[index].name);
        svg
            .select(".x-axis")
            .style("transform", `translateY(${((d3.max(arrayValues)) * 0.5) + 15}px)`)
            .call(xAxis);

        const yAxis = axisLeft(yScale)
            .ticks(data.applicationYear.length)
            .tickFormat(index => index + 1);
        svg
            .select(".y-axis")
            //  .style("transform", "translateX(300px)")
            .call(yAxis);

        // generates the "d" attribute of a path element
        const myLine = line()
            .x((value, index) => xScale(index))
            .y(yScale)
            .curve(curveCardinal);

        // renders path element, and attaches
        // the "d" attribute from line generator above
        svg
            .selectAll(".line")
            .data([data.applicationYear.map((d => d.value))])
            .join("path")
            .attr("class", "line")
            .attr("d", myLine)
            .attr("fill", "none")
            .attr("stroke", "blue")
            //.on('mouseover', function () {
            //    svg.append("rect")
            //        .attr('x', -35)
            //        .attr('y', 30)
            //        .attr('width', 80)
            //        .attr('height', 40)
            //        .style('fill', '#F0F1F5')
            //        .attr("stroke", "gray")
            //        .attr("transform", "translate(" + (d3.event.pageX - 50) + "," + ((d3.event.pageY - (d3.max(arrayValues) * 3) - 50)) + ")");

            //    svg.append("text")
            //        .attr('id', 'tooltip')
            //        .attr("dy", ".3em") // change dx and dy in order to center in the circle.
            //        .style("text-anchor", "middle")
            //        .text(`${data.applicationYear[Math.trunc(xScale.invert(d3.event.pageX))].name}, ${data.applicationYear[Math.trunc(xScale.invert(d3.event.pageX))].value}`)
            //        .attr("transform", "translate(" + (d3.event.pageX - 50) + "," + (d3.event.pageY - (d3.max(arrayValues) * 3)) + ")");
            //})
            //.on('mouseout', function () {
            //    svg.select('rect').remove();
            //    svg.select('#tooltip').remove();
            //});


        svg
            .selectAll(".line2")
            .data([data.publicationYear.map((d => d.value))])
            .join("path")
            .attr("class", "line2")
            .attr("d", myLine)
            .attr("fill", "none")
            .attr("stroke", "red")
            //.on('mouseover', function () {

            //    svg.append("rect")
            //        .attr('x', -35)
            //        .attr('y', 30)
            //        .attr('width', 80)
            //        .attr('height', 40)
            //        .style('fill', '#F0F1F5')
            //        .attr("stroke", "gray")
            //        .attr("transform", "translate(" + (d3.event.pageX - 50) + "," + ((d3.event.pageY - (d3.max(arrayValues) * 3) - 50)) + ")");

            //    svg.append("text")
            //        .attr('id', 'tooltip')
            //        .attr("dy", ".3em") // change dx and dy in order to center in the circle.
            //        .style("text-anchor", "middle")
            //        .text(`${data.publicationYear[Math.trunc(xScale.invert(d3.event.pageX))].name}, ${data.publicationYear[Math.trunc(xScale.invert(d3.event.pageX))].value}`)
            //        .attr("transform", "translate(" + (d3.event.pageX - 50) + "," + (d3.event.pageY - (d3.max(arrayValues) * 3)) + ")");
            //})
            //.on('mouseout', function () {
            //    svg.select('rect').remove();
            //    svg.select('#tooltip').remove();
            //});


        function mousemove(x, y) {

        }


    }, [dataInit]);

    const processData = (dataToProcess) => {
        const Data = [];
        for(var i = Object.keys(dataToProcess.Year_application).length - 10;i < Object.keys(dataToProcess.Year_application).length;i++) {
            Data.push({name: Object.keys(dataToProcess.Year_application)[i], value: Object.values(dataToProcess.Year_application)[i]});
        }


        const _Data = [];
        for(var i = Object.keys(dataToProcess.Year_publication).length - 10;i < Object.keys(dataToProcess.Year_publication).length;i++) {
            _Data.push({name: Object.keys(dataToProcess.Year_publication)[i], value: Object.values(dataToProcess.Year_publication)[i]});
        }

        const applicationYear = Data;
        const publicationYear = _Data;

        return {'applicationYear': applicationYear, 'publicationYear': publicationYear};
    }

    return (
        <div className="mainG" style={{marginLeft: '50px'}}>
           <div style={{width:800}} className="box">
                <svg ref={svgRef} style={{margin: '50px'}}>
                    <g className="x-axis" />
                    <g className="y-axis" />
                </svg>
                <br />
                <br />
                <br />
                <br />
                <br />
                <div style={{marginLeft: 50, display:"inline-flex", color:"gray"}}><div style={{backgroundColor: '#b8a5a5', width: '25px', height: '25px', marginRight:'15px'}}></div> Año de aplicación</div>
                <div style={{marginLeft: 50, display:"inline-flex", color:"gray"}}><div style={{backgroundColor: '#f1ab4f', width: '25px', height: '25px', marginRight:'15px'}}></div> Año de publicación</div>
            </div>
        </div>
    );
}

export default LineChart;


