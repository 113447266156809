const processEquation = (equation) => {
    let Equation = equation.replace(/AND/g, '*22 AND *22');
    Equation = Equation.replace(/OR/g, '*22 OR *22');
    Equation = Equation.replace(/NOT/g, '*22 NOT *22');


    for (let index = 0; index < equation.split('(').length + 1; index++) {
        Equation = Equation.replace('(', '[ *22');
        Equation = Equation.replace(')', '*22 ]');
    }

    for (let index = 0; index < equation.split('(').length + 1; index++) {
        Equation = Equation.replace('*22 [', '(');
        Equation = Equation.replace('*22[', '(');
        Equation = Equation.replace('] *22', ')');
        Equation = Equation.replace(']*22', ')');
    }

    if (Equation.charAt(0) === "[") {
        Equation = Equation.replace('[', '(');
    } else {
        Equation = '*22' + Equation;
    }

    if (Equation.charAt(Equation.length - 1) === "]") {
        Equation = Equation.replace(']', ')');
    } else {
        Equation = Equation + '*22';
    }
    return Equation;
}
export default processEquation;