import React, { useState, useEffect } from 'react'
import translateNew from '../../../../../../hooks/useTranslate'
import TranslateModal from '../../../../../ui/translateModal/TranslateModal'
import { Link } from 'react-router-dom'

const EventResult = (props) => {

    const [descriptionText, setDescription] = useState(props.description.split('\n'))
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTranslate(false);
        setDescription(props.description.split('\n'))
    }, [props.description])

    useEffect(() => {
        if(translate){
            translateText();
            return;
        }
        setDescription(props.description.split('\n'))
    },[translate])

    const translateText = () => {
        translateNew(descriptionText).then(response => {
            setDescription(response)
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-8">
                    <p><strong>{props.title}</strong></p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-2">
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                </div>
            </div>
            {props.modal ?
                <p style={{ columnCount: 2, marginTop: '2rem' }}>{
                    props.description.slice(0, 360)} <Link to={'/resultSelected'}> ver mas...</Link>
                </p> :
                <div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p><strong>Fecha del evento: </strong>{props.date}</p>
                            <p><strong>Lugar: </strong>{props.location}</p>
                        </div>
                        <div className="col-12 col-md-6">
                            {descriptionText.map(paragraph => {
                                return (
                                    <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                        {paragraph}
                                    </p>
                                );
                            })}
                            {/* <p style={{marginTop: '2rem', color:'black'}}>{props.description}</p> */}
                        </div>
                    </div>
                </div>
            }
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} />
        </div>
    )
}
export default EventResult;
