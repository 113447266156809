import React from 'react';
import { useDispatch } from 'react-redux';
import { uiOpenModal } from '../../../../actions/uiAction';
import {eventClearActiveEvent} from '../../../../actions/eventsAction';

export const AddNewFab = () => {

    const dispatch = useDispatch();

    const handleClickNew = () => {
        dispatch(eventClearActiveEvent());
        dispatch( uiOpenModal() );
    }


    return (
        <button
            className="btn btn-primary fab"
            onClick={ handleClickNew }
        >
            <i className="fa fa-plus"></i>
        </button>
    )
}
