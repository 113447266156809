import React, { useState, useEffect } from 'react'
import './datosTab.scss';
import translateText from '../../../../../../../hooks/useTranslate'
import { useSelector } from 'react-redux';


const Datos = ({ translate }) => {

    const patent = useSelector(state => state.home.resultSelected);
    const [abstract, setAbstract] = useState(patent.abstract_en);

    useEffect(() => {
        if (!translate) {
            setAbstract(patent.abstract_en)
            return;
        }

        translateText(abstract).then(response => {
            setAbstract(response)
        });

    }, [translate, patent])

    return (
        <div className='datosMain'>
            <div className="bodyTab">
                <div className="item">
                    <div style={{ float: 'left' }}>
                        <h6>N.° de publicación: </h6>
                        {patent.publicacion_reference_country && patent.publicacion_reference_country[0]}
                        {patent.publicacion_reference_doc_number && patent.publicacion_reference_doc_number[0]}
                        {patent.publicacion_reference_kind && patent.publicacion_reference_kind[0]}
                    </div>
                </div>
                <br />
                <br />
                <div className="item">
                    <h6>IPC: </h6>
                    {patent.ipcr && patent.ipcr.map(x => <p>{x}, </p>)}
                </div>
                <div className="item"><h6>Inventores: </h6><p>{patent.inventors_name ? patent.inventors_name[0] : ''}</p></div>
                <hr />
                <div>
                    <h6>Título:</h6>
                    <p>{patent.hasOwnProperty('originaltitle') ? patent.originaltitle : patent.title}</p>
                </div>

                <div className="abstract">
                    <h6>Abstract:</h6>
                    <p></p>

                    {Array.isArray(abstract) ?
                        abstract.map(paragraph => {
                            return (
                                <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                    {paragraph}
                                </p>
                            );
                        }) :
                        <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                            {abstract}
                        </p>
                    }
                    {/* <p style={{ color: 'black' }}>{patent.abstract_en}</p> */}
                </div>
            </div>
        </div>
    )
}

export default Datos
