import React, { useState, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import cookie from 'react-cookies'

//redux
import { useDispatch } from 'react-redux';
import { loginTokenAction } from '../../../actions/authAction'
import { getUserOptionsAction } from '../../../actions/profileActions'
import {getLastSearh} from '../../../actions/homeAction'

const ProtectedRoute = ({...props}) => {
    //componente prop
    const Component = props.component;
    const location = useLocation(); 

    //states
    const [wait,setWait] = useState(0);
     
    //redux
    const dispatch = useDispatch();
    const autenticated = useSelector(state => state.auth.autenticated);
    const profiled = useSelector(state => state.auth.profiled);
    const role = useSelector(state => state.auth.role);
    
    //useEffect
    useEffect(() => {
        dispatch(loginTokenAction());
        getUserOptions();
    }, [profiled])// eslint-disable-line react-hooks/exhaustive-deps

    /**Funciones */
    //trae las opciones del usuario
    const getUserOptions = async() => {
        if(wait === 0 && profiled === 1){
           await dispatch(getLastSearh());
           await dispatch(getUserOptionsAction());
           setWait(1);
        }
    }
    
    if(!cookie.load('token') || !autenticated){
        return <Redirect to={{ pathname: '/login' }} />
    }
    else if(autenticated && profiled === 0){
        if(location.pathname !== "/profile"){
            return <Redirect to={{ pathname: '/profile' }} />
        }else{
            return  <Component />
        }
    }
    else if(autenticated && profiled === 1 && wait === 1){
        if(location.pathname === "/profile"){
            return <Redirect to={{ pathname: '/homeApp' }} />
        }else if(location.pathname.indexOf('massiveSignup')>-1 ||
                location.pathname.indexOf('graphicTest')>-1 ||
                location.pathname.indexOf('omnichannel')>-1){
                if(role !== 2){
                    return <Redirect to={{ pathname: '/homeApp' }} />
                }
            return  <Component {...props} />
        }else{
            return  <Component {...props}/>
        }      
    }else{
        return ( <h1>Loading...</h1>)
    }
    
}

export default ProtectedRoute;