import React,{useEffect,useState} from 'react'
import './graph.scss';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import {useSelector} from 'react-redux';

const GraphTab = ({translate}) => {
    
    const patent = useSelector(state => state.home.resultSelected);
    const [newImages,setNewImages] = useState([]);

    useEffect(() => {        
        setImages();
    }, [])

    const setImages = () =>{  
        var imagesArray = [];  
        console.log('aaaaaaaa'); 
        patent.drawings_file.forEach((img,index) => {
            if (patent.hasOwnProperty("source_code")){
                if(patent.source_code[0].toUpperCase() === 'EP'){
                    img = img.replace('/mnt/raid/EPO/Procesados/',"https://imgpatents.lantiasas.com/epo/imagenes/");
                    img = img.replace("tif",'jpg');
                }
            }else if(img.includes('USPTO/descomprimidos')){ 
                    img = img.replace('/mnt/raid/USPTO/descomprimidos/',"https://imgpatents.lantiasas.com/ustpo/grant/imagenes/");
                    img = img.replace("TIF",'jpg');
            } 
            else if(patent.type[0].toLowerCase() === 'application'){
                /* img = img.replace('/mnt/raid/USPTO/aplicacion/descomprimidos/',"http://imgpatents.lantiasas.com/uspto/aplication/imagenes/");
                img = img.replace("TIF",'jpg');*/
            }
            
            var imageName = 'Fig'+(index+1).toString();
            var jimg = {
                original:img,
                thumbnail:img,
                originalTitle:imageName,
                thumbnailLabel:imageName,
                description:'Figura: ' + imageName
            }
            imagesArray.push(jimg)
        });
        setNewImages(imagesArray)
    }
    return (
        <div className='graphTab row align-items-center justify-content-center w-100'>
                <ImageGallery 
                    items={newImages} 
                    showFullscreenButton={false}  
                    onErrorImageURL={'En proceso'}  
                />
        </div>
    )
}

export default GraphTab
