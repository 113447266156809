import React, { useState, useEffect } from 'react'

import allFilter from '../../../../../images/icons/filters/allFilter.png';
import eventFilter from '../../../../../images/icons/filters/eventFilter.png';
import startupFilter from '../../../../../images/icons/filters/startupFilter.png';
import leftTab from '../../../../../images/homeApp/leftTab.png';
import rightTab from '../../../../../images/homeApp/rightTab.png';
import videosFilter from '../../../../../images/icons/filters/videosFilter.png';
import convocatoriaFilter from '../../../../../images/icons/filters/convocatoriaFilter.png';
import expertFilter from '../../../../../images/icons/filters/expertFilter.png';
import cvLac from '../../../../../images/icons/filters/cv_lac.png';
import gpLac from '../../../../../images/icons/filters/gp_lac.png';
import newsFilter from '../../../../../images/icons/filters/newsFilter.png';
import patentFilter from '../../../../../images/icons/filters/patentFilter.png';
import { useDispatch, useSelector } from 'react-redux';
import scientificArticleFilter from '../../../../../images/icons/filters/scientificArticlesFilter.png';
import { changeSource } from '../../../../../actions/homeAction';
import { ADVANCED_SEARCH } from '../../../../../globalConstants/typeOfSearch'
import ReactTooltip from "react-tooltip";
import { cleanAllResultsState } from '../../../../../actions/homeAction'
import './filtersTab.scss'

const FiltersTab = ({ setChangeTab, changeTab }) => {

    const dispatch = useDispatch();
    const source = useSelector(state => state.home.source);
    const typeOfSearch = useSelector(state => state.home.typeOfSearch);
    const results = useSelector(state => state.home.results);


    const [tab, setTab] = useState(true)
    const [tooltip, setTooltip] = useState('')
    const [sources, setSource] = useState('all')

    // useEffect(() => {
    //     setSource(source);
    // }, [source])

    useEffect(() => {
        console.log('cambio el result');
        if (results.length == 0) {
            if (typeOfSearch === ADVANCED_SEARCH) {
                return;
            }
            
            dispatch(changeSource(sources))
            
        }
    }, [results])
    
    useEffect(() => {
        console.log('entro', sources);
      dispatch(cleanAllResultsState())
    }, [sources])
    
    const changeTheSource = (source) => {
        setSource(source);
    }

    const changeSide = (side) => {

        if (side === 'sideRight') {
            setTab(false);
            setChangeTab(true);
            return;
        }

        setTab(true);
        setChangeTab(false);
    }

    return (
        <div className="filtersMain">
            <div>
                <div style={{ display: 'inline-flex', width: '100%' }}>
                    <img className="imageTabs" src={tab ? leftTab : rightTab} alt="" />
                    <div onClick={() => changeSide('sideLeft')} className="chartTab">
                        <div className="filters">
                            <ReactTooltip id="registerTip" place="top" effect="solid">
                                {tooltip === 'todas' ?
                                    'ver resultados en todas las fuentes'
                                    :
                                    `ver solo resultados de ${tooltip}`
                                }
                            </ReactTooltip>
                            <img onMouseOver={() => setTooltip('todas')} data-tip data-for="registerTip" onClick={() => changeTheSource('all')} className={sources === 'all' ? "filter" : 'filterDown'} src={allFilter} alt='' />
                            <img onMouseOver={() => setTooltip('convocatorias')} data-tip data-for="registerTip" onClick={() => changeTheSource('convocatorias')} className={sources === 'convocatorias' || sources === 'all' ? "filter" : 'filterDown'} src={convocatoriaFilter} alt='' />
                            <img onMouseOver={() => setTooltip('eventos')} data-tip data-for="registerTip" onClick={() => changeTheSource('eventos')} className={sources === 'eventos' || sources === 'all' ? "filter" : 'filterDown'} src={eventFilter} alt='' />
                            {/* <img onMouseOver={()=>setTooltip('expertos')} data-tip data-for="registerTip" onClick={() => setSource('experts')} className={sources === 'experts' || sources === 'all' ? "filter" : 'filterDown'} src={expertFilter} alt='' /> */}
                            <img onMouseOver={() => setTooltip('noticias')} data-tip data-for="registerTip" onClick={() => changeTheSource('noticias')} className={sources === 'noticias' || sources === 'all' ? "filter" : 'filterDown'} src={newsFilter} alt='' />
                            <img onMouseOver={() => setTooltip('patentes')} data-tip data-for="registerTip" onClick={() => changeTheSource('patentes')} className={sources === 'patentes' || sources === 'all' ? "filter" : 'filterDown'} src={patentFilter} alt='' />
                            <img onMouseOver={() => setTooltip('artículos')} data-tip data-for="registerTip" onClick={() => changeTheSource('articles')} className={sources === 'articles' || sources === 'all' ? "filter" : 'filterDown'} src={scientificArticleFilter} alt='' />
                            <img onMouseOver={() => setTooltip('startups')} data-tip data-for="registerTip" onClick={() => changeTheSource('startups')} className={sources === 'startups' || sources === 'all' ? "filter" : 'filterDown'} src={startupFilter} alt='' />
                            <img onMouseOver={() => setTooltip('videos')} data-tip data-for="registerTip" onClick={() => changeTheSource('videos')} className={sources === 'videos' || sources === 'all' ? "filter" : 'filterDown'} src={videosFilter} alt='' />
                            <img onMouseOver={() => setTooltip('grupos de investigación')} data-tip data-for="registerTip" onClick={() => changeTheSource('gp_lac')} className={sources === 'gp_lac' || sources === 'all' ? "filter" : 'filterDown'} src={gpLac} alt='' />
                            <img onMouseOver={() => setTooltip('investigadores')} data-tip data-for="registerTip" onClick={() => changeTheSource('cv_lac')} className={sources === 'cv_lac' || sources === 'all' ? "filter" : 'filterDown'} src={cvLac} alt='' />
                        </div>
                    </div>
                    <div onClick={() => changeSide('sideRight')} className="searchTab">
                        <div className="analytics">
                            <img src={require('../../../../../images/icons/filters/analytics.png').default} alt="" />
                            <p style={{ marginBottom: '6px' }}>Análisis de busqueda Principal</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiltersTab
