import { combineReducers } from 'redux';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import patentReducer from './patentReducer';
import companyReducer from './companyReducer';
import calendarReducer from './calendarReducer';
import expertReducer from './expertReducer';
import uiReducer from './uiReducer';
import videoReducer from './videoReducer';
import articleReducer from './articleReducer';
import newReducer from './newReducer';
import homeReducer from './homeReducer';
import markReducer from './markReducer';
import graphicReducer from './graphicReducer';

export default combineReducers({
    auth:authReducer,
    company:companyReducer,
    profile:profileReducer,
    patent:patentReducer,
    calendar:calendarReducer,
    ui:uiReducer,
    experts:expertReducer,
    video:videoReducer,
    article:articleReducer,
    home:homeReducer,
    new:newReducer,
    mark:markReducer,
    graphic:graphicReducer
});