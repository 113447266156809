import React,{ useState } from 'react';
import ReactTooltip from 'react-tooltip'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { setSearchAction,setModeAdvancedAction } from '../../../../actions/patentAction'

const SearchBox = () => {

  //redux
  const dispatch = useDispatch();
  const results = useSelector(state => state.patent.results);
  const search = useSelector(state => state.patent.search);

  /**Funciones */
  //cambia state de search al usuario darle click o enter
  const onChange = e => {
    dispatch(setSearchAction(e.target.value,'keyboard'));
  } 

  //llama la accion para cambiar la variable del search en redux store
  const onClick = () => {
   dispatch(setSearchAction(search,'click'));
  }

  //cambiar de modo de busqueda
  const advancedModeChange = (mode) => {
    search.trim() === '' || search.trim() === '*' 
    ? alert('digita al menos una palabra') 
    : dispatch(setModeAdvancedAction(mode));      
  }

  return ( 
    <div className="searchBox w-100">
      <div className="inputArea">
      {
        <div className="returnPatentList">
          <span className="fas fa-arrow-left"></span>
        </div>
      }
        <input 
          type="text" 
          id="inputArea"
          className={ 'inputAreaReduce'}
          placeholder="Escribe aquí tu búsqueda"
          onChange={onChange}
        />
        <div className="resultsDisplay">{`${results} resultados para su búsqueda`}</div>
      </div>
      {/**busqueda asistida */}
      <div className="filterButton" data-for="assited" onClick={() => advancedModeChange('assisted')}  data-tip="Búsqueda guiada"></div>
      <ReactTooltip id="assited" place="bottom" type="dark" effect="solid" />
      {/**busqueda Avanzada */}
      <div className="filterButton" data-for="advanced" onClick={() => advancedModeChange('advanced')} data-tip="Búsqueda avanzada"></div>
      <ReactTooltip id="advanced" place="bottom" type="dark" effect="solid" />
      <div className="btn btn-primary w-100" id="searchButton" onClick={onClick}><h3>buscar</h3></div>
    </div>
   );
}
 
export default SearchBox;