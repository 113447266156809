import {
    ADD_COLECTION,
    GET_COLECTION,
    DELETE_MARK,
    SET_MARK,
    ADD_MARK,
    ADD_CONTENT_MARK,
    COLLECTION_WITH_LATEST_MARK
} from '../types/markTypes';
import axios from '../components/config/axios'
import { colectionWithLatestMark } from '../helpers/colectionWithLatestMark'



//funcion para traer los favoritos
export function getColectionAction() {
    return async (dispatch) => {
        try {
            var rta = await axios.get('/bookmarks');

            if (rta.data.marks.length !== 0) {
                dispatch(setColectionWithLatestMark(colectionWithLatestMark(rta.data)))
            }

            dispatch(getColection(rta.data));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para agregar una nueva coleccion a favoritos
export function addColectionAction(colection) {
    return async (dispatch) => {
        try {
            var formdata = new FormData();
            formdata.append('name', colection)
            var rta = await axios.post('/bookmarks/colection', formdata);
            var error = 1;
            if (rta.data !== 0) {
                var colectionToRedux = {
                    id: rta.data.id,
                    dateCreated: rta.data.dateCreated,
                    name: colection
                }
                dispatch(addColection(colectionToRedux));
            } else {
                error = 0;
            }
            return rta.data.id;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para eliminar los favoritos
export function delMarkAction(id) {
    return async (dispatch) => {
        try {
            var rta = await axios.delete(`bookmarks/mark/${id}`);
            if (rta.data === 1) {
                dispatch(delMark(id));
            }
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para mover favoritos
export function setMarkAction(markToUpdate) {
    return async (dispatch) => {
        try {
            var rta = await axios.put(`bookmarks/mark/${markToUpdate.id}`, markToUpdate);
            if (rta.data === 1) {
                dispatch(setMark(markToUpdate));
            }
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para agregar favoritos
export function addMarkAction(mark) {
    return async (dispatch) => {
        try {
            var rta = await axios.post(`bookmarks/mark`, mark);
            if (rta.data !== 0) {
                mark.id = rta.data;

                dispatch(addMark(mark));
                dispatch(getColectionAction());
                //dispatch(addContentToMark(mark.colection_id));                                                                                                                                                                
            }
            return 1;
        } catch (error) {
            return 0;
        }
    }
}


//ultima coleccion usada para guardar las marcas
export function setColectionWithLatestMark(colection) {
    return async (dispatch) => {
        try {
            dispatch(lastColectionUsed(colection));
        } catch (error) {
            return 0;
        }
    }
}

const getColection = colection => ({
    type: GET_COLECTION,
    payload: colection
});


const addColection = colection => ({
    type: ADD_COLECTION,
    payload: colection
});

const delMark = id => ({
    type: DELETE_MARK,
    payload: id
});

const setMark = mark => ({
    type: SET_MARK,
    payload: mark
});

const addMark = mark => ({
    type: ADD_MARK,
    payload: mark
});

const addContentToMark = mark => ({
    type: ADD_CONTENT_MARK,
    payload: mark
});

const lastColectionUsed = colection => ({
    type: COLLECTION_WITH_LATEST_MARK,
    payload: colection
});



