import React, { useState, useEffect } from 'react'
import DatosTab from './datosTab/Datos.jsx'
import ClaimsTab from './claimsTab/ClaimsTab.jsx'
import GraphTab from './graphTab/GraphTab.jsx'
import { useSelector } from 'react-redux';
import DescriptionTab from './descriptionTab/DescriptionTab.jsx'

import TranslateModal from '../../../../../ui/translateModal/TranslateModal'

import './patentResult.scss';

const PatentResult = (props) => {
    const patent = useSelector(state => state.home.resultSelected);
    console.log('patent', patent);
    const [tab, setTab] = useState('Generalidades')
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTranslate(false)
        setTab('Generalidades')
    }, [props.title])


    return (
        <div className="patentResultMain">
            <div>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <p style={{ color: 'var(--secundaryFirtFour)', fontSize: '23px' }}>{patent.hasOwnProperty('originaltitle') ? patent.originaltitle : patent.title}</p>
                    </div>
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-2">
                        <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <ul style={{ display: 'inline-flex' }}>
                        <div style={{ width: '3%' }} className='besideLi'></div>
                        {
                            ['Generalidades', 'Descripción', 'Claims', 'Esquemas y Figuras'].map(x => {
                                return (
                                    <li  onClick={() => setTab(x)} className={tab === x ? 'active' : ''}>{x}</li>
                                );
                            })
                        }
                        <div style={{ width: '3%' }} className='besideLi'></div>
                    </ul>
                </div>
                <div className="row">
                    {tab === 'Generalidades' && <DatosTab translate={translate} />}
                    {tab === 'Claims' && <ClaimsTab translate={translate} />}
                    {tab === 'Esquemas y Figuras' && <GraphTab translate={translate} />}
                    {tab === 'Descripción' && <DescriptionTab translate={translate} />}
                </div>
            </div>
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen}/>
        </div>
    )
}
export default PatentResult;
