import {
    SET_GRAPHIC,
    SET_GRAPHIC_TO_DRAW,
    ADD_GRAPHIC_ARRAY,
    DELETE_GRAPHIC,
    LOCK_GRAPHIC,
    CLEAN_GRAPHIC_ARRAY,
    LOADING_GRAPHIC
} from '../types/graphicTypes';

//state
const initialState = {
    graphicSelected: '',
    graphicToDraw: null,
    graphicArray: [],
    loading: false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case SET_GRAPHIC:
            return {
                ...state,
                graphicSelected: action.payload
            }

        case SET_GRAPHIC_TO_DRAW:
            return {
                ...state,
                graphicToDraw: action.payload
            }

        case ADD_GRAPHIC_ARRAY:
            return {
                ...state,
                graphicArray: [action.payload, ...state.graphicArray]
            }

        case CLEAN_GRAPHIC_ARRAY:
            return {
                ...state,
                graphicArray: state.graphicArray.filter(graphic => graphic.lock === true)
            }

        case DELETE_GRAPHIC:
            return {
                ...state,
                graphicArray: state.graphicArray.filter(graphic => graphic.id !== action.payload)
            }

        case LOCK_GRAPHIC:
            return {
                ...state,
                graphicArray: state.graphicArray.map(graphic => graphic.id === action.payload.id ? action.payload : graphic)
            }

        case LOADING_GRAPHIC:
            return {
                ...state,
                loading: action.payload
            }

        default:
            return state;
    }
}