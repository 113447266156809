import {NEW_EXPERT, GET_EXPERT, SEARCH_EXPERT, EXPERT_SELECTED, FIND_EXPERT} from "../types/expertTypes";
import clienteAxios from '../components/config/axios';
import Swal from 'sweetalert2';

export const expertAddNew = (expert) => {

    return async (dispatch) => {
        try {
            //endpoint de la bd
            const response = await clienteAxios.post('expert/save', expert);
            //Guarda en redux storage
            if(response.data.action === 'updated') {
                Swal.fire('Success', `El experto fue actualizado con exito`, 'success');
            } else {
                Swal.fire('Success', `El experto fue creado con exito`, 'success');
            }

            dispatch(addExpert(response.data.expert))
            return 1;
        } catch(error) {
            Swal.fire('Success', `ocurrio un error al crear o actualizar el experto`, 'error');
        }
    }
}

export const getExperts = (userId, page) => {
    return async (dispatch) => {
        try {
            //endpoint de la bd
            const response = await clienteAxios.get(`expert/${userId}?page=${page}`);
            //Guarda en redux storage
            dispatch(fetchExperts(response.data))
            return 1;
        } catch(error) {
        }
    }
}

export const selectExpert = (expert) => {
    return async (dispatch) => {
        try {
            dispatch(expertSelected(expert))
            return 1;
        } catch(error) {
        }
    }
}

export const searchExpert = (keyWord, page, searchType, id) => {
    return async (dispatch) => {
        try {
            let keywords = new FormData();
            keywords.append('keyword', JSON.stringify(keyWord));
            //endpoint de la bd
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            const response = await clienteAxios.get(`expert/search/${JSON.stringify(keyWord)}/${searchType}/${id}?page=${page}`);
            //Guarda en redux storage
            dispatch(search(response.data))
            return 1;
        } catch(error) {
        }
    }
}

//conectar con el moculo de expertos y mandar 
//las palabras claves al store para buscar los expertos
export const expertConnect = (keyword) => {
    return async (dispatch) => {
        dispatch(findExperts(keyword));
    }
}

//agrega evento
const addExpert = newExpert => ({
    type: NEW_EXPERT,
    payload: newExpert
});

//agrega los expertos al store (paginación = 8)
const fetchExperts = experts => ({
    type: GET_EXPERT,
    payload: experts
});

const search = search => ({
    type: SEARCH_EXPERT,
    payload: search
})

const expertSelected = expert => ({
    type: EXPERT_SELECTED,
    payload: expert
})

const findExperts = keyword => ({
    type: FIND_EXPERT,
    payload: keyword
})