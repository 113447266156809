import React, {useState} from 'react'
import ClienteAxios from '../../../config/axios'

const Support = () => {

    /**States */
    const [email, setEmail] = useState('')

    //llena el mensaje del email
    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = async() => {
      await ClienteAxios.post('email/profile-support',{'body':email});
    }

    return (
        <div>
            <div className="supportContainer">
                <div>
                    <h4>Contáctenos</h4>
                    <p>
                        La mejor manera de contactarnos es a través de éste formulario.
                        También nos puedes enviar un correo eletrónico a admin@lantiasas.com
                    </p>
                    <div>
                        <textarea value={email} onChange={handleChange} placeholder="Cuéntanos algo..."/>
                        <br/>
                        <br/>
                        <button className="btn btn-primary" onClick={handleSubmit}>Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Support
