import React from 'react'
import { Link } from 'react-router-dom'


const VideoItem = ({video}) => {
    return (    
        <>
            <h1>{video.name}</h1>
            <h4>Pais: {video.country_code}</h4>
            <h4>Region: {video.region}</h4>
            <h4>Ciudad: {video.city}</h4>
            <Link to={{pathname: `/videopage/${video.id}`}}>
                <button className='btn btn-outline-dark'>Conoce mas</button>
            </Link>          
        </>
      );
}
 
export default VideoItem;