import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Highlighter from "react-highlight-words"
import { useHistory } from 'react-router-dom';

//redux
import {useSelector, useDispatch} from 'react-redux'
import {expertConnect} from '../../../../actions/expertsAction';

const PatentItem = ({patent}) => {
    //redux
    const connector = useSelector(state => state.patent.wordsConnectorAssisted)
    const dispatch = useDispatch();
    const history = useHistory();

    /**States */
    const [abstract, setAbstract] = useState(false);//state para hacer visible el abstract
    const [highlighterWords, setHighlighterWords] = useState({'words': [], 'color': ''});//state para hacer visible el abstract

    useEffect(() => {
        if(isEmpty(connector)) {
        } else {
            var orLight = connector.or.split(' ');
            var andLight = connector.and.split(' ');

            var arrAnd = andLight.map(item => {
                let obj = {}
                obj[item] = "color_and"; return obj;
            }, {});

            var arrOr = orLight.map(item => {
                let obj = {}
                obj[item] = "color_or"; return obj;
            }, {})

            var wordsResults = {
                words: [...andLight, ...orLight],
                color: Object.assign({}, ...arrAnd, ...arrOr)
            }
            setHighlighterWords(wordsResults);
        }
    }, [connector])

    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    //conectar con el modulo de expertos
    const sendKeywordToFindExperts = async (title) => {
        await dispatch(expertConnect(title));
        history.push('/expertApp');
    }

    return (
        <div className='patent-items row'>

            <div className="col-3">
                <div className='patent-item-img'>

                </div>
            </div>
            <div className="col-6">
                <h3>Titulo:</h3>
                <p>{patent.invention_title_en[0]}</p>
                <h5 onClick={() => setAbstract(!abstract)}>Abstract</h5>
                {abstract
                    ? <p>
                        <Highlighter
                            highlightClassName={highlighterWords.color}
                            searchWords={highlighterWords.words}
                            textToHighlight={patent.abstract_en[0]}
                        >
                        </Highlighter>
                    </p>
                    : ''
                }
            </div>
            <div className="col-3 row mt-4">
                <h1>+</h1>
                <Link to={{pathname: `/patentpage/${patent.id}`}}>
                    <h3 className='ml-1 mt-2'>Conoce más</h3>
                </Link>
                <button onClick={()=>sendKeywordToFindExperts(patent.invention_title_en[0])}>conectar con experto</button>
            </div>
        </div>
    );
}

export default PatentItem;