import React, { useEffect } from 'react'
import SearchBox from './searchBox/SearchBox'
import NewItem from './newItem/NewItem'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getNewsAction } from '../../../actions/newAction'

const NewsApp = () => {

    //redux
    const dispatch = useDispatch();
    const search = useSelector(state => state.new.search);
    const news = useSelector(state => state.new.news);

    //si el buscador queda en blanco hace llamado a la api
    useEffect(() => {
        if(search.trim() === '' || search.trim() === '*'){
            getNews(0);
        }
    }, [search])

    //hace el llamado de traer empresas al endpoint
    const getNews = async(pageSended) => {
        await dispatch(getNewsAction(pageSended));        
    }

    return ( 
        <>
            <SearchBox />
            <div className="row">
                <div className="col-3">
                    <h2>Hola soy filtros</h2>
                </div>
                <div className="col-9">
                    {news.map((oneNew,index) => (
                        <NewItem 
                            oneNew={oneNew}
                            key={index}
                        />
                    ))

                    }
                </div>
            </div>
        </>
     );
}
 
export default NewsApp;