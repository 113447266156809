import React from 'react'


const VideoPage = ({ ...props }) => {

    
    return ( 
        <>
            <h1>soy video con el id:{props.computedMatch.params.id}</h1>
        </>
    );
}
 
export default VideoPage;