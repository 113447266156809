import React, {useState, useEffect, useRef} from 'react'
import ExpertItem from './expertItem/ExpertItem'
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {getExperts, searchExpert, expertConnect} from '../../../actions/expertsAction'
import {goToSchedule} from '../../../actions/eventsAction';
import './index.css'

const ExpertApp = () => {

    const [search, setSearch] = useState('');
    const experts = useSelector(state => state.experts.experts);
    const pages = useSelector(state => state.experts.pages);
    const keywordToFindExpert = useSelector(state => state.experts.keywordToFindExpert);
    const results = useSelector(state => state.experts.results);
    const amIexpert = useSelector(state => state.experts.amIexpert);
    const userId = useSelector(state => state.auth.userId);
    const currentPage = useRef(null);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(keywordToFindExpert !== '') {
            dispatch(searchExpert(keywordToFindExpert, 1, 'expertConnect', userId))
        } else {
            dispatch(getExperts(userId, 1));
        }
    }, [])

    useEffect(() => {
    }, [experts])

    const handleInputChange = (event) => {
        setSearch(event.target.value);
    }

    const sendData = (event) => {
        event.preventDefault();
        if(search !== '') {
            dispatch(searchExpert(search, 1, 'normal', userId))
        } else {
            dispatch(getExperts(userId, 1));
        }
    }

    const pagination = (p) => {
        if(search === '') {
            dispatch(getExperts(userId, p));
        } else {
            dispatch(searchExpert(search, p, 'normal', userId))
        }
        currentPage.current = p;
    }

    const prevPage = (p) => {
        if(search === '') {
            dispatch(getExperts(userId, p));
        } else {
            dispatch(searchExpert(search, p, 'normal', userId))
        }
        currentPage.current = p;
    }

    const nextPage = (p) => {
        if(search === '') {
            dispatch(getExperts(userId, p));
        } else {
            dispatch(searchExpert(search, p, 'normal', userId))
        }
        currentPage.current = p;
    }

    const goSchedule = () => {
        dispatch(goToSchedule(true));
    }

    return (
        <div>
            <div className="expertContainer">

                <Link id="expertFormLink" to="/expertApp/form">
                    {amIexpert === null ? <span>Quieres ser un experto?</span> : <span>Editar perfil de experto</span>}
                </Link>
                <Link onClick={goSchedule} id="agenda" to="/expertApp/calendar">
                    <span>ver mi agenda</span>
                </Link>
                <div id="searchBox">
                    <form onSubmit={sendData}>
                        <div className="form-group">
                            <label>lantia//engine</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Buscar experto" onChange={handleInputChange} />
                        </div>
                        <button type="submit" className="btn btn-primary">Buscar</button>
                    </form>
                </div>
                <div>
                    <h6>Resultados:{results}</h6>
                </div>
                <div className="row">
                    {
                        (experts !== undefined) ? (       
                            experts.length > 0 ?(
                                experts.map((e, i) => {
                                    return (<div key={i} className="col-12 col-sm-12 col-md-3">
                                        <ExpertItem item={e} key={i} />
                                    </div>)
                                })
                            ):'no se encontraron resultados'
                        ):'no se encontraron resultados'
                    }
                </div>
            </div>
            <div className="pagination">
                <div className="page" onClick={() => prevPage(currentPage.current === 1 || currentPage.current === null ? 1 : currentPage.current - 1)}>{'<<'}</div>
                {[...Array(pages).keys()].map(i => <div key={i} onClick={() => pagination(i + 1)} className="page">{i + 1}</div>)}
                <div className="page" onClick={() => nextPage(currentPage.current < pages || currentPage.current === null ? currentPage.current + 1 : pages)}>{'>>'}</div>
            </div>
        </div>
    )
}
export default ExpertApp;