import React, { useState, useEffect, useRef } from 'react'
import PatentFilter from './PatentFilter'
import CountryFilter from './CountryFilter'
import IpcFilter from './IpcFilter'
import DateFilter from './DateFilter'
import Equation from './Equation'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setFilterAction, 
  deleteDateFilterAction, 
  deleteTypeFilterAction, 
  deleteCountriesFilterAction, 
  deleteIpcFilterAction } 
from '../../../../actions/patentAction'

const Filters = () => {
    //ref para prevenir que se renderice dos veces la primera vez, ya que el filtro esta vacio
    const didMountRef = useRef(false);

    //redux
    const dispatch = useDispatch();
    const datesReux = useSelector(state => state.patent.datesFilter);

    
    /**States */
    const [endpointDates,setEndpointDates] = useState(''); //state de fechas
    const [endpointTypes,setEndpointTypes] = useState(''); //state de tipos de patente
    const [endpointCountries,setEndpointCountries] = useState(''); //state de tipos de paises
    const [endpointIpc,setEndpointIpc] = useState(''); //state de IPC
    const [filterVisible,setFilterVisible] = useState({
      patentVisible:false,
      countryVisible:false,
      ipcVisible:false,
      dateVisible:false
    })//objecto para mostrar u ocultar filtros

    /**Funciones */
    //funcion para mostrar filtros
    const setVisibles = (e) => {
      setFilterVisible({
        ...filterVisible,
        [e.target.name]:!filterVisible[e.target.name]
      })
    }

   //genera endpoint final
   useEffect(() => {
      if (didMountRef.current){
        var filterEndpoint = endpointDates+endpointTypes+endpointCountries+endpointIpc;
        dispatch(setFilterAction(filterEndpoint));
      }else{
        didMountRef.current = true;
      }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpointTypes,endpointCountries,endpointIpc,endpointDates]);

    //**Funciones */
    //elimina el filtro de la caja de texto seleccionado por el usuario
    const deletefilter = (action) => {
      if(action === 'dates'){
        dispatch(deleteDateFilterAction());
        setEndpointDates('');
      }
      else if(action === 'types'){
        dispatch(deleteTypeFilterAction());
        setEndpointTypes('');
      }else if(action === 'countries'){
        setEndpointCountries('');
        dispatch(deleteCountriesFilterAction());
      }else if(action === 'ipc'){
        setEndpointIpc('');
        dispatch(deleteIpcFilterAction());
      }
    }

    return ( 
        <>  
            {/*Ecuacion*/}
            <h2>Ecuacion</h2>
            <Equation />

            {/****Filtros****/}
            <h2>Filtros aplicados</h2>
            <div className="apply-filters">
              {endpointDates ? <h6>tipo de patente: fecha = (De {datesReux.startDate.toISOString().split('T')[0]} hasta {datesReux.finalDate.toISOString().split('T')[0]}) <strong onClick={() => deletefilter('dates')} style={{color:'red'}}>x</strong> </h6> : null}
              {endpointTypes ? <h6>tipo de patente: ({endpointTypes.replace('&type=','').replaceAll('+OR+',',')}) <strong onClick={() => deletefilter('types')} style={{color:'red'}}>x</strong> </h6> : null}
              {endpointCountries ? <h6>Paises: ({endpointCountries.replace('&geo=','').replaceAll('+OR+',',')}) <strong onClick={() => deletefilter('countries')} style={{color:'red'}}>x</strong></h6> : null}
              {endpointIpc ? <h6>IPC: ({endpointIpc.replace('&ipc_filtro=','').replaceAll('+OR+',',')}) <strong onClick={() => deletefilter('ipc')} style={{color:'red'}}>x</strong></h6> : null}
            </div>
            <h2>Filtrar búsqueda</h2>
            <div className="search-filters">
              {/**Filtros por fechas */}
              <div className="form-group row">
                <label  className="col-sm-10 col-form-label">Filtrado por fecha</label>
                <div className="col-sm-2">
                  <button name='dateVisible' onClick={setVisibles} className="btn">ok</button>
                </div>
              </div>
              {filterVisible.dateVisible 
                ? <DateFilter setEndpointDates={setEndpointDates} />
                : ''
              }

              <hr />

              {/**Filtros por tipos de patente */}
              <div className="form-group row">
                <label  className="col-sm-10 col-form-label">Tipo de patente</label>
                <div className="col-sm-2">
                  <button name='patentVisible' onClick={setVisibles}  className="btn">ok</button>
                </div>
              </div>
              {filterVisible.patentVisible 
                ? <PatentFilter setEndpointTypes={setEndpointTypes} />
                : ''
              }
              <hr />

              {/**Filtros por pais */}
              <div className="form-group row">
                <label  className="col-sm-10 col-form-label">Paises</label>
                <div className="col-sm-2">
                  <button name='countryVisible' onClick={setVisibles} className="btn">ok</button>
                </div>
              </div>
              {filterVisible.countryVisible 
                ? <CountryFilter setEndpointCountries={setEndpointCountries} />
                : ''
              }
              <hr />

              {/**Filtros por IPC */}
              <div className="form-group row">
                <label  className="col-sm-10 col-form-label">IPC</label>
                <div className="col-sm-2">
                  <button name='ipcVisible' onClick={setVisibles} className="btn">ok</button>
                </div>
              </div>
              {filterVisible.ipcVisible 
                ? <IpcFilter setEndpointIpc={setEndpointIpc} />
                : ''
              }

            </div>
            
        </>
     );
}
 
export default Filters;