import React from 'react'
import './tableOfResults.scss'
import Skeleton from "react-loading-skeleton";

function tableOfResultsTemplate() {
    return (
        <div className='tableOfResultsTemplateMain'>
            {
                [...Array(10).keys()].map(i => {
                    return (
                        <div className="row resultTableBody">
                            <div className="col-12 col-md-7 col-lg-7">
                                <div className="row">
                                    <div className="col-3 col-md-1 col-lg-1">
                                        <Skeleton style={{marginTop:'20px'}} circle={true} height={20} width={20} />
                                    </div>
                                    <div className="col-3 col-md-1 col-lg-1">
                                        <Skeleton style={{marginTop:'15px'}} height={40} width={80} />
                                    </div>
                                    <div className="col-3 col-md-10 col-lg-10">
                                        <Skeleton height={10} width={'80%'} />
                                        <Skeleton height={10} width={'75%'} />
                                        <Skeleton height={10} width={'71%'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 col-lg-2">
                                <Skeleton height={10} width={'100%'} />
                                <Skeleton height={10} width={'90%'} />
                                <Skeleton height={10} width={'95%'} />
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 pl-30">
                                <Skeleton height={10} width={'95%'} />
                                <Skeleton height={10} width={'97%'} />
                                <Skeleton height={10} width={'100%'} />
                            </div>
                        </div>
                    );

                })
            }
        </div>
    )
}

export default tableOfResultsTemplate







