import React from 'react'

//redux
import { useDispatch } from 'react-redux'
import { setOptionsAction } from '../../../actions/profileActions'

const Study = ({ option, optionType, selected }) => {

    //redux
    const dispatch = useDispatch();

    /**Funciones */
    //agrega o elimina sector
    const clickPreferencia = id => {
        selected.indexOf(id) === -1 ? dispatch(setOptionsAction({ 'optionType': optionType, 'action': 'add', 'id': id }))
            : dispatch(setOptionsAction({ 'optionType': optionType, 'action': 'rm', 'id': id }));
    }

    return (
        <div className="col-md-4 col-lg-4 col-xl-4">
            <div className='areaInte' style={{display:'inline-flex'}}>

                <div className="lblSectors" style={{fontSize:'15px', color:'black'}}>{option.name}</div>
                <input
                style={{marginTop:'5px', marginRight:'10px'}}
                    type="checkbox"
                    className="chks-studies"
                    defaultChecked={selected.indexOf(option.id) > -1 ? true : false}
                    onClick={() => clickPreferencia(option.id)}
                />
            </div>

        </div>
    );
}

export default Study;