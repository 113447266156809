import React from 'react'
import './colection.scss'
import imgFolder from '../../../../../images/icons/modules/homeApp/blue-folder.png'
import { useHistory } from 'react-router-dom';

const Colection = ({ colection }) => {

    const history = useHistory();

    const goToColection = (colect) => {
        history.push(`/myInformation/${colect.id}`);
    }

    return (
        <div onClick={() => goToColection(colection)} className='HomeInformation lantia-square'>
            <div className="infoInfo">
                <div className='title'>
                    <div className="row d-flex align-items-center titleBody">
                        <div style={{ padding: '0px' }} className="col-2">
                            <div>
                                <img width='30px' height='30px' src={imgFolder} alt="" />
                            </div>
                        </div>
                        <div style={{ padding: '0px', paddingTop: '0px' }} className="col-10 text-left">
                            <p>{colection.name}</p>
                        </div>
                    </div>
                </div>
                <hr />
                <h3>Creado:{colection.dateCreated.substring(0, 10)}</h3>
                <h3>Contenido:{colection.total} elementos</h3>
            </div>
        </div>
    );
}

export default Colection;