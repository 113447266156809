import React, { useEffect, useState } from "react";
import './lineChart.scss';
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const LineChart = ({ labels, datos, type }) => {
  console.log('labels', labels);
  console.log('datos', datos);
  const [data, setData] = useState('')

  useEffect(() => {

    const _data = {
      labels: labels,
      datasets: [
        {
          label: `Número de ${type}`,
          data: datos,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
        }
      ]
    };
    setData(_data)
    console.log('_data', _data);
  }, [datos]);



  return (
    <div className="lineChartMain">
      {data !== '' &&
        <Line data={data} width={"300"} />
      }
    </div>
  );
}
export default LineChart;