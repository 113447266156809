import React from 'react'
import { useSelector } from 'react-redux';
import './cvlac.scss'

const CvLacResult = () => {

    const perfil = useSelector(state => state.home.resultSelected);
    console.log('perfil', perfil);

    return (
        <div className='cvlacResultMain'>
            <p>{perfil.fullname}</p>
            {perfil.hasOwnProperty('category') &&
                <div>
                    <strong>Categoria:</strong> {perfil.categoria}
                </div>
            }
            <br />
            {perfil.hasOwnProperty('formacion_academica') &&
                <div>
                    <strong>Formación academica:</strong>
                    {perfil.formacion_academica.map(item => {
                        let fixItem = item.split(';;')
                        console.log('fixItem', fixItem);
                        return (
                            <div>{fixItem.map(element => {
                                let fixElement = element.replaceAll('/n', ', ')
                                return (
                                    <div>{fixElement}</div>
                                )
                            })}</div>
                        )
                    })}
                </div>
            }
            <br />
            <div>
                {perfil.hasOwnProperty('lineas_investigacion') &&
                    <div>
                        <div><strong>Lineas de investigación</strong></div>
                        {perfil.lineas_investigacion.map(item => {

                            return (
                                <div>
                                    {item.substring(item.length - 2, item.length) == 'Si' &&
                                        '• ' + item.charAt(0) + item.substring(0, item.length - 6).toLowerCase().slice(1)
                                    }
                                </div>
                            )
                        })}
                    </div>
                }
                <hr />
                {perfil.hasOwnProperty('articulos') &&
                    <div>
                        <div><strong>Articulos</strong></div>
                        {perfil.articulos.map(item => {
                            console.log('item proyects', item);
                            let fixItem = item.split(';;')

                            return (
                                <div>
                                    {fixItem.map(element => {
                                        return (
                                            <div>{element}</div>
                                        )
                                    })}
                                    <hr />
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
}

export default CvLacResult
