import React from 'react';

//redux
import { useSelector, useDispatch } from 'react-redux'
import { setSearchAction } from '../../../../actions/newAction'

const SearchBox = () => {

  //redux
  const dispatch = useDispatch();
  const {results,search} = useSelector(state => state.company);

  /**Funciones */
  //cambia state de search al usuario darle click o enter
  const onChange = e => {
    dispatch(setSearchAction(e.target.value,'keyboard'));
  } 

  //llama la accion para cambiar la variable del search en redux store
  const onClick = () => {
   dispatch(setSearchAction(search,'click'));
  }


  return ( 
    <div className="searchBox">
      <div className="inputArea">
      {
        <div className="returnPatentList">
          <span className="fas fa-arrow-left"></span>
        </div>
      }
        <input 
          type="text" 
          id="inputArea"
          className={ 'inputAreaReduce'}
          placeholder="Escribe aquí tu búsqueda"
          onChange={onChange}
        />
        <div className="resultsDisplay">{`${results} resultados para su búsqueda`}</div>
      </div>
      <div className="searchButton" id="searchButton" onClick={onClick}>Buscar</div>
    </div>
   );
}
 
export default SearchBox;