import React, { useState, useEffect } from 'react'
import './carouselBox.scss'
import ItemsCarousel from 'react-items-carousel';
import back from '../../../images/icons/arrowDirections/back.png';
import ahead from '../../../images/icons/arrowDirections/ahead.png';
import CarrouselThree from '../skeletonTemplates/carrouselThree'
import processData from '../../../hooks/useProccessData'
import { registerSelected } from '../../../actions/homeAction';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import convoImage from '../../../images/icons/ui/general/convocatorias.png'

const LastSearches = ({ itemsArray, title, numberOfCards }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();

    // const Imagencheck = (img) => {
    //     if (!img.complete) return false;
    //     if (typeof img.naturalWidth != "undefined" && img.naturalWidth == 0) return false;
    //     return true;
    // }

    // const RevisarImagenesRotas = () => {
    //     var replacementImg = "https://www.hongshen.cl/wp-content/uploads/2016/07/no-disponible.png";
    //     for (var i = 0; i < document.images.length; i++) {
    //         if (!Imagencheck(document.images[i])) {
    //             document.images[i].src = replacementImg;
    //         }
    //     }
    // }

    // useEffect(() => {
    //     setTimeout(() => {
    //         RevisarImagenesRotas()
    //     }, 1000);
    // });


    const resultSelet = (item) => {
        item.related = itemsArray;
        dispatch(registerSelected(item));
        history.push('/resultSelected')
        window.scrollTo(0, 250)
    }


    return (
        <>
            <div className='carousel-searches'>
                <h1 className='title'>{title}</h1>
                {processData(itemsArray).length > 0 ?
                    <div style={{ padding: `0 40px` }}>
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={numberOfCards}
                            gutter={20}
                            leftChevron={<i className="fas fa-angle-left fa-3x arrowIcon"></i>}
                            rightChevron={<i className="fas fa-angle-right fa-3x arrowIcon"></i>}
                            outsideChevron
                            chevronWidth={40}
                            infiniteLoop={true}
                        >
                            {itemsArray.map(item =>
                                <div onClick={() => resultSelet(item)} className='carousel-searches-item text-align-left' style={{ cursor: 'pointer' }}>
                                    <div className='carrouselImg'>
                                        {item.image ?
                                            <img style={{ width: item.imageWidth, height: item.imageHeight }} className='imageRelatedBox' src={convoImage} alt="" /> :
                                            item.thumbnail && <img style={{ width: item.imageWidth, height: item.imageHeight }} className='imageRelatedBox' src={item.thumbnail[0]} alt="" />
                                        }
                                    </div>
                                    <p style={{fontSize:'20px'}}>
                                            {/* { item.title_en ? item.title_en[0].substring(0, 60) : typeof item.title === 'string' ? item.title.substring(0, 60) :  item.title[0].substring(0, 60)}... */}
                                          <strong>
                                            {Array.isArray(item.title) ? item.title[0].substring(0, 40) : item.title.substring(0, 40)}...
                                          </strong>
                                        </p>
                                    <div style={{ marginTop: '1rem' }}>
                                        <p>
                                            {Array.isArray(item.description) ? item.description[0].substring(0, 30) : item.description.substring(0, 80)}...
                                        </p>
                                        {item.portal &&
                                            <div style={{display:'inline-flex'}}>
                                                {/* <h3>Fuente:{item.portal[0].substr(0,20)}</h3> */}
                                                {/* <h3 className='text-left pl-1'>{item.pubDate[0].toString('YYYY-MM-dd').substring(0, 10)}</h3> */}
                                                <div style={{color:'blue', transform:'translate(-128px, -7px)'}} className='text-left pl-1 mt-0'>ver mas</div>
                                            </div>
                                        }
                                        {item.thumbnail &&
                                            <h3 style={{ color: '#7B9CC6' }}>
                                                {/* Actualizado: */}
                                            </h3>}
                                    </div>
                                </div>
                            )}
                        </ItemsCarousel>
                    </div>
                    :
                    // <CarrouselThree />
                    <div className="noResults">No se encontarron resultados</div>
                    }
            </div>

        </>
    );
}

export default LastSearches;