import {
    GET_NEWS
} from '../types/newTypes';

//state
const initialState = {
    search:'',
    page:0,  //pagina actual para el envio del endpoint
    news:[],
    results:0, //total de videos encontradas
}

export default function(state = initialState, action){
    switch(action.type){

        case GET_NEWS:
            return{
                ...state,
                news:action.payload.items,
                page:action.payload.page
            }

        default:
            return state;
    }
}