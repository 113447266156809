
export const GET_COLECTION = 'GET_COLECTION';
export const ADD_COLECTION = 'ADD_COLECTION';
export const ADD_MARK = 'ADD_MARK';
export const ADD_CONTENT_MARK = 'ADD_CONTENT_MARK';
export const SET_COLECTION = 'SET_COLECTION';
export const SET_MARK = 'SET_MARK';
export const DELETE_COLECTION = 'DELETE_COLECTION';
export const DELETE_MARK = 'DELETE_MARK';
export const COLLECTION_WITH_LATEST_MARK = 'COLLECTION_WITH_LATEST_MARK';
