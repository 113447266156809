import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';

import {uiCloseModal} from '../../../../actions/uiAction';
import {eventAddNew, eventClearActiveEvent} from '../../../../actions/eventsAction';

const now = moment().minutes(0).seconds(0).add(1, 'hours');
const nowPlus1 = now.clone().add(1, 'hours');

const initEvent = {
    start_date: null,
    end_date: null,
    text: '',
    expert_id: null,
    user_id: null,
    creation_date: moment().format(),
    state: 'pendiente'
}


const EventForm = () => {

    const {activeEvent} = useSelector(state => state.calendar);
    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.userId);
    const expertSelected = useSelector(state => state.experts.expertSelected);
    const [dateSelected, setDateSelected] = useState(now.toDate());
    const [disponiblityHours, setDisponiblityHours] = useState();
    const [hoursSelected, setHoursSelected] = useState({});
    const [text, setText] = useState('');
    const [formValues, setFormValues] = useState(initEvent);
    const [error, setError] = useState('');

    useEffect(() => {
        if(Object.keys(expertSelected).length !== 0) {
            let hours = []
            for(let i = +expertSelected.disponibility.substr(0, 2);i <= +expertSelected.disponibility.substr(6, 2);i++) {
                hours = [...hours, i.toString().length === 1 ? `0${i.toString()}:00` : `${i.toString()}:00`]
            }
            setDisponiblityHours(hours);
        }
    }, [])

    useEffect(() => {

        if(activeEvent) {
            const editInitHour = moment(activeEvent.start).format().toString().substr(11, 5);
            const editFinalHour = moment(activeEvent.end).format().toString().substr(11, 5);
            const editDate = moment(activeEvent.start).format().toString().substr(0, 10);

            setHoursSelected({
                initialHour: editInitHour,
                finalHour: editFinalHour
            });
            setDateSelected(editDate);
            setText(activeEvent.notes);
            setFormValues({
                ...formValues,
                start_date: `${editDate} ${editInitHour}`,
                end_date: `${editDate}  ${editFinalHour}`,
                text: activeEvent.text
            });
        } else {
            setFormValues(initEvent);
            setHoursSelected({});
            setText('');
            setDateSelected(now.toDate());
        }

    }, [activeEvent, setFormValues])


    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
        setText(target.value);
    }


    const closeModal = () => {
        // TODO: cerrar el modal
        dispatch(uiCloseModal());
        dispatch(eventClearActiveEvent());
        setFormValues(initEvent);
    }

    const handleDate = (e) => {
        setDateSelected(e.target.value);
        setFormValues({
            ...formValues,
            start_date: `${e.target.value} ${hoursSelected.initialHour}:00`,
            end_date: `${e.target.value} ${hoursSelected.finalHour}:00`
        });
    }

    const handleStartHourChange = (e) => {
        setFormValues({
            ...formValues,
            start_date: `${dateSelected} ${e.target.value}:00`
        });
        setHoursSelected({
            ...hoursSelected,
            [e.target.name]: e.target.value
        });
    }

    const handleEndHourChange = (e) => {
        setFormValues({
            ...formValues,
            end_date: `${dateSelected} ${e.target.value}:00`
        });
        setHoursSelected({
            ...hoursSelected,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();

        const momentStart = moment(formValues.start_date);
        const momentEnd = moment(formValues.end_date);
        if(momentStart.isSameOrAfter(momentEnd)) {
            return Swal.fire('Error', 'La fecha fin debe de ser mayor a la fecha de inicio', 'error');
        }

        const duration = moment.duration(momentStart.diff(momentEnd));
        const hours = duration.asHours();

        if(hours * (-1) > 2) {
            return Swal.fire('Error', 'Solo se permiten dos horas de asesoria', 'error');
        }

        let internalError = '';

        let _dateSelected = new Date(dateSelected)
        _dateSelected.setDate(_dateSelected.getDate() + 1)

        if(_dateSelected < new Date()) {

            internalError = 'La fecha tiene que ser igual o superior al día de hoy, ';
        }
        if(formValues.start_date === null) {
            internalError = 'La hora de inicio es requerida, ';
        }
        if(formValues.end_date === null) {
            internalError = internalError + 'La hora de finalización es requerida, ';
        }
        if(formValues.text === '') {
            internalError = internalError + 'El comentario es requerido ';
        }
        if(internalError === '') {
            setError('');
            dispatch(eventAddNew({
                ...formValues,
                user_id: userId,
                expert_id: expertSelected.id,
                userIdOfExpert: expertSelected.user_id,
                id: activeEvent ? activeEvent.id : null
            }));
            setFormValues({
                ...formValues,
                start_date: null,
                end_date: null,
                text: '',
            });

            setDateSelected(now.toDate());
            setHoursSelected({});
            setText('');
            closeModal();
        } else {
            setError(internalError);
        }

    }


    return (
        <div>
             <h1> {(activeEvent) ? 'Editar evento' : 'Nuevo evento'} </h1>
            <hr />
            <form
                className="container"
                onSubmit={handleSubmitForm}
            >
                <div className="form-group">
                    <input type="date" name="dateSelected" onChange={handleDate} value={dateSelected}></input>
                </div>

                <div className="form-group">
                    <label>hora inicio</label>
                    <select name='initialHour' value={hoursSelected.initialHour} onChange={handleStartHourChange}>
                        <option>--</option>
                        {disponiblityHours && disponiblityHours.map((x, i) => <option key={i} value={x} >{x}</option>)}
                    </select>
                </div>
                <div className="form-group">
                    <label>hora finalización</label>
                    <select name='finalHour' value={hoursSelected.finalHour} onChange={handleEndHourChange}>
                        <option>--</option>
                        {disponiblityHours && disponiblityHours.map((x, i) => i > 1 && <option key={i} value={x} >{x}</option>)}
                    </select>
                </div>
                <hr />
                <div className="form-group">
                    <textarea
                        type="text"
                        className="form-control"
                        placeholder="Comentario"
                        rows="5"
                        name="text"
                        value={text}
                        onChange={handleInputChange}
                    ></textarea>
                    <small id="emailHelp" className="form-text text-muted">Información adicional</small>
                </div>
                {error !== '' && <div><p>{error}</p></div>}
                <button
                    type="submit"
                    className="btn btn-outline-primary btn-block"
                >
                    <i className="far fa-save"></i>
                    <span> Guardar</span>
                </button>

            </form>
        </div>
    )
}

export default EventForm
