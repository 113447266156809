import React, { useState } from 'react'
import ItemsCarousel from 'react-items-carousel';
import './cvlac.scss'

const CvLac = ({ cvlac }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [tab, setTab] = useState('Lineas de investigación')


    return (
        <div className="CvLacMain">

            {cvlac !== '' &&
                cvlac.items.length > 0 ?
                <ItemsCarousel
                    requestToChangeActive={setActiveItemIndex}
                    activeItemIndex={activeItemIndex}
                    numberOfCards={1}
                    gutter={20}
                    leftChevron={<i className="fas fa-angle-left fa-3x arrowIcon"></i>}
                    rightChevron={<i className="fas fa-angle-right fa-3x arrowIcon"></i>}
                    outsideChevron
                    chevronWidth={40}
                    infiniteLoop={true}
                >
                    {cvlac.items.map(item => {
                        let findPositionToTrimCategory = item.hasOwnProperty('category') && item.category[0].indexOf(')');
                        console.log('item cvlac', item);
                        return (<div className="itemBox">
                            <div style={{ borderBottom: '1px solid gray', display: 'inline-flex', width: '100%' }}>
                                {/* <img src={require('../../../images/ui/icons/avatar.png').default} width="50" height="50" /> */}
                                <div style={{ marginLeft: '20px' }}>
                                    <div style={{ marginTop: '10px' }} className="nameInvestigador"><strong>{item.fullname[0]}</strong></div>
                                    {item.hasOwnProperty('category') &&
                                        <div style={{ paddingBottom: '15px', paddingTop: '2px', display: 'inline-flex' }}>
                                            {/* <img style={{ margin: '-3px 10px 0px 0px' }} src={require('../../../images/ui/icons/maleta.png').default} width="28px" height="28px" /> */}
                                            {item.category[0].substr(0, findPositionToTrimCategory + 1)}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div style={{ display: 'inline-flex', borderBottom: '1px solid var(--grayOne)', paddingBottom: '10px' }}>


                                <div style={{ paddingTop: '10px' }}>
                                    {item.hasOwnProperty('formacion_academica') &&
                                        <div style={{ paddingLeft: '20px', paddingRight: '20px', height: '80px', overflowY: 'auto' }}>
                                            <strong style={{ float: 'left' }}>Formación académica:</strong>
                                            <div>
                                                {item.formacion_academica.slice(0, 2).map(exp => {
                                                    console.log('exp', exp);
                                                    exp = exp.split(';;')
                                                    let expContent = exp[1].split('/n')
                                                    return (<div style={{ paddingTop: '10px', textAlign: 'left' }}>
                                                        <div style={{ alignItems: 'left', width: '1%' }}>{exp[0]}</div>
                                                        {expContent.map((item) => {
                                                            return (
                                                                <div>
                                                                    {item}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>)
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className='tabs'>
                                <div className={tab === 'Lineas de investigación' ? 'tab2' : 'tab1'}>
                                    <div style={{ fontSize: '12px', color: `${tab === 'Lineas de investigación' ? '#fff' : 'gray'}`, width: '150px', marginLeft: '10px' }} onClick={() => setTab('Lineas de investigación')}>Lineas de investigación</div>
                                    <div style={{ fontSize: '12px', color: `${tab === 'Articulos publicados' ? '#fff' : 'gray'}`, width: '150px', marginLeft: '10px' }} onClick={() => setTab('Articulos publicados')}>Articulos publicados</div>
                                </div>
                            </div>

                            {tab == 'Lineas de investigación' &&
                                item.hasOwnProperty('lineas_investigacion') &&
                                <div className='tabSection'>
                                    {item.lineas_investigacion.map(item => {
                                        let fixItem = item.split(';;')
                                        console.log('fixItem', fixItem);
                                        return (
                                            <div style={{ padding: '10px' }}>
                                                <div style={{ textAlign: 'left' }}>{fixItem[1] === " Si" ? '• ' +fixItem[0] : ''}</div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            }
                            {tab == 'Articulos publicados' &&
                                item.hasOwnProperty('articulos') &&
                                <div className='tabSection' style={{ display: 'inline-flex' }}>
                                    <div className='articlesPublic' style={{textAlign:'left', padding:'15px'}}>
                                        {item.articulos.map(area => {
                                            console.log('area', area);
                                            let array = area.split(',');
                                            let arrayDos = array[array.length - 3].split(';;')
                                            console.log('arrayDos', arrayDos);
                                            return <div>{'• ' + arrayDos[0]}</div>
                                        })}</div>
                                </div>
                            }
                            {/* {item.hasOwnProperty('proyectos') &&
                                <div className="producciones">
                                    <h5>Proyectos</h5>
                                    <br />
                                    <br />
                                    {item.proyectos.map(project => {
                                        let proyecto = project.split(';;')
                                        return (<div>
                                            <div>
                                                {proyecto[4]}
                                            </div>
                                            <div>
                                                {proyecto[0]}
                                            </div>
                                            <div>
                                                {proyecto[1]}
                                            </div>
                                            <div>
                                                {proyecto[2]}
                                            </div>
                                            <hr />
                                        </div>)
                                    })}

                                </div>
                            }
                            {item.hasOwnProperty('consultorias') &&
                                <div className="producciones">
                                    <h5>Consultorias</h5>
                                    <br />
                                    <br />

                                    {item.consultorias.map(consult => {
                                        let consultoria = consult.split(';;')
                                        return (<div>
                                            <div>
                                                {consultoria[0]}
                                            </div>
                                            <div>
                                                {consultoria[1]}
                                            </div>
                                            <div>
                                                {consultoria[2]}
                                            </div>
                                            <div>
                                                {consultoria[4]}
                                            </div>
                                            <hr />
                                        </div>)
                                    })}

                                </div>
                            } */}
                        </div>)
                    })}

                </ItemsCarousel> :
                <div>No se encontraron perfiles relacionados</div>
            }
        </div>
    )
}

export default CvLac
