import React, {useState} from 'react'
import TableOfResults from './tableOfResults/TableOfResults';

//images
import GraphicalAnalysis from './graphicalAnalysis/index.jsx'
import FiltersTab from './filtersTab/FiltersTab.jsx';

/**Filtros de Busqueda */
import EventsFilter from './filter/EventsFilter'
import StartupsFilter from './filter/StartupsFilter'
import NewsFilter from './filter/NewsFilter'
import VideosFilter from './filter/VideosFilter'
import PatentsFilter from './filter/PatentsFilter'
import ArticlesFilter from './filter/ArticlesFilter'

//hook para estandarizar campos
import processData from '../../../../hooks/useProccessData';

import './resultBox.scss';

const ResultBox = () => {

    const [changeTab, setChangeTab] = useState(false);

    return (
        <div className="resultBoxMain">
            <FiltersTab changeTab={changeTab} setChangeTab={setChangeTab} />
            <div>
                {
                    //(sources !== 'all' && !changeTab) &&
                    <div className='row justify-content-center'>
                        {/* <div style={{width: '81%'}} className='resultFilters mt-3'>
                            <div className='lantia-square params'>
                                <p><strong>Parametrización</strong></p>
                                <hr />
                                {comp}
                            </div>
                        </div> */}
                    </div>
                }
            </div>
            {
                changeTab ?
                    <GraphicalAnalysis />
                    :
                    <TableOfResults
                    />
            }

        </div>

    )
}
export default ResultBox;
