import React from 'react';
import {eventDeleted} from '../../../../actions/eventsAction';
import {useSelector, useDispatch} from 'react-redux';

export const DeleteEventFab = () => {
    const {activeEvent} = useSelector(state => state.calendar);
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(eventDeleted(activeEvent.id));
    }

    return (
        <div onClick={handleDelete}>
            <i className="fas fa-trash"></i>
        </div>
    )
}
