import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { registerSelected } from '../../../../actions/homeAction';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import folderIcon from '../../../../images/icons/colorModulesIcons/folder.jpg'
import SaveFavouritesDropdown from '../resultBox/tableOfResults/saveFavouritesDropdown/SaveFavouritesDropdown'
import './OneBigElement.scss'

const OneBigElement = ({ newOne }) => {
    const [viewmore, setViewmore] = useState(false)
    const history = useHistory();
    const dispatch = useDispatch();

    const goToResult = () => {
        dispatch(registerSelected(newOne));
        history.push('/resultSelected')
    }

    return (
        <div>
            {newOne !== undefined &&
                <div className="row text-left my-1  align-items-center separator OneBigElementMain">
                    <div className="col-2 col-md-2 col-lg-2 align-items-center iconsHomeAppFolder">
                        <div>
                            {/* <img style={{ marginRight: '12px' }} src={folderIcon} alt="" width='15' height='15' /> */}
                            <SaveFavouritesDropdown item={newOne} />
                        </div>
                        <div>
                            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                <h3 onClick={() => setViewmore(!viewmore)} className='homeSmallLink' style={{ marginTop: '20px', cursor: 'pointer' }}>Ver más</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-9 col-md-10 col-lg-10 item">
                        <div className="row">
                            <div style={{ cursor: 'pointer' }} className="col-12 col-md-7 col-lg-7" onClick={goToResult}>
                                <p style={{ marginTop: '1rem' }} className='homeBigLink'>{Array.isArray(newOne.title) ? newOne.title[0].substr(0, 30) + '...' : newOne.title.substr(0, 30)}...</p>
                            </div>
                            <div className="col-1 col-md-2 col-lg-2 pb-2"><p style={{ marginTop: '1rem' }} >{newOne.datePublication.substring(0, 4)}</p></div>
                            <div className="col-1 col-md-3 col-lg-3 pb-2"><p style={{ marginTop: '1rem' }} >{Array.isArray(newOne.source) ? newOne.source[0].substr(0, 10) + '...' : newOne.source.substr(0, 10) + '...'}</p></div>
                        </div>

                    </div>
                    {viewmore &&
                        <div className='viewMore'>
                            <hr />
                            {newOne.description.length > 200 ? newOne.description.substr(0, 200) + '...' : newOne.description}
                            <div style={{cursor:'pointer', color: 'var(--primaryBlueThree)', fontSize:'13px'}} onClick={goToResult}>ver todo</div>
                        </div>
                    }
                </div>

            }
        </div>
    );
}

export default OneBigElement;