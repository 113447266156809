import React, { useState, useEffect } from 'react'
import './contenido.scss'
import translateText from '../../../../../../../hooks/useTranslate'

const Contenido = ({ description, translate } ) => {
    const [descriptionText, setDescrption] = useState(description[0].split('\n'));

    useEffect(() => {
        if (!translate) {
            setDescrption(description[0].split('\n'))
            return;
        }

        translateText(descriptionText).then(response => {
            setDescrption(response)
        });

    }, [translate, description])

    return (
        <div className="contenidoMain">
            <br />
            <br />
            {/* <p style={{ textAlign: 'justify', color: 'black' }}>{description !== undefined && description}</p> */}
            {(descriptionText !== undefined && descriptionText !== '') &&
                descriptionText.map(paragraph => {
                    return (
                        <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                            {paragraph}
                        </p>
                    );
                })
            }
        </div>
    )
}

export default Contenido
