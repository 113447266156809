import React from 'react'

const OneFilter = (props) => {

    return ( 
        <div className='row'>
            <div className="col-4 section-h">
                <select 
                    name="filter" 
                    onChange={(e) => props.changeFinalFilter(e,props.index)}
                    style={{width:'200px'}}
                    className='form-control'
                    value={props.oneField.filter}
                >                    
                    {props.endFilters.length > 0 && props.endFilters.map((name,index) => (
                        <option key={index} >{name}</option>
                    ))}
                </select>
            </div>
            <div className="col-5 ml-3">
                <input type="text" value={props.oneField.name} name='name' onChange={(e) => props.changeFinalFilter(e,props.index)} className='form-control'/>
            </div>
            <div className="col-1">
                <button style={{backgroundColor:'var(--primaryBlueFive)', color:'#fff', width:'36px'}} className='btn' onClick={() => props.deleteRow(props.index)}>-</button>
            </div>
            <div className="col-1">
                <button style={{backgroundColor:'var(--primaryBlueFive)', color:'#fff'}} className='btn' onClick={props.addRow}>+</button>
            </div>
        </div> 
     );
}
 
export default OneFilter;