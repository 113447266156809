import React, {useState, useEffect} from 'react'
import Plot from 'react-plotly.js';
import './plotly.scss'

const HorizontalBar = ({xAxis,yAxis,orientation,title,color,barClick,name}) => {

    const [data, setdata] = useState({})

  useEffect(() => {
    setdata({x:xAxis, y:yAxis});
  }, [xAxis,yAxis])
  const [moduleColor] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primaryBlueThree'));

    return ( 
      <div className={name === 'Cantidad por propietario' && 'c_propietario'}>
        <Plot
            data={[
                {
                    x: data.x,
                    y: data.y,
                    orientation:orientation,
                    type: 'bar',
                    marker: {color: moduleColor},
                }
            ]}
            onClick={(figure) => barClick(figure)}
            layout={ {width: '100%',title: title} }
        />

      </div>
     );
}
 
export default HorizontalBar;