import React, { useState, useEffect } from 'react'
import ClienteAxios from '../../config/axios'
import axios from 'axios'
import DatePicker from "react-datepicker"

//redux
import {useSelector} from 'react-redux'

const Omnicanalidad = () => {

    const apiKey = useSelector(state => state.auth.apiKey);


    //states
    const [dataToSend,setDataToSend] = useState({
        publicType:'link',
        users_list:[],
        time:'27/10/2020 16:41:30',
        channel:'whatsapp',
        email_subject:'Contenido Lantia',
        message_to_send:'Hola Diego, hay nuevo contenido disponible en la convocatoria, ingresa al siguiente link',
        check_attachment:'on',
        link:'https://http://vtsas.co/encuesta/admin',
        file:null
    });
    
    const [dateSelected,setDateSelected] = useState(new Date());

    const [userList,setUserList] = useState(
        [{first_name:'Diego',last_name:'Salazar',cellphone:'21231312',email:'ariza397@hotmail.com',platform:'Ed_4.0'}]
    );

   
    const [email,setEmail] = useState(true);
    const [whatsapp,setWhatsapp] = useState(true);
    const [sms,setSms] = useState(true);
    const [voice,setVoice] = useState(true);

    const [timeAvailable,setTimeAvailable] = useState({});

    useEffect(() => {
        ClienteAxios.get('profile/schedule').then(function (response){
            setTimeAvailable(response.data.schedule);
        })
    }, [])

    //functions
    const fileChange = (e) => {
        if([e.target.name][0] === 'file'){
            setDataToSend({
                 ...dataToSend,
                 file:e.target.files[0]
             })
         }
    }

    const onChange = (e) => {
       var temporaluser = userList;
       temporaluser[0][e.target.name] = e.target.value
        /*if([e.target.name] === 'date' || [e.target.name] === 'time'){
         }*/
         setUserList(temporaluser);
    } 

    const changeDate= (date) => {
        var newTime = date.toLocaleDateString('en-GB') + ' ' + date.getHours()+':'+date.getMinutes()+':'+date.getSeconds();
        setDataToSend({...dataToSend,time:newTime})
        setDateSelected(date);
    }

    const onChangeMain = (e) => {
        setDataToSend({
            ...dataToSend,
            [e.target.name] : e.target.value
        })
    }
    
    //envio de datos a la API de omnicanalidad, primero mira si la fecha esta disponible, si no envia la fecha disponible mas proxima
    const sendForm = async() => {        
        var data = dataToSend;
            //data.users_list = userList;
            data.users_list = userList;

        var startAvailable = new Date(timeAvailable.startTime)
        var finishAvailable = new Date(timeAvailable.finishTime)
        //el dia esta disponible
        if(timeAvailable.days.indexOf(dateSelected.getDay()) > -1 && finishAvailable.getHours() >= dateSelected.getHours()){            
            if(startAvailable.getHours() > dateSelected.getHours()){
                var newTime = dateSelected.toLocaleDateString('en-GB') + ' ' + startAvailable.getHours()+':00:00';
                data.time = newTime;
            }
        }
        //el dia no esta disponible
        else{
            var daySelected = '';
            let daysCounter = 0;
            var counter = 1;
            var newDate = new Date();
            //para este while tiene que haber al menos un dia disponible, si no, entra a un loop infinito
            while(daySelected === ''){
                daysCounter = 1000 * 60 * 60 * 24;
                daysCounter = daysCounter * counter;               
                newDate = new Date(dateSelected.getTime()+daysCounter);
                if(timeAvailable.days.indexOf(newDate.getDay()) > -1){
                    daySelected = newDate.toLocaleDateString('en-GB') + ' ' + startAvailable.getHours()+':00:00';;
                    data.time = daySelected;
                }
                counter++;
            }
        }

        //var rta = await axios.post('http://lantiasas.com:5011/communications_module/',formData);
       // console.log(startDate.toLocaleDateString('en-GB') + ' ' + startDate.getHours()+':'+startDate.getMinutes()+':'+startDate.getSeconds());
            
       if(email){                        
            data.channel = 'email'; 
            const formDataEmail = new FormData();
            formDataEmail.append('file',dataToSend.file);     
            formDataEmail.append('json',JSON.stringify(data));
            axios.post(`https://lantiasas.com:5011/communications_module?key=${apiKey}`,formDataEmail);
        }
        if(whatsapp){            
            data.channel = 'whatsapp';        
            const formDataWp = new FormData();
            formDataWp.append('file',dataToSend.file);      
            formDataWp.append('json',JSON.stringify(data));
            axios.post('https://lantiasas.com:5011/communications_module?key=${apiKey}',formDataWp);
        }
        if(sms){            
            data.channel = 'sms';        
            const formDataSms = new FormData();
            formDataSms.append('file',dataToSend.file);      
            formDataSms.append('json',JSON.stringify(data));
            axios.post('https://lantiasas.com:5011/communications_module?key=${apiKey}',formDataSms);
        }
        if(voice){ 
            data.channel = 'call';  
            data.mode = 'audio';
            data.filename = 'prueba2.wav';
            data.check_attachment = 'on';
            const formDataVoice = new FormData();            
            formDataVoice.append('file',dataToSend.file);      
            formDataVoice.append('json',JSON.stringify(data));      
            //formDataVoice.append('filename','prueba.wav');      
            formDataVoice.append('users_list',data.users_list);      
            formDataVoice.append('mode','audio');
            axios.post('http://lantiasas.com:5011/call?key=${apiKey}',formDataVoice);
        }
    }

    

    return ( 
        <>
       
            <div className='container'>
                <div className='prueba'>

                </div>
                <div className='wp mb-5'>
                    <h1>Envio por email y Whatsapp</h1>
                    <div className='row my-2 mb-1'>
                        <div className="col-sm-1">
                            <label htmlFor="">Nombree</label>
                        </div>
                        <div className="col-sm-6">
                            <input placeholder='Tu Nombre' onChange={onChange} type="text" name="first_name" id=""/>
                        </div>
                    </div>
                    <div className='row my-2 mb-1'>
                        <div className="col-sm-1">
                            <label htmlFor="">Celular</label>
                        </div>
                        <div className="col-sm-6">
                            <input placeholder='Tu Celular' onChange={onChange} type="number" name="cellphone" id=""/>
                        </div>
                    </div>
                    <div className='row my-2 mb-1'>
                        <div className="col-sm-1">
                            <label htmlFor="">Email</label>
                        </div>
                        <div className="col-sm-6">
                            <input placeholder='Tu Email' onChange={onChange} type="text" name="email" id=""/>
                        </div>
                    </div>
                    <div className='row my-2 mb-1'>
                        <div className="col-sm-1">
                            <label htmlFor="">Link</label>
                        </div>
                        <div className="col-sm-6">
                            <input placeholder='Un Link' onChange={onChangeMain} type="text" name="link" id=""/>
                        </div>
                    </div>
                    <div className='row my-2 mb-1'>
                        <div className="col-sm-1">
                            <label htmlFor="">Mensaje</label>
                        </div>
                        <div className="col-sm-6">
                            <input placeholder='Mensaje' onChange={onChangeMain} type="text" name="message_to_send" id=""/>
                        </div>
                    </div>
                    
                    <div className='row mb-1 mt-1'>
                        <div className="col-sm-6">
                            <label htmlFor="">Fecha</label>
                            <DatePicker
                                selected={dateSelected}
                                onChange={date => changeDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                            />
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <input name='file'  type="file" className="form-control" onChange={fileChange}/>
                    </div>
                    <div className="opt row mt-2">
                        <div className='col-2 row'>
                            <label htmlFor="">Email</label>
                            <input className='mt-1' type="checkbox" name="email" checked={email} onChange={() => setEmail(!email)} />
                        </div>
                        <div className='col-2 row'>
                            <label htmlFor="">Whatsapp</label>
                            <input className='mt-1' type="checkbox" name="whatsapp" checked={whatsapp} onChange={() => setWhatsapp(!whatsapp)} />
                        </div>
                        <div className='col-2 row'>
                            <label htmlFor="">SMS</label>
                            <input className='mt-1' type="checkbox" name="whatsapp" checked={sms} onChange={() => setSms(!sms)} />
                        </div>
                        <div className='col-2 row'>
                            <label htmlFor="">Voz</label>
                            <input className='mt-1' type="checkbox" name="whatsapp" checked={voice} onChange={() => setVoice(!voice)} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <button className='mt-5 btn btn-outline-success btn-block' onClick={sendForm}>Enviar</button>
                        </div>
                    </div>
                </div>                
            </div>
        </>
     );
}
 
export default Omnicanalidad;