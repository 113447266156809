import axios from 'axios';

const useTranslate = async (text) => {

    let bodyFormData = new FormData();
    bodyFormData.append('langpair', 'eng|spa');
    bodyFormData.append('markUnknown', 'no');
    bodyFormData.append('prefs', '');
    bodyFormData.append('q', text);

    let response= await axios({
        method: "post",
        url: "https://apertium.org/apy/translate",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data.responseData.translatedText.split('\n\n')

}
export default useTranslate;