import React from 'react';

//redux
import { useSelector, useDispatch } from 'react-redux'
import { deleteEquationAction } from '../../../../actions/patentAction'

const Equation = () => {

    //redux
    const dispatch = useDispatch();
    const equation = useSelector(state => state.patent.equation)

    /**Funciones */
    //llama action para eliminar ecuacion
    const deleteEquation = () => {
        dispatch(deleteEquationAction());
    }

    return ( 
        <div className='equation'>
            {equation.trim() !== '' ?
                <p>{equation.replaceAll('+','')} <strong style={{color:'red'}} onClick={deleteEquation}>x</strong></p>
                :
                ''
            }
        </div>
     );
}
 
export default Equation;