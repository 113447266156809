import React from 'react'
import ColorTitle from '../../../ui/styles/ColorTitle'
import Slider from "react-slick";
import './video.scss'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green" }}
            onClick={onClick}
        />
    );
}

const Videos = ({ videos }) => {



    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        vertical: false,

    };
    return (
        <div className='sectionHome homeVideo'>
            <div className="lantia-square smallHeight" style={{ height: '300px' }}>
                {videos !== null &&
                    <Slider {...settings}>
                        {videos.map((video) => (
                            <div style={{ height: '100%' }}>
                                <div className=''>
                                    <iframe
                                        className="iframeModal mt-3"
                                        src={`https://www.youtube.com/embed/${video._id}?rel=0&autoplay=0`}
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                    <p style={{ fontSize: '15px', marginTop:'10px' }}> <strong>{video.title}</strong></p>
                                    <div style={{ width: '80%', marginLeft: '10%' }}>
                                        {/* <h3 className='paragraph'>{video.publishedAt[0]}</h3> */}
                                        {/* <p className='title'>{video.channel_title}</p>
                                        {console.log('video.description', typeof video.description)} */}
                                        {/* <div style={{ textAlign: 'justify' }}>
                                            {video.hasOwnProperty('description') &&
                                                <p>
                                                    {typeof video.description !== 'string' &&
                                                        video.description[0].substr(0,300)+'...'
                                                    }
                                                </p>
                                            }
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                }
            </div>
        </div>
    );
}

export default Videos;