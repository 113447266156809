import {
    GET_VIDEOS
} from '../types/videoTypes';

//state
const initialState = {
    search:'',
    page:0,  //pagina actual para el envio del endpoint
    videos:[],
    results:0, //total de videos encontradas
}

export default function(state = initialState, action){
    switch(action.type){

        case GET_VIDEOS:
            return{
                ...state,
                videos:action.payload.items,
                page:action.payload.page
            }

        default:
            return state;
    }
}