import React, { useState, useEffect, useRef } from 'react';

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setTypeFilterAction } from '../../../../actions/patentAction'

const PatentFilter = ({setEndpointTypes}) => {
    //ref para prevenir que se ejecuten funciones la primera vez
    const didMountRef = useRef(false);

    //redux
    const dispatch = useDispatch();
    const types = useSelector(state => state.patent.typesFilter);

    //**States */
    const [internaltypes,setInternalTypes] = useState({
        utility:false,
        design:false,
        plant:false,
        reissue:false
    });//opciones escogidas

    //cuando cambia una tipo de patente recorre el objecto y actualiza el endpoint final con las opciones en true
    useEffect(() => {
        //iguala el state interno al de redux
        setInternalTypes(types);
        if (!didMountRef.current){
            //primera vez no llama endpoint
        }else{
            let end = '';
            for (const property in types) {
                if(end === '' && types[property]){
                    end = '&type='+property;
                }else if(end !== '' && types[property]){
                    end = end+'+OR+'+property
                }
            }
            //hace nuevo llamado al endpoint cuando cambia un tipo de patente
            setEndpointTypes(end);// eslint-disable-next-line react-hooks/exhaustive-deps
        }      
   }, [types]);

   //cambia los tipos de patente en el redux store cuando el usuario selecciona un filtro de patente
   useEffect(() => {
        if (didMountRef.current){
            dispatch(setTypeFilterAction(internaltypes));
        }else{
            didMountRef.current = true;
        }
        
    }, [internaltypes])

   ///**Funciones */
   //agrega o elimina tipo de patente al state interno
   const typeChange = async (e) =>{     
        await setInternalTypes({
            ...internaltypes,
            [e.target.name] : !types[e.target.name]
        })
    }
    

    return ( 
            <div className="ml-3" >
                <div className="">
                    <div className="row">
                        <input type="checkbox" name="utility" checked={types.utility} value={types.utility} onChange={typeChange} />
                        <div className="text ml-1">Utilidad</div>
                    </div>
                    <div className="row">
                        <input type="checkbox" name="design" checked={types.design} value={types.design} onChange={typeChange} />
                        <div className="text ml-1">Diseño</div>
                    </div>
                    <div className="row">
                        <input type="checkbox" name="plant" checked={types.plant} value={types.plant} onChange={typeChange} />
                        <div className="text ml-1">Planta</div>
                    </div>
                    <div className="row">
                        <input type="checkbox" name="reissue" checked={internaltypes.reissue} value={internaltypes.reissue} onChange={typeChange} />
                        <div className="text ml-1">Emisión</div>
                    </div>
                </div>
            </div>
     );
}
 
export default PatentFilter;