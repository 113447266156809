import React, { useState } from "react";
import Preference from "./Preference";
import recurso15 from "../../../images/icons/form register/Recurso 15.png";

//redux
import { useSelector } from "react-redux";

const PageOne = ({ optioninterests, optionsectors, changePage }) => {
    //redux dispatch and redux variables
    const userinterests = useSelector((state) => state.profile.userinterests);
    const usersectors = useSelector((state) => state.profile.usersectors);

    //state
    const [error, setError] = useState({
        interestError: false,
        sectorError: false,
    });

    //funciones

    //valida que se halla seleccionado al menos uno de cada uno
    const clickValidation = () => {
        if (userinterests.length > 0) {
            setError({ ...error, sectorError: false });
            changePage(1);
            // if(usersectors.length > 0){
            //     //si el array de intereses y sectores es mayor a uno, podra continuar a la siguiente pagina

            // }else{
            //     setError({...error,interestError:false,sectorError:true});
            // }
        }
        // else if(usersectors.length === 0){
        //     setError({...error,interestError:true,sectorError:true});
        // }
        else {
            setError({ ...error, interestError: true });
        }
    };

    return (
        <>
            <div className="title1">
                <h4 className="white-title">2. INFORMACIÓN EMPRESARIAL</h4>{" "}
            </div>
            <div className="slide-page-profiler">
                {/** Primera Pagina de seleccion con intereses y sectores */}
                <div className="row">
                    {/* <div className="section-introduction col-md-12 col-lg-12 col-xl-12">
                    <h1>¡Bienvenido a plataforma Lantia!</h1>
                    <p>
                        Antes de usar toda la potencia de nuestros motores de búsqueda, queremos que nos cuentes un poco más acerca de ti y 
                        tu perfíl profesional, para eso, te hacemos unas cuantas preguntas, no te toma más de dos minutos.
                    </p>
                </div> */}
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="row no-gutters">
                            <div
                                className="col-md-12 col-lg-12 col-xl-12"
                                id="section-interest"
                            >
                                <div className="section-box-header">
                                    <div className="section-header-ico">
                                        <img
                                            src={recurso15}
                                            className="star"
                                            alt="intereses"
                                        ></img>
                                    </div>
                                    <div className="section-header-title">
                                        {" "}
                                        <h4 className="blue-title">
                                            Intereses
                                        </h4>
                                    </div>
                                </div>
                                <div className="section-box-body row">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        <p className="paragraph">
                                            ¿Cuál es su intención para hacer uso
                                            de la plataforma?
                                        </p>
                                        <div className="section-checkboxes row">
                                            {optioninterests.map((interest) => (
                                                <Preference
                                                    option={interest}
                                                    key={interest.id}
                                                    optionType="interest"
                                                    selected={userinterests}
                                                />
                                            ))}
                                        </div>
                                        <p className="blue-paragraph">
                                            Elige una o varias opciones{" "}
                                        </p>
                                        {error.interestError ? (
                                            <div
                                                className="alert alert-danger lnt-alert"
                                                role="alert"
                                            >
                                                No has escogido ningún interes.
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/**Seccion de sectores */}
                            {/* <div className="section-profile col-md-12 col-lg-12 col-xl-12" id="section-sectors">
                            <div className="section-box-header">
                                <div className="section-header-ico">
                                    <span className="fas fa-industry"></span>
                                </div>
                                <div className="section-header-title">
                                    <h1>Sectores de la economía</h1>
                                </div>
                            </div>
                            <div className="section-box-body row">
                            <div className="col-md-12 col-lg-12 col-xl-12">
                                <p>
                                    Ahora que sectores de la economía son de tu interés.
                                </p>
                                <div className="section-checkboxes row">
                                    {optionsectors.map(sector => (
                                        <Preference
                                            option = {sector}
                                            key = {sector.id}
                                            optionType = 'sector'
                                            selected = {usersectors}
                                        />
                                    ))}
                                </div>
                                {error.sectorError && 
                                    <div className="w-100 alert alert-danger lnt-alert" role='alert'>
                                        No has escogido ningún sector.
                                    </div>
                                }
                            </div>
                            </div>
                        </div> */}
                            {/**Termina seccion de sectores */}
                            <div className="col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-success"
                                    onClick={clickValidation}
                                >
                                    Siguiente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/** Termina row principal de la primera pagina de seleccion */}
            </div>
        </>
    );
};

export default PageOne;
