import React, {useEffect, useRef} from 'react';
import './App.css';
import './app.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './components/auth/routes/ProtectedRoute';
import AuthenticatedRoute from './components/auth/routes/AuthenticatedRoute';
import Profile from './components/profile';
import Login from './components/auth/login/Login';
import SignUp from './components/auth/signUp/SignUp';
import MassiveSignUp from './components/auth/signUp/MassiveRegister';

import HomeApp from './components/modules/homeApp';
//import HomeApp from './components/modules/homeApp';
import PatentsApp from './components/modules/patentsApp';
import PatentPage from './components/modules/patentsApp/PatentPage/PatentPage';
import CompanyApp from './components/modules/companiesApp';
import CompanyPage from './components/modules/companiesApp/companyPage/CompanyPage';
import VideoApp from './components/modules/videosApp';
import VideoPage from './components/modules/videosApp/videoPage/VideoPage';
import NewApp from './components/modules/newsApp';
import NewPage from './components/modules/newsApp/newPage/NewPage';
import ArticleApp from './components/modules/articlesApp';
import ArticlePage from './components/modules/articlesApp/articlePage/ArticlePage';
import Omnichannel from './components/modules/omnicanalidad';
import MyInformation from './components/modules/myInformation/MyInformation';
import Result from './components/modules/homeApp/resultBox/result/Result'
import MercadoPago from './components/modules/mercadopago/MercadoPago';
import PayU from './components/auth/payu/PayU';

import ExpertApp from './components/modules/expertApp'
import CalendarScreen from './components/modules/expertApp/calendar/CalendarScreen'
import ExpertForm from './components/modules/expertApp/expertForm/ExpertForm.jsx'
import Streaming from './components/modules/expertApp/calendar/Streaming';
import ResultBox from './components/modules/homeApp/resultBox/ResultBox.jsx';
import JtsiStreaming from './components/modules/expertApp/calendar/streaming/JtsiStreaming'

import GraphicTest from './components/graphics/ExGraphics'

//import Navbar from './components/navbar/Navbar.jsx'
import Navbar from './components/ui/header/NewHeader'
import Support from './components/profile/profileMenu/support/Support'
import MyProfile from './components/profile/profileMenu/myProfile/MyProfile'

import Sidebar from './components/ui/sidebar/Sidebar.jsx'
import Repository from './components/ui/repository'
import GraphicList from './components/graphics/graphicList/GraphicList.js';

//redux
import { Provider } from 'react-redux';
import store from './store';

function App() {

  // const test = useRef(null); 
 
  // var PERMITTED_DOMAIN = "http://localhost:3001";
 
  // useEffect(() => {
  //   window.addEventListener('message', function (event) {

  //     if (event.origin === PERMITTED_DOMAIN) {
  //       if (event.data) {
  //         var win = test.current.contentWindow;
  //         win.postMessage(getCookie('token'), "http://localhost:3001");

  //       } else {
  //         //remove cookie
  //       }
  //     }

  //   });
  // });


  // function getCookie(name) {
  //   const value = `; ${document.cookie}`;
  //   const parts = value.split(`; ${name}=`);
  //   if (parts.length === 2) return parts.pop().split(';').shift();
  // }

  return (
      <Router>
         {/* <iframe style={{ display: 'none' }} src="http://localhost:3001" ref={test}></iframe> */}
        <Provider store={store}>     
        <Navbar/> 
        {/* <Sidebar /> */}
        <Repository />
          <Switch>
            <AuthenticatedRoute exact path="/" component={Login} />
            <AuthenticatedRoute exact path="/login" component={Login} />
            <AuthenticatedRoute exact path="/signup" component={SignUp} />
            <ProtectedRoute exact path="/massiveSignup" component={MassiveSignUp} />
            <ProtectedRoute  exact path="/profile" component={Profile} />
            <ProtectedRoute  exact path="/my-profile" component={MyProfile} />
            <ProtectedRoute exact path="/homeApp" component={HomeApp} />
            <ProtectedRoute exact path="/patentApp" component={PatentsApp} />
            <ProtectedRoute path="/patentpage/:id" component={PatentPage} />
            <ProtectedRoute exact path="/companyApp" component={CompanyApp} />
            <ProtectedRoute path="/companypage/:id" component={CompanyPage} />
            <ProtectedRoute exact path="/videoApp" component={VideoApp} />
            <ProtectedRoute path="/videopage/:id" component={VideoPage} />
            <ProtectedRoute exact path="/newApp" component={NewApp} />
            <ProtectedRoute path="/newpage/:id" component={NewPage} />
            <ProtectedRoute exact path="/articleApp" component={ArticleApp} />
            <ProtectedRoute path="/articlepage/:id" component={ArticlePage} />
            <ProtectedRoute exact path="/support" component={Support} />
            <ProtectedRoute exact path="/omnichannel" component={Omnichannel} />
            <ProtectedRoute exact path="/myInformation/:id?" component={MyInformation} />

            {/*expert module*/}
            <ProtectedRoute exact path="/expertApp" component={ExpertApp} />
            <ProtectedRoute exact path="/expertApp/calendar" component={CalendarScreen} />
            <ProtectedRoute exact path="/expertApp/form" component={ExpertForm} />
            {/*<ProtectedRoute exact path="/expertApp/streaming" component={Streaming} />*/}
            <ProtectedRoute exact path="/expertApp/streaming" component={JtsiStreaming} />

            {/*homeApp*/}
            <ProtectedRoute exact path="/resultBox" component={ResultBox} />
            <ProtectedRoute exact path="/graph/graphicTest" component={GraphicList} />
            <ProtectedRoute exact path="/resultSelected/:id?" component={Result} />
            
            <Route exact path="/mercadoPago" component={MercadoPago} />
            <Route exact path="/payu" component={PayU} />

          </Switch>
        </Provider>
      </Router>
  );
}

export default App;
