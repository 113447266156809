import React, { useState } from 'react'
import One from '../ui/OneBigElement'
import clienteAxios from '../../../config/axios'
import processData from '../../../../hooks/useProccessData'
import './articles.scss'


const Articles = ({ articles, keyWords }) => {

    const [allArticles, setAllArticles] = useState(articles)
    const [articlesPagination, setArticlesPagination] = useState(articles.slice(0, 7))
    const [currentPage, setCurrentPage] = useState(0)

    const order = async (filter) => {

        console.log('keyword Articles', keyWords.split('OR'));

        let params = '';
        keyWords.split('OR').map((item, index) => {
            console.log(index, keyWords.length);
            if (index == keyWords.split('OR').length - 1) {
                console.log('entro');
                params = params + item + '*22'
                return;
            }
            params = params + item + '*22OR'
        })
        console.log('params', params);
        try {
            var response = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=articles&equation=${params}&start=0&filter=${filter}&sort=1&rows=14&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);

            console.log('responseee', response);
            response.data.items = response.data.items.map(item => {
                item.category = 'article';
                return item;
            })
            setAllArticles(processData(response.data.items));
            setArticlesPagination(response.data.items.slice(0, 7))
            // response.data.items = response.data.items.map(item => {
            //     item.category = 'article';
            //     return item;
            // })
            // setArticles(processData(response.data.items));
        } catch (error) { }

    }

    return (
        <div className='articlesMain'>
            <div className="lantia-square bigHeight" style={{marginRight:'10px'}}>
                {articles !== null &&
                    <>
                        <div style={{ marginTop: '1rem' }} className="row text-left">
                            <div className="col-2"></div>
                            <div style={{ marginLeft: '2%', display: 'inline-flex' }} className="col-5">
                                <p>Título</p>
                                <img onClick={() => order('titulo')} src={require('../../../../images/icons/arrowDirections/rowDown.png').default} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px' }} width="20" height="12" alt="" />
                            </div>
                            <div style={{ paddingLeft: '6%', display: 'inline-flex' }} className="col-2">
                                <p>Fecha</p>
                                <img onClick={() => order('date')} src={require('../../../../images/icons/arrowDirections/rowDown.png').default} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px' }} width="20" height="12" alt="" />
                            </div>
                            <div style={{ paddingLeft: '3%', display: 'inline-flex' }} className="col-2">
                                <p>Journal</p>
                                <img onClick={() => order('source')} src={require('../../../../images/icons/arrowDirections/rowDown.png').default} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px' }} width="20" height="12" alt="" />
                            </div>
                        </div>
                        {articlesPagination.map(newOne => {
                            return (
                                <One newOne={newOne} />
                            )
                        })}
                    </>

                }
                <div style={{ width: '20%', display: 'inline-flex' }}>
                    {Array.apply(null, { length: 5 }).map((x, i) => {
                        return (
                            <div onClick={() => { setArticlesPagination(allArticles.slice(i * 7, (i + 1) * 7)); setCurrentPage(i) }}
                                className='pagination'
                                style={{
                                    border: `${currentPage !== i ? '1px solid var(--primaryBlueTwo)' : '#fff'}`,
                                    backgroundColor: `${currentPage === i ? 'var(--primaryBlueTwo)' : '#fff'}`
                                }} />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Articles;