import React, { useState, useEffect } from 'react'
import './announcement.scss'
import ItemsCarousel from 'react-items-carousel';
import processData from '../../../../hooks/useProccessData'
import { registerSelected } from '../../../../actions/homeAction';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import convoImage from '../../../../images/icons/ui/general/convocatorias.png'



const Announcement = (props) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();

    const resultSelet = (item) => {
        item.related = props.announcement;
        dispatch(registerSelected(item));
        history.push('/resultSelected')
        window.scrollTo(0, 250)
    }


    return (
        <>
            {props.announcement !== null &&
                <div style={{ height: '300px' }} className="lantia-square smallHeight">
                    {/* <CarouselBox
                        numberOfCards={1}
                        itemsArray={props.announcement}
                        title={''}
                    /> */}
                    <div className='carousel-searches'>
                        <h1 className='title'>{''}</h1>
                        {processData(props.announcement).length > 0 ?
                            <div style={{ padding: `0 40px` }}>
                                <ItemsCarousel
                                    requestToChangeActive={setActiveItemIndex}
                                    activeItemIndex={activeItemIndex}
                                    numberOfCards={1}
                                    gutter={20}
                                    leftChevron={<i className="fas fa-angle-left fa-3x arrowIcon"></i>}
                                    rightChevron={<i className="fas fa-angle-right fa-3x arrowIcon"></i>}
                                    outsideChevron
                                    chevronWidth={40}
                                    infiniteLoop={true}
                                >
                                    {props.announcement.map(item =>
                                        <div onClick={() => resultSelet(item)} className='carousel-searches-item text-align-left' style={{ cursor: 'pointer' }}>
                                            <div className='carrouselImg'>
                                                <img style={{ width: '400px', height: '150px' }} className='imageRelatedBox' src={convoImage} alt="" />
                                            </div>
                                            <p style={{ fontSize: '20px' }}>
                                                {/* { item.title_en ? item.title_en[0].substring(0, 60) : typeof item.title === 'string' ? item.title.substring(0, 60) :  item.title[0].substring(0, 60)}... */}
                                                <strong>
                                                    {Array.isArray(item.title) ? item.title[0].substring(0, 40) : item.title.substring(0, 40)}...
                                                </strong>
                                            </p>
                                            <div style={{ marginTop: '1rem' }}>
                                                <p>
                                                    {Array.isArray(item.description) ? item.description[0].substring(0, 30) : item.description.substring(0, 80)}...
                                                </p>
                                                {item.portal &&
                                                    <div style={{ display: 'inline-flex' }}>
                                                        {/* <h3>Fuente:{item.portal[0].substr(0,20)}</h3> */}
                                                        {/* <h3 className='text-left pl-1'>{item.pubDate[0].toString('YYYY-MM-dd').substring(0, 10)}</h3> */}
                                                        <div style={{ color: 'blue', transform: 'translate(-128px, -7px)' }} className='text-left pl-1 mt-0'>ver mas</div>
                                                    </div>
                                                }
                                                {item.thumbnail &&
                                                    <h3 style={{ color: '#7B9CC6' }}>
                                                        {/* Actualizado: */}
                                                    </h3>}
                                            </div>
                                        </div>
                                    )}
                                </ItemsCarousel>
                            </div>
                            :
                            // <CarrouselThree />
                            <div className="noResults">No se encontarron resultados</div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default Announcement;