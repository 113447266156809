import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import clienteAxios from '../../../../config/axiosBigData';
import './graphicalAnalysis.scss'
import Bar from '../../../../graphics/d3/BarGraphic/Bar'
import Pie from '../../../../graphics/d3/PieGraphic/Pie'
import GeoChart from '../../../../graphics/d3/GeoGraphic/GeoChart'
import Bars from '../../../../graphics/Plotly/Bars'
import LineChart from '../../../../graphics/d3/LineGraphic/lineChart';
import Treemap from '../../../../graphics/d3/TreeMapsGraphic/Treemap'
import TrendsBar from '../../../../graphics/d3/TrendGraphic/TrendsBar'
import CorrelationGraphic from '../../../../graphics/d3/CorrelationGraphic/CorrelationGraphic'
import SurfaceGraphic from '../../../../graphics/d3/SurfaceGraphic/SurfaceGraphic'
import NetworkGraphic from '../../../../graphics/d3/NetworkGraphic/NetworkGraphic'
import CalendarGraphic from '../../../../graphics/google/Calendar'
import {color} from 'd3';
import SunBurst from '../../../../graphics/Plotly/SunBurst'
import CurveS from '../../../../graphics/Plotly/CurveS'
import TimeSerie from '../../../../graphics/Plotly/TimeSerie'
import Sankey from '../../../../graphics/Plotly/Sankey'
import ModalDos from 'react-modal';
import {Modal, Button} from 'react-bootstrap';
import {setDatesFilterAction} from '../../../../../actions/patentAction';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

ModalDos.setAppElement('#root');

const GraphicalAnalysis = () => {
    //redux
    const search = useSelector(state => state.home.search);
    const networkGraphicTag = useSelector(state => state.home.networkGraphicTag);

    //state
    const [dataPatents, setDataPatents] = useState(null);
    const [dataNoticias, setDataNoticias] = useState(null);
    const [dataEventos, setDataEventos] = useState(null);
    const [dataCorrelationPatents, setCorrelationPatents] = useState(null);
    const [dataCorrelationPatentsInventor, setCorrelationPatentsInventor] = useState(null);
    const [dataPatentesVencer, setDataPatentesVencer] = useState(null);
    const [calendarSelected, setCalendarSelected] = useState(null);
    const [dataPatentesVencerConteo, setDataPatentesVencerConteo] = useState(null);
    const [dataCorrelationPatentsMatrix, setCorrelationPatentsMatrix] = useState(null);
    const [curveS, setCurveS] = useState(null);
    const [inventorsIpcAll, setInventorsIpcAll] = useState(null);
    const [inventorsIpc, setInventorsIpc] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [word, setWord] = useState("")
    const [ipcTranslated, setIpcTranslated] = useState(null);
    const [numberOfMonths, setNumberOfMonths] = useState('');
    const [showipcLabelModal, setShowipcLabelModal] = useState(false)
    const [date, setDate] = useState(null)
    const [tagIPC, setTagIPC] = useState('')

    //state de grafica sankey
    const [dataPatentSubsidiaryAll, setPatentSubsidiaryAll] = useState(null);
    const [dataPatentSubsidiary, setPatentSubsidiary] = useState(null);
    const [PatentSubsidiaryNumber, setPatentSubsidiaryNumber] = useState(10);
    const [sankeyCompany, setSankeyCompany] = useState(null);
    const [numberOfIPC, setNumberOfIPC] = useState('')
    const [ipcLabelresponse, setIpcLabelresponse] = useState(null)
    const [showSankeyModal, setShowSankeyModal] = useState(false); //modal

    //state de grafica s


    /*******GRAFICA SANKEY */
    const getSunkeyFunc = async () => {
        const responsePatentSubsidiary = await clienteAxios.get(`parent_and_subsidiary?q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        setPatentSubsidiaryAll(responsePatentSubsidiary.data);
        var label = [];
        var source = [];
        var target = [];
        var value = [];
        var contLabel = 0;
        var contador = 0;
        responsePatentSubsidiary.data.slice(0, 10).map((ps) => {
            contLabel = contador;
            label.push(ps.value);

            ps.pivot.map(piv => {
                label.push(piv.value);
                source.push(contLabel);
                target.push(contador + 1);
                value.push(piv.count);
                contador++;
            })
            contador++;
        })
        setPatentSubsidiary({
            label: label,
            source: source,
            target: target,
            value: value
        })
    }

    const getSankeyName = async (figure) => {
        const company = figure.points[0].source.label;
        const responseSankeyCompany = await clienteAxios.get(`company_parent_perfil?name=${company}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        setSankeyCompany(responseSankeyCompany.data);
        setShowSankeyModal(true);
    }

    const patentSubsidiaryOrder = () => {
        var label = [];
        var source = [];
        var target = [];
        var value = [];
        var contLabel = 0;
        var contador = 0;
        dataPatentSubsidiaryAll.slice(0, PatentSubsidiaryNumber).map((ps) => {
            contLabel = contador;
            label.push(ps.value);

            ps.pivot.map(piv => {
                label.push(piv.value);
                source.push(contLabel);
                target.push(contador + 1);
                value.push(piv.count);
                contador++;
            })
            contador++;
        })
        setPatentSubsidiary({
            label: label,
            source: source,
            target: target,
            value: value
        })
    }
    /******* TERMINA GRAFICA SANKEY */

    useEffect(() => {
        getSunkeyFunc();
        curveSFunc();
        patentAnalitycFunc();
        calendarFunc();
        CorrelationPatents();
        CorrelationPatentsInventor();
        events();
        news();
        getInventorsIpc();
    }, [search])

    useEffect(() => {
        if(networkGraphicTag !== '') {
            setWord(networkGraphicTag);
        }

    }, [networkGraphicTag])

    useEffect(() => {
    }, [dataPatents,
        dataNoticias,
        dataEventos,
        dataCorrelationPatents,
        dataCorrelationPatentsInventor,
        dataPatentesVencer,
        dataPatentesVencerConteo,
        inventorsIpc,
        ipcTranslated,
        calendarSelected,
        numberOfIPC])

    const patentAnalitycFunc = async () => {
        const responsePatents = await clienteAxios.get(`patents_analytics_data?q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        //Serie de tiempo
        var publicX = [];
        var publicY = [];
        var apliX = [];
        var apliY = [];
        for(const property in responsePatents.data.Month_application) {
            apliX.push(property)
            apliY.push(responsePatents.data.Month_application[property])
        }
        for(const property in responsePatents.data.Month_publication) {
            publicX.push(property)
            publicY.push(responsePatents.data.Month_publication[property])
        }
        responsePatents.publicX = publicX;
        responsePatents.publicY = publicY;
        responsePatents.apliX = apliX;
        responsePatents.apliY = apliY;
        setDataPatents(responsePatents);
    }

    const getInventorsIpc = async () => {
        try {
            const responseInventorsIPC = numberOfIPC === '' ? await clienteAxios.get(`inventors_for_ipc?q=${search}&no_results_inven=5&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`) :
                await clienteAxios.get(`inventors_for_ipc?q=${search}&no_results_inven=${numberOfIPC}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            setInventorsIpcAll(responseInventorsIPC.data);
            setInventorsIpc(responseInventorsIPC.data);
        } catch(error) { }
    }

    /**GRAFICA CURVA S */
    const curveSFunc = async () => {
        const responseCurveS = await clienteAxios.get(`curve_s?q=${search}&Year_predict=2023&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        //Curva S
        var curvsx = [];
        var curveYP = [];
        var curveYA = [];
        var curveYAD = [];
        var pointInflectionX = [];
        var pointInflectionY = [];
        for(const property in responseCurveS.data.Year_application.data_for_year) {
            if(responseCurveS.data.Year_application.data_for_year[property].acumulado !== 0) {
                curvsx.push(property);
                curveYP.push(responseCurveS.data.Year_application.data_for_year[property].acumulado)
                curveYA.push(responseCurveS.data.Year_application.data_for_year[property].ajustado)
                curveYAD.push(responseCurveS.data.Year_application.data_for_year[property].ajustado_dev)
            }
        }
        for(const property in responseCurveS.data.Year_application.point_inflection) {
            pointInflectionX.push(parseInt(Math.floor(property)))
            pointInflectionY.push(parseInt(responseCurveS.data.Year_application.point_inflection[property]))
        }
        responseCurveS.data.curvsx = curvsx;
        responseCurveS.data.curveYP = curveYP;
        responseCurveS.data.curveYA = curveYA;
        responseCurveS.data.curveYAD = curveYAD;
        responseCurveS.data.pointInflectionX = pointInflectionX;
        responseCurveS.data.pointInflectionY = pointInflectionY;
        setCurveS(responseCurveS.data);

    }
    /**FINALIZA GRAFICA CURVA S */

    /**GRAFICA CALENDARIO */
    const calendarFunc = async () => {
        const responsepatentesVencerConteo = numberOfMonths === '' ? await clienteAxios.get(`patentes_a_vencer_conteo?number_months=6&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`) :
            await clienteAxios.get(`patentes_a_vencer_conteo?number_months=${numberOfMonths}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        //Calendar
        const datapatentesVencerCont = responsepatentesVencerConteo.data.map(x => {
            return x = [new Date(x[0], x[1], x[2]), x[3]]
        });

        datapatentesVencerCont.unshift([{type: 'date', id: 'Date'}, {type: 'number', id: 'Won/Loss'}]);

        setDataPatentesVencerConteo(datapatentesVencerCont);

    }
    const dateSelected = async (calendarDate) => {
        setDate(calendarDate)
        try {
            const responsesunburst = await clienteAxios.get(`sunburst_patents_vencer?date_str=${formatDate(calendarDate)}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            setCalendarSelected(responsesunburst.data);
        } catch(error) {

        }
    };

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if(month.length < 2)
            month = '0' + month;
        if(day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const getSunBurstLabel = async (figure) => {
        try {
            const responsesunburstPatentesvencerInfo = await clienteAxios.get(`sunburst_patents_vencer?date_str=${formatDate(date)}&subclass=${figure.points[0].label}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            setShowipcLabelModal(true);
            setTagIPC(figure.points[0].label);
            setIpcLabelresponse(responsesunburstPatentesvencerInfo);
        } catch(error) {

        }
    }

    /**TERMINAR GRAFICA CALENDARIO */
    const CorrelationPatents = async () => {
        try {
            const responseCorrelationPatents = await clienteAxios.get(`correlation_of_patents?q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            setCorrelationPatents(responseCorrelationPatents);
        } catch(error) { }

    }

    const CorrelationPatentsInventor = async () => {
        try {
            const responseCorrelationPatentsInventor = await clienteAxios.get(`correlation_of_patents_inventor?q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            setCorrelationPatentsInventor(responseCorrelationPatentsInventor);
        } catch(error) { }
    }

    const news = async () => {
        try {
            const responseNoticias = await clienteAxios.get(`noticias_analytic?q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            var axisx = [];
            var axisy = [];
            for(const property in responseNoticias.data.Sector) {
                axisx.push(property);
                axisy.push(responseNoticias.data.Sector[property]);
            }
            responseNoticias.data.Sector = {x: axisx, y: axisy}


            axisx = [];
            axisy = [];
            for(const property in responseNoticias.data.Year) {
                axisx.push(property);
                axisy.push(responseNoticias.data.Year[property]);
            }
            responseNoticias.data.Year = {x: axisx, y: axisy};
            setDataNoticias(responseNoticias);
        } catch(error) { }
    }

    const events = async () => {
        try {
            const responseEventos = await clienteAxios.get(`eventos_analytic?q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
            setDataEventos(responseEventos);
        } catch(error) { }

    }

    const translateIPC = async (ipc) => {

        const IPCTraductor = ipc === "" ? await clienteAxios.get(`description_ipc?ipc=${word}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`) :
            await clienteAxios.get(`description_ipc?ipc=${ipc}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        var proper = [];
        for(const property in IPCTraductor.data) {
            var ipcObject = {
                property: property,
                description: IPCTraductor.data[property]
            };
            proper.push(ipcObject);
        }
        setIpcTranslated(proper);
    }

    //modal functions---
    const openModal = () => {
        setIsOpen(true);
    }

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //subtitle.style.color = '#f00';
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const showDescription = (e) => {
        openModal();
        translateIPC(e);
    }

    const selectNumberOfMonths = () => {
        if(numberOfMonths === '') {
            return alert('El campo esta vacio');
        }
        if(numberOfMonths > 24) {
            return alert('El número de meses debe ser 24 o menos');
        }
        if(numberOfMonths < 1) {
            return alert('El número de meses debe ser un número positivo');
        }
        calendarFunc();
    }

    const selectNumberOfIPC = () => {
        if(numberOfIPC === '') {
            return alert('El campo esta vacio');
        }
        if(numberOfIPC < 1) {
            return alert('El número de IPC debe ser un número positivo');
        }

        var temInventors = inventorsIpcAll;

        for(let index = 0;index < temInventors.length; index++) {
            if(temInventors[index].Inventors) {
                temInventors[index].Inventors.x = temInventors[index].Inventors.x.slice(0, numberOfIPC)
                temInventors[index].Inventors.y = temInventors[index].Inventors.y.slice(0, numberOfIPC)
            } else {
                temInventors[index].Assignee_for.x = temInventors[index].Assignee_for.x.slice(0, numberOfIPC)
                temInventors[index].Assignee_for.y = temInventors[index].Assignee_for.y.slice(0, numberOfIPC)
            }

        }
        setInventorsIpc(temInventors);
    }

    return (
        <div className="mainGraphicalAnalysis">
            { dataPatents !== null ?
                <div>
                    <div>
                        <Bar
                            application={dataPatents.data.Year_application}
                            publication={dataPatents.data.Year_publication}
                            width={900}
                            height={200}
                            top={20}
                            bottom={30}
                            left={50}
                            right={0}
                            title={''}
                            title1={'Patentes por Año de aplicación'}
                            title2={'Patentes por Año de publicación'}
                        />
                    </div>
                    <div>
                        <Pie
                            width={450}
                            height={450}
                            margin={20}
                            title={'Patentes por tipo'}
                            type={dataPatents.data.Type}
                            office={true}
                        />
                    </div>
                    <div>
                        <h2>Serie de tiempo</h2>
                        <TimeSerie
                            xAxisPubli={dataPatents.publicX}
                            yaxisPubli={dataPatents.publicY}
                            xAxisApli={dataPatents.apliX}
                            yaxisApli={dataPatents.apliY}
                        />
                    </div>
                    <div>
                        <GeoChart countries={dataPatents.data.Country} />
                    </div>
                    <div>
                        <Bars
                            xAxis={dataPatents.data.Owner.x}
                            yAxis={dataPatents.data.Owner.y}
                            orientation={'v'}
                            color={'red'}
                            title={'Cantidad de patentes por propietario'}
                        />
                    </div>
                    <div>
                        <TrendsBar trends={dataPatents.data.Office} />
                    </div>
                </div>
                : <h6>Cargando dataPatents...</h6>
            }
            { dataNoticias !== null ?
                <div>
                    <div className='w-100'>
                        <Bars
                            xAxis={dataNoticias.data.Sector.x}
                            yAxis={dataNoticias.data.Sector.y}
                            orientation={'v'}
                            color={'blue'}
                            title={'cantidad noticias por sector economico'}
                        />
                    </div>
                    <div>
                        <h4>cantidad noticias por portales</h4>
                        <TrendsBar
                            trends={dataNoticias.data.Portal}
                        />
                    </div>
                    <div>
                        <Bars
                            xAxis={dataNoticias.data.Year.x}
                            yAxis={dataNoticias.data.Year.y}
                            orientation={'v'}
                            color={'blue'}
                            title={'Cantidad noticias por año'}
                        />
                    </div>
                </div>
                : <h6>Cargando dataNoticias...</h6>
            }
            { dataEventos !== null ?
                <div>
                    <div>
                        <h2>Cantidad de eventos por Sector</h2>
                        <TrendsBar
                            trends={dataEventos.data.Sector}
                        />
                    </div>
                </div>
                : <h6>Cargando dataEventos...</h6>
            }
            { dataCorrelationPatentsInventor !== null ?
                <div>
                    <div>
                        <h2>correlacion de inventores</h2>
                        <NetworkGraphic
                            nodesProp={dataCorrelationPatentsInventor.data.Redes.nodes}
                            linksprop={dataCorrelationPatentsInventor.data.Redes.links}
                        />
                    </div>
                </div>
                : <h6>Cargando dataCorrelationPatentsInventor...</h6>
            }
            { dataCorrelationPatents !== null ?
                <div>
                    <div className="inputBox">
                        <input onChange={(e) => setWord(e.target.value)} type="text" value={word} id="fname" name="fname" />
                        <button onClick={() => translateIPC("")} className="btn btn-success btn-sm" style={{color: "white"}} type='button'>traducir IPC</button>
                    </div>
                    <div>
                        <h2>correlacion de redes IPC</h2>
                        <NetworkGraphic
                            nodesProp={dataCorrelationPatents.data.Redes.nodes}
                            linksprop={dataCorrelationPatents.data.Redes.links}
                        />
                    </div>
                </div>
                : <h6>Cargando dataCorrelationPatents...</h6>
            }
            { dataPatentesVencer !== null ?
                <div>
                    <h1>Patentes a vencer</h1>
                    <div>
                        {dataPatentesVencer.data.map(patente => {
                            return (
                                <div>
                                    <h6>id: {patente._id[0]}</h6>
                                    <h4>id: {patente.invention_title_en[0]}</h4>
                                    <p>Resumen: {patente.abstract_en[0]}</p>
                                </div>
                            )
                        })

                        }
                    </div>
                </div>
                : <h6>Cargando dataPatentesVencer...</h6>
            }
            {dataPatentesVencerConteo !== null &&
                <div>
                    <div className="inputBox">
                        <label>número de meses a partir de la fecha actual</label>
                        <input onChange={(e) => setNumberOfMonths(e.target.value)} type='number' name="numberOfMonths" value={numberOfMonths} />
                        <button type='button' onClick={() => selectNumberOfMonths()}>Buscar</button>
                    </div>
                    <div>
                        <CalendarGraphic
                            data={dataPatentesVencerConteo}
                            title={'Patentes proximas a vencer'}
                            dateSelected={dateSelected}
                        />
                    </div>
                </div>
            }
            {/*<h1>{calendarSelected}</h1>*/}
            {calendarSelected !== null &&
                <div>
                    <SunBurst
                        //labels={["Eve", "Cain", "Seth", "Enos", "Noam", "Abel", "Awan", "Enoch", "Azura"]}
                        //parents={["", "Eve", "Eve", "Seth", "Seth", "Eve", "Eve", "Awan", "Eve"]}
                        //values={[10, 14, 12, 10, 2, 6, 6, 4, 4]}

                        labels={calendarSelected.labels}
                        parents={calendarSelected.parents}
                        values={calendarSelected.values}
                        getSunBurstLabel={getSunBurstLabel}
                    />
                </div>
            }
            {/*{ dataCorrelationPatentsMatrix !== null ?
                <div>
                    <CorrelationGraphic data={dataCorrelationPatentsMatrix.data} />
                </div>
                :<h6>Cargando dataCorrelationPatentsMatrix...</h6>
            }*/}
            { ipcTranslated !== null &&
                ipcTranslated.map(ipc => {
                    return (
                        <div>
                            <h5><strong>{ipc.property}: </strong> {ipc.description}</h5>
                        </div>
                    )
                })
            }
            { curveS !== null ?
                <div>
                    <div>
                        <h2></h2>
                        <CurveS
                            xAxis={curveS.curvsx}
                            yaxisP={curveS.curveYP}
                            curveYA={curveS.curveYA}
                            curveYAD={curveS.curveYAD}
                            pointInflectionX={curveS.pointInflectionX}
                            pointInflectionY={curveS.pointInflectionY}
                        />
                    </div>
                </div>
                : <h6>Cargando datos curva s...</h6>
            }
            <div className="inputBox">
                <label>cantidad de IPC</label>
                <input onChange={(e) => setNumberOfIPC(e.target.value)} type='number' name="numberOfMonths" value={numberOfIPC} />
                <button type='button' onClick={() => selectNumberOfIPC()}>Buscar</button>
            </div>
            {inventorsIpc ?
                inventorsIpc.map(ipc => {
                    return (
                        ipc.Inventors ?
                            <div>
                                <h6>Grafico de inventores IPC <strong onClick={() => showDescription(ipc.Inventors.ipc)} style={{color: "blue", cursor: 'pointer'}}>{ipc.Inventors.ipc}</strong></h6>
                                <Bars
                                    xAxis={ipc.Inventors.x}
                                    yAxis={ipc.Inventors.y}
                                    orientation={'v'}
                                    color={'blue'}
                                />
                            </div> :
                            <div>
                                <h6>Grafico de propietarios IPC <strong onClick={() => showDescription(ipc.Assignee_for.ipc)} style={{color: "blue", cursor: 'pointer'}}>{ipc.Assignee_for.ipc}</strong></h6>
                                <Bars
                                    xAxis={ipc.Assignee_for.x}
                                    yAxis={ipc.Assignee_for.y}
                                    orientation={'v'}
                                    color={'red'}
                                />
                            </div>
                    );
                })
                : <h6>Cargando datos inventors Ipc...</h6>
            }
            { dataPatentSubsidiary !== null &&
                <div>
                    <div className="row">
                        <input type="number"
                            onChange={(e) => setPatentSubsidiaryNumber(e.target.value)}
                        />
                        <button onClick={patentSubsidiaryOrder}>Buscar</button>
                    </div>
                    <Sankey
                        label={dataPatentSubsidiary.label}
                        source={dataPatentSubsidiary.source}
                        target={dataPatentSubsidiary.target}
                        value={dataPatentSubsidiary.value}
                        getSankeyName={getSankeyName}
                    />
                    {/*} <NetworkGraphic 
                        nodesProp={dataPatentSubsidiary.MainNodes}
                        linksprop ={dataPatentSubsidiary.Linksprop}
                    />{*/}
                </div>
            }

            <ModalDos
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Descripción de IPC"
            >
                <button onClick={closeModal}>close</button>
                {ipcTranslated !== null ?
                    ipcTranslated.map(ipc => {
                        return (
                            <div style={{width: '50rem'}}>
                                <h5><strong>{ipc.property}: </strong> {ipc.description}</h5>
                            </div>
                        )
                    }) :
                    <p>Cargando ipcTranslated...</p>
                }
            </ModalDos>
            {sankeyCompany !== null &&
                <Modal style={{marginLeft: '600px'}} show={showSankeyModal} onHide={() => setShowSankeyModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="row">
                                <a href={sankeyCompany.homepage_url[0]}>
                                    <img src={sankeyCompany.logo_url[0]} alt="logo" />
                                </a>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <a href={sankeyCompany.facebook_url[0]} target='blank'>Facebook</a>
                            <a className='mx-2' href={sankeyCompany.linkedin_url[0]} target='blank'>Linkedin</a>
                            <a href={sankeyCompany.twitter_url[0]} target='blank'>Twitter</a>
                        </div>

                        <div className='mt-3'>
                            <p>{sankeyCompany.short_description[0]}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowSankeyModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <Modal style={{marginLeft: '600px'}} show={showipcLabelModal} onHide={() => setShowipcLabelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {tagIPC}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ipcLabelresponse !== null ?
                        ipcLabelresponse.data.map(x => {
                            return (<div>
                                <p>{x._id[0] && x._id[0]}</p>
                                <p>{x.invention_title_en[0] && x.invention_title_en[0]}</p>
                                <p>{x.assignee_name[0] && x.assignee_name[0]}</p>
                                <hr/>
                            </div>

                            );
                        })
                        :
                        <p>Cargando..</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowipcLabelModal(false)}>
                        Close
                        </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}
export default GraphicalAnalysis;
