 import React from "react";
 import PropTypes from "prop-types";
 import * as d3 from "d3v5";
 import './trendGraphic.css';

 const Tooltip = ({ hovered, xScale, yScale, marginLeft, marginTop }) => {

   const x = xScale(hovered.name) + marginLeft;

   const y = yScale(hovered.value) + yScale.bandwidth();

   const formatWomenPercentage = d3.format(".1f");

   const styles = {
     transform:
       "translate(" + `calc( -50% + ${x}px),` + `calc(-100% + ${y}px)` + ")"
   };

   const name = `${hovered.name}`;
   const value = `${hovered.value}`;
 
   return (
     <div className="tooltip" style={styles}>
       <div className="tooltipCountry">{name}</div>
       <div>{name}</div>
       <div>{value}</div>
     </div>
   );
 };

 Tooltip.propTypes = {
   hovered: PropTypes.shape({
     name: PropTypes.string,
     value: PropTypes.number
   }),
   xScale: PropTypes.func,
   yScale: PropTypes.func,
   marginLeft: PropTypes.number,
   marginTop: PropTypes.number
 };

 export default Tooltip;
