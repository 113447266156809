import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './login.scss'
import HeaderLogo from '../ui/HeaderLogo'

//redux
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { loginAction } from '../../../actions/authAction'

const Login = () => {

    //redux
    const history = useHistory();
    const dispatch = useDispatch();

    //states del componente
    const [user,setUser] = useState({
        email:'',
        password:'',
        rememberToken:true
    })

    const [errorLogin,setError] = useState(false);

    //funciones
    const onChange = e => {
        e.target.name !== 'rememberToken' ? 
        setUser({
            ...user,
            [e.target.name] : e.target.value
        })
        :
        setUser({
            ...user,
            rememberToken : !user.rememberToken
        })
    }

    //inicia sesion
    const login = async e => {
        e.preventDefault();
        //validacion


        //envio formulario
        const response = await dispatch(loginAction(user));
        //accion a tomar
        if(response === '1'){
            history.push('/homeApp')
        }else if(response === '0'){
            history.push('/profile')
        }else if(response === 'invalid'){
            setError(true);
        }else{
            //letrero error de conexion
        }
        
    }

    const [jitsi,setJitsi] = useState(true);
    const onClose = () => {
        setJitsi(false);
    } 

    return ( 
        <div>
            {/*<HeaderLogo />*/}
            <div className='row justify-content-center align-items-center'>                
                <div className='login'>
                    <div className='form-group'>
                        <label htmlFor="email">Usuario o email</label>
                        <input 
                            id="email" 
                            className='form-control'
                            name="email" 
                            type="text" 
                            maxLength="60" 
                            value={user.email}
                            onChange={onChange}
                        />
                    </div>
                    <div className='form-group'>
                        <label htmlFor="password">Contraseña</label>
                        <input 
                            id="password" 
                            className='form-control'
                            name="password" 
                            type="password"  
                            value={user.password}
                            onChange={onChange}
                        />
                    </div>
                    <div className='row justify-content-center align-items-center'>
                        <div className="form-check">
                            <input 
                                name="rememberToken" 
                                className='form-check-input'
                                type="checkbox" 
                                onChange={onChange}
                                checked={user.rememberToken ? true : false}
                            />
                            <label 
                                htmlFor="rememberToken"
                                className='form-check-label'    
                            >Recordar mi cuenta</label>
                        </div>
                    </div>
                    <button
                        className='btn btn-primary btn-block'
                        type="submit"
                        onClick={login}
                    >
                        INICIAR SESION  
                    </button>
                    {errorLogin && 
                        <div className="w-100 alert alert-danger lnt-alert" role='alert'>
                            Login Invalido
                        </div>
                    } 
                    <div className='links mt-5 text-center'>
                        <div><Link className="recover" to={'/'}>¿Has olvidado tu contraseña?</Link></div>
                        <div><Link className="singup" to={'/signup'}>Registrate ahora </Link></div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Login;