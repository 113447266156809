import axios from 'axios';

var clienteAxios = axios;
if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === ""){
    clienteAxios = axios.create({
        //baseURL:'http://127.0.0.1:8001/api/'
        baseURL:'https://lantiasas.com/lantialogin/api/'
    });
}else{
    clienteAxios = axios.create({
        baseURL:'https://lantiasas.com/lantialogin/api/'
    });
}


export default clienteAxios;