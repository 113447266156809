import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import noticias from '../../../../../images/icons/colorModulesIcons/news.png'
import videos from '../../../../../images/icons/colorModulesIcons/video.png'
import patentes from '../../../../../images/icons/colorModulesIcons/patents.png'
import event from '../../../../../images/icons/colorModulesIcons/events.png'
import Startup from '../../../../../images/icons/colorModulesIcons/Startups.png'
import Article from '../../../../../images/icons/colorModulesIcons/articles.png'
import convocatoria from '../../../../../images/icons/filters/convocatoriaFilter.png'
import cvlac from '../../../../../images/icons/filters/cv_lac.png'
import gplac from '../../../../../images/icons/filters/gp_lac.png'
import volver from '../../../../../images/icons/Iconos-23.png';
import clienteAxios from '../../../../config/axiosBigData';
import CarouselRelated from './related/CarouselRelated';
import CarrouselThree from '../../../../ui/skeletonTemplates/carrouselThree'

//subcomponents
import NewResult from './newResult/NewResult';
import PatentResult from './patentResult/PatentResult';
import StartupResult from './startupResult/StartupResult';
import VideoResult from './videoResult/VideoResult';
import EventResult from './eventResult/EventResult';
import ArticleResult from './articleResult/ArticleResult';
import ConvoResult from './convoResult/ConvoResult';
import CvLacResult from './cvlavResult/CvLacResult';
import GpLacResult from './grouplacResult/GpLacResult';

import './result.scss'

const Result = (props) => {

    //redux
    const history = useHistory();
    const [resultSelected, setResultSelected] = useState(useSelector(state => state.home.resultSelected));
    const resultsStorage = useSelector(state => state.home.results);
    const apiKey = useSelector(state => state.auth.apiKey);
    //state
    const [otherResults, setOtherResults] = useState(null)
    const [moduleColor, setModuleColor] = useState({ name: '', color: '' })
    const [titleToSend, setTitleToSend] = useState('')
    const [properties, setProperties] = useState({});
    const slashCharacterReplace = 'jfn-mdrt}{';

    useEffect(() => {
        if (props.computedMatch.params.id !== undefined) {
            const idRecomended = props.computedMatch.params.id.split(slashCharacterReplace).join('/');
            getRecomended(idRecomended)
            return;
        }
        category(resultSelected.category);
        related();
        spanishCategory();
    }, [])

    useEffect(() => {
        category(resultSelected.category);
        related();
    }, [resultSelected])


    const imageProcess = (src) => {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = src
        }).then(values => {
            if (values.width > 200) {
                return { width: '100%', height: '144px' };
            }

            return { width: values.width.toString() + 'px', height: values.height.toString() + 'px' }

        }).catch(error => {
            return 'fail';
        })
    }


    const related = async () => {
        try {
            if (resultSelected.category === 'new') {
                setTitleToSend('Noticias Relacionadas');
            }
            if (resultSelected.category === 'patent') {
                setTitleToSend('Patentes Relacionadas');
            }
            if (resultSelected.category === 'article') {
                setTitleToSend('Artículos Relacionados');
            }
            if (resultSelected.category === 'event') {
                setTitleToSend('Eventos Relacionados');
            }
            if (resultSelected.category === 'startup') {
                setTitleToSend('Startups Relacionadas');
            }
            if (resultSelected.category === 'video') {
                setTitleToSend('Videos Relacionados');
            }

            if (resultSelected.category === 'convocatoria') {
                setTitleToSend('Convocatorias Relacionadas');
            }

            if (resultSelected.hasOwnProperty('related')) {
                setOtherResults(resultSelected.related);
                return;
            }

            let resultsStorageProccessed = []
            for (var i = 0; i < resultsStorage.length; i++) {


                let url = resultsStorage[i].thumbnails ? resultsStorage[i].thumbnails :
                    resultsStorage[i].picture ? resultsStorage[i].picture[0] :
                        resultsStorage[i]['thumbnails.high.url'] ? resultsStorage[i]['thumbnails.high.url'][0] :
                            resultsStorage[i].thumbnail ? resultsStorage[i].thumbnail : '';

                if (url !== '') {

                    let size = await imageProcess(url)

                    if (size !== 'fail') {
                        resultsStorage[i].imageWidth = size.width;
                        resultsStorage[i].imageHeight = size.height;
                        resultsStorage[i].imageUrlFail = false;
                        resultsStorageProccessed.push(resultsStorage[i])
                    } else {
                        resultsStorage[i].imageUrlFail = true;
                        resultsStorageProccessed.push(resultsStorage[i])
                    }

                } else {
                    resultsStorageProccessed.push(resultsStorage[i])
                }

                if (resultsStorageProccessed.length === resultsStorage.length) {
                    setOtherResults(resultsStorage.filter(result => result.category === resultSelected.category));
                }

            }

        } catch (error) {
        }

    }


    const getRecomended = async (id) => {
        try {
            const response = await clienteAxios.get(`noticias?key=${apiKey}&id=${id}`);
            response.data.items[0].category = 'new';
            category(response.data.items[0].category);
            setResultSelected(response.data.items[0]);
        } catch (error) {
        }
    }

    const category = (category) => {
        switch (category) {
            case 'new':
                return setProperties({ category: 'Noticias', image: noticias, color: 'secundarySecondtwo' });
            case 'patent':
                return setProperties({ category: 'Patentes', image: patentes, color: 'secundarySecondTen' });
            case 'video':
                return setProperties({ category: 'Videos', image: videos, color: 'secundarySecondSeven' });
            case 'event':
                return setProperties({ category: 'Eventos', image: event, color: 'secundaryFirtNine' });
            case 'startup':
                return setProperties({ category: 'Startups', image: Startup, color: 'secundarySecondFive' });
            case 'article':
                return setProperties({ category: 'Article', image: Article, color: 'secundarySecondFive' });
            case 'convocatoria':
                return setProperties({ category: 'convocatoria', image: convocatoria, color: 'secundarySecondFive' });
            case 'cv_lac':
                return setProperties({ category: 'cv_lac', image: cvlac, color: 'secundarySecondFive' });
            case 'gp_lac':

                return setProperties({ category: 'gp_lac', image: gplac, color: 'secundarySecondFive' });
            default:
                break;
        }
    }


    const comeBack = () => {
        history.goBack()
    }

    const relatedSelected = (item) => {
        setResultSelected(item)
    }

    const spanishCategory = () => {
        var module = {};
        if (resultSelected.category === 'patent') {
            module.name = 'Patentes';
            module.color = 'secundaryFour';
        } else if (resultSelected.category === 'new') {
            module.name = 'Noticias';
            module.color = 'secundarySeven';
        } else if (resultSelected.category === 'video') {
            module.name = 'Videos';
            module.color = 'secundaryEight';
        } else if (resultSelected.category === 'event') {
            module.name = 'Eventos';
            module.color = 'secundaryNine';
        } else if (resultSelected.category === 'startup') {
            module.name = 'Startups';
            module.color = 'primaryBlueThree';
        } else if (resultSelected.category === 'article') {
            module.name = 'Articulos';
            module.color = 'secundarySix';
        } else if (resultSelected.category === 'convocatoria') {
            module.name = 'Convocatorias';
            module.color = '';
        }
        setModuleColor(module);
    }

    return (
        <div className="resultSelectedMain">
            <div className="resultBox">
                <div className="new">
                    <div onClick={comeBack} className="comeBack">
                        <div style={{ width: '9rem', display: 'inline-flex' }}><img src={volver} alt="" width='30' height='30' /><h1>Volver</h1></div>
                    </div>
                    <div className='row categoryBox'>
                        <div>
                            <img src={properties.image} alt="" width='30' height='30' />
                        </div>
                        <h1 className='ml-2' style={{ color: `var(--${moduleColor.color})` }}>{moduleColor.name}</h1>
                        <div className="lineTitle">

                        </div>
                    </div>
                    <div className="bodyResult">
                        {resultSelected.category === 'new' &&
                            <NewResult
                                date={resultSelected.pubDate}
                                source={resultSelected.portal}
                                title={resultSelected.title}
                                description={resultSelected.description}
                                modal={props.modal}
                                image={resultSelected.thumbnail}
                                link={resultSelected.link[0]}
                            />
                        }
                        {resultSelected.category === 'video' &&
                            <VideoResult
                                title={resultSelected.title[0]}
                                date={resultSelected.publishedAt}
                                url={resultSelected._id}
                                description={resultSelected.description}
                                tags={resultSelected.hasOwnProperty('tags') ? resultSelected.tags : ''}
                                modal={props.modal} />
                        }
                        {resultSelected.category === 'patent' &&
                            <PatentResult
                                date={resultSelected.date_produced}
                                source={resultSelected.source ? resultSelected.source[0] : ''}
                                title={resultSelected.title}
                                description={resultSelected.description}
                                modal={props.modal} />
                        }
                        {resultSelected.category === 'event' &&
                            <EventResult
                                date={resultSelected.apertura}
                                title={resultSelected.titulo[0]}
                                description={resultSelected.descripcion[0]}
                                location={resultSelected.location}
                                modal={props.modal} />
                        }
                        {resultSelected.category === 'startup' &&
                            <StartupResult
                                title={resultSelected.name}
                                description={resultSelected.description}
                                location={resultSelected.location}
                                picture={resultSelected.picture && resultSelected.picture[0]}
                                modal={props.modal} />

                        }
                        {resultSelected.category === 'convocatoria' &&
                            <ConvoResult resultSelected={resultSelected} />
                        }
                        {resultSelected.category === 'article' &&
                            <ArticleResult
                                title={resultSelected.title}
                                date={resultSelected.hasOwnProperty('pub_date_epub') ? resultSelected.pub_date_epub[0] : resultSelected.date}
                                autor={resultSelected.hasOwnProperty('authors_information') ? resultSelected.authors_information : resultSelected.author}
                                summary={resultSelected.hasOwnProperty('abstract') ? (resultSelected.abstract ? resultSelected.abstract[0] : '') : resultSelected.summary}
                                description={resultSelected.hasOwnProperty('full_text') ? resultSelected.full_text : resultSelected.description}
                            />
                        }
                        {resultSelected.category === 'cv_lac' &&
                            <CvLacResult />
                        }
                        {resultSelected.category === 'gp_lac' &&
                            <GpLacResult />
                        }
                    </div>
                </div>
            </div>
            {(resultSelected.category !== 'gp_lac' && resultSelected.category !== 'cv_lac') &&
                <div>
                    {otherResults ?
                        <div className="resultBox">

                            <CarouselRelated
                                itemsArray={otherResults}
                                title={titleToSend}
                                relatedSelected={relatedSelected}
                            />

                        </div> :
                        <div className="resultBox">
                            <CarrouselThree />
                        </div>
                    }
                </div>
            }
        </div>
    )
}
export default Result;