import React, { useState, useEffect } from 'react'
import translateNew from '../../../../../../hooks/useTranslate'
import TranslateModal from '../../../../../ui/translateModal/TranslateModal'
import './videoResult.scss'

const VideoResult = (props) => {
    const [iFrame, setIFrame] = useState(false)

    const [descriptionText, setDescription] = useState(props.description[0].split('\n'))
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTranslate(false);
        setDescription(props.description[0].split('\n'))
    }, [props.description])

    useEffect(() => {
        if (translate) {
            translateText();
            return;
        }
        setDescription(props.description[0].split('\n'))
    }, [translate])

    const translateText = () => {
        translateNew(descriptionText).then(response => {
            setDescription(response)
        });
    }

    return (
        <div className="videoResultMain">
            <div className="row">
                <div className="col-12 col-md-8">
                    <p style={{ color: 'var(--secundaryFirtFour)', fontSize: '23px' }}>{props.title}</p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-2">
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                </div>
            </div>
            <div style={{ marginBottom: "2rem" }}>
                <div>
                    <iframe
                        className="iframe"
                        src={`https://www.youtube.com/embed/${props.url}?rel=0&autoplay=1`}
                        frameBorder="0"
                        allowFullScreen
                        onLoad={() => setIFrame(true)}
                    ></iframe>
                    <hr />
                    <div className="col-12 col-md-12 pl-0">
                        <p className="sourceAndDate"><strong>Fuente: </strong>Youtube</p>
                        <p className="sourceAndDate"><strong>Fecha de publicación: </strong>{props.date.toString().substr(0, 10)}</p>
                        <p className="description"><strong>Descripción: </strong></p>
                    </div>

                    {descriptionText.map(paragraph => {
                        return (
                            <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                {paragraph}
                            </p>
                        );
                    })}
                    {/* <hr/>
                    { props.tags !== '' &&
                        props.tags.map(tag=>{
                            return <p>{tag}</p>;
                        })
                    } */}
                </div>
            </div>
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} />
        </div>
    )
}
export default VideoResult;