import {types} from "../types/types";
import clienteAxios from '../components/config/axios';
import Swal from 'sweetalert2';

export const getEvents = (id) => {

    return async (dispatch) => {
        try {
            //endpoint de la bd
            const response = await clienteAxios.get(`event/${id}`);
            //Guarda en redux storage
            dispatch(getAllEvents(response.data.events));
            dispatch(getEventsPending(response.data.eventsPending));
            dispatch(getEventsAcceptedOrRejected(response.data.eventsAcceptedOrRejected));
            return 1;
        } catch(error) {
        }
    }
}

export const eventAddNew = (event) => {

    return async (dispatch) => {
        try {
            //endpoint de la bd
            const response = await clienteAxios.post('event/save', event);

            if((typeof response.data === 'string') && (response.data === 'updated')) {
                return Swal.fire('Success', `El evento fue actualizado con exito, se mostrara en el calendario cuando el experto acepte`, 'success');
            }
            if(typeof response.data === 'string') {
                return Swal.fire('Error', `El evento no fue posible: ${response.data}`, 'error');
            }

            //Guarda en redux storage
            //dispatch(addEvent(response.data))
            return Swal.fire('Success', `El evento fue solicitado con exito, se mostrara en el calendario cuando el experto acepte`, 'success');
        } catch(error) {
            Swal.fire('Error', `Ocurrio un error al guardar los datos, vuelve a intentarlo mas tarde o consulta con el administrador`, 'error');
        }
    }
}

//elimina el evento de la base de datos
export const eventDeleted = (id) => {
    return (dispatch) => {
        try {

            Swal.fire({
                title: 'Estas seguro de eliminar este evento?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, estoy seguro!'
            }).then((result) => {
                if(result.value) {
                    clienteAxios.delete(`event/${id}`)
                        .then(res => {
                            dispatch(deleteEv());
                            Swal.fire("El evento se elimino con exito!", {
                                icon: "success",
                            });
                        })
                        .catch(err => {
                        });
                }
            });

        } catch(error) {
        }
    }
}

//extraer del store el evento pendiente
//ya sea que se haya rechazado o aceptado
export const clearEventPending = (ep, state) =>{
    
    return async(dispatch) => {
        try {
            const response = await clienteAxios.put(`event/${ep}/${state}`);
            dispatch(addEvent(response.data));
            dispatch(clearEventsPending(ep));
            return 1;
        } catch(error) {
        }
    }
}

//indica si se hizo click en editar el evento dentro del modal
export const editEvent =(e)=>{
    return async(dispatch)=>{
        dispatch(edit(e))
    }
}


/*usuario ya vio la notificación de que 
el experto acepto el evento*/
export const userOk = (e)=>{
    return async(dispatch) => {
        try {
            const response = await clienteAxios.put(`event/userAccept/${e}`);
            dispatch(clearEventAcceptOrReject(e));
            return 1;
        } catch(error) {
        }
    }
}

//evento seleccionado en el calendario
export const eventSetActive = (event) => {
    return ({
        type: types.eventSetActive,
        payload: event
    });
}

//limpia del store el evento seleccionado
export const eventClearActiveEvent = () => ({type: types.eventClearActiveEvent});

//indicador de agenda
export const goToSchedule = (flag) =>({
    type: types.goSchedule,
    payload: flag
})

//actualiza el evento
export const eventUpdated = (event) => ({
    type: types.eventUpdated,
    payload: event
});

//agrega evento
const addEvent = event => ({
    type: types.eventAddNew,
    payload: event
});

//trae todos los eventos
const getAllEvents = event => ({
    type: types.getEventsData,
    payload: event
});

//elimina evento
const deleteEv = () => ({
    type: types.eventDeleted
});

//eventos pendientes
const getEventsPending = (events) =>({
    type: types.getEventsPenging,
    payload: events
});

//eliminar evento del store eventPendings
const clearEventsPending = (ep) =>({
    type: types.clearEventPending,
    payload: ep
});

//Obtener eventos aceptados o rechazados por parte del experto
const getEventsAcceptedOrRejected =(events)=>({
    type: types.eventsAcceptedOrRejected,
    payload:events
});

//limpiar del store los eventos que ya acepto
//el experto
const clearEventAcceptOrReject = (e)=>({
    type: types.clearEventsAcceptedOrRejected,
    payload:e
});

//indica si se hizo click en editar el evento dentro del modal
const edit = (e) =>({
    type: types.editE,
    payload:e
});