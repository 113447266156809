import React, { useState, useEffect } from 'react'

const urlPrueba = 'https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/';
const urlProduccion = 'https://checkout.payulatam.com/ppp-web-gateway-payu/';
const apiKey = 'AQk7p3G1isInmjN5NzYNhaQI44';
const apiKeytest = '4Vj8eK4rloUd272L48hsrarnUA';
const merchantId ='967301';
const merchantIdTest = '508029';
const accountId = '975143';
const accountIdtest = '512321';
const plan1 = 'plansencillo';
const valor = '1000';

const PayU = () => {

  const [parameters, setParameters] = useState('');

  useEffect(() => {
    pay();
  }, [])

  const pay = () => {
    var md5 = require('md5');
    setParameters({
      merchantId: merchantIdTest,
      accountId: accountIdtest,
      description: 'Test PAYU',
      referenceCode: plan1,
      amount: '1000',
      tax: '0',
      taxReturnBase: '0',
      currency: 'COP',
      signature: md5(`${apiKeytest}~${merchantIdTest}~${plan1}~${valor}~COP`), //'359a9f28624641160bc2e7a655491f11',
      test: '0',
      buyerEmail: 'estivetg@gmail.com',
      responseUrl: 'http://lantiasas.com/',
      confirmationUrl: 'http://lantiasas.com/'
    })
  }

  return (
    <div>Prueba
      {parameters !== '' &&
        <form method="post" action={urlPrueba}>
          <input name="merchantId" type="hidden" value={parameters.merchantId} />
          <input name="accountId" type="hidden" value={parameters.accountId} />
          <input name="description" type="hidden" value={parameters.description} />
          <input name="referenceCode" type="hidden" value={parameters.referenceCode} />
          <input name="amount" type="hidden" value={parameters.amount} />
          <input name="tax" type="hidden" value={parameters.tax} />
          <input name="taxReturnBase" type="hidden" value={parameters.taxReturnBase} />
          <input name="currency" type="hidden" value={parameters.currency} />
          <input name="signature" type="hidden" value={parameters.signature} />
          <input name="test" type="hidden" value={parameters.test} />
          <input name="buyerEmail" type="hidden" value={parameters.buyerEmail} />
          <input name="responseUrl" type="hidden" value={parameters.responseUrl} />
          <input name="confirmationUrl" type="hidden" value={parameters.confirmationUrl} />
          <input name="Submit" type="submit" value="Enviar" ></input>
        </form>
      }
    </div>
  )
}

export default PayU