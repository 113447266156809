//images
import noticias from '../images/icons/colorModulesIcons/news.png';
import patentes from '../images/icons/colorModulesIcons/patents.png';
import videos from '../images/icons/colorModulesIcons/Videos.png';
import Startups from '../images/icons/colorModulesIcons/Startups.png';
import events from '../images/icons/colorModulesIcons/events.png';
import article from '../images/icons/colorModulesIcons/articles.png';
import convocatoria from '../images/icons/filters/convocatoriaFilter.png';


const useProcessData = (results) => {

    return results.filter(r => (r !== 'fail' && r !== 'empty')).map(r => {

        r.description = r.description_en ? r.description_en[0] :
            r.description_es ? r.description_es[0] :
                r.descriptions_en ? r.descriptions_en[0] :
                    r.descripcion ? r.descripcion[0] :
                        r.description ? r.description :
                            r.abstract_en ? r.abstract_en[0] :
                                r.summary ? r.summary[0] :
                                    r.full_text ? r.full_text[0] :
                                        r.description_es ? r.description_es[0] : '';

        r.title = r.title_en ? r.title_en[0] :
            r.title_es ? r.title_es[0] :
                r.name ? r.name[0] :
                    r.titulo ? r.titulo[0] :
                        r.invention_title ? (r.invention_title.length > 1 ? r.invention_title[1] : r.invention_title[0]) :
                            r.title ? r.title :
                                r.article_title ? r.article_title[0] :
                                    r.invention_title_en ? r.invention_title_en[0] : '';


        r.source = r.category === 'video' ? 'YouTube' :
            r.portal ? r.portal :
                r.journal_title ? r.journal_title[0] :
                    r.fuente ? r.fuente : '';

        r.datePublication = r.pubDate ? r.pubDate :
            r.date_publ ? r.date_publ[0] :
                r.pubdate ? r.pubdate[0] :
                    r.publishedAt ? r.publishedAt :
                        r.fecha ? r.fecha :
                            r.pub_date_epub ? r.pub_date_epub[0] :
                                r.date_produced ? r.date_produced : '';

        r.thumbnail = r.thumbnails ? r.thumbnails :
            r.picture ? r.picture[0] :
                r['thumbnails.high.url'] ? r['thumbnails.high.url'][0] :
                    r.thumbnail ? r.thumbnail : '';

        switch (r.category) {
            case 'new':
                r.icon = noticias
                break;
            case 'patent':
                r.icon = patentes
                break;

            case 'video':
                r.icon = videos
                break;

            case 'startup':
                r.icon = Startups
                break;

            case 'event':
                r.icon = events
                break;

            case 'article':
                r.icon = article
                break;

            case 'convocatoria':
                r.icon = convocatoria
                break;

            case 'empty':
                r.empty = 'No se encontraron resultados';
                break;

            case 'fail':
                r.fail = 'Error de conexión'
                break;

            default:
                break;
        }

        return r;
    });

}
export default useProcessData;