import React from 'react'
import SaveFavouritesDropdown from '../../saveFavouritesDropdown/SaveFavouritesDropdown'

const ConvoTable = ({ results, viewDescription, id, goToResult }) => {
    console.log('ConvoTable', ConvoTable);

    return (
        <div>
            <div className="resultTable row">
                <div className="col-md-7 col-lg-7"><p className='firtsChild'>Convocatoria <img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p style={{ marginRight: '3rem' }}>Apertura <img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-1 col-lg-1"><p style={{ float: 'left' }}>Cierre <img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p style={{ float: 'left', marginLeft: '4rem' }}>Sector <img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
            </div>
            { (results[0].category !== 'empty' && results[0].category !== 'fail') ?
                results.map(r => {
                    return (<div className="resultTableBody row">
                        <div className="col-md-2 col-lg-2">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 firstColumns pl-0">
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <div className="categoryDiv">
                                            <img onClick={() => goToResult(r)} className="icon" src={r.icon} alt='' />
                                        </div>
                                        <div className="addRepository">
                                        <SaveFavouritesDropdown item={r}/>
                                        </div>
                                        <div className="imageToPreviewDiv">
                                            {r.thumbnail ?
                                                <img className="imageToPreview" src={r.thumbnail ? r.thumbnail : ''} />
                                                :
                                                <div style={{ paddingTop: '1.5rem' }}>
                                                    {/* <div className='imageToPreviewDivEmpty'>

                                                </div> */}
                                                    <p onClick={() => viewDescription(r)} style={{ color: 'var(--primaryBlueOne)', fontSize: '14px', cursor: 'pointer' }}>ver más</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 col-lg-10 threeLastColumns">
                            <div onClick={() => goToResult(r)} className="row">
                                <div className="title col-md-6 col-lg-6" >
                                    <p style={{ float: 'left' }} className="title">{Array.isArray(r.title) ? r.title[0].substring(0, 40) : r.title.substring(0, 40)}...</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.apertura && r.apertura.toString().substr(0, 10)}</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.cierre && r.cierre.toString().substr(0, 10)}</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.Fuente && r.Fuente[0]}</p>
                                </div>
                            </div>
                        </div>
                        { (id === r.id) &&
                            <div className="view">
                                <p style={{ textAlign: 'left' }}>{Array.isArray(r.descripcion) ? r.descripcion[0].substr(0, 300) : r.descripcion.substr(0, 300)}...
                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => goToResult(r)}>ver más</span>
                                </p>
                            </div>
                        }

                    </div>)

                }):
                <div>No se encontraron resultados</div>
            }
        </div>
    );
}

export default ConvoTable;