import React, {useState, useEffect} from 'react';
import GeoChart from '../../graphics/d3/GeoGraphic/GeoChart.jsx';
import Pie from '../../graphics/d3/PieGraphic/Pie.jsx';
import Bar from '../../graphics/d3/BarGraphic/Bar.jsx';
import {Treemap} from '../../graphics/d3/TreeMapsGraphic/Treemap.jsx';
import CorrelationGraphic from '../../graphics/d3/CorrelationGraphic/CorrelationGraphic.jsx';
import SurfaceGraphic from '../../graphics/d3/SurfaceGraphic/SurfaceGraphic.jsx';
import SGraphic from '../../graphics/d3/SGraphic/SGraphic.jsx';
import NetworkGraphic from '../../graphics/d3/NetworkGraphic/NetworkGraphic.jsx';

//Redux
import {useSelector} from 'react-redux';

const PatentsGraphics = () => {

    const connector = useSelector(state => state.patent.graphicsArray);
    const connectorSCurve = useSelector(state => state.patent.graphicsSCurve)
    
    //**States */
    const [internalGraphics, setInternalGraphics] = useState({
        Tipe: null,
        Year_application: null,
        Year_publication: null,
        Month_application: null,
        Month_publicattion: null,
        Country: null,
        Office: null,
        Trend: null
    });//datos que trae el endpoint para ser graficados

    const [internarSGraphic, setInternalSGraphic] = useState({
        Year_application: null,
        Year_publication: null
    })


    useEffect(() => {
        //iguala el state interno al de redux
        setInternalGraphics(connector); 
   }, [connector]);

   useEffect(() => {
        //iguala el state interno al de redux
        setInternalSGraphic(connectorSCurve); 
    }, [connectorSCurve]);


    return (<>
        {
            internalGraphics.Country != undefined &&
            <GeoChart countries={internalGraphics.Country}/>
        }
        {
            internalGraphics.Tipe != undefined &&
            <Pie
                width={450}
                height={450}
                margin={20}
                title={'Patentes por tipo'}
                type={internalGraphics.Tipe}
                office={''}
            />
        }
        {
            internalGraphics.Year_application != undefined &&
            <Bar 
            application={internalGraphics.Year_application}
            publication={internalGraphics.Year_publication}
            width={900}
            height={200}
            top={20}
            bottom={30}
            left={50}
            right={0}
            title={''}
            title1={'Patentes por Año de aplicación'}
            title2={'Patentes por Año de publicación'}
          />
        }
        {
            internalGraphics.Office != undefined &&
            <Pie
            width={450}
            height={450}
            margin={20}
            title={'Patentes por oficina'}
            type={internalGraphics.Office}
            office={true}
          />
        }
        {
            internalGraphics.Month_application != undefined &&
            <Bar 
            application={internalGraphics.Month_application}
            publication={internalGraphics.Month_publication}
            width={900}
            height={200}
            top={20}
            bottom={30}
            left={50}
            right={0}
            title={'Tendencias de las patentes año/mes'}
            title1={'Tendencia aplicación'}
            title2={'Tendencia publicación'}
          />
        }
        <Treemap
            chartHeight={400}
            colorRange={["rgba(0,0,255, 0.1)", "rgba(0,0,255, 1)"]}
        />
        <CorrelationGraphic />
        <SurfaceGraphic /> 
        {
            internarSGraphic != undefined &&
            <SGraphic 
                title='Año de aplicación'
                year={internarSGraphic.Year_application}
            />
        } 
        {
            internarSGraphic != undefined &&
            <SGraphic 
                title='Año de publicación'
                year={internarSGraphic.Year_publication}
            />
        } 
        <NetworkGraphic />
    </> );
}
 
export default PatentsGraphics;