import moment from 'moment';

import {types} from '../types/types';

const initialState = {
    events: [],
    activeEvent: null,
    eventsPending: [],
    eventsAcceptedOrRejected: [],
    eventStreamingToken: null,
    goSchedule:false,
    editE: false
};


const calendarReducer = (state = initialState, action) => {
    switch(action.type) {

        case types.getEventsData:
            return {
                ...state,
                events: action.payload.map(x => {
                    return {
                        id: x.id,
                        userId:x.user_id,
                        title: 'asesoria',
                        start: moment(x.start_date).toDate(),
                        end: moment(x.end_date).toDate(),
                        bgcolor: '#fafafa',
                        notes: x.text,
                        expertToken: x.expertToken ? x.expertToken : null,
                        userToken: x.userToken ? x.userToken : null,
                        user: {
                            name: `${x.firstname} ${x.lastname}`
                        }
                    }
                })
            }
        
        case types.goSchedule:
            return{
                ...state,
                goSchedule:action.payload
            }

        case types.clearEventPending:
            return {
                ...state,
                eventsPending: state.eventsPending.filter(e => e.id !== action.payload)
            }

        case types.eventsAcceptedOrRejected:
            return {
                ...state,
                eventsAcceptedOrRejected: action.payload
            }

        case types.getEventsPenging:
            return {
                ...state,
                eventsPending: action.payload
            }

        case types.eventSetActive:
            return {
                ...state,
                activeEvent: action.payload
            }

        case types.eventAddNew:
            return {
                ...state,
                events: [
                    ...state.events,
                    {
                        id: action.payload.id,
                        title: 'asesoria',
                        start: action.payload.start_date,
                        end: action.payload.end_date,
                        bgcolor: '#fafafa',
                        notes: action.payload.text,
                        user: {
                            _id: '',
                            name: ''
                        }
                    }
                ]
            }

        case types.eventClearActiveEvent:
            return {
                ...state,
                activeEvent: null
            }


        case types.eventUpdated:
            return {
                ...state,
                events: state.events.map(
                    e => (e.id === action.payload.id) ? action.payload : e
                )
            }

        case types.eventDeleted:
            return {
                ...state,
                events: state.events.filter(
                    e => (e.id !== state.activeEvent.id)
                ),
                activeEvent: null
            }

        case types.clearEventsAcceptedOrRejected:
            return {
                ...state,
                eventsAcceptedOrRejected: state.eventsAcceptedOrRejected.filter(
                    e => (e.id !== action.payload)
                ),
            }
         
        case types.editE:
            return{
                ...state,
                editE: action.payload
            }    

        default:
            return state;
    }


}
export default calendarReducer