import React, { useState } from 'react'
import { setSearchAction } from '../../../../actions/homeAction'
import { useDispatch } from 'react-redux'
import { typeOfSearch } from '../../../../actions/homeAction';
import { NORMAL_SEARCH } from '../../../../globalConstants/typeOfSearch'

const HistoryOne = ({ word }) => {
    const [wordDate] = useState(new Date(word.date_inserted))
    const dispatch = useDispatch();

    const goToTableOfResults = (word) => {
        dispatch(typeOfSearch(NORMAL_SEARCH));
        dispatch(setSearchAction(word));
    }


    return (
        <div onClick={() => { goToTableOfResults(word.word) }} className='row text-left my-1  align-items-center justify-content-between separator' style={{ cursor: 'pointer' }}>
            <div className='ml-3'>
                <p className='pSmallHome'>{word.word}</p>
                <h3>{wordDate.toString('YYYY-MM-dd').substring(0, 15)}</h3>
            </div>
            <h3 className='homeSmallLink'>ver de nuevo</h3>
        </div>
    );
}

export default HistoryOne;