import React, { useEffect, useState } from 'react'
import './advancedSearch.scss'
import axios from '../../../config/axiosBigData'
import OneFilter from './onefilter/OneFilter'
import { useDispatch, useSelector } from 'react-redux';
import { ADVANCED_SEARCH, NORMAL_SEARCH } from '../../../../globalConstants/typeOfSearch'
import { getSearchAdvanced, typeOfSearch, advancedSearchParams, changeSource, getSearch, cleanResult, cleanTotal, cleanLast, cleantoTalPages } from '../../../../actions/homeAction';

const AdvancedSearch = () => {

    const [filters, setFilters] = useState(null);
    const [filtersChosen, setFiltersChosen] = useState('');
    const [nameFilter, setNameFilter] = useState([]);
    const [endFilters, setEndFilter] = useState([]);
    const [totalFields, setTotalFields] = useState([{ filter: endFilters[0], name: '' }]);
    const [interval, setInterval] = useState({ date1: new Date("1970-01-01").toISOString(), date2: new Date().toISOString() })

    const dispatch = useDispatch();
    const equation = useSelector(state => state.home.equation);
    const category = useSelector(state => state.home.source);

    useEffect(() => {
        getFilters();
    }, [])

    useEffect(() => {
    }, [totalFields])

    const addRow = () => {
        setTotalFields([...totalFields, { filter: endFilters[0], name: '' }]);
    }

    const deleteRow = (key) => {
        var arrayTmp = [...totalFields];
        arrayTmp.splice(key, 1);
        setTotalFields(arrayTmp);
    }

    const getFilters = async () => {
        const Ansfilters = await axios.get('/advanced_search_filter');
        setFilters(Ansfilters.data.items);
        setNameFilter(Object.keys(Ansfilters.data.items))
    }

    const onFilterChange = e => {
        if (e.target.value === 'Patentes') {
            setEndFilter(filters['PatentesV2']);
            setTotalFields([{ filter: filters['PatentesV2'][0], name: '' }]);
            setFiltersChosen('PatentesV2');
        } else {
            setEndFilter(filters[e.target.value]);
            setTotalFields([{ filter: filters[e.target.value][0], name: '' }]);
            setFiltersChosen([e.target.value]);
        }

    }

    const changeFinalFilter = (e, key) => {

        var arayTmp = [...totalFields];
        var tmp = totalFields[key];
        tmp = {
            ...tmp,
            [e.target.name]: e.target.value
        }
        arayTmp[key] = tmp
        setTotalFields(arayTmp)
    }

    const intervalTime = e => {
        setInterval({ ...interval, [e.target.name]: e.target.value })
    }

    const sendAdvanced = async () => {

        if (equation !== '' && filtersChosen === '') {
            dispatch(typeOfSearch(NORMAL_SEARCH));

            let Equation = equation.replace(/AND/g, '*22 AND *22');
            Equation = Equation.replace(/OR/g, '*22 OR *22');
            Equation = Equation.replace(/NOT/g, '*22 NOT *22');

            for (let index = 0; index < equation.split('(').length + 1; index++) {
                Equation = Equation.replace('(', '[ *22');
                Equation = Equation.replace(')', '*22 ]');
            }

            for (let index = 0; index < equation.split('(').length + 1; index++) {
                Equation = Equation.replace('*22 [', '(');
                Equation = Equation.replace('*22[', '(');
                Equation = Equation.replace('] *22', ')');
                Equation = Equation.replace(']*22', ')');
            }

            if (Equation.charAt(0) === "[") {
                Equation = Equation.replace('[', '(');
            } else {
                Equation = '*22' + Equation;
            }

            if (Equation.charAt(Equation.length - 1) === "]") {
                Equation = Equation.replace(']', ')');
            } else {
                Equation = Equation + '*22';
            }

            // dispatch(cleanResult());
            // dispatch(cleanTotal());
            // dispatch(cleanLast());
            // dispatch(cleantoTalPages());
            dispatch(changeSource('all'))
            dispatch(getSearch(Equation, 0, 'all', `equation=${Equation}`));
            return;
        }

        var finalFilter = ''
        var contador = 0;
        totalFields && totalFields.map((oneField) => {
            contador++;
            if (contador < totalFields.length) {
                finalFilter = finalFilter + oneField.filter + ': %22' + oneField.name + '%22 AND '
            }
            else {
                finalFilter = finalFilter + oneField.filter + ': %22' + oneField.name + '%22'
            }
        })

        let source = Array.isArray(filtersChosen) ? filtersChosen[0] : filtersChosen;

        switch (source) {
            case 'Patentes':
                source = 'patentes'
                break;

            case 'PatentesV2':
                source = 'patentes'
                break;

            case 'noticias':
                source = 'noticias'
                break;

            case 'convocatorias':
                source = 'convocatorias'
                break;

            case 'eventos':
                source = 'eventos'
                break;

            case 'articulos':
                source = 'articles'
                break;

            case 'videos':
                source = 'videos'
                break;

            case 'startups':
                source = 'startups'
                break;

            case 'cv_lac':
                source = 'cv_lac'
                break;

            default:
                break;
        }

        let params = {
            filtersChosen: filtersChosen,
            finalFilter: finalFilter,
            date1: interval.date1.substr(0, 10),
            date2: interval.date2.substr(0, 10),
            source: source
        };
        dispatch(changeSource(source))
        dispatch(typeOfSearch(ADVANCED_SEARCH));
        dispatch(advancedSearchParams(params))
        dispatch(getSearchAdvanced(0, params, null))

    }

    return (
        <div className='advancedSearch lantia-square row searchBoxPanel'>
            <div className="col-3">
                <div className='titleParams'>
                    <h3>Rango de tiempo</h3>
                </div>
                <div className="row">
                    <div style={{ padding: '0px' }} className="col-4">
                        <h3 className='pt-2'>Desde</h3>
                    </div>
                    <div style={{ padding: '0px' }} className="col-8">
                        <input onChange={intervalTime} defaultValue={interval.date1} name="date1" class="form-control form-control-sm" type="date" />
                    </div>
                </div>
                <div className="row mt-1">
                    <div style={{ padding: '0px' }} className="col-4">
                        <h3 className='pt-2'>Hasta</h3>
                    </div>
                    <div style={{ padding: '0px' }} className="col-8">
                        <input onChange={intervalTime} defaultValue={interval.date2} name="date2" class="form-control form-control-sm" type="date" />
                    </div>
                </div>
            </div>
            <div className="col-3 section-h">
                <h3 className='selection'>Selecciona una fuente</h3>
                <select
                    name="name"
                    onChange={onFilterChange}
                    defaultValue={'DEFAULT'}
                    className='form-control'
                >
                    <option value="DEFAULT" disabled>Selecciona una fuente</option>
                    {nameFilter && nameFilter.map((name, index) => (
                        <option key={index} >{name === 'PatentesV2' ? 'Patentes' : name}</option>
                    ))}
                </select>
            </div>
            <div className="col-6">
                <div className="row">
                    {totalFields && totalFields.map((oneField, index) => {
                        return (
                            <OneFilter
                                key={index}
                                endFilters={endFilters}
                                index={index}
                                addRow={addRow}
                                deleteRow={deleteRow}
                                changeFinalFilter={changeFinalFilter}
                                oneField={oneField}
                                aca={oneField.field}
                            />
                        )
                    })}
                </div>
            </div>
            <div className='row justify-content-center w-100'>
                <div className="col-2">
                    <button className='btn btn-primary btn-block mt-2' onClick={sendAdvanced}>BUSCAR</button>
                </div>
            </div>
        </div>
    );
}

export default AdvancedSearch;