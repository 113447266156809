import {
    SET_SEARCH,
    SET_RESULTS_OF_SEARCH,
    SET_RESULTS_SELECTED,
    SET_NETWORK_GRAPHIC_ID,
    CLEAN_RESULTS,
    NUMBER_RESULTS,
    CLEAN_NUMBER_RESULTS,
    LAST_REGISTERS,
    CLEAN_LAST_REGISTERS,
    NUMBER_OF_PAGES,
    CLEAN_NUMBER_OF_PAGES,
    PARAMS_OF_SEARCH,
    CLEAN_PARAMS_OF_SEARCH,
    TYPE_OF_SEARCH,
    ADVANCED_SEARCH_PARAMS,
    SOURCE,
    LAST_SEARH,
    SET_EQUATION
} from '../types/homeTypes';

//state
const initialState = {
    search: '',
    results: [],
    resultSelected: {},
    networkGraphicTag: '',
    totalResults: 0,
    lastRegisters: 0,
    numberOfPages: 0,
    paramsOfSearch: '',
    typeOfSearch: '',
    paramsOfAdvancedSearch: {},
    source: 'all',
    lastSearches: [],
    equation:''
}

export default function (state = initialState, action) {
    switch (action.type) {

        case LAST_REGISTERS:
            return {

                ...state,
                lastRegisters: action.payload
            }

        case SOURCE:
            return {
                ...state,
                source: action.payload
            }

        case CLEAN_LAST_REGISTERS:
            return {
                ...state,
                lastRegisters: action.payload
            }

        case NUMBER_OF_PAGES:
            return {
                ...state,
                numberOfPages: action.payload
            }

        case CLEAN_NUMBER_OF_PAGES:
            return {
                ...state,
                numberOfPages: action.payload
            }



        case NUMBER_RESULTS:
            return {
                ...state,
                totalResults: action.payload
            }

        case CLEAN_NUMBER_RESULTS:
            return {
                ...state,
                totalResults: action.payload
            }

        case CLEAN_RESULTS:
            return {
                ...state,
                results: action.payload
            }

        case SET_SEARCH:
            return {
                ...state,
                search: action.payload
            }

        case SET_RESULTS_OF_SEARCH:
            return {
                ...state,
                results: action.payload
            }

        case SET_RESULTS_SELECTED:
            return {
                ...state,
                resultSelected: action.payload
            }
        case SET_NETWORK_GRAPHIC_ID:
            return {
                ...state,
                networkGraphicTag: action.payload
            }

        case PARAMS_OF_SEARCH:
            return {
                ...state,
                paramsOfSearch: action.payload
            }

        case CLEAN_PARAMS_OF_SEARCH:
            return {
                ...state,
                paramsOfSearch: action.payload
            }

        case TYPE_OF_SEARCH:
            return {
                ...state,
                typeOfSearch: action.payload
            }
        case ADVANCED_SEARCH_PARAMS:
            return {
                ...state,
                paramsOfAdvancedSearch: action.payload
            }
        case LAST_SEARH:
            return {
                ...state,
                lastSearches: [...state.lastSearches, ...action.payload]
            }
            case SET_EQUATION:
                return {
                    ...state,
                    equation: action.payload
                }
            
        default:
            return state;
    }
}