import React from 'react';
import Skeleton  from "react-loading-skeleton";
import './carrouselThree.scss'

const CarrouselThree = () => {
    return ( 
        <>  
            <div className="row justify-content-center carrouselThree">
                <div className="col-4 row justify-content-center">
                    <div>
                        <p><Skeleton width={250}/></p>
                        <p><Skeleton width={100}/></p>
                        <p><Skeleton height={140} width={250}/></p> 
                        <p><Skeleton width={250}/></p> 
                        <p><Skeleton width={250}/></p> 
                        <p><Skeleton width={100}/></p> 
                    </div>                    
                </div>
                <div className="col-4 mid row justify-content-center">
                    <div>
                        <p><Skeleton width={250}/></p>
                        <p><Skeleton width={100}/></p>
                        <p><Skeleton height={140} width={250}/></p> 
                        <p><Skeleton width={250}/></p> 
                        <p><Skeleton width={250}/></p> 
                        <p><Skeleton width={100}/></p> 
                    </div>                    
                </div>
                <div className="col-4 row justify-content-center">
                    <div>
                        <p><Skeleton width={250}/></p>
                        <p><Skeleton width={100}/></p>
                        <p><Skeleton height={140} width={250}/></p> 
                        <p><Skeleton width={250}/></p> 
                        <p><Skeleton width={250}/></p> 
                        <p><Skeleton width={100}/></p> 
                    </div>                    
                </div>
            </div>
        </>
     );
}
 
export default CarrouselThree;