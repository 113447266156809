import React, { useState } from 'react'
import One from '../ui/OneBigElement'
import './patents.scss'

const Events = ({ patents }) => {
    const [allPatents, setAllPatents] = useState(patents)
    const [patentsPagination, setPatentsPagination] = useState(patents.slice(0, 7))
    const [currentPage, setCurrentPage] = useState(0)

    return (
        <div className='patentsMain'>
            <div style={{height:'43rem'}} className="lantia-square bigHeight" style={{marginRight:'10px'}}>
                {patents !== null &&
                    <div>
                        <div style={{marginTop:'1rem'}} className="row text-left">
                            <div className="col-2"></div>
                            <div style={{marginLeft:'10px'}} className="col-5 pr-0"><p>Título</p></div>
                            <div style={{paddingLeft:'7%'}} className="col-2"><p>Fecha</p></div>
                            <div style={{paddingLeft:'2rem'}} className="col-2"><p>Fuente</p></div>
                        </div>

                        {patentsPagination !== undefined &&
                            patentsPagination.map(newOne => {
                                return (
                                    <One
                                        newOne={newOne}
                                    />
                                )
                            })}
                    </div>
                }
                <div style={{ width: '20%', display: 'inline-flex' }}>
                    {Array.apply(null, { length: 5 }).map((x, i) => {
                        return (
                            <div onClick={() => { setPatentsPagination(allPatents.slice(i * 7, (i + 1) * 7)); setCurrentPage(i) }}
                                className='pagination'
                                style={{
                                    border: `${currentPage !== i ? '1px solid var(--primaryBlueTwo)' : '#fff'}`,
                                    backgroundColor: `${currentPage === i ? 'var(--primaryBlueTwo)' : '#fff'}`
                                }} />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Events;

