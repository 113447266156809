import React,{useState} from 'react'
import {useDispatch} from 'react-redux'
import { generateGraphicAction } from '../../../../../../../actions/graphicAction'
import DatePicker from "react-datepicker"

const PieFilter = () => {
    //redux
    const dispatch = useDispatch();

    //states
    const [filter,setFilter] = useState({
        source:'Patentes',
        limit:10,
        color:'#F5AE33',
        dateStart:null,
        dateFinish:null,
        name:'Patentes por tipo',
        type:'pie'
    });

    const onFilterChange = (e) => {
        if([e.target.name][0] !== 'source'){
            setFilter({
                ...filter,
                [e.target.name] : e.target.value
            })
        }else{
            switch (e.target.value) {
                case 'Patentes':
                    setFilter({
                        ...filter,
                        [e.target.name] : e.target.value,
                        name:'Patentes por tipo'
                    })
                    break;

                default:
                    break;
            }
        }
    };

    const generateGraphic = () => {
        dispatch(generateGraphicAction(filter))
    }

    //const patentsFilter = ['Patentes por tipo']
    const patentsFilter = []
    const colorFilter = ['#F5AE33','#C2DB77','#C277DB','#453443','#3CCBF4','#B30B00']

    return ( 
        <>
            <div class="row w-100 mt-2">
                    <div class="col">
                        <h3>Fuente</h3>
                        <select 
                            name="source" 
                            onChange={onFilterChange}
                            class="form-control form-control-sm"
                        >
                            <option>Patentes</option>
                        </select>
                    </div>
                    <div class="col">
                        <h3>Límite</h3>
                        <select 
                            name='limit'
                            class="form-control form-control-sm"
                            onChange={onFilterChange}    
                        >
                            <option>5</option>
                            <option>10</option>
                            <option>20</option>
                        </select>
                    </div>
                    <div class="col">
                        <h3>Rango de tiempo</h3>
                        <div className="row">
                            <div style={{padding:'0px'}} className="col-4">
                                <h3 className='pt-2'>Desde</h3>
                            </div>
                            <div style={{padding:'0px'}} className="col-8">
                                    <DatePicker
                                        selected={filter.dateStart}
                                        onChange={(e) => setFilter({
                                            ...filter,
                                            dateStart:e
                                        })}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat="dd-MM-yyyy"
                                    />
                            </div>
                        </div>
                        <div style={{marginTop:'10px'}} className="row">
                            <div style={{padding:'0px'}} className="col-4">
                                <h3 className='pt-2'>Hasta</h3>
                            </div>
                            <div style={{padding:'0px'}} className="col-8">
                                <DatePicker
                                    selected={filter.dateFinish}
                                    onChange={(e) => setFilter({
                                        ...filter,
                                        dateFinish:e
                                    })}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    dateFormat="dd-MM-yyyy"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <h3>Tipo de gráfica</h3>
                        <select 
                            name='name'
                            class="form-control form-control-sm"                            
                            onChange={onFilterChange}
                        >   
                            {
                                patentsFilter.map((filter,index) => (
                                    <option key={index} >{filter}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                {/**Termina primera fila y empieza segunda fila*/}
                <div class="row w-100 mt-2 justify-content-center">
                <button style={{padding:'0px 10px'}} onClick={generateGraphic} className='btn btn-primary'>GENERAR</button>
                </div>
        </>
     );
}
 
export default PieFilter;