import {
    SET_GRAPHIC,
    SET_GRAPHIC_TO_DRAW,
    ADD_GRAPHIC_ARRAY,
    DELETE_GRAPHIC,
    LOCK_GRAPHIC,
    CLEAN_GRAPHIC_ARRAY,
    LOADING_GRAPHIC
} from '../types/graphicTypes';
import nextId from "react-id-generator";
import clienteAxios from '../components/config/axiosBigData';

//funcion para cambiar grafica
export function setGraphicAction(graphic) {
    return async (dispatch) => {
        try {
            //cambia el search de la bd
            dispatch(setGraphic(graphic));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//genera grafica
export function generateGraphicAction(graphic) {
    console.log('graphic---->', graphic);
    return async (dispatch, getState) => {
        try {
            var data = null;
            const search = getState().home.search;
            const graphicArray = getState().graphic.graphicArray;
            let graphics = graphicArray.filter(graphic => graphic.lock === true)

            if (graphics.length === 0) {
                dispatch(loadingGraphic(true));
            } else {
                dispatch(loadingGraphic(false));
            }

            /**Verticales */
            if (graphic.name === 'Cantidad por propietario') {
                data = await patentProperty(search, graphic);
            }
            else if (graphic.name === 'Año de aplicación') {
                data = await patentYearAplication(search, graphic);
            }
            else if (graphic.name === 'Sector economico') {
                data = await newSectorEconomy(search, graphic);
            }
            else if (graphic.name === 'Cantidad por año') {
                data = await newTotalYear(search, graphic);
            }
            /**Horizontales */
            else if (graphic.name === 'Barras de tendencia') {
                data = await patenTrendBar(search);
            }
            else if (graphic.name === 'Cantidad por portales') {
                data = await newsTotalPortals(search, graphic);
            }
            else if (graphic.name === 'Eventos por sector') {
                data = await eventSector(search, graphic);
            }
            /**MAPA, TREEMAP, PIE */
            else if (graphic.name === 'Patentes por paises') {
                data = await patentCountry(search, graphic);
            }
            else if (graphic.name === 'Patentes por tipo') {
                data = await patentsType(search, graphic);
            }
            /**LINEAS*/
            else if (graphic.name === 'Curva S') {
                data = await patentCurveS(search);
            }
            else if (graphic.name === 'Patent Ipc') {
                data = await patentIpc(search);
            }
            else if (graphic.name === 'Serie de tiempo') {
                data = await patentTimeSerie(search, graphic);
            }
            else if (graphic.name === 'Patentes por año de publicación') {
                data = await patentByPublicationYear(search, graphic);
            }
            else if (graphic.name === 'Artículos por año de publicación') {
                data = await articlesByPublicationYear(search, graphic);
            }
            else if (graphic.name === 'Año de publicación y aplicación de Patentes') {
                data = await publicationAplicationYearPatent(search, graphic);
            }
            var graphToCreate = {
                id: nextId(),
                data: data,
                source: graphic.source,
                name: graphic.name,
                lock: false,
                type: graphic.type,
                color: graphic.color
            }
            dispatch(cleanGraphicToDraw(graphToCreate));
            dispatch(addGraphicArray(graphToCreate));
            dispatch(loadingGraphic(false));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para eliinar grafica
export function deleteGraphicAction(id) {
    return async (dispatch) => {
        try {
            //cambia el search de la bd
            dispatch(deleteGraphic(id));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

//funcion para eliinar grafica
export function lockGraphicAction(id) {
    return async (dispatch, getState) => {
        try {
            const graphicArray = getState().graphic.graphicArray;
            var newGraphic = {};
            graphicArray.forEach(graphic => {
                if (graphic.id === id) {
                    newGraphic = graphic;
                }
            });
            newGraphic.lock = !newGraphic.lock;
            dispatch(lockGraphic(newGraphic));
            return 1;
        } catch (error) {
            return 0;
        }
    }
}

/***************GRAFCAS VERTICALES******************************/
const patentProperty = async (search, graphic) => {
    var ultimateDateStart = null;
    if (graphic.dateStart !== null) {
        var yearStart = graphic.dateStart.getFullYear();
        var monthStart = graphic.dateStart.getMonth() + 1;
        var dayStart = graphic.dateStart.getDate();
        ultimateDateStart = yearStart + '-' + monthStart + '-' + dayStart
    }
    var ultimateDateFinish = null;
    if (graphic.dateFinish !== null) {
        var yearFinish = graphic.dateFinish.getFullYear();
        var monthFinish = graphic.dateFinish.getMonth() + 1;
        var dayFinish = graphic.dateFinish.getDate();
        ultimateDateFinish = yearFinish + '-' + monthFinish + '-' + dayFinish
    }
    var endPointDate = '';
    if (ultimateDateStart !== null) {
        endPointDate = '&date_pub1=' + ultimateDateStart
    }
    if (ultimateDateFinish !== null) {
        endPointDate = endPointDate + '&date_pub2=' + ultimateDateFinish
    }
    const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/bars/owners&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo${endPointDate}`);
    var data = {}
    if (graphic.type === 'vertical') {
        var x = [];
        var y = [];
        var counter = 0;
        for (const property in responsePatents.data.propietario) {
            if (counter < graphic.limit) {
                x.push(property);
                y.push(responsePatents.data.propietario[property]);
            }
            counter++
        }
        data.items = { x: x, y: y };
        data.total = responsePatents.data.total
    } else {
        var counter = 0;
        var propietario = {};
        for (const property in responsePatents.data.propietario) {
            if (counter < graphic.limit) {
                propietario[property] = responsePatents.data.propietario[property];
            }
            counter++
        }
        data.items = propietario;
        data.total = responsePatents.data.total;
    }
    return data;
}
const patentYearAplication = async (search, graphic) => {
    const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/bars/time_s_year&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
    var data = {};
    var counter = 0;
    if (graphic.type === 'vertical') {
        var Year_application = {};
        var Year_publication = {};

        var finishDate = new Date();
        if (graphic.dateFinish === null) {
            finishDate = new Date('2030-01-01');
        } else {
            finishDate = graphic.dateFinish;
        }
        var convertProperty = new Date();
        for (const property in responsePatents.data.Year_application) {
            if (counter < graphic.limit) {
                convertProperty = new Date(property);
                if (graphic.dateStart <= convertProperty && finishDate >= convertProperty) {
                    Year_application[property.toString().substring(0, 4)] = responsePatents.data.Year_application[property];
                }

            }
            counter++
        }
        counter = 0;
        for (const property in responsePatents.data.Year_publication) {
            if (counter < graphic.limit) {
                Year_publication[property.toString().substring(0, 4)] = responsePatents.data.Year_publication[property];
            }
            counter++
        }
        data.Year_application = Year_application;
        data.Year_publication = Year_publication;
        data.total = responsePatents.data.total;

    } else {
        data.total = responsePatents.data.total;
        var arrayTempAppli = [];
        var tempAppli = {};
        var Year_application = {};
        var Year_publication = {};

        var finishDate = new Date();
        if (graphic.dateFinish === null) {
            finishDate = new Date('2030-01-01');
        } else {
            finishDate = graphic.dateFinish;
        }
        var convertProperty = new Date();
        for (const property in responsePatents.data.Year_application) {
            if (counter < graphic.limit) {
                convertProperty = new Date(property);
                if (graphic.dateStart <= convertProperty && finishDate >= convertProperty) {
                    Year_application[property.toString().substring(0, 4)] = responsePatents.data.Year_application[property];
                    tempAppli = {};
                    tempAppli.year = property.toString().substring(0, 4);
                    tempAppli.appli = responsePatents.data.Year_application[property];
                    arrayTempAppli.push(tempAppli);
                }

            }
            counter++
        }
        data.items = [['Año', 'Patentes por Año de aplicación', 'Patentes por Año de publicación']]
        counter = 0;
        for (const property in responsePatents.data.Year_publication) {
            if (counter < graphic.limit) {
                convertProperty = new Date(property);
                if (graphic.dateStart <= convertProperty && finishDate >= convertProperty) {
                    Year_publication[property.toString().substring(0, 4)] = responsePatents.data.Year_publication[property];
                    var temp = [arrayTempAppli[counter].year, arrayTempAppli[counter].appli, responsePatents.data.Year_publication[property]];
                    data.items.push(temp)
                }
            }
            counter++
        }
    }
    return data;
}
const newSectorEconomy = async (search, graphic) => {
    var ultimateDateStart = null;
    if (graphic.dateStart !== null) {
        var yearStart = graphic.dateStart.getFullYear();
        var monthStart = graphic.dateStart.getMonth() + 1;
        var dayStart = graphic.dateStart.getDate();
        ultimateDateStart = yearStart + '-' + monthStart + '-' + dayStart
    }
    var ultimateDateFinish = null;
    if (graphic.dateFinish !== null) {
        var yearFinish = graphic.dateFinish.getFullYear();
        var monthFinish = graphic.dateFinish.getMonth() + 1;
        var dayFinish = graphic.dateFinish.getDate();
        ultimateDateFinish = yearFinish + '-' + monthFinish + '-' + dayFinish
    }

    var endPointDate = '';
    if (ultimateDateStart !== null) {
        endPointDate = '&date1=' + ultimateDateStart
    }
    if (ultimateDateFinish !== null) {
        endPointDate = endPointDate + '&date2=' + ultimateDateFinish
    }
    const responseNoticias = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=news/graphics/bars/sector&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo${endPointDate}`);
    var x = [];
    var y = [];
    var counter = 0;



    for (const property in responseNoticias.data.Sector) {
        if (counter < graphic.limit) {
            x.push(property);
            y.push(responseNoticias.data.Sector[property]);
        }
        counter++
    }
    var data = { x: x, y: y };
    return data;
}
const newTotalYear = async (search, graphic) => {
    const responseNoticias = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=news/graphics/bars/year&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
    var x = [];
    var y = [];

    var finishDate = new Date();
    if (graphic.dateFinish === null) {
        finishDate = new Date('2030-01-01');
    } else {
        finishDate = graphic.dateFinish;
    }
    var convertProperty = new Date();
    for (const property in responseNoticias.data) {
        convertProperty = new Date(property);
        if (graphic.dateStart <= convertProperty && finishDate >= convertProperty) {
            x.push(property);
            y.push(responseNoticias.data[property]);
        }
    }
    var data = { x: x, y: y };
    return data;
}

/***************GRAFCAS HORIZONTALES******************************/
const patenTrendBar = async (search) => {
    const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents_analytics_data&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
    //var data = responsePatents.data.Office;
    //return data;
}
const newsTotalPortals = async (search, graphic) => {
    var ultimateDateStart = null;
    if (graphic.dateStart !== null) {
        var yearStart = graphic.dateStart.getFullYear();
        var monthStart = graphic.dateStart.getMonth() + 1;
        var dayStart = graphic.dateStart.getDate();
        ultimateDateStart = yearStart + '-' + monthStart + '-' + dayStart
    }
    var ultimateDateFinish = null;
    if (graphic.dateFinish !== null) {
        var yearFinish = graphic.dateFinish.getFullYear();
        var monthFinish = graphic.dateFinish.getMonth() + 1;
        var dayFinish = graphic.dateFinish.getDate();
        ultimateDateFinish = yearFinish + '-' + monthFinish + '-' + dayFinish
    }

    var endPointDate = '';
    if (ultimateDateStart !== null) {
        endPointDate = '&date1=' + ultimateDateStart
    }
    if (ultimateDateFinish !== null) {
        endPointDate = endPointDate + '&date2=' + ultimateDateFinish
    }
    const responseNoticias = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=news/graphics/hbars/portal&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo${endPointDate}`);
    var counter = 0;
    var portal = {};
    for (const property in responseNoticias.data.Portal) {
        if (counter < graphic.limit) {
            portal[property] = responseNoticias.data.Portal[property];
        }
        counter++
    }
    var data = portal;
    return data;
}
const eventSector = async (search, graphic) => {
    var ultimateDateStart = null;
    if (graphic.dateStart !== null) {
        var yearStart = graphic.dateStart.getFullYear();
        var monthStart = graphic.dateStart.getMonth() + 1;
        var dayStart = graphic.dateStart.getDate();
        ultimateDateStart = yearStart + '-' + monthStart + '-' + dayStart
    }
    var ultimateDateFinish = null;
    if (graphic.dateFinish !== null) {
        var yearFinish = graphic.dateFinish.getFullYear();
        var monthFinish = graphic.dateFinish.getMonth() + 1;
        var dayFinish = graphic.dateFinish.getDate();
        ultimateDateFinish = yearFinish + '-' + monthFinish + '-' + dayFinish
    }

    var endPointDate = '';
    if (ultimateDateStart !== null) {
        endPointDate = '&date1=' + ultimateDateStart
    }
    if (ultimateDateFinish !== null) {
        endPointDate = endPointDate + '&date2=' + ultimateDateFinish
    }
    const responseEventos = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=eventos_analytic&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo${endPointDate}`);
    var counter = 0;
    var sector = {};
    for (const property in responseEventos.data.Sector) {
        if (counter < graphic.limit) {
            sector[property] = responseEventos.data.Sector[property];
        }
        counter++
    }
    var data = {};
    data.items = sector;
    data.total = responseEventos.data.total;
    return data;
}
/***************GRAFCAS MAPAS, TREEMAP, PIE******************************/
const patentCountry = async (search, graphic) => {
    var ultimateDateStart = null;
    if (graphic.dateStart !== null) {
        var yearStart = graphic.dateStart.getFullYear();
        var monthStart = graphic.dateStart.getMonth() + 1;
        var dayStart = graphic.dateStart.getDate();
        ultimateDateStart = yearStart + '-' + monthStart + '-' + dayStart
    }
    var ultimateDateFinish = null;
    if (graphic.dateFinish !== null) {
        var yearFinish = graphic.dateFinish.getFullYear();
        var monthFinish = graphic.dateFinish.getMonth() + 1;
        var dayFinish = graphic.dateFinish.getDate();
        ultimateDateFinish = yearFinish + '-' + monthFinish + '-' + dayFinish
    }
    var endPointDate = '';
    if (ultimateDateStart !== null) {
        endPointDate = '&date_pub1=' + ultimateDateStart
    }
    if (ultimateDateFinish !== null) {
        endPointDate = endPointDate + '&date_pub2=' + ultimateDateFinish
    }
    const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/maps/country&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo${endPointDate}`);
    var data = responsePatents.data.Country;
    return data;
}
const patentsType = async (search, graphic) => {
    var ultimateDateStart = null;
    if (graphic.dateStart !== null) {
        var yearStart = graphic.dateStart.getFullYear();
        var monthStart = graphic.dateStart.getMonth() + 1;
        var dayStart = graphic.dateStart.getDate();
        ultimateDateStart = yearStart + '-' + monthStart + '-' + dayStart
    }
    var ultimateDateFinish = null;
    if (graphic.dateFinish !== null) {
        var yearFinish = graphic.dateFinish.getFullYear();
        var monthFinish = graphic.dateFinish.getMonth() + 1;
        var dayFinish = graphic.dateFinish.getDate();
        ultimateDateFinish = yearFinish + '-' + monthFinish + '-' + dayFinish
    }
    var endPointDate = '';
    if (ultimateDateStart !== null) {
        endPointDate = '&date_pub1=' + ultimateDateStart
    }
    if (ultimateDateFinish !== null) {
        endPointDate = endPointDate + '&date_pub2=' + ultimateDateFinish
    }
    const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/pie/type&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo${endPointDate}`);
    var data = responsePatents.data.Type;
    return data;
}
/***************GRAFCAS DE LINEAS******************************/
const patentCurveS = async (search) => {
    const responseCurveS = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/lines/curve_s&q=${search}&Year_predict=2023&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
    var curvsx = [];
    var curveYP = [];
    var curveYA = [];
    var curveYAD = [];
    var pointInflectionX = [];
    var pointInflectionY = [];
    for (const property in responseCurveS.data.Year_application.data_for_year) {
        if (responseCurveS.data.Year_application.data_for_year[property].acumulado !== 0) {
            curvsx.push(property);
            curveYP.push(responseCurveS.data.Year_application.data_for_year[property].acumulado)
            curveYA.push(responseCurveS.data.Year_application.data_for_year[property].ajustado)
            curveYAD.push(responseCurveS.data.Year_application.data_for_year[property].ajustado_dev)
        }
    }
    for (const property in responseCurveS.data.Year_application.point_inflection) {
        pointInflectionX.push(parseInt(Math.floor(property)))
        pointInflectionY.push(parseInt(responseCurveS.data.Year_application.point_inflection[property]))
    }
    var data = {
        curvsx: curvsx,
        curveYP: curveYP,
        curveYA: curveYA,
        curveYAD: curveYAD,
        pointInflectionX: pointInflectionX,
        pointInflectionY: pointInflectionY
    };
    return data;
}
const patentIpc = async (search) => {
    const rta = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=marks_ipc_graph`);

    var data = {
        x: rta.data.x,
        y: rta.data.y,
        mName: rta.data.name
    };
    return data;
}
const patentTimeSerie = async (search, graphic) => {
    const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/lines/time_s_month&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
    var publicX = [];
    var publicY = [];
    var apliX = [];
    var apliY = [];

    var finishDate = new Date();
    if (graphic.dateFinish === null) {
        finishDate = new Date('2030-01-01');
    } else {
        finishDate = graphic.dateFinish;
    }
    var convertProperty = new Date();
    for (const property in responsePatents.data.Month_application) {
        convertProperty = new Date(property);
        if (graphic.dateStart <= convertProperty && finishDate >= convertProperty) {
            apliX.push(property)
            apliY.push(responsePatents.data.Month_application[property])
        }
    }
    for (const property in responsePatents.data.Month_publication) {
        convertProperty = new Date(property);
        if (graphic.dateStart <= convertProperty && finishDate >= convertProperty) {
            publicX.push(property)
            publicY.push(responsePatents.data.Month_publication[property])
        }
    }
    var data = {
        publicX: publicX,
        publicY: publicY,
        apliX: apliX,
        apliY: apliY
    };

    return data;
}

const patentByPublicationYear = async (search, graphic) => {

    try {
        if (graphic.type === 'tree') {
            const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=graph_patents_pub_year`);
            let data = [];
            responsePatents.data.patentes.forEach(item => {
                data.push({
                    "label": item.date,
                    "value": item.count
                })
            })
            console.log('------------------------->', data);
            return data;
        }

        const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=graph_patents_pub_year`);
        console.log('responsePatents', responsePatents);
        let labels = [];
        let data = [];
        responsePatents.data.patentes.forEach(item => {
            labels.push(item.date);
            data.push(item.count);
        })
        return { labels: labels, data: data }
    } catch (error) {

    }

}

const articlesByPublicationYear = async (search, graphic) => {
    try {
        if (graphic.type === 'tree') {
            const responseArticles = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=graph_articles_pub_date`);
            let data = [];
            responseArticles.data.articulos.forEach(item => {
                data.push({
                    "label": item.date,
                    "value": item.count
                })
            })
            console.log('------------------------->', data);
            return data
        }

        const responseArticles = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=graph_articles_pub_date`);
        console.log('responseArticles', responseArticles);
        let labels = [];
        let data = [];
        responseArticles.data.articulos.forEach(item => {
            labels.push(item.date);
            data.push(item.count);
        })
        return { labels: labels, data: data }
    } catch (error) {

    }
}

const publicationAplicationYearPatent = async (search, graphic) => {
    try {
        const responsePatents = await clienteAxios.get(`https://vtsas.co/diagnosis/api/bridge?url=patents/graphics/bars/time_s_year&q=${search}&key=ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo`);
        console.log('responseArticles', responsePatents);

        let year = [];
        let dataYearAplication = [];
        for (const property in responsePatents.data.Year_application) {
            year.push(property.substring(0, 4));
            dataYearAplication.push(responsePatents.data.Year_application[property])
        }

        let dataYearPublication = [];
        for (const property in responsePatents.data.Year_publication) {
            dataYearPublication.push(responsePatents.data.Year_publication[property])
        }

        return { _labels: year, data: {dataYearAplication: dataYearAplication, dataYearPublication: dataYearPublication } }

    } catch (error) {

    }
}

const setGraphic = graphic => ({
    type: SET_GRAPHIC,
    payload: graphic
});

const cleanGraphicToDraw = graphic => ({
    type: CLEAN_GRAPHIC_ARRAY,
    payload: graphic
});

const addGraphicArray = graphic => ({
    type: ADD_GRAPHIC_ARRAY,
    payload: graphic
});

const deleteGraphic = id => ({
    type: DELETE_GRAPHIC,
    payload: id
});

const lockGraphic = newGraphic => ({
    type: LOCK_GRAPHIC,
    payload: newGraphic
});

const loadingGraphic = (load) => ({
    type: LOADING_GRAPHIC,
    payload: load
});