import React, { useState, useEffect } from 'react'
import translate from '../../../../../../../hooks/useTranslate'
import './bibliografia.scss';

const Bibliografia = (props) => {

    const [text, setText] = useState('');

    useEffect(() => {

        let originalText = (props.summary !== '' && props.summary !== undefined) ?
            props.summary : (props.description !== undefined && props.description !== '') ?
                props.description : ''

        if (props.translate === true) {

            translateText(originalText)
            return;
        }
        setText(originalText);

    }, [props.description, props.translate])

    const translateText = (textToTranslate) => {

        translate(textToTranslate).then(response => {
            setText(response)
        });
    }

    return (
        <div className="bibliografiaMain">
            <div className="row pt-5">
                {props.pubDate !== undefined &&
                    <div className="item"><h6>Fecha de publicación:</h6><p>{props.pubDate !== undefined && props.pubDate.substr(0, 10)}</p></div>
                }
                <div className="item">
                    {props.autor !== undefined &&
                        <div>
                            <h6>Autor:</h6>
                            <p>{props.autor !== undefined && props.autor.map(author => {
                                author = author.replace('surname:', "");
                                author = author.replace('firstname:', "");
                                author = author.split(' ')
                                return author[1] + ' ' + author[0] + ', ';
                            })}</p>
                        </div>
                    }
                </div>
                <hr />
                {props.summary !== '' && props.summary !== undefined ?
                    <div>
                        <h6>Abstract:</h6>
                        {/* <p style={{ color: 'black' }}>{props.summary}</p> */}
                        <p style={{ color: 'black' }}>{text}</p>
                    </div> :
                    <div>
                        <h6>Descripción:</h6>
                        {/* {(props.description !== undefined && props.description !== '') &&
                            props.description[0].split('\n').map(paragraph => {
                                return (
                                    <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                        {paragraph}
                                    </p>
                                );
                            })

                            
                        } */}
                        {text !== '' &&
                            Array.isArray(text) ?
                            text.map(paragraph => {
                                return (
                                    <p className="clear" style={{ color: 'black', textAlign: 'justify' }}>
                                        {paragraph}
                                    </p>
                                );
                            }) :
                            <p>{text}</p>
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default Bibliografia
