import React from 'react'
import TrendsBar from '../../../../../../graphics/d3/TrendGraphic/TrendsBar'
import DobleBar from '../../../../../../graphics/google/DobleBarHori'
import './horizontalBar.scss'

const HorizontalBars = ({graphic,clickImage}) => {
    let comp;
        if(graphic.data.total === 0){
            comp =
            <>
                <h1>No tenemos resultados para analizar esta busqueda</h1>
            </>
        }
        else if(graphic.name === 'Año de aplicación'){
            comp=
            <DobleBar
                data={graphic.data.items}
            />
        }
        else{
            comp=
            <TrendsBar 
                    trends={graphic.data.items} 
                    clickImage={clickImage}
            />
        }
    return ( 
        <>               
            {graphic.type === 'horizontal' && comp} 
        </>
     );
}
 
export default HorizontalBars;