import React, { useState, useEffect } from 'react'
import './claimsTab.scss';
import translateText from '../../../../../../../hooks/useTranslate'
import { useSelector } from 'react-redux';

const ClaimsTab = ({ translate }) => {

    const patent = useSelector(state => state.home.resultSelected);
    const [claims, setCLaims] = useState(patent.claims_en);

    useEffect(() => {
        if (!translate) {
            setCLaims(patent.claims_en)
            return;
        }

        let claimsString = ''
        claims.map(claim => {
            claimsString = claimsString + '\n\n' + claim;
        })

        translateText(claimsString).then(response => {
            setCLaims(response)
        });

    }, [translate, patent])

    return (
        <div className='claimsTabMain'>
            <br />
            <br />
            <div style={{ overflowY: 'scroll', height: '60rem' }}>
                {claims &&
                    claims.map((claim, i) => {
                        return (
                            <p style={{ color: 'black' }}>
                                {/* <strong>{i+1}</strong>. */}
                                {claim}
                            </p>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ClaimsTab
