import React from 'react'
import { Link } from 'react-router-dom'


const ArticleItem = ({article}) => {
    return (    
        <>
            <h1>{article.name}</h1>
            <h4>Pais: {article.country_code}</h4>
            <h4>Region: {article.region}</h4>
            <h4>Ciudad: {article.city}</h4>
            <Link to={{pathname: `/articlepage/${article.id}`}}>
                <button className='btn btn-outline-dark'>Conoce mas</button>
            </Link>     
        </>
      );
}
 
export default ArticleItem;