/////////PROFILE//////////////
export const LOAD_OPTIONS = 'LOAD_OPTIONS';
export const LOAD_OPTIONS_USER = 'LOAD_OPTIONS_USER';
export const ADD_INTEREST = 'ADD_INTEREST';
export const ADD_SECTOR = 'ADD_SECTOR';
export const ADD_PROFESSION = 'ADD_PROFESSION';
export const ADD_STUDY = 'ADD_STUDY';
export const REMOVE_INTEREST = 'REMOVE_INTEREST';
export const REMOVE_SECTOR = 'REMOVE_SECTOR';
export const REMOVE_PROFESSION = 'REMOVE_PROFESSION';
export const REMOVE_STUDY = 'REMOVE_STUDY';
export const GET_RECOMMENDATION = 'GET_RECOMMENDATION';
export const LOG_OUT_PROFILE = 'LOG_OUT_PROFILE';