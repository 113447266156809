import React,{ useState,useEffect } from 'react'
import AdvancedSearchItem from './AdvancedSearchItem'
import ClienteAxion from '../../../config/axios';

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getPatentsAction } from '../../../../actions/patentAction'


const AdvancedSearchBox = () => {

    //redux store
    const dispatch = useDispatch();
    const search = useSelector(state => state.patent.search);//total de resultados por busqueda del usuario
    const equation = useSelector(state => state.patent.equation);//ecuacion creada por el usuario

    /**States */
    const [advancedSearch,setAdvancedSearch] = useState([]);//state para genera el array de la busqueda general
    const [endpointArray,setEndpointArray] = useState([]);//state de array para introducion los endpoint de cada palabra
    const [endpoint,setEndpoint] = useState([]);//state donde se guardara la ecuacion para enviar a la api

    //con un cambio en el search, genera un nuevo array con cada palabra
    useEffect(() => {
        setAdvancedSearch(search.trim().split(" "));        
    }, [search])

    /**Funciones */
    //construye la ecuacion
    const setFinalEndPoint = (end,index) => {
        //end=endpoint del item a actualziarse en el indice indicado
        let arrayEndPoint = endpointArray;
        arrayEndPoint[index] = end;
        setEndpointArray(arrayEndPoint);
        
        //genera la ecuacion
        var equation = 'equation=(';
        endpointArray.forEach(function(end) {
            if(equation !== 'equation=('){
                equation=equation+'+AND+('+end+')'
            }else{
                equation=equation+'('+end+')'
            }
        });
        equation=equation+')';
        setEndpoint(equation);
    }

    //envia ecuacion a la api
    const sendEquation = () => {
        dispatch(getPatentsAction(0,endpoint));
    }

    //Guarda ecuacion en mysql
    const saveEquation = async() => {
        try {            
            const response = await ClienteAxion.post('patent/equation',equation);
            response.data === 1 ? alert('Ecuacion guardada correctamente') : alert('No se pudo guardar');
        } 
        catch (error) {
            alert('No se pudo guardar');
        }
    }

    return ( 
        <div className='row justify-content-center align-items-center'>
                <div className='w-50'>
                {advancedSearch.map((word,index) => (
                    <AdvancedSearchItem 
                        word={word}
                        index={index}
                        key={index}
                        setFinalEndPoint={setFinalEndPoint}
                    />
                ))}
                    <button className='btn btn-outline-dark' onClick={sendEquation}>Buscar</button>
                    {equation !== '' ? <button className='btn btn-outline-dark' onClick={saveEquation}>Guardar ecuacion</button> : ''}
                </div>
        </div>
     );
}
 
export default AdvancedSearchBox;