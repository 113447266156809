import React, { useState, useEffect } from 'react'
import warning from '../../../images/icons/ui/general/warningImage.png'
import Modal from 'react-modal';

const customStyles = {
    content: {
        width:'26%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const TranslateModal = ({ open, setTranslate, translate, setOpen }) => {
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        if (translate) {
            setTranslate(false)
            setOpen(false);
            return;
        }
        setIsOpen(true);
    }

    useEffect(() => {
        if (open) {
            openModal();
        }

    }, [open])

    const closeModal = () => {
        setIsOpen(false);
        setOpen(false)
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div style={{display:'inline-flex'}}>
                <div style={{padding:'10px'}}>
                    <img style={{
                        marginLeft: 'auto', marginRight: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }} width="100" height="100" src={warning} />
                </div>
                <div>
                    <p>Este es un traductor automático que no ofrece
                        la mejor calidad de traducción.</p> 
                        <p style={{textAlign: 'center'}}>¿Desea continuar?</p>
                </div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <button onClick={() => { setTranslate(true); closeModal() }} style={{ backgroundColor: 'var(--primaryBlueSix)', color: '#fff', marginRight: '10px' }} className="btn btn-sm">Si</button>
                <button onClick={() => closeModal()} style={{ backgroundColor: 'var(--primaryBlueSix)', color: '#fff' }} className="btn btn-sm">No</button>
            </div>
        </Modal>
    );
}

export default TranslateModal