import React, { useEffect } from 'react'
import SearchBox from './searchBox/SearchBox'
import VideoItem from './videoItem/VideoItem'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getVideosAction } from '../../../actions/videoAction'

const VideosApp = () => {

    //redux
    const dispatch = useDispatch();
    const search = useSelector(state => state.video.search);
    const videos = useSelector(state => state.video.videos);

    //si el buscador queda en blanco hace llamado a la api
    useEffect(() => {
        if(search.trim() === '' || search.trim() === '*'){
            getVideos(0);
        }
    }, [search])

    //hace el llamado de traer empresas al endpoint
    const getVideos = async(pageSended) => {
        await dispatch(getVideosAction(pageSended));        
    }

    return ( 
        <>
            <SearchBox />
            <div className="row">
                <div className="col-3">
                    <h2>Hola soy filtros</h2>
                </div>
                <div className="col-9">
                    {videos.map((video,index) => (
                        <VideoItem 
                            video={video}
                            key={index}
                        />
                    ))

                    }
                </div>
            </div>
        </>
     );
}
 
export default VideosApp;