import React from 'react'
import Mark from '../marks/Mark'
import './colection.scss'

//redux
import {useSelector} from 'react-redux'

const Colection = ({colection}) => {
    const marks = useSelector(state => state.mark.marks)

    return (
        <div className='mainColection'>
            {/*{marks.map(mark => (
                mark.colection_id === colection.id && 
                    <Mark 
                        key={mark.id}
                        mark={mark}
                    />
            ))}*/}
            <div className='colectionItem'>
                {/* <div style={{display: 'inline-flex', width: '100%'}}>
                    <div className='colectionIcon'>
                        <img className='imageIcon' src={require('../../../../images/icons/colorModulesIcons/blueFolder.png')} width='10' height='8' alt="" />
                    </div>
                    <div className='colectionHeader'>
                        <h3 style={{color: 'var(--primaryBlueThree)', fontWeight: '400'}}>Colección</h3>
                    </div>
                    <div className='threePoints'>
                        <i class="fas fa-ellipsis-v"></i>
                    </div>
                </div> */}
                <div className='colectionItemInside'>
                    <div className='colectionTitle'>
                        <p style={{color: 'var(--primaryBlueFour)', fontWeight: '400'}}>
                            {colection.name}
                        </p>
                    </div>
                    <div className='colectionCreate'>
                        <h3 style={{fontWeight: '300', color: 'var(--primaryGreyFive)'}}>creado: {colection.dateCreated.substr(0,10)}</h3>
                    </div>
                    <div className='colectionContent'>
                        <h3 style={{fontWeight: '400', color: 'var(--primaryGreyFive)'}}>contenido: {colection.total}</h3>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Colection;