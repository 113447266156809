import React,{useEffect,useRef} from "react";

const VideoConference = ({roomToGo,password,onClose}) => {
    const didMountRef = useRef(false);
    const [jitsi, setJitsi] = React.useState({});
    useEffect(() => {
      if (didMountRef.current){
        //jitsi.executeCommand('displayName', 'Carlos Ariza Ocazionez');
        jitsi.addEventListener('participantRoleChanged', function(event) {
          if (event.role === "moderator") {
              jitsi.executeCommand('password', password);
              jitsi.executeCommand('user', 'el experto');
          }
        });
        jitsi.on('passwordRequired', function ()
        {
            jitsi.executeCommand('password', password);
            jitsi.executeCommand('user', 'el cliente');
        });
        jitsi.addEventListener('readyToClose', function(event) {
            onClose();
        });
      }else{
          didMountRef.current = true;
      }      
    }, [jitsi])
    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;
    
        const loadJitsiScriptPromise = new Promise((resolve) => {
          resolveLoadJitsiScriptPromise = resolve;
        });
    
        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.async = true;
        script.onload = resolveLoadJitsiScriptPromise
        document.body.appendChild(script);
    
        return loadJitsiScriptPromise;
      };
  const jitsiContainerId = "jitsi-container-id";

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }
    const options = {
      roomName: roomToGo,
      parentNode: document.getElementById(jitsiContainerId)
    }
    const _jitsi = new window.JitsiMeetExternalAPI("streaming.lantiasas.com",options);

    setJitsi(_jitsi)
  };

  React.useEffect(() => {
    initialiseJitsi();

    return () => jitsi?.dispose?.();
  }, []);

  return <div id={jitsiContainerId} style={{ height: 720, width: "100%" }} />;
};

export default VideoConference;