import {
    LOADING,
    LOGIN_SUCCESS,
    LOG_OUT,
    LOGIN_FAIL,
    SEND_OPTIONS
} from '../types/authTypes';

//state
const initialState = {
    userId:null,
    token:null,
    firstname:null,
    lastname:null,
    email:null,
    phone:null,
    profiled:null,
    loading:false,
    autenticated:-1,
    reload:true,
    img_profile:null,
    rememberToken:-1,
    apiKey:'ZYfZ9e4VBfUwuWbNf1doscjH3C8desarrollo',
    role:-1
}

export default function(state = initialState, action){
    switch(action.type){

        case LOADING:
            return{
                loading:action.payload.load,
                error:action.payload.error
            }

        case LOGIN_SUCCESS:
            return{
                ...state,
                userId:action.payload.user.id,
                token:action.payload.token,
                firstname:action.payload.user.firstname,
                lastname:action.payload.user.lastname,
                email:action.payload.user.email,
                phone:action.payload.user.phone,
                profiled:action.payload.user.profiled,
                img_profile:action.payload.user.profile_image,
                loading:false,
                autenticated:true,
                reload:true,
                rememberToken:action.payload.user.remember_login,
                role:action.payload.user.role
            }

        case LOGIN_FAIL:
            return{
                ...state,
                autenticated:false
            }

        case LOG_OUT:
            return{
                ...state,
                token:null,
                firstname:null,
                lastname:null,
                email:null,
                phone:null,
                loading:false,
                autenticated:-1,
                rememberToken:-1
            }
        
        case SEND_OPTIONS:
            return{
                profiled:1
            }

        default:
            return state;
    }
}