import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import translateText from '../../../../../../../hooks/useTranslate'

const DescriptionTab = ({ translate }) => {
    
    const patent = useSelector(state => state.home.resultSelected);
    const [description, setDescription] = useState(patent.descriptions_en);

    useEffect(() => {
        if (!translate) {
            setDescription(patent.descriptions_en)
            return;
        }

         translateText(description).then(response => {
            setDescription(response)
        });
        
    }, [translate, patent])

    return (
        <div className='descriptionTabMain'>
            <br />
            <br />
            <div style={{ overflowY: 'scroll', height: '60rem', color: 'black' }}>
                {description &&
                    description.map(item => {
                        return (<p style={{ color: 'black' }}>
                            {item}
                        </p>)
                    })
                }
            </div>
        </div>
    )
}

export default DescriptionTab
