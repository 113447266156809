import React from 'react'

const UserCompany = ({setCompany,setCompanySignup}) => {
    
    const userCompanySelected = (selected) => {
        setCompany(selected);
        selected === 1 && setCompanySignup(true);
    } 
    
    return ( 
        <>
            <div className="row justify-content-center align-items-center mt-5 ">
                <button
                        className='btn btn-primary mr-3'
                        onClick={() => userCompanySelected(0)}
                    >
                        Registrarse como usuario  
                </button>
                <button
                        className='btn btn-primary ml-3'
                        onClick={() => userCompanySelected(1)}
                    >
                        Registrarse como empresa  
                </button>
            </div>
          
        </>
     );
}
 
export default UserCompany;