import React, { useState, useEffect } from 'react'
import './carouselRelated.scss'
import ItemsCarousel from 'react-items-carousel';
import CarrouselThree from '../../../../../ui/skeletonTemplates/carrouselThree'
import processData from '../../../../../../hooks/useProccessData'
import { registerSelected } from '../../../../../../actions/homeAction';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const CarouselRelated = ({ itemsArray, title, relatedSelected }) => {

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();

    const imageUnavailable = "https://www.hongshen.cl/wp-content/uploads/2016/07/no-disponible.png";

    const resultSelet = (item) => {
        item.related = itemsArray;
        relatedSelected(item)
        dispatch(registerSelected(item));
        history.push('/resultSelected')
        window.scrollTo(0, 250)
    }

    return (
        <>
            <div className='carousel-searches'>
                <h1 className='mt-1'>{title}</h1>
                {itemsArray.length > 0 ?
                    <div style={{ padding: `0 40px` }}>
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={3}
                            gutter={20}
                            leftChevron={<i className="fas fa-angle-left fa-4x arrowIcon"></i>}
                            rightChevron={<i className="fas fa-angle-right fa-4x arrowIcon"></i>}
                            outsideChevron
                            chevronWidth={40}
                            infiniteLoop={true}
                        >
                            {processData(itemsArray).map(item => {
                                return (
                                    <div onClick={() => resultSelet(item)} className='carousel-searches-item text-align-left' style={{ cursor: 'pointer' }}>
                                        <h3 className='carousel-title'>
                                            <strong>
                                                {Array.isArray(item.title) ? item.title[0].substring(0, 40) : item.title.substring(0, 40)}...
                                    </strong>
                                        </h3>
                                        {['new', 'video', 'startup'].includes(item.category) &&
                                            <div className='carrouselImg'>
                                                <img style={{ width: '100% !important', height: item.imageHeight }} className='imageRelatedBox' src={item.imageUrlFail ? imageUnavailable : item.thumbnail} alt="" />
                                            </div>
                                        }
                                        <div style={{ marginTop: '3rem' }}>
                                            <h3>
                                                {Array.isArray(item.description) ? item.description[0].substring(0, 80) : item.description.substring(0, 80)}
                                            </h3>
                                            {item.source &&
                                                <div>
                                                    <h3>Fuente:{item.source}</h3>
                                                    <h3 className='text-left pl-1'>
                                                        {Array.isArray(item.datePublication) ? item.datePublication[0].substring(0, 80) : item.datePublication.substring(0, 80)}
                                                    </h3>
                                                    <h3 className='carousel-more text-left pl-1 mt-3'>ver mas</h3>
                                                </div>
                                            }
                                            {item.thumbnail &&
                                                <h3 style={{ color: '#7B9CC6' }}>
                                                    {/* Actualizado: */}
                                                </h3>}
                                        </div>
                                    </div>
                                )

                            }

                            )}
                        </ItemsCarousel>
                    </div>
                    :
                    <CarrouselThree />}
            </div>

        </>
    );
}

export default CarouselRelated;