import React from 'react'
import { Link } from 'react-router-dom'


const CompanyItem = ({company}) => {
    return (    
        <>
            <h1>{company.name}</h1>
            <h4>Pais: {company.country_code}</h4>
            <h4>Region: {company.region}</h4>
            <h4>Ciudad: {company.city}</h4>
            <Link to={{pathname: `/companypage/${company.id}`}}>
                <button className='btn btn-outline-dark'>Conoce mas</button>
            </Link>            
        </>
      );
}
 
export default CompanyItem;