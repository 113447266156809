import {
    LOAD_OPTIONS,
    ADD_INTEREST,
    ADD_SECTOR,
    ADD_PROFESSION,
    ADD_STUDY,
    REMOVE_INTEREST,
    REMOVE_SECTOR,
    REMOVE_PROFESSION,
    REMOVE_STUDY,
    LOAD_OPTIONS_USER,
    GET_RECOMMENDATION
} from '../types/profileTypes';
import clienteAxios from '../components/config/axios';

//funcion para traer todas las opciones a escoger de la bd
export function getOptionsAction(){    
    return async(dispatch) => {
        try {
            //endpoint de la bd
            const response = await clienteAxios.get('profile');
            //Guarda en redux storage
            dispatch(getOption(response.data))
            return 1;
        } catch (error) {

        }
    }
}

//funcion para traer opciones escogidas del usudario de la bd
export function getUserOptionsAction(){    
    return async(dispatch) => {
        try {
            //endpoint de la bd
            const response = await clienteAxios.get('profile/user');
            //Guarda en redux storage
            dispatch(getOptionUser(response.data))
            return 1;
        } catch (error) {

        }
    }
}

//funcion para agregar opciones al perfil
export function setOptionsAction(option){    
    return async(dispatch) => {
        try {

            if(option.optionType === 'interest'){
                option.action === 'add' ? dispatch(addInterest(option.id)) :  dispatch(removeInterest(option.id))
            }
            else if(option.optionType === 'sector'){
                option.action === 'add' ? dispatch(addSector(option.id)) :  dispatch(removeSector(option.id))
            }
            else if(option.optionType === 'profession'){
                option.action === 'add' ? dispatch(addProfession(option.id)) :  dispatch(removeProfession(option.id))
            }
            else if(option.optionType === 'study'){
                option.action === 'add' ? dispatch(addStudy(option.id)) :  dispatch(removeStudy(option.id))
            }
            return 1;
        } catch (error) {
        }
    }
}

//trae las recomendaciones
export function getRecommendationAction(recomendation){    
    return async(dispatch) => {
        try {
            dispatch(getRecommendation(recomendation))
            return 1;
        } catch (error) {

        }
    }
}


//carga todas las opciones
const getOption = options => ({
    type:LOAD_OPTIONS,
    payload:options
});

//carga opciones del usuario
const getOptionUser = options => ({
    type:LOAD_OPTIONS_USER,
    payload:options
});

//agrega interest
const addInterest = option => ({
    type:ADD_INTEREST,
    payload:option
});

//agrega sector
const addSector = option => ({
    type:ADD_SECTOR,
    payload:option
});

//agrega profesion
const addProfession = option => ({
    type:ADD_PROFESSION,
    payload:option
});

//agrega estudio
const addStudy = option => ({
    type:ADD_STUDY,
    payload:option
});

//elimina interest
const removeInterest = option => ({
    type:REMOVE_INTEREST,
    payload:option
});

//elimina sector
const removeSector = option => ({
    type:REMOVE_SECTOR,
    payload:option
});

//elimina profesion
const removeProfession = option => ({
    type:REMOVE_PROFESSION,
    payload:option
});

//elimina estudio
const removeStudy = option => ({
    type:REMOVE_STUDY,
    payload:option
});

//trae recomendaciones
const getRecommendation = recommendation => ({
    type:GET_RECOMMENDATION,
    payload:recommendation
});