import {
    GET_COMPANIES,
    SET_SEARCH,  
} from '../types/companyTypes';

//state
const initialState = {
    search:'',
    page:0,  //pagina actual para el envio del endpoint
    companies:[], //se guardan las empresas    
    results:0, //total de empresas encontradas
}

export default function(state = initialState, action){
    switch(action.type){

        case GET_COMPANIES:
            return{
                ...state,
                companies:action.payload.items,
                page:action.payload.page
            }
        
        /**BUSQUEDAS */
        case SET_SEARCH:
            return{
                ...state,
                search:action.payload
            }

        default:
            return state;
    }
}