import React from 'react'
import Slider from "react-slick";
import './startups.scss'

const Startups = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 2,
        vertical: true,

    };

    return (<div className='sectionHome homeStartup'>
        <div className="lantia-square smallHeight" style={{ height: '300px' }}>
            {props.startups !== null &&
                <Slider {...settings}>
                    {props.startups.map((startup) => (
                        <div style={{ height: '100%' }}>
                            <div className=''>
                                <div className="item" style={{ width: '80%', marginLeft: '10%' }}>
                                    <p className='title'>{startup.title}</p>
                                    <p className='description'>{startup.description[0].substr(0,60)}...</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            }
        </div>
    </div>);
}
export default Startups;