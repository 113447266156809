import React, {useEffect, useState} from 'react';
import data2grid from 'data2grid';
import jeezy from 'jeezy';
import chroma from 'chroma-js';
import ReactTooltip from "react-tooltip";
import * as d3 from 'd3v5';


const CorrelationGraphic = (props) => {


    const chart = React.useRef();
    const [tooltipContent, setTooltipContent] = useState("");

  
    useEffect(() => {
        renderChart();
    }, [props.data])

    const renderChart = () => {
        let cols = [];
        let data = [];
        props.data.map(d => {
            for(var i in d) {
                cols.push(i);
                data.push(d[i])
            }
        });
        d3.select("svg").append("div").attr("class", "tip").style("display", "none");

        var jz = require("jeezy");
        var data2grid = require("data2grid");
        var data2 = [];
        
        data.forEach((x, indice) => {
            var obj = {index: indice};
            cols.forEach((col, i) => {
                obj[col] = x[i]
            });
            data2.push(obj);

        }
        );
        //}
        var corr = jz.arr.correlationMatrix(data2, cols);
        var extent = d3.extent(corr.map(function (d) {return d.correlation;}).filter(function (d) {return d !== 1;}));

        var grid = data2grid.grid(corr);
        var rows = d3.max(grid, function (d) {return d.row;});

        var margin = {top: 20, bottom: 1, left: 80, right: 1};

        var dim = d3.min([window.innerWidth * 1.5, window.innerHeight * 1.3]);

        var width = dim - margin.left - margin.right, height = dim - margin.top - margin.bottom;

        var svg = d3.select("#grid").append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

        var padding = .1;

        var x = d3.scaleBand()
            .range([0, width])
            .paddingInner(padding)
            .domain(d3.range(1, rows + 1));

        var y = d3.scaleBand()
            .range([0, height])
            .paddingInner(padding)
            .domain(d3.range(1, rows + 1));

        var c = chroma.scale(["tomato", "white", "steelblue"])
            .domain([extent[0], 0, extent[1]]);

        var x_axis = d3.axisTop(y).tickFormat(function (d, i) {return cols[i];});
        var y_axis = d3.axisLeft(x).tickFormat(function (d, i) {return cols[i];});

        svg.append("g")
            .attr("class", "x axis")
            .call(x_axis);

        svg.append("g")
            .attr("class", "y axis")
            .call(y_axis);

        svg.selectAll("rect")
            .data(grid, function (d) {return d.column_a + d.column_b;})
            .enter().append("rect")
            .attr("x", function (d) {return x(d.column);})
            .attr("y", function (d) {return y(d.row);})
            .attr("width", x.bandwidth())
            .attr("height", y.bandwidth())
            .style("fill", function (d) {return c(d.correlation);})
            .style("opacity", 1e-6)
            .transition()
            .style("opacity", 1);

        svg.selectAll("rect")
            .on('mouseover', function (d) {return setTooltipContent(`(${d.column_x},${d.column_y}) correlación: ${d.correlation}`)})
            .on('mouseout', function () {return setTooltipContent('')});


        var legend_top = 15;
        var legend_height = 15;

        var legend_svg = d3.select("#legend").append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", legend_height + legend_top)
            .append("g")
            .attr("transform", "translate(" + margin.left + ", " + legend_top + ")");

        var defs = legend_svg.append("defs");

        var gradient = defs.append("linearGradient")
            .attr("id", "linear-gradient");

        var stops = [{offset: 0, color: "tomato", value: extent[0]}, {offset: .5, color: "white", value: 0}, {offset: 1, color: "steelblue", value: extent[1]}];

        gradient.selectAll("stop")
            .data(stops)
            .enter().append("stop")
            .attr("offset", function (d) {return (100 * d.offset) + "%";})
            .attr("stop-color", function (d) {return d.color;});

        legend_svg.append("rect")
            .attr("width", width)
            .attr("height", legend_height)
            .style("fill", "url(#linear-gradient)");

        legend_svg.selectAll("text")
            .data(stops)
            .enter().append("text")
            .attr("x", function (d) {return width * d.offset;})
            .attr("dy", -3)
            .style("text-anchor", function (d, i) {return i == 0 ? "start" : i == 1 ? "middle" : "end";})
            .text(function (d, i) {return d.value.toFixed(2) + (i == 2 ? ">" : "");})
    }


    return (
        <>
            <div data-tip="" ref={chart}>
                <div id="legend"></div>
                <div id="grid"></div>
                <ReactTooltip>{tooltipContent}</ReactTooltip>
            </div>
        </>);
}

export default CorrelationGraphic;