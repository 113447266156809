import React, { useEffect } from 'react'
import SearchBox from './searchBox/SearchBox'
import ArticleItem from './articleItem/ArticleItem'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getArticlesAction } from '../../../actions/articleAction'

const VideosApp = () => {

    //redux
    const dispatch = useDispatch();
    const search = useSelector(state => state.article.search);
    const articles = useSelector(state => state.article.articles);

    //si el buscador queda en blanco hace llamado a la api
    useEffect(() => {
        if(search.trim() === '' || search.trim() === '*'){
            getArticles(0);
        }
    }, [search])

    //hace el llamado de traer empresas al endpoint
    const getArticles = async(pageSended) => {
        await dispatch(getArticlesAction(pageSended));        
    }

    return ( 
        <>
            <SearchBox />
            <div className="row">
                <div className="col-3">
                    <h2>Hola soy filtros</h2>
                </div>
                <div className="col-9">
                    {articles.map((article,index) => (
                        <ArticleItem 
                            article={article}
                            key={index}
                        />
                    ))

                    }
                </div>
            </div>
        </>
     );
}
 
export default VideosApp;