import React, { useState, useEffect } from "react";
import Preference from "./Preference";
import recurso17 from "../../../images/icons/form register/Recurso 17.png";

//redux
import { useSelector } from "react-redux";

const PageTwo = ({ optionsectors, optionstudies, changePage, addModal }) => {
    //redux dispatch and redux variables
    const usersectors = useSelector(
        (state) => state.profile.usersectors
    );
    const userstudies = useSelector((state) => state.profile.usersectors);

    //state
    const [error, setError] = useState({
        professionError: false,
        studyError: false,
    });


    //valida que se halla seleccionado al menos uno de cada uno
    const clickValidation = () => {
        if (usersectors.length > 0) {
            setError({ ...error, studyError: false });
            addModal(1);
            // if (userstudies.length > 0) {
            // } else {
            //     setError({ ...error, professionError: false, studyError: true });
            // }
        }
        //  else if (userstudies.length === 0) {
        //     setError({ ...error, professionError: true, studyError: true });
        // }
        else {
            setError({ ...error, professionError: true });
        }
    };

    // Esta función trae los elementos seleccionados de la tabla ec_sectors desde el id 52, hasta el id 85
   
    // tabla

    //funciones
    return (
        <>
            <div className="title1">
                <h4 className="white-title">3. INFORMACIÓN EMPRESARIAL</h4>
            </div>
            <div className="slide-page-profiler">
                {/**Segunda Pagina de seleccion profesiones y estudios*/}
                <div className="row">
                    {/* <div className="section-introduction col-md-12 col-lg-12 col-xl-12">
                    <h1>¡Bienvenido a plataforma Lantia!</h1>
                    <p>
                        Ahora cuéntanos en que te desempeñas y cuales son tus estudios.
                    </p>
                </div> */}

                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="row no-gutters">
                            <div
                                className="section-profile col-md-12 col-lg-12 col-xl-12"
                                id="section-profession"
                                style={{ borderRadius: "10px" }}
                            >
                                {/* <div className="section-box-header">
                                <div className="section-header-ico">
                                    <span className="fas fa-user-tie"></span>
                                </div>
                                <div className="section-header-title">
                                </div>
                            </div> */}
                                <div className="section-box-header">
                                    <img
                                        src={recurso17}
                                        className="pencils"
                                        alt="intereses"
                                    ></img>
                                    <h4 className="blue-title">
                                        Áreas de interés{" "}
                                    </h4>
                                </div>
                                <div className="section-box-body row">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        <p>
                                            ¿ Sobre cuáles áreas requiere
                                            información?
                                        </p>
                                        <div className="section-checkboxes row">
                                            {console.log('optionsectors', optionsectors)}
                                            {optionsectors.map(
                                                (sector) => (
                                                    <Preference
                                                        option={sector}
                                                        key={sector.id}
                                                        optionType="sector"
                                                        selected={
                                                            usersectors
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                        {error.professionError ? (
                                            <div
                                                className="alert alert-danger lnt-alert"
                                                role="alert"
                                            >
                                                No has escogido ninguna
                                                área.
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="section-profile col-md-12 col-lg-12 col-xl-12" id="section-studies">
                            <div className="section-box-header">
                                <div className="section-header-ico">
                                    <span className="fas fa-graduation-cap"></span>
                                </div>
                                <div className="section-header-title">
                                    <h1>Estudios</h1>
                                </div>
                            </div>
                            <div className="section-box-body row">
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <p>
                                        De las siguientes áreas, ¿A cuáles están asociados tus estudios realizados?
                                    </p>
                                    <div className="section-checkboxes row">
                                        {optionstudies.map(study => (
                                            <Preference
                                                option = {study}
                                                key = {study.id}
                                                optionType = 'study'
                                                selected = {userstudies}
                                            />
                                        ))}
                                    </div>
                                    {error.studyError ? 
                                        <div className="alert alert-danger lnt-alert" role='alert'>
                                         No has escogido ningún estudio
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div> */}
                            <div className="col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-secondary me-4"
                                    onClick={() => changePage(0)}
                                >
                                    Atrás
                                </button>
                                <button
                                    className="btn btn-success"
                                    onClick={clickValidation}
                                >
                                    Siguiente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/** Termina row principal de la segunda pagina de seleccion */}
            </div>
        </>
    );
};

export default PageTwo;
