import React, {useState, useEffect} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {messages} from '../helpers/calendar-messages-es';
import {CalendarEvent} from './CalendarEvent';
import {CalendarModal} from './calendarModal/CalendarModal';
import {useHistory} from 'react-router-dom';
import {uiOpenModal} from '../../../../actions/uiAction';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import {eventSetActive, eventClearActiveEvent} from '../../../../actions/eventsAction';
import {AddNewFab} from '../ui/AddNewFab';
import {DeleteEventFab} from '../ui/DeleteEventFab';

moment.locale('es');

const localizer = momentLocalizer(moment);

const CalendarScreen = () => {
    const dispatch = useDispatch();
    const {events, activeEvent} = useSelector(state => state.calendar);
    const expertSelected = useSelector(state => state.experts.expertSelected);
    const schedule = useSelector(state => state.calendar.goSchedule);
    const [lastView, setLastView] = useState(localStorage.getItem('lastView') || 'month');
    const history = useHistory();

    useEffect(() => {
        if(Object.keys(expertSelected).length === 0 && !schedule) {
            history.push('/expertApp')
        }
    }, [])

    //const onDoubleClick = (e) => {
    //    dispatch(uiOpenModal());
    //}

    const onSelectEvent = (e) => {
        dispatch(uiOpenModal());
        dispatch(eventSetActive(e));
    }

    const onViewChange = (e) => {
        setLastView(e);
        localStorage.setItem('lastView', e);
    }

    const onSelectSlot = (e) => {
        dispatch(eventClearActiveEvent());
    }


    const eventStyleGetter = (event, start, end, isSelected) => {

        const style = {
            backgroundColor: '#367CF7',
            borderRadius: '0px',
            opacity: 0.8,
            display: 'block',
            color: 'white'
        }


        return {
            style
        }
    };

    const redirectStreaming = () => {
        history.push('/expertApp/streaming')
    }

    return (
        <div className="calendar-screen">
            {schedule ?
                <h3>Agenda</h3> :
                <h3>{`Asignar cita con el experto: ${expertSelected.firstname} ${expertSelected.lastname}`}</h3>
            }
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                messages={messages}
                eventPropGetter={eventStyleGetter}
                //onDoubleClickEvent={onDoubleClick}
                onSelectEvent={onSelectEvent}
                onView={onViewChange}
                onSelectSlot={onSelectSlot}
                selectable={true}
                view={lastView}
                components={{
                    event: CalendarEvent
                }}
            />


            {
                !schedule && <AddNewFab />
            }

            <CalendarModal />

        </div>
    )
}
export default CalendarScreen;