import React, { useState, useEffect } from 'react'
import clienteAxios from '../../../config/axiosBigData';


const PatentPage = ({ ...props }) => {

    const [patent,setPatent] = useState({});

    useEffect(() => {
        getPatent();
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const getPatent = async() => {
        const response = await clienteAxios.get(`patente?id=${props.computedMatch.params.id}`);
        setPatent(response.data);
    }

    return ( 
        <>  
            {Object.keys(patent).length === 0  
                ? (<h1>Loading</h1>) 
                : 
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="row no-gutters">
                <div className="col-md-8 col-lg-8 col-xl-8 border-right pl-3 pr-3">
                    <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>{patent.invention_title_en}</h1>
                        </div>
                        <span className="spanSectionTitle"><b>Publicada:</b> {patent.date_publ}</span>
                        <span className="spanSectionTitle"><b>Solicitada:</b> {patent.application_date}</span>
                        <span className="spanSectionTitle"><b>Claims:</b> {patent.num_claims}</span>
                        </div>
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Abstract</h1>
                            <p>{patent.abstract_en}</p>
                        </div>
                        </div>
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Claims</h1>
                            <ol>
                            {patent.claims_en.map((e, i) => {
                                return <li>{e}</li>
                            })}
                            </ol>
                        </div>
                        </div>
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Descripción</h1>
                            {patent.descriptions_en.map((e, i) => { return <p>{e}</p> })}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 col-lg-4 col-xl-4 pl-3 pr-3">
                    <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Información sobre la patente</h1>
                            <p>{patent.id}</p>
                        </div>
                        </div>
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Clasificación IPC</h1>
                            {patent.ipcs.map((e, i) => {
                            return <span className="boxIPC">{e}</span>
                            })}
                        </div>
                        </div>
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Propietarios</h1>
                            <ul>
                            {patent.assignee_name.map((e, i) => {
                                return <li>{e}</li>
                            })}
                            </ul>
                        </div>
                        </div>
                        <div className="patentSection">
                        <div className="patentTitleSection">
                            <h1>Solicitantes</h1>
                            <ul>
                            {patent.applicants.map((e, i) => {
                                return <li>{e}</li>
                            })}
                            </ul>
                        </div>
                        </div>
                        <div className="patentSection">
                       <div className="patentTitleSection">
                            <h1>Inventores</h1>
                            <ul>
                            {patent.inventors.map((e, i) => {
                                return <li>{e}</li>
                            })}
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            }
        </>
     );
}
 
export default PatentPage;