import React, { useState, useEffect } from 'react'
import Bibliografia from './bibliografiaTab/Bibliografia';
import Contenido from './contenidoTab/Contenido';
import { useSelector } from 'react-redux';
import './articleResult.scss';
import TranslateModal from '../../../../../ui/translateModal/TranslateModal'

const ArticleResult = (props) => {
    console.log('props', props);
    const article = useSelector(state => state.home.resultSelected);
    const [tab, setTab] = useState('Resumen')
    const [translate, setTranslate] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTranslate(false)
        setTab('Resumen')
    }, [props.title])

    return (
        <div className="articleResultMain">
            <div className="row">
                <div className="col-12 col-md-8 pt-3 pb-4">
                    <p style={{ color: 'var(--secundaryFirtFour)', fontSize: '23px' }}>{article.title}</p>
                </div>
                <div className="col-12 col-md-2"></div>
                <div className="col-12 col-md-2">
                    <button onClick={() => setOpen(true)} style={{ backgroundColor: 'var(--primaryBlueTwo)' }} className="btn btn-primary btn-sm">{translate ? 'Texto original' : 'Traducir al español'}</button>
                </div>
            </div>
            <div className="row">
                <ul style={{ display: 'inline-flex' }}>
                    <div style={{ width: '6%' }} className='besideLi'></div>
                    {
                        ['Resumen', 'Contenido'].map(x => {
                            return (
                                <li onClick={() => setTab(x)} className={tab === x ? 'active' : ''}>{x}</li>
                            );
                        })

                    }
                    <div style={{ width: '6%' }} className='besideLi'></div>
                </ul>
            </div>
            <div className="row">
                {tab === 'Resumen' && <Bibliografia translate={translate} autor={props.autor} pubDate={props.date} summary={props.summary} description={props.description} />}
                {tab === 'Contenido' && <Contenido translate={translate} description={props.description} />}
            </div>
            <TranslateModal setTranslate={setTranslate} translate={translate} open={open} setOpen={setOpen} />
        </div>
    )
}

export default ArticleResult
