const extractWordsOfEcuation = (ecuation) => {
    let andWords = [];
    let notWords = [];
    let words = ecuation.split(' AND ');
    words.map(word => {
        if (word.includes("NOT(")) {
            // notWords = word.split('NOT(');
            word.split('NOT(').map(wordInNot=>{
                if(wordInNot.includes(')')){
                    notWords.push(wordInNot);
                }else{
                    
                    andWords.push(wordInNot);
                }
            })
        } else {
            andWords.push(word);
        }
    });

    let query = 'and='
    andWords.map(word => {
        query = query + word.trim() + ','
    })

    query = query + '&not='
    notWords.map(word => {
        query = query + word.replace(')', '').trim() + ','
    })

    return query;

}
export default extractWordsOfEcuation;