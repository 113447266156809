import React,{useState} from 'react'
import Chart from "react-google-charts";

const DoubleBarHori = ({data,gtitle,htitle,vtitle}) => {
 
    return ( 
        <div className='doble-bar'>
            <Chart
                width={'900px'}
                height={'500px'}
                chartType="BarChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    title: '',
                    chartArea: { width: '60%' },
                    colors: ['#0073af','#004e77']
                }}
                // For tests
                rootProps={{ 'data-testid': '1' }}
                />

        </div>
     );
}
 
export default DoubleBarHori;
