import React from 'react'
import SaveFavouritesDropdown from '../../saveFavouritesDropdown/SaveFavouritesDropdown'
import './gplac.scss'

const GpLacTable = ({ results, viewDescription, id, goToResult }) => {
    return (
        <div className="GpLacMain">
             <div className="resultTable row">
                <div className="col-md-7 col-lg-7"><p className='firtsChild'>Nombre<img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p style={{ marginRight: '3rem' }}>Categoria<img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-1 col-lg-1"><p style={{ float: 'left' }}>Instituciones <img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
                <div className="col-md-2 col-lg-2"><p style={{ float: 'left', marginLeft: '4rem' }}>Ciudad <img src={require('../../../../../../../images/icons/arrowDirections/rowDown.png')} className='rowDown' alt="" /></p></div>
            </div>
            { (results[0].category !== 'empty' && results[0].category !== 'fail') &&
                results.map(r => {
                    console.log('r', r);
                    return (<div className="resultTableBody row">
                        <div className="col-md-2 col-lg-2">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 firstColumns pl-0">
                                    <div style={{ display: 'inline-flex', width: '100%' }}>
                                        <div className="categoryDiv">
                                            <img onClick={() => goToResult(r)} className="icon" src={require('../../../../../../../images/icons/filters/gp_lac.png').default} alt='' />
                                        </div>
                                        <div className="addRepository">
                                        <SaveFavouritesDropdown item={r}/>
                                        </div>
                                        <div className="imageToPreviewDiv">
                                            {r.thumbnail ?
                                                <img className="imageToPreview" src={r.thumbnail ? r.thumbnail : ''} />
                                                :
                                                <div style={{ paddingTop: '1.5rem' }}>
                                                    {/* <div className='imageToPreviewDivEmpty'>

                                                </div> */}
                                                    <p onClick={() => viewDescription(r)} style={{ color: 'var(--primaryBlueOne)', fontSize: '14px', cursor: 'pointer' }}>ver más</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 col-lg-10 threeLastColumns">
                            <div onClick={() => goToResult(r)} className="row">
                                <div className="title col-md-6 col-lg-6" >
                                    <p style={{ float: 'left' }} className="title">{Array.isArray(r.nombre) ? r.nombre[0].substring(0, 40) : r.nombre.substring(0, 40)}...</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.clasificacion && r.clasificacion[0].toString().substr(0, 10)}</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.instituciones && r.instituciones[0].toString().substr(0, 20)}</p>
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <p>{r.dep_city && r.dep_city[0].toString().substr(0, 20)}</p>
                                </div>
                            </div>
                        </div>
                        { (id === r.id) &&
                            <div className="view">
                                <p style={{ textAlign: 'left' }}>{Array.isArray(r.descripcion) ? r.descripcion[0].substr(0, 300) : r.descripcion.substr(0, 300)}...
                                    {/* <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => goToResult(r)}>ver más</span> */}
                                </p>
                            </div>
                        }

                    </div>)

                })
            }
        </div>
    )
}

export default GpLacTable
