import React, { useState } from 'react'
import Axios from '../../config/axios'

const MassiveRegister = () => {

    const [registerFile,SetRegisterFile] = useState(null);//state para los workspace de la BD
    const [usersTable,setUsersTable] = useState([]);//state para los workspace de la BD

    const loadFile = async() => {
        try {
            const formData = new FormData();
            formData.append('file',registerFile);
            var rta = await Axios.post('/signup/get/massiveregister',formData);
            setUsersTable(rta.data);
        } catch (error) {
            
        }
        
    }

    const sendFile = async() => {
        var register = await Axios.post('/signup/massiveregister',usersTable);
        if(register.data === 1){
            /*var rta = await Axios.post('/email/massive-register',usersTable);
            */
        }else{
        }
    }
    return ( 
        <>
            <div className='container my-5'>
                <div className="form-group row  mt-2">
                    <div className="col-sm-6">
                        <input name='file'  type="file" className="form-control" onChange={(e) => SetRegisterFile(e.target.files[0])}/>
                    </div>
                    <button onClick={loadFile} 
                        className="col-sm-3 btn btn-primary">
                        <h3>Cargar Documento</h3>
                    </button>
                </div>              
            </div>
            {usersTable.length > 0 &&
                <div className='w-100'>
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Nombres</th>
                            <th scope="col">Apellidos</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Email</th>
                            <th scope="col">password</th>
                        </tr>
                        </thead>
                        <tbody>
                        {usersTable.map((user,index) =>(
                                <tr>
                                    <th scope="row">{index}</th>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.email}</td>
                                    <td>{user.firstname.substring(0,3)+user.firstname.substring(0,3)+2021}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> 
                    <div className='w-100 d-flex justify-content-center'>
                        <button 
                            onClick={sendFile} 
                            className='mt-2 mb-4 btn btn-primary'
                            ><h3>Registrar usuarios</h3>
                        </button>
                    </div> 
                </div>                         
            }
                            

        </>
     );
}
 
export default MassiveRegister;