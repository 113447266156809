import React, { useEffect, useState } from 'react';
import { useMercadopago } from 'react-sdk-mercadopago';
import './mercadopago.scss'
import axios from 'axios';

const MercadoPago = () => {

    const [pagarOk, setPagarOk] = useState(false);

    // const mp = useMercadopago.v2('APP_USR-9c0bc8e7-67f7-4106-ad77-3801d617233a', {
    //     locale: 'es-CO'
    // });

    const mp = useMercadopago.v2('TEST-ce7e5287-aee5-4016-b4ea-0330c2fe6579', {
        locale: 'es-CO'
    });

    // useEffect(() => {
    //     if (mp) {
    //         createPreference();
    //     }
    // }, [mp]);

    const createPreference = async () => {

        const preference = {
            "items": [
                {
                    "title": "Producto x",
                    "description": "muy bueno",
                    "quantity": 1,
                    "unit_price": 5000,
                    "back_urls": {"success": "https://lantiasas.com/mercadoPago"}
                }
            ]
        }


        let response = await axios.post('https://agile-ridge-65866.herokuapp.com/preference', preference)
        console.log('response', response);

        if (response.data.hasOwnProperty('idPreference')) {
            setPagarOk(true)
            // Inicializa el checkout
            mp.checkout({
                preference: {
                    id: response.data.idPreference,
                },
                render: {
                    container: ".cho-container", // Indica el nombre de la clase donde se mostrará el botón de pago
                    label: "Pagar", // Cambia el texto del botón de pago (opcional)
                },
            });
        }else{
            setPagarOk(false)
        }
    }

    return <div className='mercadopagoMain'>
        MercadoPago
        <div onClick={() => createPreference()} className='plan'>
            escojer plan 1
        </div>
        {pagarOk &&
            <div class="cho-container" />
        }
    </div>;
};

export default MercadoPago;
