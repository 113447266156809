import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { setParamsOfSearch, typeOfSearch } from '../../../actions/homeAction';
import { ASSISTED_SEARCH } from '../../../globalConstants/typeOfSearch'
import volver from '../../../images/icons/Iconos-23.png';
import blueFolder from '../../../images/icons/modules/homeApp/blue-folder.png';
import clienteAxios from '../../config/axiosBigData';
import { registerSelected } from '../../../actions/homeAction';
import { useDispatch } from 'react-redux';
import processData from '../../../hooks/useProccessData';
import extractWordsOfEcuation from '../../../helpers/extractWordsOfEcuation'

import './myInformation.scss'


const MyInformation = (props) => {

    //redux
    let colections = useSelector(state => state.mark.colections);
    const marks = useSelector(state => state.mark.marks);

    //states
    const [collections, setCollections] = useState(colections)
    const [stylesLeft, setstylesLeft] = useState({})
    const [stylesRight, setstylesRight] = useState({})
    const [stylesMiddle, setstylesMiddle] = useState({})

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.computedMatch.params.id !== undefined) {
            try {
                const colectionFound = collections.find(colection => props.computedMatch.params.id == colection.id);

                if (colectionFound !== undefined) {
                    if (colectionFound.id !== collections[1].id) {
                        let newArrayOfColections = collections.filter(colection => colection.id !== colectionFound.id)
                        let provisional = newArrayOfColections[1];
                        newArrayOfColections[1] = colectionFound;
                        setCollections([...newArrayOfColections, provisional])
                    }

                }
            } catch (error) {
            }

        }

    }, [props.computedMatch.params.id])

    const resultSelet = (item) => {

        if (item.hasOwnProperty('id_mongo')) {

            let source = '';
            let category = '';
            switch (item.tag) {
                case 'patents':
                    source = 'patente'
                    category = 'patent'
                    break;

                case 'video':
                    source = 'video'
                    category = 'video'
                    break;

                case 'events':
                    source = 'evento'
                    category = 'event'
                    break;

                case 'news':
                    source = 'noticia'
                    category = 'new'
                    break;

                case 'Startups':
                    source = 'startup'
                    category = 'startup'
                    break;

                case 'convocations':
                    source = 'convocatorias'
                    category = 'convocatoria'
                    break;

                case 'articles':
                    source = 'articulo'
                    category = 'article'
                    break;

                default:
                    break;
            }

            if (item.tag === 'ecuations') {
                let query = extractWordsOfEcuation(item.title);
                dispatch(setParamsOfSearch(query));
                dispatch(typeOfSearch(ASSISTED_SEARCH));
                history.push('/homeApp')
                return;
            }

            getMyFavoriteResult(source, item.id_mongo, category);

            return;
        }
    }



    const getMyFavoriteResult = async (source, id, category) => {
        try {
            const response = await clienteAxios.get(`search_id?search=${source}&id=${id}`);
            response.data.item.category = category;
            dispatch(registerSelected(processData([response.data.item])[0]));
            history.push('/resultSelected')

        } catch (error) {
        }
    }


    const left = () => {

        setstylesLeft({ transform: 'translate(100px)', width: '20rem', height: '30rem', position: 'absolute', zIndex: '-10' });
        setstylesRight({ transform: 'translate(-200px)', width: '20rem', height: '30rem', position: 'absolute', zIndex: '-10' });
        setstylesMiddle({ transform: 'translate(-50px)', width: '40rem', height: '70rem' });

        let arraytmp = [];
        for (var i = 0; i < collections.length; i++) {
            if (i === 0) {
                arraytmp[collections.length - 1] = collections[i];
            } else {
                arraytmp[i - 1] = collections[i];
            }

        }

        setCollections(arraytmp);
        setTimeout(() => { setstylesLeft({}); setstylesMiddle({}); setstylesRight({}); }, 300);

    }

    const right = () => {

        setstylesLeft({ transform: 'translate(100px)', width: '20rem', height: '30rem', position: 'absolute', zIndex: '-10' });
        setstylesRight({ transform: 'translate(-200px)', width: '20rem', height: '30rem', position: 'absolute', zIndex: '-10' });
        setstylesMiddle({ transform: 'translate(-50px)', width: '40rem', height: '70rem' });
        let arraytmp = [];
        for (var i = 0; i < collections.length; i++) {

            if (i === collections.length - 1) {
                arraytmp[0] = collections[i];
            } else {
                arraytmp[i + 1] = collections[i];
            }

        }

        setCollections(arraytmp);
        setTimeout(() => { setstylesLeft({}); setstylesMiddle({}); setstylesRight({}); }, 300);
    }

    const comeBack = () => {
        history.push('/homeApp')
    }


    const styles = {

    }

    return (
        <div className='my-information'>
            <div onClick={comeBack} className="comeBack">
                <div style={{ display: 'inline-flex' }}><img style={{ marginRight: '10px' }} src={volver} alt="" width='30' height='30' /><h1>Volver</h1></div>
            </div>

            <div className='row categoryBox'>
                <div className='ColorTitle'>
                    <div className='row'>
                        <div style={{ border: `2px solid var(--primaryBlueFour)` }} className='image'>
                            <img src={blueFolder} alt="" width='25' height='25' />
                        </div>
                        <div style={{ backgroundColor: 'var(--primaryBlueFour)' }} className='title'>
                            <h2>Mi información</h2>
                        </div>
                    </div>
                </div>
                <div className="lineTitle">

                </div>
            </div>
            <div style={{ display: 'inline-flex', width: '100%', marginTop: '5rem', marginLeft: '1%' }}>
                <i style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '2rem', cursor: 'pointer' }}
                    onClick={() => right()}
                    className="fas fa-angle-left fa-3x arrowIcon"></i>
                <div className="divLeft">
                    <div style={stylesLeft} className="besideLeft">
                        {collections !== null &&
                            <div style={{ marginTop: '10rem' }}>
                                <p>{collections[0].name}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className="divMiddle">
                    <div style={stylesMiddle} className="middle">
                        {collections !== null &&
                            <div>
                                <div style={{ display: 'inline-flex' }}>
                                    <p style={{ color: 'var(--primaryBlueThree)', marginRight: '5px' }}><strong>Nombre:</strong></p>
                                    <p style={{ color: 'var(--primaryBlueThree)' }}>{collections[1] ? collections[1].name : collections[0].name}</p>
                                </div>
                                <br />
                                <div style={{ display: 'inline-flex', marginTop: '5px' }}>
                                    <label style={{ color: 'var(--primaryGreyFour)', fontSize: '15px', marginBottom: '0', marginRight: '5px' }}>Creado:</label>
                                    <label style={{ color: 'var(--primaryGreyFour)', fontSize: '14px', marginBottom: '0' }}>{collections[1] ? collections[1].dateCreated : collections[0].dateCreated}</label>
                                </div>
                                <br />
                                <div style={{ display: 'inline-flex' }}>
                                    <label style={{ color: 'var(--primaryGreyFour)', fontSize: '15px', marginBottom: '0', marginRight: '5px' }}>Contenido:</label>
                                    <label style={{ color: 'var(--primaryGreyFour)', fontSize: '14px', marginBottom: '0' }}>{collections[1] ? collections[1].total : collections[0].total}</label>
                                </div>
                                <br />
                                <br />
                                <div>
                                    <div style={{ borderBottom: '1px solid var(--primaryGreyFour)' }} className="row">
                                        <div className="col-2 col-md-2 col-sm-2 col-lg-2">
                                            <p><strong>Fuente</strong></p>
                                        </div>
                                        <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                                            <p><strong>Título</strong></p>
                                        </div>
                                        <div className="col-4 col-md-4 col-sm-4 col-lg-4">
                                            <p><strong>Agregado</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ overflowY: 'scroll', height: '37rem' }}>
                                    {

                                        marks.filter(mark => mark.colection_id === (collections[1] ? collections[1].id : collections[0].id)).map(item => {
                                            return (
                                                <div onClick={() => resultSelet(item)} style={{ borderBottom: '1px solid var(--primaryGreyFour)', paddingTop: '1rem', cursor: 'pointer' }} className="row">
                                                    <div className="col-2 col-md-2 col-sm-2 col-lg-2">
                                                        <img src={require(`../../../images/icons/colorModulesIcons/${item.tag}.png`)} width='25' height='25' alt="" />
                                                    </div>
                                      <div className="col-6 col-md-6 col-sm-6 col-lg-6">
                                                        <p style={{ color: 'var(--primaryBlueThree)' }}>
                                                            <strong>
                                                                {Array.isArray(item.title) ? item.title[0].substr(0, 40) : item.title.substr(0, 40)}...
                                                            </strong>
                                                        </p>
                                                    </div>
                                                    <div className="col-4 col-md-4 col-sm-4 col-lg-4">
                                                        <p>{(typeof item.addDate === 'string') ?
                                                            item.addDate.substr(0, 10) :
                                                            item.addDate.toISOString().substr(0, 10)}
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }
                                </div>

                            </div>
                        }
                    </div>
                </div>

                <div className="divRight">
                    <div style={stylesRight} className="besideRight">
                        {collections !== null &&
                            <div style={{ marginTop: '10rem' }}>
                                <p>{collections[2] && collections[2].name}</p>
                            </div>
                        }
                    </div>
                </div>

                <i style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '2rem', cursor: 'pointer' }}
                    onClick={() => left()}
                    className="fas fa-angle-right fa-3x arrowIcon"></i>
            </div>
        </div>
    );
}

export default MyInformation;