import React from 'react'
import ColorTitle from '../../../ui/styles/ColorTitle'
import HistoryOne from './HistoryOne'

const History = ({ lastWords }) => {
    return (
        <>
            <div className="lantia-square smallHeight text-left" style={{ height: '300px', overflowY: lastWords.length > 4 ? 'auto' : 'hidden' }}>
                <p className='homeBigLink'><strong>Últimas búsquedas</strong></p>
                {(lastWords !== null && lastWords.length > 0) ?
                    <div className="historySection">
                        {lastWords.map(word => {
                            return (
                                <HistoryOne
                                    word={word}
                                />
                            )
                        })}
                    </div> :
                    <div>Aun no tienes busquedas</div>
                }
            </div>
        </>
    );
}

export default History;