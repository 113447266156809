import React, { useEffect, useRef, useState, Fragment } from "react";
import * as d3 from "d3v5";
import './bar.css';
import ReactTooltip from "react-tooltip";

const format = d3.format(".0f");

const XAxis = ({ top, bottom, left, right, height, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current).call(d3.axisBottom(scale));
  });

  return (
    <g
      className="axis x"
      ref={axis}
      transform={`translate(${left}, ${height - bottom})`}
    />
  );
};

const YAxis = ({ top, bottom, left, right, scale }) => {
  const axis = useRef(null);

  useEffect(() => {
    d3.select(axis.current).call(d3.axisLeft(scale));
  });

  return (
    <g className="axis y" ref={axis} transform={`translate(${left}, ${top})`} />
  );
};

const Rect = ({ data, x, y, height, top, bottom, fill, onMouseEnter, onMouseLeave, onClick }) => {

  return (
    <g transform={`translate(${x(data.date)}, ${y(data.value)})`}>
      <rect
        style={{ cursor: 'pointer' }}
        className="column"
        height={height - bottom - top - y(data.value)}
        fill={fill}
        width={15}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
    </g>
  );
};

const Bar = props => {

  const [moduleColorOne] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primaryBlueThree'));
  const [moduleColorTwo] = useState(getComputedStyle(document.documentElement).getPropertyValue('--primaryBlueFour'));

  const dataset = [
    [...Object.values(props.application)],
    [...Object.values(props.publication)]
  ]

  if (dataset[0].length === 250) {
    dataset[0] = dataset[0].slice(220, 230);
    dataset[1] = dataset[1].slice(220, 230);
  }


  let dataApp = dataset[0].map((item, index) => {
    let obj = {}
    obj['value'] = item;
    obj['index'] = index;
    obj['date'] = Object.keys(props.application)[index];
    return obj;
  }, {})

  let dataPub = dataset[1].map((item, index) => {
    let obj = {}
    obj['value'] = item;
    obj['index'] = index;
    obj['date'] = Object.keys(props.application)[index];
    return obj;
  }, {})


  const [sort, setSort] = useState(false);

  const data = sort
    ? [...dataApp].sort((a, b) => b.value - a.value)
    : [...dataApp];


  const [tooltipContent, setTooltipContent] = useState("");


  const x = d3
    .scaleBand()
    .range([0, props.width - props.left - props.right])
    .domain(data.map(d => d.date))
    .padding(0.1);


  const y = d3
    .scaleLinear()
    .range([props.height - props.top - props.bottom, 0])
    .domain([0, d3.max(dataPub, d => d.value)]);


  return (
    <Fragment>
      <div className="">
        <h5>{props.title}</h5>
        <div className="d-flex justify-content-center">
          {/*} <button className="bar-button"
          onClick={() => {
            setSort(!sort);
          }}
        >
          Ordenar
        </button>{*/}
          <svg data-tip="" width={props.width} height={props.height}>
            <XAxis
              scale={x}
              top={props.top}
              bottom={props.bottom}
              left={props.left}
              right={props.right}
              height={props.height}
            />
            <YAxis
              scale={y}
              top={props.top}
              bottom={props.bottom}
              left={props.left}
              right={props.right}
            />
            <g transform={`translate(90, ${props.top})`}>
              {data.map((d, i) => (
                <Rect
                  key={i}
                  data={d}
                  x={x}
                  y={y}
                  top={props.top}
                  bottom={props.bottom}
                  height={props.height}
                  fill={moduleColorOne}
                  onMouseEnter={() => { setTooltipContent(d.value) }}
                  onMouseLeave={() => { setTooltipContent('') }}
                  onClick={() => { props.d3BarClick(d.date) }}
                />
              ))}
            </g>
            <g transform={`translate(${props.left + 18}, ${props.top})`}>
              {dataPub.map((d, i) => (
                <Rect
                  key={i}
                  data={d}
                  x={x}
                  y={y}
                  top={props.top}
                  bottom={props.bottom}
                  height={props.height}
                  fill={moduleColorTwo}
                  onMouseEnter={() => { setTooltipContent(d.value) }}
                  onMouseLeave={() => { setTooltipContent('') }}
                  onClick={() => { props.d3BarClick(d.date) }}
                />
              ))}
            </g>
          </svg>
          <ReactTooltip>{tooltipContent}</ReactTooltip>
        </div>
        <div className="Bar">
          <div className="d-flex justify-content-center">
            <label>{props.title1}</label>
            <div style={{ "background": moduleColorOne, "display": "block", "width": "20px", "height": "15px" }}></div>
            <label>{props.title2}</label>
            <div style={{ "background": moduleColorTwo, "display": "block", "width": "20px", "height": "15px" }}></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Bar;