import React from 'react'
import GeoChart from '../../../../../../graphics/d3/GeoGraphic/GeoChart'
const Maps = ({ graphic, clickImage }) => {
    console.log('graphic.data', graphic.data);

    for (const property in graphic.data) {
        console.log(`${property}: ${graphic.data[property]}`);
        if (graphic.data[property] == 0) {
            delete graphic.data[property]
        }
    }
    console.log('dataFiltered', graphic.data);
    return (
        <>
            {graphic.type === 'maps' &&
                <GeoChart
                    countries={graphic.data}
                    d3Click={clickImage}
                />
            }
        </>
    );
}

export default Maps;