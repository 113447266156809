import React, { useEffect, useState } from 'react';
import * as d3 from 'd3v5';
import './sgraphic.css';
import ReactTooltip from "react-tooltip";

class Axis extends React.Component {
  componentDidMount() {
    this.renderAxis();
  }

  renderAxis() {
    d3.select(this.axisElement).call(this.props.axis);
  }

  render() {
    return (
      <g
        className={`Axis Axis-${this.props.orient}`}
        ref={el => {
          this.axisElement = el;
        }}
        transform={this.props.translate}
      />
    );
  }
}

const Line = ({ xScale, yScale, data, lineColor, pointColor, onMouseEnter, onMouseLeave, type  }) => {

  const line = d3
    .line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.value))
    .curve(d3.curveMonotoneX);

  //const dateParser = d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ");

  data.forEach(d => {
    return d.date;
  });

  const plotLine = line(data);

  return (
    <React.Fragment>
      <path
        className="line"
        fill="none"
        stroke={lineColor}
        strokeWidth="2"
        d={plotLine}
      />
      {data.map((datum) => {
        return (
            <circle
              cx={xScale(datum.date)}
              cy={yScale(datum.value)}
              r="5"
              fill={pointColor}
              stroke="white"
              strokeWidth="3"
              onMouseEnter={() => onMouseEnter(`${type} ${datum.value}`)}
              onMouseLeave={onMouseLeave}
          />
        );
      })}
    </React.Fragment>
  );
};


const TestChart = ({data}) => {

  const [tooltipContent, setTooltipContent] = useState("");
  const width = 520;
  const height = 600;

  const margin = {
    top: 20,
    right: 50,
    bottom: 40,
    left: 40
  };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const trendScoreHistoryData = data.trendScores.map(datum => {
    return {
      date: datum.date,
      value: datum.value
    };
  });
  const articlesData = data.publishedArticles;
  const mentionsData = data.mentions;
  const filteredTrendScores = data.filteredTrendScores.map(datum => {
    return {
      date: datum.date,
      value: datum.value
    };
  });

  //const dateParser = d3.timeParse("%Y-%m-%dT%H:%M:%S.%LZ");

  const xRange = [2000, 2023];
  const yRange0 = [0, 600000];
  const yRange1 = [0, d3.max(mentionsData, d => d.value)];

  const xScale = d3
    .scaleLinear()
    .domain(xRange)
    .range([0, innerWidth]);
  // .ticks(d3.timeWeek, 1)
  // .tickFormat(d3.timeFormat("%d/%m/%d"));

  const yScale0 = d3
    .scaleLinear()
    .domain(yRange0)
    .range([innerHeight, 0]);

  const yScale1 = d3
    .scaleLinear()
    .domain(yRange1)
    .range([innerHeight, 0]);

  const xAxis = d3
    .axisBottom(xScale)
    .ticks(10);

  const yAxisLeft = d3.axisLeft(yScale0);
  const yAxisRight = d3.axisRight(yScale1);

  let svg = d3.select('#idS').append('svg')
  svg
  .on('mouseover', function (d) { })
  .on('mouseout',function () {return setTooltipContent('')});

  return (
    <>
      <svg id='idS' data-tip='' width={width} height={height} >
        <g transform={`translate(${margin.right}, ${margin.top})`}>
          <Axis
            orient="x"
            axis={xAxis}
            translate={`translate(0, ${innerHeight})`}
          />
          <Axis orient="y" axis={yAxisLeft} translate={`translate(0, 0)`} />
          <Axis
            orient="y2"
            axis={yAxisRight}
            translate={`translate(${innerWidth}, 0)`}
          />
          <line
            x1={xScale(xRange[0])}
            y1={yScale0(75)}
            x2={xScale(xRange[1])}
            y2={yScale0(75)}
            stroke="green"
            strokeDasharray="5,5"
          />
          <Line
            xScale={xScale}
            yScale={yScale0}
            data={trendScoreHistoryData}
            lineColor="DARKSALMON"
            pointColor="DARKSALMON"
            onMouseEnter={setTooltipContent}
            onMouseLeave={() => {setTooltipContent('')}}
            type='Ajustado'
          />
          <Line
            xScale={xScale}
            yScale={yScale0}
            data={filteredTrendScores}
            lineColor="TOMATO"
            pointColor="TOMATO"
            onMouseEnter={setTooltipContent}
            onMouseLeave={() => {setTooltipContent('')}}
            type='Ajustado derivada'
          />
          <Line
            xScale={xScale}
            yScale={yScale1}
            data={mentionsData}
            lineColor="none"
            pointColor="LIGHTSKYBLUE"
            onMouseEnter={setTooltipContent}
            onMouseLeave={() => {setTooltipContent('')}}
            type='Puntos de inflección'
          />
          <Line
            xScale={xScale}
            yScale={yScale0}
            data={articlesData}
            lineColor="none"
            pointColor="MEDIUMSLATEBLUE"
            onMouseEnter={setTooltipContent}
            onMouseLeave={() => {setTooltipContent('')}}
            type='Ajustado derivada dos'
          />
        </g>
      </svg>
      <ReactTooltip>{tooltipContent}</ReactTooltip>
    </>
  )
}
 
const SGraphic = ({title, year}) => {

  const [dataApp, setDataApp] = useState('')
  const [dataInflection, setDataInflection] = useState('')
  useEffect(() => {
    if(year != null){
      setDataApp(year.data_for_year);
      setDataInflection(year.point_inflection);
    }
}, [year]);

  const obj = Object.entries(dataApp)
  const acumulado = []
  for (const iterator of obj) {
    acumulado.push({'date': +iterator[0], 'value': Object.entries(iterator[1])[0][1]})
  }

  const ajustado = []
  for (const iterator of obj) {
    ajustado.push({'date': +iterator[0], 'value': Object.entries(iterator[1])[1][1]})
  }

  const ajustado_dev = []
  for (const iterator of obj) {
    ajustado_dev.push({'date': +iterator[0], 'value': Object.entries(iterator[1])[2][1]})
  }

  const ajustado_dev2 = []
  for (const iterator of obj) {
    ajustado_dev2.push({'date': +iterator[0], 'value': Object.entries(iterator[1])[3][1]})
  }

  const point_inflection = []
  for (const property in dataInflection) {
    point_inflection.push({'date': +property, 'value': dataInflection[property]})
  }

  const margin = {top: 50, right: 50, bottom: 50, left: 50}
  const width = 900 - margin.left - margin.right;
  const height = 400 - margin.top - margin.bottom;

  const keyword = "Jackfruit";


  const trendData = {
    trendScores: [...ajustado],
    sentimentValues: [...acumulado],
    mentions: [...point_inflection],
    filteredTrendScores: [...ajustado_dev],
    publishedArticles: [...ajustado_dev2],
  };

  return (
    <div className='card'>
      <h5>{title}</h5>
      <div className="sgraphic">
        <TestChart data={trendData} />
      </div>
    </div>
  );
};

export default SGraphic;