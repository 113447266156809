
export const types = {

    uiOpenModal: '[ui] Open modal',
    uiCloseModal: '[ui] Close modal',


    eventSetActive: '[event] Set Active',
    eventAddNew: '[event] Add new',
    eventClearActiveEvent: '[event] Clear active event',
    eventUpdated: '[event] Event updated',
    eventDeleted: '[event] Event deleted',
    getEventsData: '[event] Event data',
    getEventsPenging: '[event] Event Pending',
    clearEventPending: '[event] clearEventPending',
    eventsAcceptedOrRejected: '[event] eventsAcceptedOrRejected',
    clearEventsAcceptedOrRejected:'[event] clearEventsAcceptedOrRejected',
    goSchedule: '[event] goSchedule',
    editE: '[editE] editE'
}