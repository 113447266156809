import React, { useEffect, useState } from 'react';
import './searchBox.scss';
import { setSearchAction } from '../../../actions/homeAction'
import AssistedSearch from './assisted/index.jsx'
import AdvancedSearch from './advanced/index.jsx'
import buscador1 from '../../../images/searchBox/Buscador-01.png'
import buscador2 from '../../../images/searchBox/Buscador-02.png'
import ReactTooltip from "react-tooltip";
import buscador3 from '../../../images/searchBox/Buscador-03.png'
import { typeOfSearch, storeLastSearh, setEquation } from '../../../actions/homeAction';
import { NORMAL_SEARCH } from '../../../globalConstants/typeOfSearch'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { autoType } from 'd3';

const SearchBox = () => {

    //redux
    const dispatch = useDispatch();
    const searchStore = useSelector(state => state.home.search);

    //states
    const [search, setSearch] = useState(searchStore);
    const [assistedSearch, setAssistedSearch] = useState(false);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [tooltip, setTooltip] = useState("");

    useEffect(() => {
        setSearch(searchStore);
    }, [searchStore])

    const keyPress = (e) => {
        if (e.key == 'Enter') {
            dispatch(typeOfSearch(NORMAL_SEARCH));
            toSearch(search)
        }
    }

    const toSearch = async () => {
        dispatch(typeOfSearch(NORMAL_SEARCH));
        dispatch(setSearchAction(search));
        dispatch(setEquation(''))
    }

    const assistedModal = () => {
        setAssistedSearch(!assistedSearch);
        setAdvancedSearch(false);
    }

    const advancedModal = () => {
        setAdvancedSearch(!advancedSearch);
        setAssistedSearch(false);
    }

    return (
        <div className="mainSearchBox">
            <div className="row">
                <div className='' style={{ marginLeft: 'auto', marginRight: 'auto', width: '73%', height: '3.3rem' }}>
                    <div style={{ display: "inline-flex", width: '100%' }}>
                        <div className="rightSide"></div>
                        <div className="searchBar">
                            <input className="searchInput" type="text" id="fname" name="fname" placeholder="Escribe lo que quieras buscar"
                                onChange={(e) => { setSearch(e.target.value); dispatch(setEquation(e.target.value)) }}
                                onKeyPress={keyPress}
                                value={search} />
                        </div>
                        <div onMouseOver={() => setTooltip('Busqueda Básica')} data-tip data-for="searchTooltip" onClick={toSearch} className="leftSide"><span className="searchButton" ><img className="lupa" src={buscador1} /></span></div>
                        <ReactTooltip id="searchTooltip" place="top" effect="solid">
                            {tooltip}
                        </ReactTooltip>
                        {/* <div className="leftSideTwo"
                            onClick={assistedModal}
                        >
                            <span className="searchButton" ><img className="lupa" src={buscador2} /></span>
                        </div> */}
                        <div
                        onMouseOver={() => setTooltip('Busqueda Avanzada')} data-tip data-for="searchTooltip"
                            className="leftSideThree"
                            onClick={advancedModal}
                        >
                            <span className="searchButton" ><img className="lupa" src={buscador3} /></span>
                        </div>
                        <ReactTooltip id="searchTooltip" place="top" effect="solid">
                            {tooltip}
                        </ReactTooltip>
                    </div>
                </div>
                <div className='' style={{ marginLeft: 'auto', marginRight: 'auto', width: '70%', height: '13rem' }}>
                    {assistedSearch &&
                        <AssistedSearch />
                    }
                    {advancedSearch &&
                        <AdvancedSearch />
                    }
                </div>
            </div>

            {/*}<div className="filterBox">
                <div className="filterHiden" />
                    <div className="dropdown">
                        {barVisible &&
                            <div id="myDropdown" className="dropdown-content">
                                <a href="#home">Home</a>
                                <a href="#about">About</a>
                                <a href="#contact">Contact</a>
                            </div>
                        }
                    </div>
            </div>{*/}
        </div>
    )
}
export default SearchBox;
