import React,{ useRef,useEffect } from 'react'
import PatentItem from './PatentItem/PatentItem.jsx'
import PatentsGraphics from './PatentsGraphic.jsx'
import SearchBox from './SearchBox/SearchBox.jsx'
import NewSearchBox from './SearchBox/NewSearchBox.jsx'
import Filters from './Filters/Filters.jsx'
import AssistedSearchBox from './AssistedSearchBox/AssistedSearchBox.jsx'
import AdvancedSearchBox from './AdvancedSearchBox/AdvancedSearchBox.jsx'
import { useHistory } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getPatentsAction } from '../../../actions/patentAction'
import {expertConnect} from '../../../actions/expertsAction';


const PatentsApp = () => {
    //ref para prevenir que se renderice dos veces la primera vez, ya que el filtro esta vacio
    const didMountRef = useRef(false);

    //redux
    const dispatch = useDispatch();
    const patents = useSelector(state => state.patent.patents);//15 patentes traidas del endpoint
    const results = useSelector(state => state.patent.results);//total de resultados por busqueda del usuario
    const page = useSelector(state => state.patent.page); //pagina de las 15 patentes traidas
    const search = useSelector(state => state.patent.search);//busqueda del usuario, si esta vacia se toma *
    const filter = useSelector(state => state.patent.filterEndpoint);//busca enpoint de filtro escogidos del usuario
    const advancedSearch = useSelector(state => state.patent.advancedSearch);//variable para hacer o no visible busqueda avanzada
    
    //si el buscador queda en blanco hace llamado a la api
    useEffect(() => {
        if(search.trim() === '' || search.trim() === '*'){
           getPatents(0);
        }
    }, [search])// eslint-disable-line react-hooks/exhaustive-deps

    //renderiza si hay nuevas patentes por algun filtro
    useEffect(() => {
        if (didMountRef.current){
            getPatents(0);
        }else{
            didMountRef.current = true;
        } 
    }, [filter])// eslint-disable-line react-hooks/exhaustive-deps

    //hace el llamado de traer patentes al endpoint
    const getPatents = async(pageSended) => {
        await dispatch(getPatentsAction(pageSended));        
    }
    
    return ( 
        <>  
            {/*<NewSearchBox />*/}
            <div className="p-3">
                {/**Paginacion de las patentes */}
                <div className="row">
                    <h6>&nbsp;&nbsp;{`--Página ${page + 1} de ${new Intl.NumberFormat("de-DE").format(Math.ceil(results / 15))}--`}</h6> 
                    <h6 onClick={() => getPatents(page + 1)}>Siguiente-</h6>               
                    {page > 0 ? <h6 onClick={() => getPatents(page - 1)}>Anterior</h6> : ''}               
                </div>
                <SearchBox />  
                {/**componente de busqueda avanzada o guiada */}
                {advancedSearch === 'assisted' ? <AssistedSearchBox /> : advancedSearch === 'advanced' ? <AdvancedSearchBox /> : ''}
                <hr />
                <div className="row w-100">
                    <div className="col-3">
                        <Filters />
                    </div>
                    <div className="col-9">
                        {
                        patents !== undefined && patents.map(patent => (
                            <PatentItem
                                patent={patent}
                                key={patent.id}
                            />
                        ))}
                        <PatentsGraphics />
                    </div>
                    
                </div>
            </div>
        </>
     );
}
 
export default PatentsApp;