import React,{useState} from 'react'
import './colorTitle.scss'

const ColorTitle = ({name,color,icon, width, height}) => {

    const [moduleColor] = useState(getComputedStyle(document.documentElement).getPropertyValue(color));

    return ( 
        <>
            <div className='ColorTitle'>
                <div className='row'>
                    <div style={{border:`2px solid ${moduleColor}`, width:'48px'}} className='image'>
                        <img src={require(`../../../images/icons/colorModulesIcons/${icon}`).default} alt="" width={width} height={height}/>
                    </div>
                    <div style={{backgroundColor:moduleColor}} className='title'>
                        <div className='newTitleColor'>{name.toUpperCase()}</div> 
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default ColorTitle;