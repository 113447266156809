import React,{useState} from 'react'
import ColorTitle from '../../../ui/styles/ColorTitle'
import ItemsCarousel from 'react-items-carousel';
import back from '../../../../images/icons/arrowDirections/back.png';
import ahead from '../../../../images/icons/arrowDirections/ahead.png';
import Colection from './colection/Colection'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


const MyInformation = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    //redux
    const colections = useSelector(state => state.mark.colections);

    return ( 
        <>  
            <Link className="recover" to={'/myInformation'}>
                <ColorTitle 
                    color={'--primaryBlueFour'}
                    icon={'blueFolder.png'}
                    name={'Mi información'}
                    width={20}
                    height={15}
                />
            </Link>
            <div className="lantia-square smallHeight d-flex align-items-center">
                <div className='col-12'>
                    { (colections !== undefined && colections.length > 0) ?
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={5}
                            gutter={20}
                            leftChevron={<i className="fas fa-angle-left fa-3x arrowIcon"></i>}
                            rightChevron={<i className="fas fa-angle-right fa-3x arrowIcon"></i>}
                            outsideChevron
                            chevronWidth={40}
                            infiniteLoop={true}
                        >
                        
                            {colections.map(colection => (
                                <Colection 
                                    key={colection.id}
                                    colection={colection}
                                />
                            ))}
                        
                           
                        </ItemsCarousel>
                        :
                        <div>
                            <p>En esta zona puedes visualizar las colecciones de la información que guardas. 
                                Para crear una colección ve a la barra de la derecha y añade una nueva.</p>
                        </div>
                    }
                </div>                
            </div>
        </>
     );
}
 
export default MyInformation;